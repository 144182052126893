import React, { useCallback, useEffect, useState } from 'react';
import { DropZone, LegacyStack, Modal, Thumbnail, Text, ProgressBar, Toast, Loading, BlockStack } from '@shopify/polaris';
import { SaveIcon, NoteIcon, XIcon } from '@shopify/polaris-icons';
import axios from 'axios';
import { useImportCustomer } from 'queries/customer.query';
const REACT_APP_AJAX_UPLOAD_URL = process.env.REACT_APP_AJAX_UPLOAD_URL;
export default function CustomerUploadModal({
  onClose
}) {
  const {
    mutate: importCustomer,
    isPending: importLoading,
    data: importSuccess
  } = useImportCustomer();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(true);
  }, []);
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(0);
  const [internalNotification, setInternalNotification] = useState(null);
  const clearError = useCallback(() => {
    setInternalNotification(null);
  }, []);

  /**
   * return to main progress file, callback
   */
  function uploadSuccess(mediaRep: any) {
    let {
      media_id
    } = mediaRep;
    // overwriteOption
    importCustomer({
      media_id: media_id
    });
  }
  function uploadFail(rep: any) {
    setInternalNotification('Upload file bị lỗi, vui lòng thử lại.');
  }
  const onCloseCallback = () => {
    clearError();
    onClose();
  };

  /**
   * Step2. save to server
   *
   * @param {File} file
   */

  async function saveToServer(file: File) {
    const fd = new FormData();
    fd.append('files', file);
    try {
      let result = await axios.post(REACT_APP_AJAX_UPLOAD_URL, fd, {
        onUploadProgress: (progressEvent: any) => {
          let progress_percent = Math.floor(progressEvent.loaded / progressEvent.total) * 100;
          setLoading(progress_percent);
        }
      });
      uploadSuccess(result.data);
      setLoading(0);
    } catch (_) {
      setLoading(0);
      uploadFail(_);
    }
  }
  const startUpload = useCallback(() => {
    if (!files) return;
    for (let _file of files) {
      saveToServer(_file);
    }
  }, [files]);
  const handleDropZoneDrop = useCallback((_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => setFiles(() => acceptedFiles), []);
  const uploadedFiles = files.length > 0 && <div style={{
    padding: '0'
  }}>
      <LegacyStack vertical>
        {files.map((file, index) => <LegacyStack alignment="center" key={index}>
            <Thumbnail size="small" alt={file.name} source={NoteIcon} />
            <div>
              {file.name}{' '}
              <Text variant="bodySm" as="p">
                {file.size} bytes
              </Text>
            </div>
            {loading > 0 ? <ProgressBar progress={loading} size="small" tone="success" /> : null}
          </LegacyStack>)}
      </LegacyStack>
    </div>;
  const toastMarkup = internalNotification ? <Toast content={internalNotification} error onDismiss={clearError} /> : null;
  return <>
      {toastMarkup}
      {importLoading ? <Loading /> : null}
      <Modal open={open} onClose={() => onCloseCallback()} title="Tải lên khách hàng qua file CSV" primaryAction={{
      content: 'Bắt đầu',
      loading: loading > 0,
      icon: SaveIcon,
      onAction: () => startUpload()
    }} secondaryActions={[{
      content: 'Hủy',
      icon: XIcon,
      onAction: () => onCloseCallback()
    }]}>
        <Modal.Section>
          <LegacyStack vertical>
            <DropZone allowMultiple={false} accept=".csv" errorOverlayText="File type must be .csv" type="file" onDrop={handleDropZoneDrop}>
              <DropZone.FileUpload />
            </DropZone>

            {uploadedFiles}

            <Text as="p">* Hành động này không ghi đè dữ liệu cũ.</Text>
            {importSuccess && <BlockStack gap="400">
                <Text as="p">Kết quả: Tổng {importSuccess.total} khách hàng được nhập.</Text>
              </BlockStack>}
          </LegacyStack>
        </Modal.Section>
      </Modal>
    </>;
}