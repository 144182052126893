import { FormLayout, Modal, TextField, Form, Select, Toast, RadioButton, Grid } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import helpers from 'helpers/index';
import { useField, useForm } from '@shopify/react-form';
import { allowed_transaction_category } from 'interface/transaction.model';
import __ from 'languages/index';
import QuickSearchUser from 'components/quickSearchUser';
import QuickSearchWallet from 'components/quickSearchWallet';
import { useCreateTransaction } from 'queries/transaction.query';
import { useNotification } from 'context/NotificationContext';

/**
 *   Create upload Modal for Notification
 */

export default function TransactionNewModal({
  show,
  onClose,
  default_money_quantity,
  default_customer_id,
  order_id
}: {
  show: boolean;
  onClose: Function;
  /** Default money quantity */
  default_money_quantity?: number;
  default_customer_id?: string;
  order_id?: string;
}) {
  const {
    mutateAsync: createEntity,
    isSuccess: updateSuccess
  } = useCreateTransaction();
  const {
    addNotification
  } = useNotification();
  const [selectedTransactionCategory, setSelectedTransactionCategory] = useState('general');
  const handleSelectTransactionCategoryChange = useCallback((value: string) => setSelectedTransactionCategory(value), []);
  const useFields = {
    wallet_id: useField<string>({
      value: '',
      validates: []
    }),
    transaction_value: useField<string>({
      value: String(default_money_quantity || ''),
      validates: [inputVal => {
        if (!inputVal || inputVal === '') {
          return 'Trường này không được để trống.';
        }
      }]
    }),
    transaction_note: useField<string>({
      value: '',
      validates: []
    }),
    transaction_type: useField<string>({
      value: 'credit',
      validates: [inputValue => {
        if (!['debit', 'credit'].includes(inputValue)) return 'transaction_type_invalid';
      }]
    }),
    receiver_user_id: useField<string>({
      value: '',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        // create new
        await createEntity({
          receiver_user_id: values.receiver_user_id,
          // order_pnr: order_pnr,
          wallet_id: values.wallet_id,
          amount: helpers.parseNumeric(values.transaction_value),
          transaction_note: values.transaction_note,
          transaction_category: selectedTransactionCategory,
          transaction_type: values.transaction_type,
          transaction_meta: {
            transaction_to_order: {
              order_id: order_id || undefined
            }
          }
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });

  /* End form */

  /**
   * Toast here meet an error ...
   */

  useEffect(() => {
    if (updateSuccess) {
      addNotification('info', __('Tạo giao dịch thành công.'));
      onClose();
    }
  }, [updateSuccess]);
  const optionForFilter = useMemo(() => {
    let r = [];
    for (let keyofFilter of allowed_transaction_category) {
      r.push({
        label: __(keyofFilter),
        value: keyofFilter
      });
    }
    return r;
  }, []);
  const [showMyModal, setShowMyModal] = useState(false);
  useEffect(() => {
    setShowMyModal(show);
  }, [show]);
  return <>
      <Modal open={showMyModal} onClose={() => onClose()} title={'Thêm giao dịch mới'} primaryAction={{
      content: 'Lưu lại',
      disabled: !dirty,
      loading: submitting,
      onAction: submit
    }} secondaryActions={[{
      content: 'Đóng',
      onAction: () => onClose()
    }]}>
        <Modal.Section>
          <Form onSubmit={submit}>
            <FormLayout>
              <Grid>
                <Grid.Cell columnSpan={{
                xs: 6,
                sm: 3,
                md: 3,
                lg: 6,
                xl: 6
              }}>
                  <QuickSearchWallet onClose={v => fields.wallet_id.onChange(v)} current_wallet_id={fields.wallet_id.value} title="Chọn ví" />
                </Grid.Cell>
                <Grid.Cell columnSpan={{
                xs: 6,
                sm: 3,
                md: 3,
                lg: 6,
                xl: 6
              }}>
                  <QuickSearchUser title="Ghi cho khách hàng" user_role="customer,agency,collaborator,user,visitor" current_user_id={default_customer_id} onClose={a => fields.receiver_user_id.onChange(String(a.user_id))} />
                </Grid.Cell>
              </Grid>

              <RadioButton label="Debit" helpText="Ghi nợ cho tài khoản người dùng, được hiểu là trừ tiền" checked={fields.transaction_type.value === 'debit'} name="transaction_type" id="debit" onChange={(b, a) => fields.transaction_type.onChange(String(a))} />
              <RadioButton label="Credit" helpText="Ghi có cho tài khoản người dùng, được hiểu là cộng tiền" name="transaction_type" id="credit" checked={fields.transaction_type.value === 'credit'} onChange={(b, a) => fields.transaction_type.onChange(String(a))} />

              <Select label="Nhóm giao dịch" options={optionForFilter} onChange={handleSelectTransactionCategoryChange} value={selectedTransactionCategory} />

              <TextField label="Số tiền" autoFocus requiredIndicator autoComplete="off" value={helpers.formatNumberWithCommas(fields.transaction_value.value)} onChange={a => fields.transaction_value.onChange(a)} error={fields.transaction_value.error} />

              <TextField label="Ghi chú" autoComplete="off" showCharacterCount multiline={2} {...fields.transaction_note} />
            </FormLayout>
          </Form>
          <br />
        </Modal.Section>
      </Modal>
    </>;
}