import { BlockStack, Box, Button, Checkbox, Icon, InlineError, InlineStack, Modal, SkeletonBodyText, Text, TextField, Thumbnail } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import { useAdminSearchProducts, useGetProduct } from 'queries/product.query';
import { TypedProduct } from 'interface/product.model';
import __helpers from 'helpers/index';
import { XIcon } from '@shopify/polaris-icons';
export default function QuickSearchProductAndVariantModal({
  show,
  onClose,
  setCurrentSelection,
  errorMessage = ''
}: {
  show: boolean;
  onClose: (output: {
    product_id: string;
    variant_id: string;
  } | null) => void;
  setCurrentSelection?: {
    product_id: string;
    variant_id: string;
  };
  errorMessage?: string;
}) {
  // lấy thông tin một cái đã chọn ...
  const {
    mutateAsync: getEntity,
    data: entity,
    reset,
    isPending: loadingSample
  } = useGetProduct();
  useEffect(() => {
    return () => {
      reset();
      clearSelection();
    };
  }, []);
  const [productSelected, setProductSelected] = useState<string>('');
  const [productVariantSelected, setProductVariantSelected] = useState<string>(null);

  // after selected
  const setSelected = useCallback((productSelected, productVariantSelected) => {
    getEntity(productSelected);
  }, []);
  const closeModalCallback = useCallback(() => {
    onClose({
      product_id: productSelected,
      variant_id: productVariantSelected
    });
  }, [onClose, productSelected, productVariantSelected]);

  // current selected
  useEffect(() => {
    if (setCurrentSelection) {
      setProductSelected(setCurrentSelection.product_id);
      setProductVariantSelected(setCurrentSelection.variant_id);
    }
  }, []);
  const [initialQuery, setInitialQuery] = useState({
    limit: 4,
    query: '',
    product_status: 1,
    sort: 'createdAt:desc'
  });
  const {
    refetch: fetchDataFromServerCallback,
    isLoading: searching,
    data: entities
  } = useAdminSearchProducts(initialQuery);

  /**
   *  list
   */

  const [productBrandList, setProductBrandList] = useState<TypedProduct[]>(null);
  useEffect(() => {
    if (entities) {
      let {
        body,
        totalItems
      } = entities;
      setProductBrandList(body);
    }
  }, [entities]);

  /**
   * Assign cho ai đóa
   */
  const [userQuickSearchText, setUserQuickSearchText] = useState<string>('');
  const updateTextThenDelayCallback = useMemo(() => helpers.debounce((_value: any) => updateTextThenDoitCallback?.call(this, _value), 500), []);
  const updateTextThenDoitCallback = useCallback((value: string) => {
    fetchDataFromServerCallback();
    return value;
  }, []);
  const updateText = useCallback((value: string) => {
    setUserQuickSearchText(value);
    const cloneInitialQuery = {
      ...initialQuery,
      ...{
        query: value
      }
    };
    setInitialQuery(cloneInitialQuery);
    updateTextThenDelayCallback(value);
  }, []);

  // product selected

  const clearSelection = useCallback(() => {
    setProductSelected(null);
    setProductVariantSelected(null);
    reset();
  }, []);
  useEffect(() => {
    if (!productSelected && !productVariantSelected) return;
    setSelected(productSelected, productVariantSelected);
  }, [productSelected, productVariantSelected, setSelected]);
  return <>
      <Modal activator={null} open={show} onClose={() => closeModalCallback()} title="Tìm sản phẩm">
        <Modal.Section>
          <TextField error={errorMessage ? true : false} onChange={updateText} label={'Tìm một sản phẩm bằng tên hoặc SKU hoặc mã vạch'} value={userQuickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} placeholder="Tìm kiếm" autoComplete="off" id="TextFieldSelectProductProvider" />
          <div style={{
          marginTop: '4px'
        }}>
            <InlineError message={errorMessage} fieldID="TextFieldSelectProductProvider" />
          </div>
          <br />
          <Text as="p">Đã chọn</Text>
          <br />
          {loadingSample ? <SkeletonBodyText /> : !entity || helpers.isEmpty(entity) ? <Text as="p" variant="bodySm" tone="subdued">
              Chưa chọn sản phẩm nào!
            </Text> : <Box key={`box_boxxialamaaa_kaka`}>
              <BlockStack gap="400">
                <InlineStack gap="200" align="start" blockAlign="center">
                  <Button variant="plain" icon={XIcon} onClick={() => clearSelection()}></Button>
                  <Thumbnail size="small" source={__helpers.getMediaLink(entity.product_thumbnail_to_media ? entity.product_thumbnail_to_media.media_thumbnail ? entity.product_thumbnail_to_media.media_thumbnail['128x128'] : entity.product_thumbnail_to_media.media_url : '')} alt={''} />
                  <div>
                    <Text as="p">{entity.product_name}</Text>
                    <Text as="p" variant="bodyXs" tone="subdued">
                      {helpers.formatNumber(entity.product_price)} vnd
                    </Text>
                  </div>
                </InlineStack>
                {productVariantSelected && entity.product_variant && entity.product_variant.filter(al => al.variant_id === productVariantSelected)?.map((ul, index) => {
              return <Box paddingInlineStart={'600'}>
                          <InlineStack gap="200" align="start" blockAlign="center" key={`index_` + index}>
                            <Thumbnail size="extraSmall" source={__helpers.getMediaLink(ul.variant_thumbnail_to_media ? ul.variant_thumbnail_to_media.media_thumbnail ? ul.variant_thumbnail_to_media.media_thumbnail['128x128'] : ul.variant_thumbnail_to_media.media_url : '')} alt={''} />

                            <Text as="p">
                              {ul.variant_name} - Giá {helpers.formatNumber(ul.variant_price)} vnd
                            </Text>
                          </InlineStack>
                        </Box>;
            })}
              </BlockStack>
            </Box>}

          <br />
          <Text as="p">Các tùy chọn</Text>
          <br />
          <BlockStack gap="200">
            {Array.isArray(productBrandList) && productBrandList.length < 1 && <Text as="p" tone="subdued">
                Không có kết quả nào phù hợp.
              </Text>}
            {Array.isArray(productBrandList) && productBrandList.map((el, index) => {
            return <Box key={`box_${index}`}>
                    <BlockStack gap="400">
                      <InlineStack gap="200" align="start" blockAlign="center">
                        <Checkbox label="" labelHidden checked={productSelected === el.product_id} onChange={() => setProductSelected(el.product_id)} disabled={!!el.product_has_variants} />
                        <Thumbnail size="small" source={__helpers.getMediaLink(el.product_thumbnail_to_media ? el.product_thumbnail_to_media.media_thumbnail ? el.product_thumbnail_to_media.media_thumbnail['128x128'] : el.product_thumbnail_to_media.media_url : '')} alt={''} />
                        <div>
                          <Text as="p">{el.product_name}</Text>
                          <Text as="p" variant="bodyXs" tone="subdued">
                            {helpers.formatNumber(el.product_price)} vnd
                          </Text>
                        </div>
                      </InlineStack>
                      {el.product_variant?.map((ul, index) => {
                  return <Box paddingInlineStart={'600'} key={`index_rr_` + index}>
                            <InlineStack gap="200" align="start" blockAlign="center">
                              <Checkbox labelHidden label="" checked={productVariantSelected === ul.variant_id} disabled={ul.variant_status !== 1} onChange={a => {
                        setProductVariantSelected(ul.variant_id);
                        setProductSelected(el.product_id);
                      }} />
                              <Thumbnail size="extraSmall" source={__helpers.getMediaLink(ul.variant_thumbnail_to_media ? ul.variant_thumbnail_to_media.media_thumbnail ? ul.variant_thumbnail_to_media.media_thumbnail['128x128'] : ul.variant_thumbnail_to_media.media_url : '')} alt={''} />

                              <Text as="p">
                                <span>{ul.variant_name}</span> <span style={{
                          color: '#999'
                        }}>{`${ul.variant_price}`} vnd</span>
                              </Text>
                            </InlineStack>
                          </Box>;
                })}
                    </BlockStack>
                  </Box>;
          })}
          </BlockStack>
        </Modal.Section>
      </Modal>
    </>;
}