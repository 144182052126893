import React, { useCallback, useEffect, useState } from 'react';
import 'media/css/social_post.scss';
import { useAuth } from 'context/AuthContext';
import __helpers from 'helpers/index';
import { BlockStack, Button, Form, Popover, Text } from '@shopify/polaris';
import { SaveIcon } from '@shopify/polaris-icons';
import { TypedSocial, useCreateSocialPost } from 'queries/social.query';
import axios from 'axios';
import { useNotification } from 'context/NotificationContext';
import __ from 'languages/index';
import QuickSearchProduct2 from 'components/quickSearchProduct2';
const REACT_APP_AJAX_UPLOAD_URL = process.env.REACT_APP_AJAX_UPLOAD_URL;
export type TypedUploadResult = {
  media_id: string;
  media_url: string;
  media_filename: string;
  media_filetype: string;
};
export default function NewPost({
  social_category,
  onCallback
}: {
  social_category: string;
  onCallback: (entity: TypedSocial) => void;
}) {
  const {
    user
  } = useAuth();
  const [content, setContent] = useState('');
  const [images, setImages] = useState([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const {
    addNotification
  } = useNotification();
  const [productID, setProductID] = useState(null);
  const [productName, setProductName] = useState('Đính kèm sản phẩm');
  const {
    mutateAsync: createSocialPost,
    isPending: creating,
    isSuccess,
    data
  } = useCreateSocialPost();
  useEffect(() => {
    if (isSuccess) {
      addNotification('info', __('Đăng bài thành công!'));
      onCallback(data);
    }
  }, [isSuccess, data]);

  /**
   * Step2. save to server
   *
   * @param {File} file
   */
  async function saveToServer(file: File): Promise<TypedUploadResult | undefined> {
    const fd = new FormData();
    fd.append('files', file); // append selected file to the bag named 'file'
    let {
      data
    } = await axios.post(REACT_APP_AJAX_UPLOAD_URL, fd);
    return data as TypedUploadResult;
  }
  const handleContentChange = e => {
    setContent(e.target.value);
  };
  const handleImageChange = e => {
    const files: any = Array.from(e.target.files);

    // Lưu cả file thực tế và URL tạm cho việc hiển thị
    const newImageURLs = files.map((file: any) => URL.createObjectURL(file));
    setImages(prevImages => [...prevImages, ...newImageURLs]);

    // Lưu file thực sự để upload
    setImageFiles(prevFiles => [...prevFiles, ...files]);
  };
  const handleRemoveImage = index => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index));
    // Xoá file thực tế tương ứng
    setImageFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };
  const handleSubmit = async e => {
    e.preventDefault();
    if (content.trim() || images.length > 0) {
      try {
        const uploadedImages = [];

        // Upload từng file
        for (let file of imageFiles) {
          let data = await saveToServer(file);
          uploadedImages.push(data.media_id); // lưu kết quả upload để gửi lên server
        }

        // Gọi API tạo post với media đã upload
        await createSocialPost({
          social_post_content: content,
          social_category: social_category,
          social_suggest_product_id: productID,
          media_id: uploadedImages
        });
      } catch (e) {
        console.error(e);
      }

      // Reset lại nội dung và hình ảnh
      setContent('');
      setImages([]);
      setImageFiles([]);
    }
  };
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(() => setPopoverActive(popoverActive => !popoverActive), []);
  return <div className="new-post">
      <Form onSubmit={handleSubmit}>
        <div className="new-post-header">
          <img src={__helpers.getMediaLink(user.user_avatar)} alt=" Avatar" className="avatar" />
          <textarea value={content} onChange={handleContentChange} placeholder="What's on your mind?" />
        </div>

        {images.length > 0 && <div className="image-previews">
            {images.map((image, index) => <div key={index} className="image-preview-container">
                <img src={image} alt={`Preview ${index}`} className="image-preview" />
                <button type="button" onClick={() => handleRemoveImage(index)}>
                  &times;
                </button>
              </div>)}
          </div>}

        <BlockStack gap="400">
          <div className="new-post-actions">
            <label htmlFor="image-upload" className="action-btn">
              Thêm ảnh
            </label>
            <input type="file" id="image-upload" multiple onChange={handleImageChange} style={{
            display: 'none'
          }} />
            <Button loading={creating} submit disabled={content ? false : true} icon={SaveIcon}>
              Đăng
            </Button>
          </div>
          <Popover active={popoverActive} activator={<>
                <Button variant="plain" onClick={togglePopoverActive} disclosure>
                  {productName}
                </Button>
              </>} onClose={togglePopoverActive} ariaHaspopup={false} sectioned>
            <QuickSearchProduct2 allowMultiple={false} current_product_id={null} label={''} onClose={v => {
            let product_id = v[0].product_id;
            let product_name = v[0].product_name;
            setProductID(product_id);
            setProductName(`Đính kèm sản phẩm` + product_name);
          }} />
          </Popover>
        </BlockStack>
      </Form>
    </div>;
}