import { compiledBlock as _compiledBlock } from "million/react";
const ViewRequest_1 = _compiledBlock(_props => <slot>
      {_props.v0}

      {_props.v1}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </slot>, {
  name: "ViewRequest_1",
  portals: ["v0", "v1"]
});
const ViewRequest = function ViewRequest() {
  let {
    redeem_request_id
  } = useParams();
  const {
    addNotification
  } = useNotification();
  const history = useNavigate();
  const {
    data: entity
  } = useGetRedeemRequest(redeem_request_id);
  const {
    mutateAsync: approveWithdraw,
    isSuccess: approvedSuccessfully
  } = useApprovedRedeemRequest();
  const {
    mutateAsync: deniedWithdraw,
    isSuccess: deniedSuccessfully
  } = useDeniedRedeemRequest();
  const [noteContent, setNoteContent] = useState('');
  const approveCallback = useCallback(async () => {
    try {
      await approveWithdraw(redeem_request_id);
    } catch (e) {
      console.error(e, '<<< APPROVE ERROR');
    }
  }, [noteContent, redeem_request_id]);
  const denyCallback = useCallback(async () => {
    try {
      await deniedWithdraw({
        id: String(entity.id),
        request_note: noteContent
      });
    } catch (e) {
      console.error(e, '<<< DENY ERROR');
    }
  }, [noteContent, entity]);
  useEffect(() => {
    if (entity) setNoteContent(entity?.request_note ?? '');
  }, [entity]);
  useEffect(() => {
    if (approvedSuccessfully) {
      addNotification('info', 'Đã duyệt thành công.');
    }
  }, [approvedSuccessfully]);
  useEffect(() => {
    if (deniedSuccessfully) {
      addNotification('info', 'Đã cập nhật trạng thái mới.');
    }
  }, [deniedSuccessfully]);
  return /*@million jsx-skip*/<ViewRequest_1 v0={<Helmet>
        <title>Yêu cầu quy đổi điểm</title>
      </Helmet>} v1={<Page narrowWidth title="Yêu cầu quy đổi điểm" backAction={{
    content: 'Quay lại danh sách',
    onAction: () => history('/redeem_request')
  }}>
        <DescriptionList items={[{
      term: 'Người yêu cầu',
      description: <BlockStack gap="400">
                  <InlineStack gap={'100'} align="start" blockAlign="center">
                    <Avatar size="lg" customer name={entity?.request_sender?.display_name} initials={String(entity?.request_sender?.display_name || 'R').charAt(0)} source={entity?.request_sender.user_avatar ? process.env.REACT_APP_AJAX_UPLOAD_PERMALINK + entity?.request_sender.user_avatar : 'https://placehold.co/100x100'} />
                    <Link url={`/customer/view/` + entity?.user_id + '#tab-4'}>
                      <Text variant="bodyMd" fontWeight="bold" as="span">
                        {entity?.request_sender?.display_name}
                      </Text>
                    </Link>
                  </InlineStack>

                  <Text as="p">
                    Email: {entity?.request_sender?.user_email ?? '-'} / Phone: {entity?.request_sender?.user_phonenumber ?? '-'}
                  </Text>
                </BlockStack>
    }, {
      term: 'Yêu cầu',
      description: <>
                  <BlockStack gap="400">
                    <div>
                      Số điểm quy đổi: <strong>{__helpers.formatNumberWithCommas(entity?.gift_value)}</strong> sẽ được rút khỏi ví{' '}
                      <strong>{entity?.wallet_type?.wallet_name}</strong> của người dùng trong trường hợp được duyệt.
                    </div>
                    <div>Quà tặng:</div>
                    <InlineStack gap={'100'} align="start" blockAlign="center">
                      <Thumbnail size="small" source={__helpers.getMediaLink(entity?.gift.gift_thumbnail_to_media ? entity?.gift.gift_thumbnail_to_media.media_thumbnail ? entity?.gift.gift_thumbnail_to_media.media_thumbnail['128x128'] : entity?.gift.gift_thumbnail_to_media.media_url : 'https://placehold.co/100x100')} alt={''} />
                      <Link url={`/gift/view/` + entity?.gift_id}>
                        <Text variant="bodyMd" fontWeight="bold" as="span">
                          {entity?.gift?.gift_title}
                        </Text>
                      </Link>
                    </InlineStack>
                    <Divider />
                  </BlockStack>
                </>
    }, {
      term: 'Kết quả xét duyệt',
      description: <BlockStack gap="400">
                  <TextField label="Lý do từ chối hoặc đồng ý (tùy chọn)" autoComplete="off" value={noteContent} onChange={v => setNoteContent(v)} multiline={5} maxLength={1000} showCharacterCount disabled={entity?.request_status !== 0} />

                  <ExceptionList items={[{
          icon: AlertTriangleIcon,
          status: 'critical',
          description: 'Khi bạn duyệt yêu cầu này, một thông báo "Yêu cầu đã được duyệt" được gửi tới khách hàng.'
        }, {
          icon: AlertTriangleIcon,
          status: 'critical',
          description: 'Khi bạn từ chối duyệt, khách hàng cũng sẽ nhận được thông báo từ chối với ghi chú của bạn là lý do từ chối duyệt.'
        }]} />

                  <Divider />

                  <Button size="large" icon={StatusActiveIcon}
        // tone="success"
        variant="primary" pressed={entity?.request_status === 1} onClick={() => approveCallback()} disabled={entity?.request_status !== 0}>
                    Duyệt
                  </Button>
                  <Button size="large" variant="secondary" icon={XSmallIcon} tone="critical" pressed={entity?.request_status === -1} onClick={() => denyCallback()} disabled={entity?.request_status !== 0}>
                    Từ chối duyệt
                  </Button>
                  {entity?.updatedBy && <>
                      Cập nhật lần cuối {__helpers.subtractTimeHistory(entity?.updatedAt)} bởi {entity?.request_updater?.display_name ?? '-'}
                    </>}
                </BlockStack>
    }]} />
      </Page>} />;
};
import { block as _block } from "million/react";
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DescriptionList, Page, Link, Button, Text, Avatar, InlineStack, BlockStack, Divider, TextField, ExceptionList, Thumbnail } from '@shopify/polaris';
import __helpers from 'helpers/index';
import { Helmet } from 'react-helmet-async';
import { AlertTriangleIcon, XSmallIcon, StatusActiveIcon } from '@shopify/polaris-icons';
import { useApprovedRedeemRequest, useDeniedRedeemRequest, useGetRedeemRequest } from 'queries/redeem_request.query';
import { useNotification } from 'context/NotificationContext';
export default ViewRequest;