import { Page } from '@shopify/polaris';
import __, { ___ } from 'languages/index';
import { Helmet } from 'react-helmet-async';
import ForSaleAndUpper from './forSaleAndUpper';
import Capacity from 'components/Capacity';
import ForNormalUser from './forNormalUser';

/************************************************************ *
 * MAIN
 * Private route
 ************************************************************ */

export default function Dashboard() {
  return <>
      <Helmet prioritizeSeoTags>
        <title>Dashboard</title>
      </Helmet>

      <Page>
        <Capacity current_user_can={'sale'} onFail={<ForNormalUser />}>
          <ForSaleAndUpper />
        </Capacity>
      </Page>

      <br />
    </>;
}