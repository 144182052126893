import { FooterHelp, Form, FormLayout, Modal, TextField } from '@shopify/polaris';
import helpers from 'helpers/index';
import { useCreateBlacklistDeviceUUID } from 'queries/blacklist_device_uuid.query';
import { useCallback, useEffect, useState } from 'react';
export default function AddDeviceUUIDModal({
  show = false,
  onClose
}: {
  show: boolean;
  onClose: () => void;
}) {
  function modalClose() {
    onClose.apply(this, []);
  }
  const {
    mutateAsync: createEntity
  } = useCreateBlacklistDeviceUUID();
  const [dirty, setDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentIP, setCurrentIP] = useState('');
  const [helpText, setHelpText] = useState('');
  const submit = useCallback(async () => {
    setHelpText('Đang lưu lại ...');
    setSaving(true);
    try {
      await createEntity({
        device_uuid: currentIP
      });
      await helpers.sleep(1000);
      setHelpText('Đã lưu thành công ...');
      setCurrentIP('');
    } catch (e) {
      setHelpText('Chưa lưu được ...');
    }
    setDirty(false);
    setSaving(false);
  }, [currentIP]);
  useEffect(() => {
    if (currentIP && currentIP.length > 0) setDirty(true);else setDirty(false);
  }, [currentIP]);
  return <>
      <Modal open={show} onClose={() => modalClose()} title={'Thêm mã UUID của một device vào danh sách cấm'} secondaryActions={[{
      content: 'Đóng',
      onAction: () => modalClose()
    }, {
      content: 'Lưu & Thêm nữa',
      disabled: !dirty,
      loading: saving,
      onAction: submit
    }]}>
        <Modal.Section>
          <Form onSubmit={submit}>
            <FormLayout>
              <TextField label="UUID bị cấm" requiredIndicator autoComplete="off" value={currentIP} onChange={(val: string) => setCurrentIP(val)} helpText={helpText} />
            </FormLayout>
          </Form>
        </Modal.Section>
        <FooterHelp align="start">Các Device UUID này sẽ không thể đăng nhập, đăng ký hoặc gửi yêu cầu liên hệ.</FooterHelp>
      </Modal>
    </>;
}