import { Page, BlockStack, Text, TextField, Toast, ContextualSaveBar, Checkbox, Divider, RadioButton } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useField, useForm } from '@shopify/react-form';
import helpers from 'helpers/index';
import { Helmet } from 'react-helmet-async';
import { useGetSettings, useUpdateSetting } from 'queries/setting.query';
import __ from 'languages/index';
export default function GeneralSecuritySetting() {
  const {
    data: entities,
    isLoading: loading
  } = useGetSettings({
    fields: `setting_name,setting_value`,
    setting_name: `session_keep_time,forgot_password_strict_mode,turn_off_all_notification,maintenance_mode`
  });
  const {
    mutateAsync: updateEntity,
    error,
    isSuccess,
    isPending: updating
  } = useUpdateSetting();
  const useFields = {
    forgot_password_strict_mode: useField<number>({
      value: Number(entities?.forgot_password_strict_mode ?? 0),
      validates: []
    }),
    turn_off_all_notification: useField<number>({
      value: Number(entities?.turn_off_all_notification ?? 0),
      validates: []
    }),
    maintenance_mode: useField<number>({
      value: Number(entities?.maintenance_mode || 1),
      validates: []
    }),
    session_keep_time: useField<string>({
      value: entities?.session_keep_time ?? '25',
      validates: [inputValue => {
        if (!helpers.isNumeric(inputValue)) return 'Phải là một số hợp lệ!';
      }]
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          session_keep_time: values.session_keep_time,
          forgot_password_strict_mode: values.forgot_password_strict_mode,
          turn_off_all_notification: values.turn_off_all_notification,
          maintenance_mode: values.maintenance_mode
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });

  /**
   * Notification
   */

  const [internalNotification, setInternalNotification] = useState('');
  useEffect(() => {
    if (error) {
      setInternalNotification(error.message);
    }
  }, [error]);
  const toggleToastActive = useCallback(() => {
    setInternalNotification('');
  }, []);
  const toastMarkup = internalNotification ? <Toast content={internalNotification} onDismiss={toggleToastActive} duration={4500} /> : null;
  return <>
      <Helmet>
        <title>Cài đặt chung</title>
      </Helmet>
      {toastMarkup}
      <BlockStack gap={'200'}>
        <Text as="h3" tone="critical" variant="headingMd">
          Chế độ khẩn cấp
        </Text>

        <Checkbox label="Bật chế độ bảo trì" helpText="Khi chế độ bảo trì được bật, chỉ những admin được phép đăng nhập." checked={Boolean(fields.maintenance_mode.value === 1)} onChange={e => fields.maintenance_mode.onChange(Number(e))} />

        <Checkbox label="Tắt toàn bộ thông báo" helpText="Trường hợp khẩn cấp xảy ra, tick ngay để hệ thống sẽ không gửi bất kỳ thông báo nào nữa..." checked={Boolean(fields.turn_off_all_notification.value)} onChange={e => fields.turn_off_all_notification.onChange(Number(e))} />

        <br />
        <Divider borderColor="border-disabled" borderWidth="025" />
        <br />

        <Text as="h3" variant="headingMd">
          Tùy chọn lấy lại mật khẩu
        </Text>
        <Checkbox label="Bắt buộc quên mật khẩu ở một trình duyệt đã đăng nhập" helpText="An toàn cho CRM nhưng có thể sẽ ngăn cản việc xin lại mật khẩu" checked={fields.forgot_password_strict_mode.value === 1} onChange={e => fields.forgot_password_strict_mode.onChange(Number(e))} />

        <br />
        <Divider borderColor="border-disabled" borderWidth="025" />
        <br />

        <Text as="h3" variant="headingMd">
          Thời gian giữ phiên đăng nhập
        </Text>

        <TextField label="" suffix="Giờ" labelHidden autoComplete="off" {...fields.session_keep_time} helpText="Thời gian giữ một phiên đăng nhập mặc định, tính bằng đơn vị giờ đồng hồ. Đây là thời gian tối đa cho phép một người đang đăng nhập không hoạt động trong bao nhiêu giờ thì bị đăng xuất. Mặc định là 25 giờ." />
      </BlockStack>

      {dirty && <ContextualSaveBar message="Thay đổi chưa được lưu" saveAction={{
      onAction: () => submit(),
      loading: updating
    }} discardAction={{
      onAction: () => resetForm()
    }} />}

      <br />
      <br />
      <br />
      <br />
    </>;
}