import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import { TypedBlacklistDeviceUUID } from "interface/blacklist.model";
import queryClient from 'queries';


type IQuery = TypedBlacklistDeviceUUID & IQueryParams;


/*
* List of all app
*/
export function useGetBlacklistDeviceUUID(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["blacklist_device_uuid/fetch_entity_list"],
        queryFn: () => axios.get<TypedBlacklistDeviceUUID[]>(`/blacklist_device_uuid${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useCreateBlacklistDeviceUUID() {
    return useMutation({
        mutationKey: ['blacklist_device_uuid/create_entity'],
        mutationFn: (entity: TypedBlacklistDeviceUUID) => axios.post<TypedBlacklistDeviceUUID>(`/blacklist_device_uuid`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['blacklist_device_uuid/fetch_entity_list'] });
        }
    });
}


export function useDeleteBlacklistDeviceUUID() {
    return useMutation({
        mutationKey: ['blacklist_device_uuid/delete_entity'],
        mutationFn: (id: string) => axios.delete<any>(`/blacklist_device_uuid/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['blacklist_device_uuid/fetch_entity_list'] });
        }

    });
}

