import React from 'react';
import { useParams } from 'react-router-dom';
import ListWithdraw from './list';
import ViewWithdraw from './view';
export default function WithdrawIndex() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = <ListWithdraw />;
      break;
    default:
      ActualPage = <ViewWithdraw />;
      break;
  }
  return ActualPage;
}