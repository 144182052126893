import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import { TypedOutgoing_webhook } from "interface/outgoing_webhook.model";
import queryClient from 'queries';



type IQuery = TypedOutgoing_webhook & IQueryParams;


/*
* List of all webhook
*/
export function useGetOutgoingWebhooks(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["webhook_outgoing/fetch_entity_list"],
        queryFn: () => axios.get<TypedOutgoing_webhook[]>(`/webhook_outgoing${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetOutgoingWebhook(webhook_id: string) {
    return useQuery({
        queryKey: ['webhook_outgoing/fetch_entity'],
        queryFn: () => axios.get<TypedOutgoing_webhook>(`/webhook_outgoing/${webhook_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}
export function useMutateGetOutgoingWebhook() {
    return useMutation({
        mutationKey: ['webhook_outgoing/fetch_entity'],
        mutationFn: (webhook_id: string) => axios.get<TypedOutgoing_webhook>(`/webhook_outgoing/${webhook_id}`).then((response) => response.data),
        retry: 1,
        // enabled: false,
    });
}


export function useUpdateOutgoingWebhook() {
    return useMutation({
        mutationKey: ['webhook_outgoing/update_entity'],
        mutationFn: ({ webhook_id, ...rest }: TypedOutgoing_webhook) => axios.patch<TypedOutgoing_webhook>(`/webhook_outgoing/${webhook_id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['webhook_outgoing/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['webhook_outgoing/fetch_entity'] });
        }
    });
}


export function useCreateOutgoingWebhook() {
    return useMutation({
        mutationKey: ['webhook_outgoing/create_entity'],
        mutationFn: (entity: TypedOutgoing_webhook) => axios.post<TypedOutgoing_webhook>(`/webhook_outgoing`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['webhook_outgoing/fetch_entity_list'] });
        }
    });
}


export function useDeleteOutgoingWebhook() {
    return useMutation({
        mutationKey: ['webhook_outgoing/delete_entity'],
        mutationFn: (webhook_id: string) => axios.delete<any>(`/webhook_outgoing/${webhook_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['webhook_outgoing/fetch_entity_list'] });
        }

    });
}

export function useGetOutgoingLog(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    const webhook_outgoing_id = object.webhook_outgoing_id;
    return useQuery({
        queryKey: ["webhook_outgoing_log/fetch_entity_list"],
        queryFn: () => axios.get<TypedOutgoing_webhook[]>(`/webhook_outgoing_log/${webhook_outgoing_id}${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}

