/**
*   Interface/model file auto generate for Notification
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/



export const NotificationCategoryArray = ['general', 'wallet', 'event', 'promotion', 'news', 'order'];

export interface TypedNotification {
	notification_id?: string;
	notification_title?: string;
	notification_content?: string;
	notification_channel?: string;
	"notification_category"?: string; // 'general' | 'wallet' | 'event' | 'promotion' | 'news' | 'order', // sử dụng general | wallet | event | promotion | news | order
	"notification_image"?: string, // ảnh khi post lên

	notification_user?: string;
	notification_data?: {
		[propKey: string]: string
	};
	notification_schedule?: string | number;
	createdAt?: string;
	createdBy?: string;

	notification_meta?: any;
}