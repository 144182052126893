import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypeEmailOutgoing {
    id?: string,
    title?: string,
    subject?: string,
    content?: string,
    email_template?: string,
    email_setting?: string,
    to_email?: string,
    cc?: string,
    bcc?: string,
    createdAt?: string,
    email_sent_schedule?: any;
    email_sent_status?: number;
}


type IQuery = TypeEmailOutgoing & IQueryParams;


/*
* List of all app
*/
export function useGetEmailOutgoings(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["email_outgoing/fetch_entity_list"],
        queryFn: () => axios.get<TypeEmailOutgoing[]>(`/email_outgoing${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetEmailOutgoing(app_id: string) {
    return useQuery({
        queryKey: ['email_outgoing/fetch_entity'],
        queryFn: () => axios.get<TypeEmailOutgoing>(`/email_outgoing/${app_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useUpdateEmailOutgoing() {
    return useMutation({
        mutationKey: ['email_outgoing/update_entity'],
        mutationFn: ({ id, ...rest }: TypeEmailOutgoing) => axios.patch<TypeEmailOutgoing>(`/email_outgoing/${id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['email_outgoing/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['email_outgoing/fetch_entity'] });
        }
    });
}


export function useCreateEmailOutgoing() {
    return useMutation({
        mutationKey: ['email_outgoing/create_entity'],
        mutationFn: (entity: TypeEmailOutgoing) => axios.post<TypeEmailOutgoing>(`/email_outgoing`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['email_outgoing/fetch_entity_list'] });
        }
    });
}


export function useDeleteEmailOutgoing() {
    return useMutation({
        mutationKey: ['email_outgoing/delete_entity'],
        mutationFn: (id: string) => axios.delete<any>(`/email_outgoing/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['email_outgoing/fetch_entity_list'] });
        }

    });
}


export function useUpdateStatus() {
    return useMutation({
        mutationKey: ['email_outgoing/update_status'],
        mutationFn: (entity: { id: string, email_sent_status: number }) => axios.patch<any>(`/email_outgoing/status/${entity.id}`, { email_sent_status: entity.email_sent_status }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['email_outgoing/fetch_entity'] });
            queryClient.invalidateQueries({ queryKey: ['email_outgoing/fetch_entity_list'] });
        }
    });
}