import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedMedia } from "./media.query";
import { TypedWallet_type } from "./wallet.query";
import { TypedVoucher } from "./voucher.query";

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedGift {
    gift_id?: string;
    gift_title?: string;
    gift_description?: string;
    gift_content?: string;
    gift_slug?: string;
    gift_thumbnail?: string;
    gift_thumbnail_to_media?: TypedMedia;
    gift_status?: number;
    gift_value?: number;
    gift_quantity?: number;
    wallet_type_id?: string;
    wallet_type?: TypedWallet_type;
    createdAt?: string;
    createdBy?: string;

    voucher_id?: string;

    voucher?: TypedVoucher;

    _count?: {
        redeem_request: number
    }
}


type IQuery = TypedGift & IQueryParams;


/*
* List of all 
*/
export function useGetGifts(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["gift/fetch_entity_list"],
        queryFn: () => axios.get<TypedGift[]>(`/gift${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetGift(redeem_id: string) {
    return useQuery({
        queryKey: ['gift/fetch_entity'],
        queryFn: () => axios.get<TypedGift>(`/gift/${redeem_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}
export function useMutateGetGift() {
    return useMutation({
        mutationKey: ['gift/fetch_entity'],
        mutationFn: (redeem_id: string) => axios.get<TypedGift>(`/gift/${redeem_id}`).then((response) => response.data),
        retry: 1,
    });
}


export function useUpdateGift() {
    return useMutation({
        mutationKey: ['gift/update_entity'],
        mutationFn: ({ gift_id: redeem_id, ...rest }: TypedGift) => axios.patch<TypedGift>(`/gift/${redeem_id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['gift/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['gift/fetch_entity'] });
        }
    });
}


export function useCreateGift() {
    return useMutation({
        mutationKey: ['gift/create_entity'],
        mutationFn: (entity: TypedGift) => axios.post<TypedGift>(`/gift`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['gift/fetch_entity_list'] });
        }
    });
}


export function useDeleteGift() {
    return useMutation({
        mutationKey: ['gift/delete_entity'],
        mutationFn: (redeem_id: string) => axios.delete<any>(`/gift/${redeem_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['gift/fetch_entity_list'] });
        }

    });
}