import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedUser } from '../interface/user.model';


type IQuery = TypedUser & IQueryParams;


/*
* List of all app
*/
export function useGetUsers(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["user/admin/fetch_entity_list"],
        queryFn: () => axios.get<TypedUser[]>(`/user/admin${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}

export function useMutateGetUsers() {
    return useMutation({
        mutationKey: ['user/admin/fetch_entity_list'],
        mutationFn: (object: IQuery) => axios.get<TypedUser[]>(`/user/admin${helpers.buildEndUrl(object)}`).then((response) => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
    });
}



export function useGetUser(user_id: string) {
    return useQuery({
        queryKey: ['user/fetch_entity'],
        queryFn: () => axios.get<TypedUser>(`/user/${user_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}

export function useMutateGetUser() {
    return useMutation({
        mutationKey: ['user/fetch_entity'],
        mutationFn: (user_id: string) => axios.get<TypedUser>(`/user/${user_id}`).then((response) => response.data),
    });
}


export function useUpdateUser() {
    return useMutation({
        mutationKey: ['user/admin/update_entity'],
        mutationFn: ({ user_id, ...rest }: TypedUser) => axios.patch<TypedUser>(`/user/admin/${user_id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user/admin/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['user/fetch_entity'] });
        }
    });
}


export function useCreateUser() {
    return useMutation({
        mutationKey: ['user/admin/create_entity'],
        mutationFn: (entity: TypedUser) => axios.post<TypedUser>(`/user/admin`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user/admin/fetch_entity_list'] });
        }
    });
}


export function useSetUserPassword() {
    return useMutation({
        mutationKey: ['user/admin/setUserNewPassword'],
        mutationFn: (entity: { password: string, user_id?: string }) => axios.patch<any>(`/user/admin/set_new_password`, helpers.cleanEntity(entity)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user/admin/fetch_entity_list'] });
        }
    });
}

type TypedOnetimePasswordResult = {
    ID: string,
    user_id: string,
    token: string,
    createdAt: string,
}

export function useGetOnetimepassword() {
    return useMutation({
        mutationKey: ['user/get_onetimepassword'],
        mutationFn: (user_id: string) => axios.post<TypedOnetimePasswordResult>(`/user/onetimepassword/${user_id}`).then(response => response.data)
    });
}