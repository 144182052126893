import { compiledBlock as _compiledBlock } from "million/react";
const AffiliateAdvanceSetup_1 = _compiledBlock(_props => <slot>
      {_props.v0}
      {_props.v1}
      <br />
      <br />
      <br />
      <br />
      <br />

      {_props.v2}
    </slot>, {
  name: "AffiliateAdvanceSetup_1",
  portals: ["v0", "v1", "v2"]
});
const AffiliateAdvanceSetup = function AffiliateAdvanceSetup() {
  const {
    data: allRankEntities,
    isLoading: loadingRank,
    isSuccess: loadingRankSuccess
  } = useGetRanks({
    limit: 100
  });
  const {
    data: productCommissionRankData
  } = useGetDefaultProductCommissionRank();
  const {
    mutateAsync: createProductCommissionRankData,
    isPending: creatingProductCommissionRank
  } = useCreateDefaultProductCommissionRank();
  const [allRanks, setAllRanks] = useState([]);
  useEffect(() => {
    if (allRankEntities) {
      let {
        entities
      } = allRankEntities;
      let allRanks = [];
      for (let rank of entities) {
        allRanks.push({
          label: rank.rank_name,
          value: rank.rank_id
        });
      }
      setAllRanks(allRanks);
    }
  }, [allRankEntities]);
  const {
    data: entity
  } = useGetDefaultAffiliateSetting();
  const useFields = {
    product_commission: useField<TypedProductCommission[]>({
      value: helpers.isEmpty(entity?.commission || []) ? [{
        commission_level: 1,
        commission_value: 0,
        commission_method: 'percent'
      }] : entity?.commission,
      validates: []
    }),
    product_commission_advance: useField<TypedProductCommissionRank[]>({
      value: productCommissionRankData || [],
      validates: []
    })
  };
  const {
    product_commission,
    product_commission_advance
  } = useFields;
  const addNewRuleToProductCommissionAdvanceCallback = useCallback((commission_parent_index: number) => {
    let currentValue = [...useFields.product_commission_advance.value, ...[]];
    currentValue.push({
      commission_parent_index: commission_parent_index,
      user_rank: allRanks[0] ? allRanks[0].value : '',
      commission_value: 1
    });
    useFields.product_commission_advance.onChange(currentValue);
  }, [useFields.product_commission_advance]);
  const deleteRuleToProductCommissionAdvanceCallback = useCallback((index_id: number) => {
    let currentValue = [...useFields.product_commission_advance.value, ...[]];
    currentValue = currentValue.filter((e, i) => i !== index_id);
    useFields.product_commission_advance.onChange(currentValue);
  }, [useFields.product_commission_advance]);
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        // create new
        let afterFilter = values.product_commission_advance.filter(el => el.user_rank !== '' && Number(el.commission_value) > 0);
        await createProductCommissionRankData(afterFilter);
      } catch (e: any) {
        if (typeof e.params !== 'undefined' && e.params.field) {
          useFields[e.params.field]?.setError(e.message);
        }
      }
      return {
        status: 'success'
      };
    }
  });
  return /*@million jsx-skip*/<AffiliateAdvanceSetup_1 v0={<Helmet>
        <title>Cài đặt Affiliate nâng cao</title>
      </Helmet>} v1={<Page title="Cài đặt Affiliate nâng cao">
        <BlockStack gap="600">
          <InlineGrid columns={{
        xs: '1fr',
        md: '2fr 5fr'
      }} gap="400">
            <Box as="section" paddingInlineStart={{
          xs: '400',
          sm: '0'
        }} paddingInlineEnd={{
          xs: '400',
          sm: '0'
        }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Cài đặt hoa hồng
                </Text>
                <Text as="p" variant="bodyMd">
                  Hoa hồng tại đây áp dụng cho từng hạng của người dùng. Mỗi một hạng sẽ có các ưu đãi riêng!
                </Text>
              </BlockStack>
            </Box>
            <Card>
              {loadingRank ? <SkeletonBodyText /> : allRanks && Array.isArray(allRanks) && allRanks.length > 0 ? <BlockStack gap="400">
                  <Text as="h3" variant="headingMd">
                    -- Đây là người mua (bậc 0)
                  </Text>
                  {product_commission.value.map((commission, index) => {
              return <div key={'index_product_commission_' + index}>
                        <Text as="h3" variant="headingMd">{`-- Hoa hồng giới thiệu bậc ${index + 1}`}</Text>
                        <Box paddingInlineStart={'600'} paddingBlock={'200'}>
                          <BlockStack gap={'400'}>
                            {useFields.product_commission_advance.value.map((commission_advance, index2) => {
                      if (commission_advance.commission_parent_index !== index) return undefined;
                      return <TextField size="slim" label={`Nếu người nhận là *** thì nhận thêm *** giá trị`} connectedLeft={<Select label="" labelHidden options={allRanks} onChange={value => product_commission_advance.onChange(product_commission_advance.value.map((item, i) => i === index2 ? {
                        ...item,
                        user_rank: value
                      } : item))} value={commission_advance.user_rank.toString()} />} labelAction={{
                        content: 'Xoá',
                        onAction: () => deleteRuleToProductCommissionAdvanceCallback(index2)
                      }} autoComplete="off" value={helpers.formatNumberWithCommas(commission_advance.commission_value.toString())} onChange={value => product_commission_advance.onChange(product_commission_advance.value.map((item, i) => i === index2 ? {
                        ...item,
                        commission_value: helpers.parseNumeric(value)
                      } : item))} placeholder="Giá trị" suffix={commission.commission_method === 'percent' ? '%' : 'fixed'} />;
                    })}

                            <div>
                              <Button variant="plain" tone="success" onClick={() => addNewRuleToProductCommissionAdvanceCallback(index)} icon={PlusIcon}>
                                Thêm điều kiện nâng cao
                              </Button>
                            </div>
                          </BlockStack>
                        </Box>
                      </div>;
            })}

                  <Divider />

                  <Text as="p" tone="subdued">
                    Các cấp bậc hoa hồng được cài đặt tại phần "cài đặt mặc định"
                  </Text>
                </BlockStack> : <>Chưa có cài đặt rank nào!</>}
            </Card>
          </InlineGrid>
        </BlockStack>
      </Page>} v2={dirty && <ContextualSaveBar message="Thay đổi của bạn chưa được lưu." saveAction={{
    onAction: submit,
    loading: creatingProductCommissionRank,
    disabled: !dirty
  }} discardAction={{
    content: 'Hoàn tác',
    onAction: Userreset
  }} />} />;
};
import { block as _block } from "million/react";
import { BlockStack, Box, Button, Card, ContextualSaveBar, Divider, InlineGrid, Page, Select, SkeletonBodyText, Text, TextField } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { PlusIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import __ from 'languages/index';
import { TypedProductCommission } from 'interface/product.model';
import { useField, useForm } from '@shopify/react-form';
import { TypedProductCommissionRank, useCreateDefaultAffiliateSetting, useCreateDefaultProductCommissionRank, useGetDefaultAffiliateSetting, useGetDefaultProductCommissionRank } from 'queries/default_product_commission.query';
import { useGetRanks } from 'queries/rank.query';
export default AffiliateAdvanceSetup;