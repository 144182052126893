import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Card, EmptyState, InlineStack, Layout, Page, Thumbnail, Text, BlockStack, Popover, Button, Divider, Link, DataTable } from '@shopify/polaris';
import { useGetUser_group, useUpdateUser_group } from 'queries/user_group.query';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import helpers from 'helpers/index';
import QuickSearchUser from 'components/quickSearchUser';
import TypedUser_to_group_admin, { useGetUsers_in_group } from 'queries/user_to_group.query';
import Pagination from 'components/pagination';
import dateandtime from 'date-and-time';
import SimpleFilter from 'components/simpleFilter';
import { PlusIcon } from '@shopify/polaris-icons';
import AddMemberModal from './add.member.modal';
export default function UserGroupView() {
  let {
    user_group_id
  } = useParams();
  const history = useNavigate();
  const {
    data: entity,
    isLoading,
    isError,
    isSuccess
  } = useGetUser_group(user_group_id);
  const {
    mutateAsync: updateUserGroup
  } = useUpdateUser_group();
  /**
   * ENd save quick note
   */

  const [showAddReferrer, setShowAddReferrer] = useState(false);
  const addNewReferrer = useCallback(async (user_group_leader: bigint | string) => {
    updateUserGroup({
      user_group_id: BigInt(user_group_id),
      user_group_leader: String(user_group_leader)
    });
  }, [user_group_id]);

  /** Fetch member */
  const rootSearchPath = window.location.search;
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 20,
    sort: 'createdAt:desc',
    user_group_id: user_group_id
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data: memberInGroup
  } = useGetUsers_in_group(mainQuery);
  const [entities, setEntities] = useState<TypedUser_to_group_admin[]>(null);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (memberInGroup) {
      let {
        body,
        totalItems
      } = memberInGroup;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [memberInGroup]);
  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    setMainQuery(mainQuery);
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'User Group Detail', `/user_group/${user_group_id}` + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery, user_group_id]);

  /** add new member to user group */

  const [showAddNewModal, setShowAddNewModal] = useState(false);
  return <>
      <Helmet>
        <title>{entity?.user_group_name || 'Thông tin chi tiết'}</title>
      </Helmet>
      {isError && <EmptyState heading="Ohhhhh" action={{
      content: 'Quay lại danh sách',
      onAction: () => history('/user_group')
    }} image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
          <p>Kiểm tra lại thông tin và đường link, có thể bị sai hoặc dữ liệu đã bị xóa.</p>
        </EmptyState>}
      {isSuccess && <Page title={entity?.user_group_name} titleMetadata={<InlineStack gap="400">
              {entity?.user_group_status === 1 ? <Badge tone="info">Active</Badge> : <Badge tone="warning">DeActive</Badge>}
              <Badge tone="critical">{`${entity?._count.user_to_group} thành viên`}</Badge>
            </InlineStack>} backAction={{
      content: 'Back',
      onAction: () => history('/user_group')
    }} primaryAction={{
      content: 'Thêm thành viên',
      icon: PlusIcon,
      onAction: () => setShowAddNewModal(true)
    }}>
          <Layout>
            <Layout.Section>
              {helpers.isEmpty(entities || []) ? <Text as="p">Chưa có ai trong nhóm này</Text> : <Card padding={'0'}>
                  <SimpleFilter onCallback={filterCallback} loading={false} options={[]} sortField={[{
              label: '',
              field: ''
            }]} />
                  <DataTable verticalAlign="middle" columnContentTypes={['text', 'text', 'text', 'numeric']} headings={['Thành viên', 'Tình trạng', 'Hoạt động gần nhất', 'Ngày gia nhập']} rows={entities?.map((element, index) => {
              return [element?.user_data?.display_name, element?.user_data?.user_status === 1 ? <Badge tone="info">Active</Badge> : <Badge>Inactive</Badge>, element?.user_data?.lastActive ? helpers.subtractTimeHistory(element?.user_data?.lastActive) : '--', dateandtime.format(new Date(Number(element?.createdAt)), 'DD-MM-YYYY')];
            })} />
                </Card>}
              <br />
              {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
            </Layout.Section>
            <Layout.Section variant="oneThird">
              <Card>
                <BlockStack gap="400">
                  <InlineStack>
                    {entity.user_group_thumbnail ? <InlineStack blockAlign="center" gap={'100'}>
                        <Thumbnail size="small" source={helpers.getMediaLink(entity.user_group_thumbnail_to_media ? entity.user_group_thumbnail_to_media.media_thumbnail ? entity.user_group_thumbnail_to_media.media_thumbnail['128x128'] : null : null)} alt={''} />
                        <Text as="span">{entity.user_group_name}</Text>
                      </InlineStack> : <Text as="span">{entity.user_group_name}</Text>}
                  </InlineStack>
                  <Text as="p">{entity.user_group_description}</Text>

                  <Divider />

                  <Text as="p">Tạo lúc: {helpers.subtractTimeHistory(Number(entity.createdAt))}</Text>
                  {entity?.user_group_leader ? <Text as="p">
                      Quản lý bởi:{' '}
                      <Link url={`/customer/view/${entity?.user_group_leader}`}>
                        {entity?.user_group_leader_to_user?.display_name || entity?.user_group_leader_to_user?.user_phonenumber || entity?.user_group_leader_to_user?.user_email}
                      </Link>
                    </Text> : <Popover active={showAddReferrer} activator={<Button variant="plain" disclosure onClick={() => setShowAddReferrer(true)}>
                          Thêm người quản lý nhóm
                        </Button>} autofocusTarget="first-node" onClose={() => setShowAddReferrer(false)}>
                      <Card>
                        <QuickSearchUser title="" user_role="" onClose={(userData: any) => {
                    addNewReferrer(userData.user_id);
                    setShowAddReferrer(false);
                  }} />
                      </Card>
                    </Popover>}
                </BlockStack>
              </Card>
            </Layout.Section>
          </Layout>
        </Page>}
      <AddMemberModal current_user_group_id={user_group_id} show={showAddNewModal} onClose={() => setShowAddNewModal(false)} />
    </>;
}