/**
 * Page
 */

import { Page, Layout, LegacyCard, SkeletonPage, SkeletonDisplayText, SkeletonBodyText, TextContainer, Card } from '@shopify/polaris';
import { useEffect } from 'react';
import Theme404 from '../../layout/404';
import { useParams } from 'react-router-dom';
import Parser from 'html-react-parser';
import dateandtime from 'date-and-time';
import { useGetComment } from 'queries/comment.query';
export default function View_comment() {
  /**
   * Lấy URL ra... để query. chú ý phải load nội dung mới
   * trong useEffect
   */
  let {
    comment_slug
  } = useParams();
  const {
    data: entity,
    isLoading: loading
  } = useGetComment(comment_slug);
  const loadingPageMarkup = <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card padding="0">
            <div>
              <SkeletonDisplayText size="medium" />
              <SkeletonBodyText lines={9} />
            </div>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>;
  const actual_page = entity?.comment_id ? <Page title={entity?.comment_title} subtitle={`${entity?.createdBy}, last update at ${dateandtime.format(new Date(entity?.createdAt), 'DD/MM/YYYY HH:mm:ss')} `}>
      <Layout>
        <Layout.Section>
          <LegacyCard title={null} sectioned>
            {Parser(entity ? entity?.comment_content : ' ')}
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page> : <Theme404 />;
  const pageMarkup = loading ? loadingPageMarkup : actual_page;
  return <div id="general_page">{pageMarkup}</div>;
}