import React from 'react';
import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import posts_list from './posts.list';
import posts_edit from './posts.edit';
import posts_category from './posts.category';
export default function Pagidex() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = posts_list;
      break;
    case 'edit':
      ActualPage = posts_edit;
      break;
    case 'new':
      ActualPage = posts_edit;
      break;
    case 'category':
      ActualPage = posts_category;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>{<ActualPage />}</>;
}