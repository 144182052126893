import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedEmailIncoming {
    id?: string
    message_id?: string
    subject?: string
    from_email?: string
    from_name?: string
    to_email?: string
    createdAt?: string
}


type IQuery = TypedEmailIncoming & IQueryParams;


/*
* List of all app
*/
export function useGetEmailIncomings(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["email_incoming/fetch_entity_list"],
        queryFn: () => axios.get<TypedEmailIncoming[]>(`/email_incoming${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}

