import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import { TypedUser } from "interface/user.model";
import queryClient from 'queries';


/**
*   Interface/model file auto generate for Media
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/

type TypedThumbnail = {
    '512x512': string,
    '300x200': string,
    '256x169': string,
    '128x128': string,
    [propKey: string]: string
}
export interface TypedMedia {
    media_id?: string;
    media_url?: string;
    media_filename?: string;
    media_description?: string;
    media_filetype?: string;
    media_filesize?: number;
    media_extention?: string;
    media_thumbnail?: TypedThumbnail;
    private_access?: number;
    createdBy?: string;
    createdAt?: string;
    updatedAt?: string;
    user?: TypedUser
}



type IQuery = TypedMedia & IQueryParams;


/*
* List of all app
*/
export function useGetMedias(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["media/fetch_entity_list"],
        queryFn: () => axios.get<TypedMedia[]>(`/media${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetMedia() {
    return useMutation({
        mutationKey: ['media/fetch_entity'],
        mutationFn: (id: string) => axios.get<TypedMedia>(`/media/${id}`).then((res) => res.data),
        retry: 1,
    });
}


export function useUpdateMedia() {
    return useMutation({
        mutationKey: ['media/update_entity'],
        mutationFn: ({ media_id, ...rest }: TypedMedia) => axios.put<any>(`/media/${media_id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['media/fetch_entity_list'] });
        }
    });
}


export function usePartialUpdateMedia() {
    return useMutation({
        mutationKey: ['media/partial_update_entity'],
        mutationFn: ({ media_id, ...rest }: TypedMedia) => axios.patch<any>(`/media/${media_id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['media/fetch_entity_list'] });
        }
    });
}


export function useUploadFile() {
    return useMutation({
        mutationKey: ['media/upload_entity'],
        mutationFn: (urldata: any) => axios.post<any>(`/media/upload`, urldata).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['media/fetch_entity_list'] });
        }
    });
}


export function useDeleteMedia() {
    return useMutation({
        mutationKey: ['media/delete_entity'],
        mutationFn: (media_id: string) => axios.delete<any>(`/media/${media_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['media/fetch_entity_list'] });
        }
    });
}

export function useRegenerateThumbnailMedia() {
    return useMutation({
        mutationKey: ['media/regenerate_thumbnail'],
        mutationFn: () => axios.post<any>(`/media/regenerate-thumbnail`)
    });
}
