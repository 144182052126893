import { Form, Modal, Text, ResourceItem, ResourceList, TextField, Thumbnail, Card } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import helpers from 'helpers';
import { useGetProducts } from 'queries/product.query';
import { TypedProduct } from 'interface/product.model';

/**
 * modal show to find product ...
 * @param param0
 * @returns product_id or nothing...
 */

export default function QuickSearchProduct({
  show,
  onClose,
  ignoreProduct
}: {
  show: boolean;
  ignoreProduct?: bigint[];
  onClose: (product_id: bigint | null) => void;
}) {
  const [entities, setEntities] = useState<TypedProduct[]>([]);
  const [mainQuery, setMainQuery] = useState({
    limit: 2,
    product_status: 1,
    query: '',
    product_id: ''
  });
  const {
    refetch: getEntities,
    data: productEntities,
    isLoading: loading
  } = useGetProducts(mainQuery);
  useEffect(() => {
    if (productEntities) {
      let {
        body,
        totalItems
      } = productEntities;
      setEntities(body);
    }
  }, [productEntities]);

  /** Load lần đầu ... */
  useEffect(() => {
    if (show) onChangeText('');
  }, [show]);
  const closeBtn = useCallback(() => {
    onClose(null);
  }, []);
  const [value, setValue] = useState('');
  const onChangeCallback = useMemo(() => helpers.debounce(_value => onChangeText?.call(this, _value), 300), []);
  const onChangeText = (_t: string) => {
    setMainQuery({
      limit: 2,
      product_status: 1,
      query: _t,
      product_id: ignoreProduct ? '!' + helpers.ArrayToComma(ignoreProduct) : null
    });
    setTimeout(getEntities, 500);
  };
  const handleTextChange = useCallback((newValue: string) => {
    setValue(newValue);
    onChangeCallback(newValue);
  }, []);

  /**
   * selectableCallback
   * @param product_id
   */
  function selectableCallback(product_id: bigint) {
    onClose(product_id); // truyen ID sang ben khac
  }
  return <>
      <Modal activator={null} open={show} onClose={closeBtn} title="Tìm sản phẩm">
        <Modal.Section>
          <Form onSubmit={null}>
            <TextField label="Tên sản phẩm hoặc mã SKU" value={value} onChange={handleTextChange} autoComplete="off" />
          </Form>
          <br />
          <ResourceList resourceName={{
          singular: 'product',
          plural: 'products'
        }} items={entities ?? []} loading={loading} renderItem={item => {
          const {
            product_id,
            product_name,
            product_price,
            product_sku,
            product_thumbnail_to_media
          } = item;
          const media = <Thumbnail source={helpers.getMediaLink(product_thumbnail_to_media ? product_thumbnail_to_media.media_thumbnail['128x128'] : undefined)} alt="" />;
          return <ResourceItem id={product_id} url={null} onClick={() => {
            selectableCallback(BigInt(product_id));
          }} media={media}>
                  <Text variant="bodyMd" fontWeight="bold" as="h3">
                    {product_name}
                  </Text>
                  <div>
                    <Text as="p" tone="critical">
                      Giá {product_price ?? ''}
                    </Text>
                    <Text as="p">{product_sku ?? ''}</Text>
                  </div>
                </ResourceItem>;
        }} />
        </Modal.Section>
      </Modal>
    </>;
}