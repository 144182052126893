import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import helpers from "helpers/index";
import { TypedProduct } from "interface/product.model";

/**
 * Lấy thông tin cơ bản trong tháng ...
 */

export function useGetGeneralClientAnalytics() {
    return useQuery({
        queryKey: ['analytics_client/kol/general'],
        queryFn: () => axios.get<{
            newCustomerToday: number,
            revenueToday: number,
            orderCountToday: number,
        }>(`/analytics_client/kol/general`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
    });
}


export function useGetClientMonthlyReport() {
    return useQuery({
        queryKey: ['analytics_client/kol/monthly_report'],
        queryFn: () => axios.get<{
            [propKey: string]: {
                "customer": number,
                "revenue": number,
                "order": number
            },
        }[]>(`/analytics_client/kol/monthly_report`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
    });
}

/** Để vẽ biểu đồ ... Má! */
/** 'today' | "yesterday" | "this_month" | "last_month" | "this_year" | "last_year" */
export function useGetClientOrderReport(interval: string = 'today') {
    return useQuery({
        queryKey: ['analytics_client/kol/order_report'],
        queryFn: () => axios.get<{
            hour?: string,
            date?: string,
            month?: string,
            "totalValue": number,
            "totalCount": number,
        }[]>(`/analytics_client/kol/order_report?interval=${interval}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
    });
}


export function useGetTopProduct(dateobject: { from: number, to: number }) {
    return useQuery({
        queryKey: ['analytics_client/top_products'],
        queryFn: () => axios.get<{
            _sum: { quantity: number },
            product_id: number,
            product: TypedProduct
        }[]>(`/analytics_client/top_products${helpers.buildEndUrl(dateobject)}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
    });
}