import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';


export interface TypedWallet_type {
    id?: string | bigint;
    wallet_name?: string;
    wallet_description?: string;
    wallet_unit?: string;
    wallet_sign?: string;

    is_allowed_withdraw?: number;
    withdraw_min_allowed?: number;
    withdraw_min_balance?: number;

    rank_allowed?: number;
    is_default?: number;
    createdAt?: string | bigint;
}

type TypedQuery = TypedWallet_type & IQueryParams;


/*
* List of all app
*/
export function useGetWallets(object: TypedQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["wallet_type/fetch_entity_list"],
        queryFn: () => axios.get<TypedWallet_type[]>(`/wallet_type${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
    });
}

/*
* Dành cho tìm kiếm ...
*/
export function useFindWallets() {
    return useMutation({
        mutationKey: ['wallet_type/find_entity'],
        mutationFn: (object: TypedQuery) => axios.get<TypedWallet_type[]>(`/wallet_type` + helpers.buildEndUrl(object)).then((response) => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
    });
}

type TypedWalletUserList = {
    "wallet_id"?: string,
    "wallet_type_id"?: string,
    "user_id"?: string,
    "balance"?: number,
    "wallet_hash"?: string,
    "createdAt"?: string,
    "updatedAt"?: string,
    "user"?: {
        "user_email": string,
        "user_phonenumber": string,
        "display_name": string,
        "user_id": string
    }
}

type TypedIUserQuery = TypedWalletUserList & IQueryParams;


export function useGetUserUsingWallet() {
    return useMutation({
        mutationKey: ['wallet/find_user_using_entity'],
        mutationFn: (qries: TypedIUserQuery) => axios.get<TypedWalletUserList[]>(`/wallet` + helpers.buildEndUrl(qries)).then((response) => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
    });
}



export function useGetWallet() {
    return useMutation({
        mutationKey: ['wallet_type/fetch_entity'],
        mutationFn: (wallet_id: string) => axios.get<TypedWallet_type>(`/wallet_type/${wallet_id}`).then((res) => res.data),
    });
}


export function useUpdateWallet() {
    return useMutation({
        mutationKey: ['wallet_type/update_entity'],
        mutationFn: ({ id, ...rest }: TypedWallet_type) => axios.patch<any>(`wallet_type/${id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['wallet_type/fetch_entity_list'] });
        }
    });
}

export function useCreateWallet() {
    return useMutation({
        mutationKey: ['wallet_type/create_entity'],
        mutationFn: (entity: TypedWallet_type) => axios.post<any>(`wallet_type/`, helpers.cleanEntity(entity)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['wallet_type/fetch_entity_list'] });
        }
    });
}


export function useDeleteWallet() {
    return useMutation({
        mutationKey: ['wallet_type/delete_entity'],
        mutationFn: (wallet_id: string) => axios.delete<any>(`wallet_type/${wallet_id}`)
    });
}