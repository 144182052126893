import React from 'react';
import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import userVerifiedList from './user_verified.list';
import userVerifiedView from './user_verified.view';
export default function UserVerified() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = userVerifiedList;
      break;
    case 'view':
      ActualPage = userVerifiedView;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>{<ActualPage />}</>;
}