import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, FormLayout, InlineStack, Popover, SkeletonDisplayText, Tag, Text, TextField } from '@shopify/polaris';
import { TypedBanner_tag, useAddBannerTag, useDeleteBannerTag, useGetBannerTags } from 'queries/banner.query';
import __helpers from 'helpers/index';
import { PlusIcon } from '@shopify/polaris-icons';
export default function ListBannerTag({
  onChange
}: {
  onChange: (id) => void;
}) {
  const {
    data,
    isFetching
  } = useGetBannerTags({
    limit: 20
  });
  const {
    mutateAsync: addBannerTag
  } = useAddBannerTag();
  const [entities, setEntities] = useState<TypedBanner_tag[]>(null);
  useEffect(() => {
    if (!data) return;
    let {
      body,
      totalItems
    } = data;
    setEntities(body);
  }, [data]);
  const {
    mutate: deleteBannerTag
  } = useDeleteBannerTag();
  const movingToMyTag = useCallback((id: string) => {
    onChange(id);
  }, []);
  const removeTag = useCallback((id: string) => () => {
    deleteBannerTag(id);
  }, []);
  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(() => setPopoverActive(popoverActive => !popoverActive), []);
  const [tagValue, setTagValue] = useState('');
  const handleTagValueChange = useCallback((value: string) => setTagValue(value), []);
  const addNewTag = useCallback(async () => {
    try {
      await addBannerTag({
        tag_name: tagValue
      });
      setTagValue('');
      setPopoverActive(false);
    } catch (e) {}
  }, [tagValue]);
  return <InlineStack align="start" blockAlign="center" gap="400">
      <Popover active={popoverActive} activator={<Button onClick={togglePopoverActive} icon={PlusIcon}>
            Thêm nhóm
          </Button>} onClose={togglePopoverActive} ariaHaspopup={false} sectioned>
        <Form onSubmit={addNewTag}>
          <FormLayout>
            <TextField label="Nhóm" value={tagValue} onChange={handleTagValueChange} autoComplete="off" />
            <Button submit size="slim">
              Thêm
            </Button>
          </FormLayout>
        </Form>
      </Popover>
      <div>
        {isFetching ? <SkeletonDisplayText /> : __helpers.isEmpty(entities) ? <Text as="p">Chưa có một nhóm nào!</Text> : <InlineStack gap="400" align="start" blockAlign="center">
            <Tag onClick={() => movingToMyTag('')}>Tất cả</Tag>
            {entities?.map((el, index) => {
          return <Tag key={index + 'udgfhis'} onClick={() => movingToMyTag(el.tag_slug)}>
                  {el.tag_name}
                </Tag>;
        })}
          </InlineStack>}
      </div>
    </InlineStack>;
}