import '@shopify/polaris/build/esm/styles.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { separate_route as SeparateRoute, public_route, private_route } from './config/router-define';
import AppFrame from 'layout/appFrame';
import Theme404 from 'layout/404';
import ProtectedRoute from 'ProtectedRoute';
import useRefreshTokenHelper from 'components/useRefreshTokenHelper';
const baseHref = document.querySelector('base')?.getAttribute('href')?.replace(/\/$/, '');
export function App() {
  useRefreshTokenHelper();
  return <BrowserRouter basename={baseHref}>
      <Routes>
        {SeparateRoute.map((MainRouter, index) => <Route key={index} path={MainRouter.path} element={<MainRouter.Entity />} />)}
        {public_route.map((MainRouter, index) => <Route key={`public_${index}`} path={MainRouter.path} element={<AppFrame>
                <MainRouter.Entity />
              </AppFrame>} />)}
        {private_route.map((MainRouter, index) => <Route key={`private_${index}`} path={MainRouter.path} element={<ProtectedRoute>
                <AppFrame>
                  <MainRouter.Entity />
                </AppFrame>
              </ProtectedRoute>} />)}
        <Route key={99999} path="*" element={<Theme404 />} />
      </Routes>
    </BrowserRouter>;
}
export default App;