import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import ActivityList from './activity.list';
import ActivityView from './activity.view';
import { Helmet } from 'react-helmet-async';
export default function Activity() {
  let {
    slug = 'list'
  } = useParams();
  return <>
      <Helmet>
        <title>Activity tracing</title>
      </Helmet>
      {slug === 'list' ? <ActivityList /> : slug === 'view' ? <ActivityView /> : <Theme404 />}
    </>;
}