import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import helpers from "helpers/index";
import queryClient from 'queries';


export type TypedDefaultProductCommission = {
    commission_level: number,
    commission_value: number,
    commission_method: string,
}

export type TypedDefaultProductCommissionAdvance = {
    commission_parent_index: number,
    user_role: string,
    commission_value: number,
}
export type TypedDefaultProductRebate = {
    rebate_value: number,
    rebate_method: string,
    user_role: string
}

export type TypedDefaultProductTieredRebate = {
    rebate_value: number,
    rebate_method: string,
    user_role: "agency" | "collaborator" | "customer" | string
}


export type TypedDefaultAffiliateSetting = {
    commission: TypedDefaultProductCommission[],
    commission_advance: TypedDefaultProductCommissionAdvance[],
    rebate: TypedDefaultProductRebate[],
    tiered_rebate: TypedDefaultProductTieredRebate[],
}


/*
* List of all app
*/

export function useGetDefaultAffiliateSetting() {
    return useQuery({
        queryKey: ["default_affiliate_setting/fetch_entity_list"],
        queryFn: () => axios.get<TypedDefaultAffiliateSetting>(`/default_affiliate_setting`).then(response => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}

export function useCreateDefaultAffiliateSetting() {
    return useMutation({
        mutationKey: ['default_affiliate_setting/create_entity'],
        mutationFn: (entity: TypedDefaultAffiliateSetting) => axios.post<TypedDefaultAffiliateSetting>(`/default_affiliate_setting`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['default_affiliate_setting/fetch_entity_list'] });
        }
    });
}


/** COMMISSION RANK */
/** COMMISSION RANK */
/** COMMISSION RANK */
/** COMMISSION RANK */
/** COMMISSION RANK */

export type TypedProductCommissionRank = {
    commission_parent_index: number,
    user_rank: string,
    commission_value: number,
}


export function useGetDefaultProductCommissionRank() {
    return useQuery({
        queryKey: ["default_product_commission_rank/fetch_entity_list"],
        queryFn: () => axios.get<TypedProductCommissionRank[]>(`/default_affiliate_setting/default_product_commission_rank`).then(response => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}

export function useCreateDefaultProductCommissionRank() {
    return useMutation({
        mutationKey: ['default_product_commission_rank/create_entity'],
        mutationFn: (entity: TypedProductCommissionRank[]) => axios.post<TypedProductCommissionRank[]>(`/default_affiliate_setting/default_product_commission_rank`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['default_product_commission_rank/fetch_entity_list'] });
        }
    });
}