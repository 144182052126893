import { Checkbox, ContextualSaveBar, Form, Layout, Page, Select, TextField, Toast, Card, BlockStack, Loading, Text, Box } from '@shopify/polaris';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import helpers from 'helpers';
import { FormPrompt } from 'components/FormPrompt';
import DialogConfirm from 'components/dialogConfirm';
import QuickUploadImage from 'components/oneclick-upload-image';
import { Helmet } from 'react-helmet-async';
import TextFieldRichText from 'components/TextFieldRichText';
import QuickSearchPostCategory from './quickSearchPostCategory';
import __ from 'languages/index';
import { useCreatePost, useDeletePost, useGetPostType, useMutateGetPost, useUpdatePost } from 'queries/posts.query';
import { useNotification } from 'context/NotificationContext';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
export default function PageEdit() {
  const {
    addNotification
  } = useNotification();
  const {
    mutate: getEntity,
    data: entity,
    isPending: loading
  } = useMutateGetPost();
  const {
    data: allPostType
  } = useGetPostType();
  const {
    mutateAsync: updateEntity,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdatePost();
  const {
    mutate: deleteEntity
  } = useDeletePost();
  const {
    mutateAsync: createEntity,
    isSuccess: createSuccess
  } = useCreatePost();
  const history = useNavigate();

  /**
   * Comment status change
   */

  const [comment_status, setComment_status] = useState(false);
  const handCommentStatusChange = useCallback(newChecked => setComment_status(newChecked), []);

  /**
   * End comment status
   */

  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDeletePost = useCallback(async pages_slug => {
    const confirmed = await showDeleteConfirm('Xóa bài đăng này?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      deleteEntity(pages_slug);
      history(-1);
    }
  }, []);
  const [postType, setPostType] = useState([]);
  const [selectedPostType, setSelectedPostType] = useState('post');
  const handlePostTypeChange = useCallback(_checked => {
    setSelectedPostType(_checked);
  }, []);
  let {
    pages_slug
  } = useParams();
  useEffect(() => {
    if (pages_slug) {
      getEntity(pages_slug);
    }
  }, []);
  useEffect(() => {
    if (!allPostType) return;
    let alltypes = allPostType.map(el => {
      return {
        label: el,
        value: el
      };
    });
    setPostType(alltypes);
  }, [allPostType]);
  useEffect(() => {
    if (updateSuccess) {
      addNotification('info', __('updated_successfully'));
      history('/posts/edit/' + entity?.post_id);
    }
  }, [updateSuccess]);
  useEffect(() => {
    if (createSuccess) addNotification('info', 'created_successfully');
  }, [createSuccess]);
  useEffect(() => {
    if (entity) {
      setSelectedPostType(entity.post_type);
      setComment_status(Boolean(entity.comment_status));
    }
  }, [entity]);
  const useFields = {
    post_title: useField<string>({
      value: entity?.post_title ?? '',
      validates: [notEmpty('Trường này không được để trống.'), lengthLessThan(250, 'No more than 250 characters.'), lengthMoreThan(2, 'No shorter than 2 characters.')]
    }),
    post_excerpt: useField<string>({
      value: entity?.post_excerpt ?? '',
      validates: [lengthLessThan(255, 'Không được dài quá 255 ký tự cho mô tả ngắn')]
    }),
    post_content: useField<string>({
      value: entity?.post_content ?? '',
      validates: [lengthLessThan(10000, 'Không được dài quá 10 000 ký tự')]
    }),
    post_name: useField<string>({
      value: entity?.post_name ?? '',
      validates: [notEmpty('Trường này không được để trống.'), lengthLessThan(250, 'No more than 250 characters.'), lengthMoreThan(2, 'No shorter than 2 characters.'), inputValue => {
        if (helpers.isUTF8(inputValue)) {
          return 'UTF-8 can not be used here.';
        }
      }]
    }),
    post_status: useField<string>({
      value: entity?.post_status ?? 'draft',
      validates: []
    }),
    post_thumbnail: useField<string>({
      value: entity?.post_thumbnail,
      validates: []
    }),
    post_thumbnail_cache: useField<string>({
      value: entity?.post_thumbnail_to_media ? entity?.post_thumbnail_to_media?.media_thumbnail ? entity?.post_thumbnail_to_media?.media_thumbnail['128x128'] : '' : '',
      validates: []
    }),
    post_category: useField<string[]>({
      value: entity ? entity.post_to_category?.map(el => el.category_id) : [],
      validates: []
    })
  };

  /** Slug tự động ... */
  useEffect(() => {
    if (pages_slug) return;
    useFields.post_name.onChange(helpers.slugify(useFields.post_title.value));
  }, [useFields.post_title, pages_slug]);
  const {
    fields,
    submit,
    dirty
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!pages_slug) {
          // create new
          await createEntity({
            post_title: values.post_title,
            post_content: values.post_content,
            post_excerpt: values.post_excerpt,
            post_name: values.post_name,
            post_status: values.post_status,
            post_type: selectedPostType,
            comment_status: Number(comment_status),
            post_thumbnail: values.post_thumbnail,
            post_category: values.post_category
          });
        } else {
          await updateEntity({
            post_id: entity.post_id,
            post_title: values.post_title,
            post_content: values.post_content,
            post_excerpt: values.post_excerpt,
            post_name: values.post_name,
            post_status: values.post_status,
            post_type: selectedPostType,
            comment_status: Number(comment_status),
            post_thumbnail: values.post_thumbnail,
            post_category: values.post_category
          });
        }
      } catch (e: any) {
        if (typeof e.params !== 'undefined' && e.params.field) {
          useFields[e.params.field]?.setError(e.message);
        }
      }
      return {
        status: 'success'
      };
    }
  });
  const [showConfirmUnsavedForm, setShowConfirmUnsavedForm] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const Actual_page = <>
      {dirty ? <ContextualSaveBar alignContentFlush message="Thay đổi chưa được lưu" saveAction={{
      content: 'Lưu lại',
      loading: updating,
      onAction: submit
    }} discardAction={{
      content: 'Quay về',
      onAction: () => {
        setShowConfirmUnsavedForm(true);
      }
    }} /> : null}
      <Page title="Chỉnh sửa bài viết" backAction={{
      content: 'Post list',
      onAction: () => {
        dirty ? setShowConfirmUnsavedForm(true) : history('/posts');
      }
    }} primaryAction={{
      content: 'Save',
      onAction: submit,
      disabled: !dirty,
      loading: updating
    }} secondaryActions={pages_slug ? [{
      content: 'Delete',
      destructive: true,
      onAction: () => handleDeletePost(pages_slug)
    }] : <></>}>
        <FormPrompt hasUnsavedChanges={dirty} />
        <DialogConfirm tone="critical" show={showConfirmUnsavedForm} onClose={e => {
        e && history('/posts');
        setShowConfirmUnsavedForm(false);
      }} title={'Rời khỏi trang mà chưa lưu thay đổi?'} content={'Rời khỏi trang này sẽ xóa tất cả các thay đổi chưa được lưu. Bấm hủy bỏ để tiếp tục chỉnh sửa.'} />

        <Layout>
          <Layout.Section>
            <Form onSubmit={submit}>
              <Card>
                <BlockStack gap="400">
                  <TextField autoComplete="off" label="Tiêu đề bài viết"
                // onFocus={submit}
                autoFocus {...fields.post_title} />
                  <TextField autoComplete="off" label="Đường dẫn tĩnh cho bài đăng" {...fields.post_name} />

                  <TextField autoComplete="off" showCharacterCount maxLength={255} label="Mô tả ngắn" multiline={2} {...fields.post_excerpt} />

                  <TextFieldRichText label="Nội dung" initialData={entity?.post_content} onCallback={e => fields.post_content.onChange(e)} />
                </BlockStack>
              </Card>
            </Form>
          </Layout.Section>

          <Layout.Section variant="oneThird">
            <Box background="bg-fill" borderRadius="400" padding={'400'}>
              <BlockStack gap="400">
                <Text as="h4" variant="headingSm">
                  Ảnh đại diện
                </Text>

                <QuickUploadImage height={250} onSuccess={e => {
                useFields.post_thumbnail.onChange(e.media_id);
                useFields.post_thumbnail_cache.onChange(e.media_url);
              }} onError={e => setUploadError(e)} title="" placeholder={helpers.getMediaLink(useFields.post_thumbnail_cache.value)} />
                <Text as="p" tone="subdued" variant="bodyXs">
                  Ảnh nên là kích thước theo tỉ lệ FullHD, hoặc 256x169 pixel
                </Text>

                <Text as="p" tone="critical">
                  {uploadError}
                </Text>
              </BlockStack>
            </Box>
            <br />

            <Card>
              <Select label="Tình trạng bài đăng" options={[{
              label: 'Bản nháp',
              value: 'draft'
            }, {
              label: 'Xuất bản ngay',
              value: 'publish'
            }, {
              label: 'Đã xóa',
              value: 'deleted'
            }, {
              label: 'Đã khóa',
              value: 'lock'
            }]} value={useFields.post_status.value} onChange={value => useFields.post_status.onChange(value)} />
              <br />
              <Select label="Chọn loại bài đăng" value={selectedPostType} onChange={handlePostTypeChange} options={postType} />

              <br />
              <Checkbox label="Cho phép bình luận?" checked={comment_status} onChange={handCommentStatusChange} />
            </Card>

            <br />
            <Card padding={'400'}>
              <BlockStack gap="100">
                <Text as="h3" variant="bodyMd" fontWeight="bold">
                  Chuyên mục
                </Text>
                <Suspense fallback={<Text as="p">Đang tải...</Text>}>
                  <QuickSearchPostCategory current_category_id={useFields.post_category.value} errorMessage={useFields.post_category.error} onClose={category_ids => {
                  useFields.post_category.onChange(category_ids);
                }} />
                </Suspense>
              </BlockStack>
            </Card>

            <br />
          </Layout.Section>
        </Layout>
      </Page>
      <br />
      <br />
    </>;
  return <>
      <Helmet>
        <title>Chỉnh sửa bài đăng</title>
      </Helmet>
      {loading && <Loading />}
      {Actual_page}
    </>;
}