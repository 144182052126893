import { useCallback, useEffect, useMemo, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { InlineGrid, Box, Form, Text, Loading } from '@shopify/polaris';
import dateandtime from 'date-and-time';
import __ from 'languages/index';
import { ChatIcon } from '@shopify/polaris-icons';
import For from 'components/For';
import { MentionsInput, Mention } from 'react-mentions';
import axios from 'axios';
import StarRating from './starRating';
import React from 'react';
import helpers from 'helpers/index';
import Pagination from './pagination';
import { TypedUser } from 'interface/user.model';
import { useLocation } from 'react-router-dom';
import 'media/css/mention.scss';
import { useCreateComment, useGetComments } from 'queries/comment.query';
import StarRatingComponent from './starRatingTextField';
type TypedTimeline = {
  objectId: string;
  commentType: string;
  title: string;
  showAddnew?: boolean;
  recordPerPage?: number;
  createdBy?: string;
};
const MentionComponent = forwardRef((props, ref: any) => {
  const inputRef = useRef(null);
  const [quickNoteText, setQuickNoteText] = useState('');
  const handlerQuickNoteCallback = useCallback((e: any) => {
    let text = e.target.value;
    setQuickNoteText(text);
    ref.current.value = text;
  }, []);
  useImperativeHandle(ref, () => {
    return {
      clear() {
        setQuickNoteText('');
      },
      getValue() {
        return quickNoteText;
      },
      disabled() {
        inputRef.current.disabled = true;
      },
      enabled() {
        inputRef.current.disabled = false;
        inputRef.current.focus();
      }
    };
  }, [quickNoteText]);
  const fetchUsers = useCallback((query: string, callback: Function) => {
    if (!query) return;
    axios.get(process.env.REACT_APP_BACKEND_URL + `/user/search?query=${query}`).then(res => callback(res?.data.map((user: TypedUser) => ({
      display: user.display_name,
      id: user.user_id
    }))));
  }, []);
  const fetchUserData = useMemo(() => {
    return helpers.debounce((_value, c) => {
      fetchUsers?.call(this, _value, c);
    }, 500);
  }, []);
  return <MentionsInput className="jamviet_mention_form" singleLine placeholder={"Nhắc tên ai đó sử dụng '@'"} value={quickNoteText} inputRef={inputRef} onChange={handlerQuickNoteCallback}>
      <Mention className="jamviet_mention_form_mention" trigger="@" data={fetchUserData} />
    </MentionsInput>;
});
export default function CommentsComponent({
  objectId,
  createdBy,
  commentType,
  title,
  showAddnew = true,
  recordPerPage = 50
}: TypedTimeline) {
  const {
    mutateAsync: createEntity,
    isSuccess: updateSuccess
  } = useCreateComment();
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const {
    hash
  } = useLocation();
  const mentionRef = useRef(null);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  const [mainQuery, setMainQuery] = useState({
    query: '',
    page: 1,
    limit: recordPerPage,
    sort: 'createdAt:desc',
    object_id: objectId,
    createdBy: createdBy,
    comment_type: commentType
  });
  const {
    refetch: getEntities,
    data,
    isLoading: loading
  } = useGetComments(mainQuery);
  useEffect(() => {
    if (data) {
      setEntities(data.entities);
      setTotalItems(data.totalItems);
    }
  }, [data]);

  /**
   * Change page number
   */

  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  useEffect(() => {
    getEntities();
  }, [mainQuery]);
  const point = useRef(0);
  const saveCommentForm = useCallback(async () => {
    mentionRef.current.disabled();
    try {
      let entity = await createEntity({
        comment_content: mentionRef.current.getValue(),
        object_id: objectId,
        comment_type: commentType,
        comment_status: 'publish',
        comment_point: point.current,
        notification_root_url: window.location.href
      });
      mentionRef.current.clear();
      setEntities([...[entity], ...entities]);
      mentionRef.current.enabled();
    } catch (e) {}
  }, [mentionRef, commentType, objectId, point, entities]);
  useEffect(() => {
    if (updateSuccess) {
      mentionRef.current.clear();
      point.current = 0;
    }
  }, [updateSuccess]);
  const groups = useRef('');
  const jump = useCallback(() => {
    let {
      hash
    } = new URL(window.location.href);
    if (!hash) return;
    let el = document.getElementById(String(hash).replace('#', ''));
    if (!el) return;
    window.scrollTo({
      behavior: 'smooth',
      top: el.getBoundingClientRect().x - 150
    });
  }, []);
  useEffect(() => {
    jump();
  }, [hash]);
  const commentBodyComponent = useCallback((element, index) => {
    let datetimeCreate = new Date(Number(element.createdAt));
    let {
      comment_title,
      comment_content,
      user,
      user_login,
      comment_id
    } = element;
    let dateMonthYear = dateandtime.format(datetimeCreate, 'ddd, DD, MMM, YYYY'); // Fri, 28, Apr, 2023
    let point = element.comment_point || 0;
    let A = false;
    if (dateMonthYear !== groups.current) {
      groups.current = dateMonthYear;
      A = true;
    }
    let N = dateMonthYear.toLowerCase().split(',');
    let M = __(N[0]) + ', ' + N[1] + ' ' + __(N[2]) + ', ' + N[3];
    return <React.Fragment key={`${index}_comment_body`}>
        {A && <div className="step-item">{M}</div>}
        <div className="card" id={'comment_' + comment_id}>
          <div className="timeline_handler"></div>
          <div className="time">
            <span className="hour">{dateandtime.format(datetimeCreate, 'HH:mm')}</span>
            <div className="author">{user?.display_name || user_login}</div>
            {point > 0 && <StarRating num={point} />}
          </div>
          <div className="info">
            <h3 className="title">{comment_title}</h3>
            <Text as="p" variant="bodyMd">
              {comment_content}
            </Text>
          </div>
        </div>
      </React.Fragment>;
  }, []);
  return <>
      <div className="small-icon" key="789_ih">
        <Text as="span" tone="subdued" fontWeight="bold" variant="bodyLg">
          <ChatIcon /> {title}
        </Text>
      </div>

      {loading && <Loading />}

      <InlineGrid gap="400">
        <Box padding="400">
          <div className="timeline">
            <div className="outer">
              {showAddnew && <div className="card">
                  <div className="timeline_handler"></div>
                  <div className="info">
                    <Form onSubmit={saveCommentForm}>
                      <Text as="p">{`Thêm ${title.toLowerCase()}`}</Text>
                      <br />
                      <StarRatingComponent title="Cho điểm" ref={point} />
                      <MentionComponent ref={mentionRef} />
                    </Form>
                  </div>
                </div>}
              <For each={entities} fallback={<div style={{
              padding: '15px'
            }}>
                    <Text as="p">Chưa có một hội thoại nào.</Text>
                  </div>}>
                {commentBodyComponent}
              </For>
            </div>
          </div>
        </Box>
      </InlineGrid>

      {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery?.page} pageSize={mainQuery?.limit} onChange={onChangePagination} /> : null}
      <br />
      <br />
    </>;
}