import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import { TypedProduct } from "interface/product.model";
import { TypedUser } from "interface/user.model";
import queryClient from 'queries';
import { TypedProduct_variant } from "./product_variant.query";



export interface TypedShopping_cart {
    id?: string;
    user_id?: string;
    total_value?: number;
    total_quantity?: number;
    lastUpdated?: string;
    createdAt?: string;

    user?: TypedUser
}

export interface TypedShopping_cart_item {
    id?: string;
    shopping_cart_id?: string;
    product_id?: string;
    variant_id?: string;
    cart_quantity?: number;
    cart_price?: number;
    createdAt?: string;

    product?: TypedProduct,
    product_variant?: TypedProduct_variant
}

type IQuery = TypedShopping_cart & IQueryParams;
type IQueryItem = TypedShopping_cart_item & IQueryParams;


/*
* List of all shopping_cart
*/
export function useGetShoppingCarts(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["shopping_cart/fetch_entity_list"],
        queryFn: () => axios.get<TypedShopping_cart[]>(`/shopping_cart/admin${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetShoppingCart(user_id: string) {
    return useQuery({
        queryKey: ['shopping_cart/fetch_entity'],
        queryFn: () => axios.get<TypedShopping_cart>(`/shopping_cart/admin/${user_id}`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


// shopping_cart_item/admin


export function useAdminGetShoppingCartItem(object: IQueryItem) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["shopping_cart_item/fetch_entity_list"],
        queryFn: () => axios.get<TypedShopping_cart[]>(`/shopping_cart_item/admin${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}


export function useAdminDeleteShoppingCartItem() {
    return useMutation({
        mutationKey: ['shopping_cart_item/delete_entity'],
        mutationFn: (shopping_cart_id: string) => axios.delete<any>(`/shopping_cart_item/admin/${shopping_cart_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['shopping_cart_item/fetch_entity_list'] })
        }
    });
}
