import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export type TypeEmailTemplate = {
    id?: string,
    template_name?: string,
    template_url?: string,
    // email_setting?: any,
    createdAt?: string,
}

type IQuery = TypeEmailTemplate & IQueryParams;


/*
* List of all app
*/
export function useGetEmailTemplates(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["email_template/fetch_entity_list"],
        queryFn: () => axios.get<TypeEmailTemplate[]>(`/email_template${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetEmailTemplate(app_id: string) {
    return useQuery({
        queryKey: ['email_template/fetch_entity'],
        queryFn: () => axios.get<TypeEmailTemplate>(`/email_template/${app_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useUpdateEmailTemplate() {
    return useMutation({
        mutationKey: ['email_template/update_entity'],
        mutationFn: ({ id, ...rest }: TypeEmailTemplate) => axios.patch<TypeEmailTemplate>(`/email_template/${id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['email_template/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['email_template/fetch_entity'] });
        }
    });
}


export function useCreateEmailTemplate() {
    return useMutation({
        mutationKey: ['email_template/create_entity'],
        mutationFn: (entity: TypeEmailTemplate) => axios.post<TypeEmailTemplate>(`/email_template`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['email_template/fetch_entity_list'] });
        }
    });
}


export function useDeleteEmailTemplate() {
    return useMutation({
        mutationKey: ['email_template/delete_entity'],
        mutationFn: (app_id: string) => axios.delete<any>(`/email_template/${app_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['email_template/fetch_entity_list'] });
        }

    });
}