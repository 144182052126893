import { Card, Badge, Text,
// IndexTable,
Link, Page,
// useIndexResourceState,
Thumbnail,
// LegacyCard,
DataTable,
// TableData,
InlineStack, Button } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import helpers from 'helpers';
import dateandtime from 'date-and-time';
import { DeleteIcon } from '@shopify/polaris-icons';
import Pagination from 'components/pagination';
// import SkeletonLoading from 'components/skeletonPageLoading';
import __, { ___ } from 'languages/index';
// import SimpleFilter from 'components/simpleFilter';
import { Helmet } from 'react-helmet-async';
import ProductCategoryModalAddAndEdit from './posts.category.edit.modal';
import { useGetPostCategories, useDeletePostCategory } from 'queries/post_category.query';
import { TypedPostCategory } from 'queries/posts.query';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
export default function PostCategory() {
  const [entities, setEntities] = useState<TypedPostCategory[]>(null);
  const [totalItems, setTotalItems] = useState(0);
  const {
    mutateAsync: deletePostCategory
  } = useDeletePostCategory();

  // const dispatch = useAppDispatch();
  // const history = useNavigate();

  const rootSearchPath = window.location.search;
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 20,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    isLoading: searching,
    data: productCategoryData
  } = useGetPostCategories(mainQuery);
  useEffect(() => {
    if (productCategoryData) {
      let {
        body,
        totalItems
      } = productCategoryData;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [productCategoryData]);

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    setMainQuery(mainQuery);
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Post Category', '/posts/category' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  const [showModal, setShowModal] = useState(false);
  const modalEntity = useRef(null);
  const clickToShowEditModal = useCallback((categoryData: TypedPostCategory) => {
    modalEntity.current = categoryData;
    setShowModal(true);
  }, []);

  // delete ?

  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async category_id => {
    const confirmed = await showDeleteConfirm('Xóa chuyên mục bài đăng này?', 'Chỉ các chuyên mục không có bài đăng nào mới có thể xóa bỏ. Nếu chuyên mục còn bài đăng, vui lòng di chuyển sang chuyên mục khác trước.');
    if (confirmed) {
      // Xử lý hành động xóa
      try {
        await deletePostCategory(category_id);
      } catch (e) {}
    }
  }, []);
  return <>
      <Helmet>
        <title>Quản lý danh mục bài đăng</title>
      </Helmet>

      <ProductCategoryModalAddAndEdit show={showModal} onClose={(el: any) => {
      modalEntity.current = null;
      setShowModal(false);
    }} entity={modalEntity.current} />

      <Page title="Danh mục bài đăng" primaryAction={{
      content: 'Tạo mới danh mục',
      onAction: () => setShowModal(true)
    }}>
        <Card padding={'0'}>
          <DataTable verticalAlign="middle" columnContentTypes={['text', 'text', 'numeric', 'text', 'numeric', 'numeric']} headings={['Tên chuyên mục', 'Mô tả', 'Bài đăng', 'Hiển thị', 'Ngày tạo', '-']} rows={entities?.length > 0 ? entities.map((categoryData, index) => {
          return [<Link removeUnderline onClick={() => clickToShowEditModal(categoryData)} key={index}>
                        {categoryData.category_thumbnail ? <InlineStack blockAlign="center" gap={'100'}>
                            <Thumbnail size="small" source={helpers.getMediaLink(categoryData.category_thumbnail_to_media ? categoryData.category_thumbnail_to_media.media_thumbnail ? categoryData.category_thumbnail_to_media.media_thumbnail['128x128'] : null : null)} alt={''} />
                            <Text as="span">{categoryData.category_name}</Text>
                          </InlineStack> : <Text as="span">{categoryData.category_name}</Text>}
                      </Link>, <Text as="span">{helpers.trimMiddleString(categoryData.category_description, 30, 10)}</Text>, categoryData._count.post_to_category || 0, categoryData.category_status === 1 ? <Badge tone="success">Có</Badge> : <Badge tone="warning">Không</Badge>, dateandtime.format(new Date(Number(categoryData.createdAt)), 'DD-MM-YYYY'), <Button variant="plain" onClick={() => handleDelete(categoryData.category_id)} icon={DeleteIcon}></Button>];
        }) : []} footerContent={___('Show {display_record_number} of {total_record_number} results', {
          display_record_number: <Text as="strong">{entities?.length || 0}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} />
        </Card>
        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
    </>;
}