import { Avatar, Box, Text, Modal, ResourceItem, ResourceList, BlockStack, TextField, InlineStack, Button } from '@shopify/polaris';
import helpers from 'helpers/index';
import { useGetCustomers } from 'queries/customer.query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { XIcon } from '@shopify/polaris-icons';
import { TypedUser } from 'interface/user.model';
import { useCreateUser_to_group } from 'queries/user_to_group.query';
export default function AddMemberModal({
  current_user_group_id,
  show,
  onClose
}: {
  current_user_group_id: string;
  show: boolean;
  onClose: () => void;
}) {
  const initialQuery = {
    limit: 2,
    query: ''
  };
  const [mainQuery, setMainQuery] = useState<any>(initialQuery);
  const [entities, setEntities] = useState([]);
  const {
    data,
    refetch: getEntities,
    isLoading: loading
  } = useGetCustomers(mainQuery);
  const {
    mutateAsync: addUserToGroup,
    isPending,
    isSuccess
  } = useCreateUser_to_group();
  useEffect(() => {
    if (data) {
      let {
        body,
        totalItems
      } = data;
      setEntities(body);
    }
  }, [data]);
  const closeModalCallback = useCallback(() => {
    clearSelection();
    onClose();
  }, []);
  useEffect(() => {
    if (isSuccess) closeModalCallback();
  }, [isSuccess]);
  const [inputValue, setInputValue] = useState('');
  const reduceRequest = useCallback((keyword: string) => {
    setMainQuery({
      limit: 2,
      query: keyword
    });
    getEntities();
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    reduceRequestMemo(inputValue);
  }, [inputValue]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState<TypedUser>(null);
  const selectThisCustomerCallback = useCallback(entity => {
    setSelectedEntity(entity);
    setSelectedValue(entity.user_id);
  }, []);
  const clearSelection = useCallback(() => {
    setSelectedValue(null);
    setSelectedEntity(null);
  }, []);
  const submitAddMemberToSetting = useCallback(async () => {
    //
    try {
      await addUserToGroup({
        user_group_id: current_user_group_id,
        user_id: selectedValue
      });
    } catch (e) {
      console.error(e, 'EEE_JKHKUJ');
    }
  }, [selectedValue, current_user_group_id]);
  return <Modal open={show} title={'Thêm thành viên vào nhóm'} onClose={() => closeModalCallback()} primaryAction={{
    content: 'Thêm',
    disabled: !selectedValue,
    loading: isPending,
    onAction: () => submitAddMemberToSetting()
  }} secondaryActions={[{
    content: 'Đóng lại',
    onAction: () => closeModalCallback()
  }]}>
      <Box padding="400">
        <BlockStack gap="400">
          <TextField autoComplete="off" label="Tìm một người dùng" placeholder="Số điện thoại hoặc Email" value={inputValue} onChange={a => setInputValue(a)} loading={loading} />

          {selectedEntity && <Box borderBlockEndWidth="0165" borderBlockStartWidth="0165" paddingBlock={'150'}>
              <InlineStack align="start" blockAlign="center" gap="400">
                <Button variant="plain" icon={XIcon} onClick={() => clearSelection()}></Button>
                <Avatar customer size="md" name={selectedEntity.display_name} source={selectedEntity.user_avatar} />
                <BlockStack>
                  <Text variant="bodyMd" fontWeight="bold" as="h3">
                    {selectedEntity.display_name} {selectedEntity.user_phonenumber} {selectedEntity.user_email}
                  </Text>
                </BlockStack>
              </InlineStack>
            </Box>}

          {entities && entities.length > 0 ? <ResourceList resourceName={{
          singular: 'customer',
          plural: 'customers'
        }} items={entities ?? []} renderItem={item => {
          const {
            user_id,
            display_name,
            user_avatar,
            user_address,
            user_phonenumber
          } = item;
          const media = <Avatar customer size="md" name={display_name} source={user_avatar} />;
          return <ResourceItem id={user_id} url={null} media={media} onClick={() => selectThisCustomerCallback(item)}>
                    <Text variant="bodyMd" fontWeight="bold" as="h3">
                      {display_name}
                    </Text>
                    <div>
                      {user_phonenumber ? '0' + user_phonenumber : ''}
                      {user_address ? ' / ' + user_address : ''}
                    </div>
                  </ResourceItem>;
        }} /> : <>Không tìm thấy dữ liệu khách hàng</>}
        </BlockStack>
      </Box>
    </Modal>;
}