import { IndexTable, LegacyCard, useBreakpoints, Text, useIndexResourceState, Banner, Link } from '@shopify/polaris';
import Pagination from 'components/pagination';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DeleteIcon } from '@shopify/polaris-icons';
import SimpleFilter from 'components/simpleFilter';
import AddDeviceUUIDModal from './addDeviceUUIDModal';
import { useDeleteBlacklistDeviceUUID, useGetBlacklistDeviceUUID } from 'queries/blacklist_device_uuid.query';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
export default function IPBlacklist() {
  const {
    mutateAsync: deleteEntity
  } = useDeleteBlacklistDeviceUUID();
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */

  const initialQuery = {
    query: '',
    page: 1,
    limit: 10,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState(initialQuery);
  const {
    data,
    refetch: getEntities,
    isLoading: loading
  } = useGetBlacklistDeviceUUID(mainQuery);
  useEffect(() => {
    if (data) {
      setEntities(data.entities);
      setTotalItems(data.totalItems);
    }
  }, [data]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  useEffect(() => {
    getEntities();
  }, [mainQuery]);
  const resourceIDResolver = (bannedData: any) => {
    return bannedData.id ?? null;
  };
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection
  } = useIndexResourceState(entities, {
    resourceIDResolver
  });
  const rowMarkup = entities?.map(({
    id,
    device_uuid
  }, index) => <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
      <IndexTable.Cell>
        <Text variant="bodyMd" fontWeight="bold" as="span">
          {device_uuid}
        </Text>
      </IndexTable.Cell>
    </IndexTable.Row>);
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async () => {
    const confirmed = await showDeleteConfirm('Xóa các Device UUID này?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      for (let ip of selectedResources) {
        await deleteEntity(ip);
      }
    }
  }, [selectedResources]);
  const promotedBulkActions = [{
    icon: DeleteIcon,
    destructive: true,
    content: 'Delete device_uuid',
    onAction: () => handleDelete()
  }];
  const [showModal, setShowModal] = useState(false);
  const showAddnewModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);
  return <>
      <Helmet>
        <title>Danh sách device UUID bị cấm truy cập</title>
      </Helmet>

      <AddDeviceUUIDModal show={showModal} onClose={() => {
      setShowModal(false);
    }} />

      <Banner tone="critical">
        <p>
          Tất cả các device UUID trong danh sách này được bạn thêm vào thủ công, hoặc được chức năng bảo vệ của hệ thống THÊM VÀO một cách tự động.
          <br />
          Các truy vấn từ các device UUID này sẽ không thể làm một số việc như đăng nhập hoặc gửi yêu cầu hỗ trợ.
          <br />
          <Link onClick={showAddnewModal}>Thêm một device UUID vào danh sách</Link> nếu bạn nghi ngờ bị tấn công.
        </p>
      </Banner>

      <br />
      <br />

      <LegacyCard>
        <SimpleFilter loading={loading} onCallback={filterCallback} options={[]} sortField={[]} />
        <IndexTable condensed={useBreakpoints().smDown} resourceName={{
        singular: 'UUID',
        plural: 'UUID'
      }} itemCount={entities?.length ?? 0} headings={[{
        title: 'Chọn tất cả UUID'
      }]} promotedBulkActions={promotedBulkActions} selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length} onSelectionChange={handleSelectionChange} selectable={true}>
          {rowMarkup}
        </IndexTable>
      </LegacyCard>

      {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery?.page} pageSize={mainQuery?.limit} onChange={onChangePagination} /> : null}
      <br />
      <br />
    </>;
}