import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/

export interface TypedDevice {
    device_id?: string
    device_uuid?: string
    user_agent?: string
    system_type?: string
    status?: number
    createdAt?: string
    user_to_device?: any
    device?: any
}


type IQuery = TypedDevice & IQueryParams;


/*
* List of all app
*/
export function useGetDevices(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["device/fetch_entity_list"],
        queryFn: () => axios.get<TypedDevice[]>(`/device${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetDevice(device_id: string) {
    return useQuery({
        queryKey: ['device/fetch_entity'],
        queryFn: () => axios.get<TypedDevice>(`/device/${device_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useUpdateDevice() {
    return useMutation({
        mutationKey: ['device/update_entity'],
        mutationFn: ({ device_id, status }: any) => axios.patch<TypedDevice>(`/device/${device_id}`, { status }).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['device/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['device/fetch_entity'] });
        }
    });
}



export function useAssignUserToDeviceUUID() {
    return useMutation({
        mutationKey: ['device/assignUserToDeviceUUID'],
        mutationFn: ({ user_id, device_uuid }: any) => axios.post<any>(`/device/assign`, { user_id, device_uuid }).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['device/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['device/fetch_entity'] });
        }

    });
}