import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import { TypedUser } from "interface/user.model";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export type TypedPhoneCall = {
    phonelog_id?: string,
    phonelog_number?: string,
    phonelog_source?: string,
    user_id?: string,
    createdAt?: string,
    user?: TypedUser
}

type IQuery = TypedPhoneCall & IQueryParams;


/*
* List of all app
*/
export function useMutateGetPhonelogs() {
    return useMutation({
        mutationKey: ["phonelog/fetch_entity_list"],
        mutationFn: (object: IQuery) => axios.get<TypedPhoneCall[]>(`/phonelog${helpers.buildEndUrl(object)}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
    });
}


export function useUpdatePhonelog() {
    return useMutation({
        mutationKey: ['phonelog/update_entity'],
        mutationFn: ({ phonelog_id, ...rest }: TypedPhoneCall) => axios.patch<TypedPhoneCall>(`/phonelog/${phonelog_id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['phonelog/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['phonelog/fetch_entity'] });
        }
    });
}

