import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedGift } from "./gift.query";
import { TypedWallet_type } from "./wallet.query";
/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/

export interface TypedRedeem_request {
    id?: string;
    user_id?: string;
    gift_id?: string;
    gift_value?: number;
    wallet_type_id?: string;
    request_note?: string;
    /** -1 là từ chối, 0 là chưa làm gì, 1 là duyệt */
    request_status?: number;
    createdAt?: string;
    updatedAt?: string;
    updatedBy?: string;

    request_sender?: {
        user_id: string,
        user_avatar: string,
        display_name: string,
        user_phonenumber: string,
        user_email: string,
    }
    request_updater?: {
        user_id: string,
        user_avatar: string,
        display_name: string,
    },
    gift?: TypedGift,
    wallet_type?: TypedWallet_type
}



type IQuery = TypedRedeem_request & IQueryParams;


/*
* List of all 
*/
export function useGetRedeemRequests(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["redeem_request/fetch_entity_list"],
        queryFn: () => axios.get<TypedRedeem_request[]>(`/redeem_request${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: true,
        enabled: true,
    });
}



export function useGetRedeemRequest(redeem_id: string) {
    return useQuery({
        queryKey: ['redeem_request/fetch_entity'],
        queryFn: () => axios.get<TypedRedeem_request>(`/redeem_request/${redeem_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}
export function useMutateGetRedeemRequest() {
    return useMutation({
        mutationKey: ['redeem_request/fetch_entity'],
        mutationFn: (redeem_id: string) => axios.get<TypedRedeem_request>(`/redeem_request/${redeem_id}`).then((response) => response.data),
        retry: 1,
    });
}


export function useApprovedRedeemRequest() {
    return useMutation({
        mutationKey: ['redeem_request/update_entity'],
        mutationFn: (id: string) => axios.patch<TypedRedeem_request>(`/redeem_request/approved/${id}`).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['redeem_request/fetch_entity'] });
        }
    });
}


export function useDeniedRedeemRequest() {
    return useMutation({
        mutationKey: ['redeem_request/delete_entity'],
        mutationFn: (entity: { id: string, request_note: string }) => axios.patch<any>(`/redeem_request/denied/${entity.id}`, entity),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['redeem_request/fetch_entity'] });
        }

    });
}