import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';


import { TypedProductCategory } from '../interface/product.model';

interface IQuery extends IQueryParams, TypedProductCategory { };

/*
* List of all app
*/
export function useGetProductCategories(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["product_category/fetch_entity_list"],
        queryFn: () => axios.get<TypedProductCategory[]>(`/product_category/admin${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}
export function useMutateGetProductCategories() {
    return useMutation({
        mutationKey: ["product_category/fetch_entity_list"],
        mutationFn: (object: IQuery) => axios.get<TypedProductCategory[]>(`/product_category/admin${helpers.buildEndUrl(object)}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
    });
}



export function useGetProductCategory(app_id: string) {
    return useQuery({
        queryKey: ['product_category/fetch_entity'],
        queryFn: () => axios.get<TypedProductCategory>(`/product_category/${app_id}`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useUpdateProductCategory() {
    return useMutation({
        mutationKey: ['product_category/update_entity'],
        mutationFn: ({ category_id, ...rest }: TypedProductCategory) => axios.patch<any>(`product_category/${category_id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_category/fetch_entity_list'] });
        }
    });
}


export function useCreateProductCategory() {
    return useMutation({
        mutationKey: ['product_category/create_entity'],
        mutationFn: (entity: TypedProductCategory) => axios.post<any>(`product_category/`, helpers.cleanEntity(entity)).then(res => res.data).catch(e => { throw e }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_category/fetch_entity_list'] });
        }
    });
}


export function useDeleteProductCategory() {
    return useMutation({
        mutationKey: ['product_category/delete_entity'],
        mutationFn: (category_id: string) => axios.delete<any>(`product_category/${category_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_category/fetch_entity_list'] });
        }
    });
}
