import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import { TypedProduct } from "interface/product.model";
import queryClient from 'queries';



type IQuery = TypedProduct & IQueryParams;


/*
* List of all product, for admin only
*/
export function useGetProducts(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["product/fetch_entity_list"],
        queryFn: () => axios.get<TypedProduct[]>(`/product/admin${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}

export function useMutateGetProducts() {
    return useMutation({
        mutationKey: ["product/fetch_entity_list"],
        mutationFn: (object: IQuery) => axios.get<TypedProduct[]>(`/product/admin${helpers.buildEndUrl(object)}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
    });
}


/*
* Phục vụ chức năng search trong admin 
*/
export function useAdminSearchProducts(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["product/search_entity_list"],
        queryFn: () => axios.get<TypedProduct[]>(`/product/admin/search${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}


/** Mutate get Product ... */
export function useGetProduct() {
    return useMutation({
        mutationKey: ['product/fetch_entity'],
        mutationFn: (product_id: string) => axios.get<TypedProduct>(`/product/admin/${product_id}`).then((res) => res.data),
    });
}


export function useUpdateProduct() {
    return useMutation({
        mutationKey: ['product/update_entity'],
        mutationFn: ({ product_id, ...rest }: TypedProduct) => axios.patch<any>(`product/${product_id}`, rest).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['product/fetch_entity'] });
        }
    });
}


export function useCreateProduct() {
    return useMutation({
        mutationKey: ['product/create_entity'],
        mutationFn: (entity: TypedProduct) => axios.post<any>(`product/`, helpers.cleanEntity(entity)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product/fetch_entity_list'] });
        }
    });
}


export function useDeleteProduct() {
    return useMutation({
        mutationKey: ['product/delete_entity'],
        mutationFn: (product_id: string) => axios.delete<any>(`product/${product_id}`)
    });
}


type typedUpdateManyEntry = {
    product_id: string;
    product_status: string;
};

export function useBulkupdateProducts() {
    return useMutation({
        mutationKey: ['product/update_entities'],
        mutationFn: (entity: typedUpdateManyEntry) => axios.patch<any>(`product/bulkUpdateEntities`, helpers.cleanEntity(entity))
    });
}
