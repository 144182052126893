import { useCallback, useEffect } from 'react';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import { Form, Text, Modal, Select, TextField, BlockStack } from '@shopify/polaris';
import { TypedRank, useCreateRank, useUpdateRank } from 'queries/rank.query';
import __helpers from 'helpers/index';
import __ from 'languages/index';
import { useNotification } from 'context/NotificationContext';
import helpers from 'helpers/index';
export default function AddAchievementModal({
  open = false,
  entity,
  onClose
}: {
  open: boolean;
  onClose: Function;
  entity?: TypedRank;
}) {
  const {
    mutateAsync: createRank,
    isSuccess: createSuccesfully
  } = useCreateRank();
  const {
    mutateAsync: updateRank,
    isSuccess: updateSuccessfully
  } = useUpdateRank();
  const {
    addNotification
  } = useNotification();
  useEffect(() => {
    if (createSuccesfully) {
      addNotification('info', __('created_successfully'));
      closeModal();
    }
  }, [createSuccesfully]);
  useEffect(() => {
    if (updateSuccessfully) {
      addNotification('info', __('updated_successfully'));
      closeModal();
    }
  }, [updateSuccessfully]);
  const closeModal = useCallback(() => {
    onClose?.call(this, null);
    resetForm();
  }, []);
  const useFields = {
    rank_name: useField<string>({
      value: entity?.rank_name ?? '',
      validates: [notEmpty('Trường này không được để trống.'), lengthLessThan(150, 'Không được dài hơn 150 ký tự.'), lengthMoreThan(2, 'Không được ngắn hơn 2 ký tự.')]
    }),
    rank_slug: useField<string>({
      value: entity?.rank_slug,
      validates: [inputVal => {
        if (helpers.isUTF8(inputVal)) {
          return 'Không được chứa ký tự đặc biệt';
        }
      }]
    }),
    min_points: useField<string>({
      value: String(entity?.min_points ?? '0'),
      validates: [notEmpty('Trường này không được để trống.')]
    }),
    rank_description: useField<string>({
      value: entity?.rank_description ?? '',
      validates: [notEmpty('Trường này không được để trống.'), lengthLessThan(150, 'Không được dài hơn 150 ký tự.'), lengthMoreThan(2, 'Không được ngắn hơn 2 ký tự.')]
    }),
    timeframe: useField<string>({
      value: entity?.timeframe ?? 'daily',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!entity) {
          await createRank({
            rank_name: values.rank_name,
            rank_slug: values.rank_slug,
            rank_description: values.rank_description,
            timeframe: values.timeframe,
            min_points: __helpers.parseNumeric(values.min_points)
          });
        } else {
          await updateRank({
            rank_id: entity?.rank_id,
            rank_name: values.rank_name,
            rank_slug: values.rank_slug,
            rank_description: values.rank_description,
            timeframe: values.timeframe,
            min_points: __helpers.parseNumeric(values.min_points)
          });
        }
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success',
        errors: []
      };
    }
  });
  useEffect(() => {
    // chế độ thêm mới, thì mình chỉnh rank_slug
    if (!entity) useFields.rank_slug.onChange(helpers.slugify(useFields.rank_name.value));
  }, [useFields.rank_name.value, entity]);
  useEffect(() => {
    resetForm();
  }, []);
  return <Modal open={open} title={'Thêm một xếp hạng'} onClose={closeModal} primaryAction={{
    content: 'Lưu lại',
    loading: submitting,
    disabled: !dirty,
    onAction: submit
  }} secondaryActions={[{
    content: 'Đóng lại',
    onAction: closeModal
  }]}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <BlockStack gap={'400'}>
            <TextField autoFocus autoComplete="off" label="Tựa đề" {...fields.rank_name} />
            <TextField autoComplete="off" label="Slug" {...fields.rank_slug} />

            <TextField autoComplete="off" label="Mô tả" {...fields.rank_description} />

            <Text as="h4" variant="headingSm">
              Tự động hóa
            </Text>
            <TextField autoComplete="off" label="Điểm tối thiểu" value={__helpers.formatNumberWithCommas(fields.min_points.value)} onChange={v => fields.min_points.onChange(v)} />
            <Select label="Chu kỳ xét thứ hạng" options={[{
            label: 'Hàng ngày',
            value: 'daily'
          }, {
            label: 'Hàng tuần',
            value: 'weekly'
          }, {
            label: 'Hàng tháng',
            value: 'monthly'
          }, {
            label: 'Hàng năm',
            value: 'yearly'
          }]} onChange={e => useFields.timeframe.onChange(e)} value={useFields.timeframe.value} />
          </BlockStack>
        </Form>
      </Modal.Section>
    </Modal>;
}