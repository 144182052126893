import React, { useEffect, useState } from 'react';
import QuickSearchWallet from 'components/quickSearchWallet';
import QuickUploadImage from 'components/oneclick-upload-image';
import QuickSearchVoucher from 'components/quickSearchVoucher';
import { lengthLessThan, notEmpty, notEmptyString, useField, useForm } from '@shopify/react-form';
import { BlockStack, Form, Modal, TextField, Text, ChoiceList, InlineGrid } from '@shopify/polaris';
import helpers from 'helpers/index';
import __ from 'languages/index';
import { TypedLuckyPresent, useCreatePresent, useGetPresent, useUpdatePresent } from 'queries/game.query';
import { SaveIcon } from '@shopify/polaris-icons';
import QuickSearchWalletCode from 'components/quickSearchWalletCode';
export default function PresentModal({
  current_id,
  show,
  onClose
}: {
  current_id: string;
  show: boolean;
  onClose: () => void;
}) {
  const {
    mutateAsync: createPresent
  } = useCreatePresent();
  const {
    mutateAsync: updatePresent
  } = useUpdatePresent();
  const [uploadError, setUploadError] = useState(null);
  const {
    mutateAsync: getEntity,
    data
  } = useGetPresent();
  const [entity, setEntity] = useState<TypedLuckyPresent>();
  useEffect(() => {
    if (current_id) {
      getEntity(current_id);
    } else {
      setEntity(null);
    }
  }, [current_id]);
  useEffect(() => {
    if (data) setEntity(data);
  }, [data]);
  const [loadingCreatePresent, setLoadingCreatePresent] = useState(false);
  const useFields = {
    present_name: useField<string>({
      value: entity?.present_name ?? '',
      validates: [notEmpty('Không được để trống trường này.'), notEmptyString('Không được để trống trường này.')]
    }),
    present_name_short: useField<string>({
      value: entity?.present_name_short ?? '',
      validates: [notEmpty('Không được để trống trường này.'), notEmptyString('Không được để trống trường này.')]
    }),
    present_thumbnail: useField<string>({
      value: entity?.present_thumbnail ?? undefined,
      validates: []
    }),
    present_thumbnail_url: useField<string>({
      value: entity?.media ? entity?.media.media_thumbnail ? entity?.media?.media_thumbnail['128x128'] : '' : undefined,
      validates: []
    }),
    present_slug: useField<string>({
      value: entity?.present_slug ?? '',
      validates: [lengthLessThan(250, 'No more than 250 characters.'), inputValue => {
        if (inputValue && inputValue.length < 2) {
          return 'Present slug is too short, or it is empty.';
        }
        if (inputValue && inputValue.match(/[^a-zA-Z0-9-]/g)) {
          return 'Like URL, Present slug accepted only a-z, A-Z, 0-9 and "-" characters.';
        }
      }]
    }),
    reward_probability: useField<string>({
      value: String(entity?.reward_probability || '50'),
      validates: [inputVal => {
        if (!helpers.isNumeric(inputVal)) return 'Chỉ được nhập số.';
        if (Number(inputVal) > 100) return 'Chỉ được nhập số phần trăm, lớn nhất 100%';
      }]
    }),
    wallet_number: useField<string>({
      value: String(entity?.wallet_number || 0),
      validates: [inputVal => {
        if (!helpers.isNumeric(inputVal)) return 'Chỉ được nhập số.';
      }]
    }),
    wallet_id: useField<string>({
      value: entity?.wallet_id,
      validates: []
    }),
    voucher_id: useField<string>({
      value: entity?.voucher_id,
      validates: []
    }),
    wallet_code_id: useField<string>({
      value: entity?.wallet_code_id,
      validates: []
    }),
    /** Internal use */
    reward_type: useField<string>({
      value: entity?.voucher_id ? 'voucher' : entity?.wallet_id ? 'wallet' : entity?.wallet_code_id ? 'wallet_code' : '',
      validates: []
    })
  };
  const {
    submit,
    dirty,
    reset: resetForm,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      setLoadingCreatePresent(true);
      await helpers.sleep(1000);
      try {
        switch (values.reward_type) {
          case 'wallet':
            delete values.voucher_id;
            delete values.wallet_code_id;
            break;
          case 'voucher':
            delete values.wallet_id;
            delete values.wallet_number;
            delete values.wallet_code_id;
            break;
          case 'wallet_code':
            delete values.wallet_id;
            delete values.wallet_number;
            delete values.voucher_id;
            break;
          default:
            delete values.wallet_id;
            delete values.wallet_number;
            delete values.voucher_id;
            delete values.wallet_code_id;
        }
        if (current_id) {
          await updatePresent({
            present_id: current_id,
            present_name: values.present_name,
            present_name_short: values.present_name_short,
            present_thumbnail: values.present_thumbnail ?? undefined,
            // present_slug: values.present_slug,
            reward_probability: Number(values.reward_probability),
            wallet_number: helpers.parseNumeric(values.wallet_number || 0),
            wallet_id: values.wallet_id || undefined,
            voucher_id: values.voucher_id || undefined,
            wallet_code_id: values.wallet_code_id || undefined
          });
        } else {
          await createPresent({
            present_name: values.present_name,
            present_name_short: values.present_name_short,
            present_thumbnail: values.present_thumbnail ?? undefined,
            present_slug: values.present_slug,
            reward_probability: Number(values.reward_probability),
            wallet_number: helpers.parseNumeric(values.wallet_number || 0),
            wallet_id: values.wallet_id || undefined,
            voucher_id: values.voucher_id || undefined,
            wallet_code_id: values.wallet_code_id || undefined
          });
        }
        resetForm();
        onClose();
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      setLoadingCreatePresent(false);
      return {
        status: 'success'
      };
    }
  });
  useEffect(() => {
    if (!current_id) useFields.present_slug.onChange(helpers.slugify(useFields.present_name.value));
  }, [useFields.present_name.value, current_id]);
  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [show]);
  return <Modal open={show} title={'Tạo mới một quà tặng'} onClose={() => {
    onClose();
  }} primaryAction={{
    content: __('save'),
    icon: SaveIcon,
    disabled: !dirty,
    loading: loadingCreatePresent,
    onAction: submit
  }}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <BlockStack gap="400">
            <InlineGrid columns={['oneThird', 'twoThirds']} gap={'200'}>
              <div className="d1">
                <QuickUploadImage height={150} width={150} onSuccess={e => {
                useFields.present_thumbnail.onChange(e.media_id);
                useFields.present_thumbnail_url.onChange(e.media_url);
              }} onError={e => setUploadError(e)} title="" placeholder={helpers.getMediaLink(useFields.present_thumbnail_url.value, 'https://placehold.co/128x128')} />
                <Text as="p" tone="critical">
                  {uploadError}
                </Text>
              </div>

              <BlockStack gap={'400'}>
                <TextField requiredIndicator label="Tên của quà tặng" autoComplete="off" {...useFields.present_name} maxLength={250} showCharacterCount />
                <TextField label="Tên ngắn, hiển thị trên vòng quay" maxLength={50} autoComplete="off" requiredIndicator showCharacterCount {...useFields.present_name_short} />
                <TextField label="Present slug" readOnly={!!current_id} autoComplete="off" {...useFields.present_slug} />
              </BlockStack>
            </InlineGrid>

            <TextField label="Khả năng trúng quà này" autoComplete="off" {...useFields.reward_probability} suffix="%" />

            <ChoiceList title={'Chọn phần thưởng nâng cao'} choices={[{
            label: 'Quà tặng vật lý ship ngoài',
            value: ''
          }, {
            label: 'Thưởng thẳng vào ví',
            value: 'wallet',
            renderChildren: (isSeleted: boolean) => {
              return isSeleted && <BlockStack gap="400">
                          <TextField label="Họ sẽ được?" autoComplete="off" value={helpers.formatNumberWithCommas(useFields.wallet_number.value)} onChange={v => useFields.wallet_number.onChange(v)} />
                          <QuickSearchWallet title="Ví nhận" current_wallet_id={entity?.wallet_id} onClose={wallet_id => useFields.wallet_id.onChange(wallet_id)} />
                          <br />
                        </BlockStack>;
            }
          }, {
            label: 'Thưởng bằng voucher giảm giá',
            value: 'voucher',
            renderChildren: (isSeleted: boolean) => {
              return isSeleted && <QuickSearchVoucher title={''} current_voucher_id={entity?.voucher_id} onClose={v => useFields.voucher_id.onChange(v.voucher_id)} />;
            }
          }, {
            label: 'Hay thưởng bằng mã Wallet Code',
            value: 'wallet_code',
            renderChildren: (isSeleted: boolean) => {
              return isSeleted && <QuickSearchWalletCode title={''} current_wallet_code_id={entity?.wallet_code_id} onClose={v => useFields.wallet_code_id.onChange(v.wallet_code_id)} />;
            }
          }]} selected={[useFields.reward_type.value]} onChange={v => useFields.reward_type.onChange(v[0])} />
          </BlockStack>
        </Form>
      </Modal.Section>
    </Modal>;
}