import Theme404 from '../../layout/404';
import { useParams } from 'react-router-dom';
import List from './list';
import New from './new';
export default function WalletCodeIndex() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = List;
      break;
    case 'new':
      ActualPage = New;
      break;
    case 'edit':
      ActualPage = New;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>{<ActualPage />}</>;
}