import { Modal, TextField, Text, InlineStack, BlockStack, Divider, Button } from '@shopify/polaris';
import { notEmpty, useField, useForm } from '@shopify/react-form';
import QuickUploadImage from 'components/oneclick-upload-image';
import QuickSearchProduct2 from 'components/quickSearchProduct2';
import StarRatingComponent from 'components/starRatingTextField';
import { useNotification } from 'context/NotificationContext';
import __helpers from 'helpers/index';
import __ from 'languages/index';
import { useQuickCreateReview } from 'queries/review.query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { UploadIcon, XIcon } from '@shopify/polaris-icons';
import QuickUploadFile from 'components/oneclick-upload-file';
import helpers from 'helpers/index';
export default function CreateReviewModal({
  show,
  onClose
}: {
  show: boolean;
  onClose: () => void;
}) {
  const {
    mutateAsync: quickCreateReview,
    isPending,
    isSuccess: createSuccessfully
  } = useQuickCreateReview();
  const {
    addNotification
  } = useNotification();
  const point = useRef(0);
  const mediaFile = useRef([]);
  const [mediaFileForDisplay, setMediaFileForDisplay] = useState([]);
  const useFields = {
    product_id: useField<string>({
      value: '',
      validates: [notEmpty('Trường này không được để trống.')]
    }),
    user_avatar: useField<string>({
      value: '',
      validates: []
    }),
    display_name: useField<string>({
      value: '',
      validates: [notEmpty('Trường này không được để trống.')]
    }),
    review_title: useField<string>({
      value: '',
      validates: [notEmpty('Trường này không được để trống.')]
    }),
    review_content: useField<string>({
      value: '',
      validates: [notEmpty('Trường này không được để trống.')]
    })
  };
  const {
    fields,
    submit,
    reset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await quickCreateReview({
          product_id: values.product_id,
          user: {
            display_name: values.display_name,
            user_avatar: values.user_avatar
          },
          review_meta: {
            review_title: values.review_title,
            review_content: values.review_content,
            review_point: Number(point.current)
          },
          review_media: mediaFile.current
        });
      } catch (e) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  const [uploadError, setUploadError] = useState(null);
  const removeThisElement = useCallback(async (media_id: string) => {
    try {
      setMediaFileForDisplay(previousTags => previousTags.filter(previousTag => previousTag.media_id !== media_id));
      mediaFile.current = mediaFile.current.filter(previousTag => previousTag !== media_id);
    } catch (e) {}
  }, [mediaFile.current]);
  useEffect(() => {
    if (uploadError) {
      addNotification('error', uploadError);
      setUploadError(null);
    }
  }, [uploadError]);
  useEffect(() => {
    if (createSuccessfully) {
      addNotification('info', 'Đã tạo thành công! Tiếp tục ...');
      reset(); // reset form
      setMediaFileForDisplay([]); // reset media
      mediaFile.current = [];
    }
  }, [createSuccessfully]);
  return <Modal size="fullScreen" open={show ? true : false} onClose={() => {
    onClose();
  }} title={'Tạo một review mới'} primaryAction={{
    content: 'Tạo',
    disabled: isPending,
    loading: isPending,
    onAction: submit
  }}>
      <Modal.Section>
        <QuickSearchProduct2 current_product_id={[]} label={'Tạo review cho sản phẩm'} allowMultiple={false} errorMessage={useFields.product_id.error} onClose={productData => useFields.product_id.onChange(productData[0]?.product_id)} />
        <br />
        <Divider />
        <br />
        <BlockStack gap="400">
          <Text as="h3" variant="headingMd">
            Thông tin người mua
          </Text>
          <InlineStack align="start" blockAlign="center" gap="400">
            <div className="d1">
              <QuickUploadImage height={50} width={50} onSuccess={e => {
              useFields.user_avatar.onChange(e.media_url);
            }} onError={e => setUploadError(e)} title="" placeholder={__helpers.getMediaLink(useFields.user_avatar.value, 'https://placehold.co/128x128')} />
            </div>
            <TextField label="Tên hiển thị" autoComplete="off" placeholder="Tên hiển thị" labelHidden requiredIndicator {...fields.display_name} />
          </InlineStack>

          <Divider />

          <Text as="h3" variant="headingMd">
            Thông tin review
          </Text>

          <StarRatingComponent title="Đánh giá trải nghiệm" ref={point} />
          <TextField label="Tựa đề" autoComplete="off" requiredIndicator {...fields.review_title} />
          <TextField label="Nội dung nhận xét" autoComplete="off" multiline={3} maxLength={5000} showCharacterCount requiredIndicator {...fields.review_content} />

          <div id="product_gallery">
            <div className="product_gallery_wrap">
              {mediaFileForDisplay.length < 1 ? <Text as="p" tone="subdued" alignment="center">
                  Chưa có ảnh nào ở đây cả!
                </Text> : mediaFileForDisplay.map((file, index) => {
              return <div className="product_gallery_element" key={`product_gallery_index_` + index}>
                      <img className="product_gallery_thumbnail" alt={file.media_filename} src={helpers.getMediaLink(file.media_url)} />
                      <Button icon={XIcon} variant="plain" onClick={() => removeThisElement(file.media_id)}></Button>
                    </div>;
            })}
            </div>
          </div>

          <QuickUploadFile onSuccess={e => {
          mediaFile.current.push(e.media_id);
          setMediaFileForDisplay(old => [...old, ...[e]]);
        }} onError={e => setUploadError(e)} children={<Button icon={UploadIcon}>Tải ảnh/video lên</Button>} acceptMimeType={['image/png', 'image/jpg', 'image/jpeg', 'image/heic', 'image/gif', 'image/webp', 'video/mp4', 'video/hevc', 'video/mpeg']} />
        </BlockStack>
      </Modal.Section>
    </Modal>;
}