import { LegacyCard, Layout, SkeletonBodyText, SkeletonPage, Card } from '@shopify/polaris';

/** Version 2 of skeleton loading page */
export default function SkeletonPageLoading2({
  fullWidth = false
}: {
  fullWidth?: boolean;
}) {
  return <SkeletonPage fullWidth={fullWidth} primaryAction>
      <Layout>
        <Layout.Section>
          <Card>
            <SkeletonBodyText />
            <SkeletonBodyText />
            <SkeletonBodyText />
            <SkeletonBodyText />
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>;
}