import React, { lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import 'media/css/social_post.scss';
import { TypedSocial, useDeleteSocialPost, useMutateSocialPosts, useSocialPostLike, useSocialPostUnlike } from 'queries/social.query';
import __helpers from 'helpers/index';
import Parser from 'html-react-parser';
import For from 'components/For';
import { HeartIcon, ThumbsUpIcon, ChatIcon, ShareIcon, DeleteIcon } from '@shopify/polaris-icons';
import { Text, Box, Button, Link, InlineStack } from '@shopify/polaris';
import NewPost from './new_social_post';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
const SocialComment = lazy(() => import('./comment'));
const Post = React.memo(({
  post
}: {
  post: TypedSocial;
}) => {
  let {
    social_post_media,
    social_post_id,
    product
  } = post;
  const {
    mutate: LikePost
  } = useSocialPostLike();
  const {
    mutate: UnLikePost
  } = useSocialPostUnlike();
  const [likeCount, setLikeCount] = useState(0);
  const {
    mutate: deleteEntity
  } = useDeleteSocialPost();
  const mainImage = social_post_media[0] ? social_post_media[0] : ''; // Ảnh đầu tiên làm ảnh to
  const slideImages = useMemo(() => post.social_post_media.slice(1), [post.social_post_media]);
  const [isLiked, setIsLiked] = useState(false);
  useEffect(() => {
    let likeOrnot = !__helpers.isEmpty(post?.social_post_like);
    setIsLiked(likeOrnot);
    setLikeCount(post._count.social_post_like);
  }, [post]);
  const toggleLikeCallback = useCallback(() => {
    let current_like_status = isLiked;
    if (current_like_status) {
      // nếu like rồi thì truy vấn unlike
      UnLikePost(social_post_id);
      setLikeCount(old => old - 1);
    } else {
      LikePost(social_post_id);
      setLikeCount(old => old + 1);
    }
    setIsLiked(!current_like_status);
  }, [isLiked, social_post_id]);
  const [isCommentVisible, setIsCommentVisible] = useState(false);
  const postRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsCommentVisible(true); // Chỉ tải comment khi post nằm trong viewport
        }
      });
    }, {
      threshold: 0.5 // Tỉ lệ bài viết cần nhìn thấy để kích hoạt, ở đây là 50%
    });
    if (postRef.current) {
      observer.observe(postRef.current);
    }
    return () => {
      if (postRef.current) {
        observer.unobserve(postRef.current);
      }
    };
  }, []);
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async social_post_id => {
    const confirmed = await showDeleteConfirm('Xóa bài đăng này?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      deleteEntity(social_post_id);
    }
  }, []);
  return <div className="post" key={`nv_${social_post_id}`} ref={postRef}>
      <InlineStack gap={'400'} align="space-between" blockAlign="start">
        <div className="post-header">
          <img src={__helpers.getMediaLink(post.user.user_avatar)} alt="User Avatar" className="avatar" />
          <div className="post-user-info">
            <h4>{post.user.display_name}</h4>
            <span>{__helpers.subtractTimeHistory(post.createdAt)}</span>
          </div>
        </div>
        <div>
          <Button variant="plain" icon={DeleteIcon} onClick={() => handleDelete(social_post_id)}></Button>
        </div>
      </InlineStack>
      <div className="post-content">
        <p>{Parser(post.social_post_content || ' ')}</p>
        {mainImage && <div className="main-image-container">
            <img src={__helpers.getMediaLink(mainImage.media.media_url || '')} alt="Main Post" className="main-post-image" />
          </div>}
        {slideImages.length > 0 && <div className="slide-images-container">
            {slideImages.map((image, index) => <img key={index} src={__helpers.getMediaLink(image.media.media_thumbnail ? image.media.media_thumbnail['128x128'] : '')} alt={`Slide ${index}`} className="slide-post-image" />)}
          </div>}
      </div>
      {product && <Box paddingBlock={'400'}>
          <Link>Đính kèm: {product?.product_name}</Link>
        </Box>}
      <div className="post-actions">
        <Button removeUnderline icon={isLiked ? ThumbsUpIcon : HeartIcon} pressed={isLiked ? false : true} onClick={toggleLikeCallback} variant="plain">
          {isLiked ? `Đã thích (${likeCount})` : `Thích (${likeCount})`}
        </Button>
        <Button removeUnderline variant="plain" icon={ChatIcon}>
          {`Comment (${post._count.social_post_comment})`}
        </Button>
        <Button removeUnderline icon={ShareIcon} variant="plain">
          Share
        </Button>
      </div>
      {/* Chỉ tải comment khi post nằm trong viewport */}
      {isCommentVisible && <Suspense fallback={<div>Loading Comments...</div>}>
          <SocialComment current_post_id={post.social_post_id} key={post.social_post_id} />
        </Suspense>}
    </div>;
});
export default function SocialPost({
  mode: social_category
}) {
  const [mainQuery, setMainQuery] = useState({
    limit: 20,
    page: 0,
    social_category: social_category,
    sort: 'createdAt:desc'
  });
  const {
    mutateAsync: getEntities,
    data
  } = useMutateSocialPosts();
  const [entities, setEntities] = useState<TypedSocial[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Track if more data is available
  const observerRef = useRef<HTMLDivElement | null>(null); // Ref for the observer

  useEffect(() => {
    if (data) {
      const {
        entities: newEntities,
        totalItems: newTotalItems
      } = data;
      if (newEntities.length === 0) {
        setHasMore(false); // No more data
      } else {
        setEntities(prevEntities => [...prevEntities, ...newEntities]); // bồi thêm data vào cái cũ
        setTotalItems(newTotalItems);
      }
    }
  }, [data]);
  const loadData = useCallback(async () => {
    try {
      return await getEntities(mainQuery);
    } catch (e) {
      console.log(e, 'ERR_JHGFKDD');
    }
    return;
  }, [mainQuery]);
  useEffect(() => {
    setEntities([]);
    let newX = {
      ...mainQuery,
      ...{
        page: 0,
        // trở về trang đầu tiên
        social_category: social_category === 'all' ? '' : social_category // change cateogry
      }
    };
    setMainQuery(newX);
    loadData();
  }, [social_category]);

  // Handle intersection observer
  useEffect(() => {
    if (loading || !hasMore) return; // Prevent further loading if already loading or no more data

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setLoading(true);
        setMainQuery(prevQuery => ({
          ...prevQuery,
          page: prevQuery.page + 1 // Increment page for next load
        }));
      }
    }, {
      threshold: 0.8
    } // Trigger when 80% of the target is visible
    );
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [loading, hasMore]);
  useEffect(() => {
    if (loading) {
      getEntities(mainQuery).finally(() => setLoading(false));
    }
  }, [mainQuery]);
  const autoInsertNewData = useCallback((entity: TypedSocial) => {
    setEntities(prevEntities => [...[entity], ...prevEntities]); // bồi thêm data vào cái cũ
  }, []);
  return <>
      <NewPost social_category={social_category} onCallback={autoInsertNewData} />

      <div className="post-list">
        <For children={(post, index) => <Post key={index} post={post} />} each={entities} fallback={<div>Không có bài đăng nào!</div>} />
        {loading && <div>Loading...</div>}
        {!hasMore && entities.length > 0 && <Text as="p" variant="bodySm" tone="subdued">
            Không còn bài đăng nào khác nữa!
          </Text>}

        {/* The div to observe for infinite scrolling */}
        <div ref={observerRef} style={{
        height: '1px',
        marginBottom: '20px'
      }}></div>
      </div>
    </>;
}