import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import comment_list from './comment.list';
import comment_view from './comment.view';

/**
 *   Create index file for Comment
 */

export default function List_comment() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = comment_list;
      break;
    case 'view':
      ActualPage = comment_view;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>{<ActualPage />}</>;
}