import { useCallback, useEffect, useState } from 'react';
import { BlockStack, Text, InlineStack, Tag, Icon, Popover, Card, SkeletonBodyText, SkeletonThumbnail } from '@shopify/polaris';
import { PlusIcon } from '@shopify/polaris-icons';
import QuickSearchUser from 'components/quickSearchUser';
import Capacity from 'components/Capacity';
import { useAddAssignee, useDeleteAssignee, useGetAssignList } from 'queries/contactform.query';
export default function ContactformAssign({
  readonly = false,
  entity
}: {
  readonly?: boolean;
  entity: any;
}) {
  const {
    mutateAsync: getAssignList,
    data: assignList,
    isPending: assignLoading
  } = useGetAssignList();
  const {
    mutateAsync: addAssignee,
    isPending: addAssigneeLoading,
    isSuccess: addSuccess
  } = useAddAssignee();
  const {
    mutateAsync: deleteAssign
  } = useDeleteAssignee();
  const [selectedTags, setSelectedTags] = useState([]);
  useEffect(() => {
    setSelectedTags(assignList);
  }, [assignList]);
  useEffect(() => {
    if (!entity) return;
    getAssignList(entity?.contactform_id);
  }, [entity, addSuccess]);
  const removeTag = useCallback((tag: any) => () => {
    if (readonly) return;
    setSelectedTags(previousTags => previousTags.filter(previousTag => previousTag.id !== tag.id));
    deleteAssign(tag.id);
  }, [readonly]);
  const [showAddnewUser, setShowAddnewUser] = useState(false);
  const addNewAssignee = useCallback(async user_id => {
    await addAssignee({
      contactform_id: entity?.contactform_id,
      user_id: user_id
    });
  }, [entity]);
  return <>
      {assignLoading ? <SkeletonBodyText /> : <BlockStack gap={'200'}>
          <Text as="p" variant="bodyMd" fontWeight="bold" key="CustomerAssign_title">
            Phân quyền
          </Text>
          <InlineStack gap={'200'} align="start" blockAlign="center">
            {entity?.user && <Tag>{entity?.user?.display_name || entity?.user?.user_email}</Tag>}
            {Array.isArray(selectedTags) && selectedTags.length > 0 && selectedTags.map((e, index) => {
          return <Tag key={index + 'taglist'} onRemove={removeTag(e)}>
                    {e?.assignee?.display_name}
                  </Tag>;
        })}

            {addAssigneeLoading && <SkeletonThumbnail size="extraSmall" />}

            {!readonly && <Capacity current_user_can={'sale_admin'}>
                <Popover active={showAddnewUser} activator={<Tag onClick={() => setShowAddnewUser(true)}>
                      <InlineStack align="start" blockAlign="center" gap="200">
                        <span key="add_new_assignee">Thêm nhân viên</span>
                        <Icon source={PlusIcon} />
                      </InlineStack>
                    </Tag>} autofocusTarget="first-node" onClose={() => setShowAddnewUser(false)}>
                  <Card>
                    <QuickSearchUser title="" user_role="sale,sale_admin,admin" onClose={(userData: any) => {
                let __newUser = userData.user_id;
                addNewAssignee(__newUser);
                setShowAddnewUser(false);
              }} />
                  </Card>
                </Popover>
              </Capacity>}
          </InlineStack>
          <Text as="p" variant="bodySm" tone="subdued" key="CustomerAssign_subtitle">
            Những người trong danh sách phân quyền này sẽ có thể xem và chỉnh sửa dữ liệu.
          </Text>
        </BlockStack>}
    </>;
}