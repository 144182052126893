import { ActionList, Avatar, Button, Card, InlineGrid, Link, Page, Popover, Thumbnail } from '@shopify/polaris';
import Capacity from 'components/Capacity';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IndexTable, Text } from '@shopify/polaris';
import { PlusCircleIcon } from '@shopify/polaris-icons';
import Pagination from 'components/pagination';
import helpers from 'helpers/index';
import AppModal from './app.modal';
import dateandtime from 'date-and-time';
import SimpleFilter from 'components/simpleFilter';
import { useGetApps, useUpdateApp } from 'queries/app.query';
export default function AppList() {
  // const loading = useAppSelector((state) => state.app.loading);
  // const entities = useAppSelector((state) => state.app.entities);
  // const totalItems = useAppSelector((state) => state.app.totalItems);

  const {
    mutateAsync: updateEntity
  } = useUpdateApp();
  const history = useNavigate();
  const [showModal, setShowModal] = useState(false);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(window.location.search) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const {
    data,
    isLoading: loading,
    refetch: getEntities
  } = useGetApps(mainQuery);
  const [totalItems, setTotalItems] = useState(0);
  const [records, setRecords] = useState([]);
  useEffect(() => {
    if (data) {
      let {
        entities,
        totalItems
      } = data;
      setRecords(entities);
      setTotalItems(totalItems);
    }
  }, [data]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Ứng dụng', '/app' + buildURLSearch);
    }
    getEntities();
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);

  /**
   * Action menu ...
   */

  const [currentLoading, setCurrentLoading] = useState<any>(null);
  const quickUpdateSetting = useCallback(async (ID: any, status: number) => {
    setShowActionList(null);
    setCurrentLoading(ID);
    let updatedData: any = await updateEntity({
      ID: ID,
      status: status
    });
    let data = updatedData?.data || false;
    if (data) {
      let M = records.map(el => {
        if (el.ID === data.ID) {
          return data;
        }
        return el;
      });
      setRecords(M);
    }
    setCurrentLoading(null);
  }, [records]);

  // ------------
  const [showActionList, setShowActionList] = useState(null);
  const onCloseActionList = useCallback((ID: any) => {
    setShowActionList(!ID);
  }, []);
  const togglePophoverActionListCallback = useCallback((_s: any) => {
    setShowActionList(showActionList === _s ? false : _s);
  }, [showActionList]);
  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);
  const rowMarkup = records?.map(({
    ID,
    app_name,
    app_thumbnail,
    app_type,
    createdAt,
    app_description,
    client_public_key,
    status
  }, index) => <IndexTable.Row id={`` + ID} position={index} key={`app_setting` + index}>
      <IndexTable.Cell className="table_app_cellMinWidth">
        <Link onClick={() => history('/app/view/' + ID)}>
          <InlineGrid columns={['oneThird', 'twoThirds']} gap={'100'} alignItems="center">
            <Avatar size="lg" customer name={app_name} initials={String(app_name || 'APP').charAt(0)} source={helpers.getMediaLink(app_thumbnail)} />
            <Text variant="bodyMd" fontWeight="bold" as="span">
              {app_name}
            </Text>
          </InlineGrid>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>{app_type}</IndexTable.Cell>
      <IndexTable.Cell>
        <Popover active={showActionList === ID} activator={<Button loading={currentLoading === ID} variant="tertiary" disclosure={'down'} onClick={() => togglePophoverActionListCallback(ID)}>
              {status === 1 ? 'Active' : 'inActive'}
            </Button>} autofocusTarget="first-node" onClose={() => onCloseActionList(ID)}>
          <ActionList actionRole="menuitem" items={[{
          content: 'Active',
          onAction: () => quickUpdateSetting(ID, 1)
        }, {
          content: 'inActive',
          onAction: () => quickUpdateSetting(ID, 0)
        }]} />
        </Popover>
      </IndexTable.Cell>
      <IndexTable.Cell>{helpers.trimMiddleString(app_description, 20, 10)}</IndexTable.Cell>
      <IndexTable.Cell>{client_public_key}</IndexTable.Cell>
      <IndexTable.Cell>{dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm:ss')}</IndexTable.Cell>
    </IndexTable.Row>);
  return <Capacity current_user_can="admin" onFail={<>
          <div>You are not allowed to do anything here.</div>
        </>}>
      <AppModal show={showModal} onClose={closeModal} entity={null} />
      <Page title="Danh sách ứng dụng" subtitle="Tạo một ứng dụng và nhóm các cài đặt lại với nhau" primaryAction={{
      content: 'Thêm ứng dụng',
      primary: true,
      destructive: true,
      icon: PlusCircleIcon,
      onAction: () => setShowModal(true)
    }}>
        <Card padding="0">
          <SimpleFilter loading={loading} onCallback={filterCallback} options={[{
          label: 'android_app',
          value: 'android_app',
          field: 'app_type'
        }, {
          label: 'ios_app',
          value: 'ios_app',
          field: 'app_type'
        }, {
          label: 'desktop_app',
          value: 'desktop_app',
          field: 'app_type'
        }, {
          label: 'website',
          value: 'website',
          field: 'app_type'
        }, {
          label: 'extension',
          value: 'extension',
          field: 'app_type'
        }, {
          label: 'tv_app',
          value: 'tv_app',
          field: 'app_type'
        }]} sortField={[{
          label: 'Tình trạng',
          field: 'status'
        }]} />
          <IndexTable resourceName={{
          singular: 'App setting',
          plural: 'App setting'
        }} itemCount={totalItems || 1} headings={[{
          title: 'Tên App'
        }, {
          title: 'Loại'
        }, {
          title: 'Tình trạng'
        }, {
          title: 'Mô tả'
        }, {
          title: 'Khóa công khai'
        }, {
          title: 'Ngày tạo'
        }]} loading={loading} selectable={false}>
            {records?.length > 0 && rowMarkup}
          </IndexTable>
        </Card>

        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
    </Capacity>;
}