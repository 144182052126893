import { Form, InlineGrid, Modal, TextField, Text, Banner, BlockStack } from '@shopify/polaris';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import __helpers from 'helpers/index';
import { TypedSettings } from 'interface/settings.model';
import __ from 'languages/index';
import { useNotification } from 'context/NotificationContext';
import { useUpdateSetting } from 'queries/setting.query';
import { useState, useEffect, useCallback } from 'react';
export default function NewSettingModal({
  show = false,
  onClose = null,
  entity = null
}: {
  show: boolean;
  entity?: TypedSettings;
  onClose?: (entity?: TypedSettings) => void;
}) {
  const [open, setOpen] = useState(false);
  const {
    addNotification
  } = useNotification();
  const {
    mutateAsync: updateEntity,
    isSuccess: updatedSuccess
  } = useUpdateSetting();

  // const dispatch = useAppDispatch();

  useEffect(() => {
    if (show) setOpen(true);
  }, [show]);
  useEffect(() => {
    if (updatedSuccess) {
      addNotification('info', __('update_successfully'));
      onCloseCallback();
    }
  }, [updatedSuccess]);
  const onCloseCallback = useCallback((___?: any) => {
    onClose(___);
    setOpen(false);
  }, []);
  useEffect(() => {
    reset();
  }, []);
  const useFields = {
    setting_name: useField<string>({
      value: entity?.setting_name,
      validates: [notEmpty('không được để trống trường này'), inputValue => {
        if (__helpers.isUTF8(inputValue)) {
          return 'Không dùng mã UTF-8 ở trường này.';
        }
        if (/^[a-zA-Z0-9_.]+$/.exec(inputValue) === null) {
          return 'Chỉ sử dụng chữ cái, số, gạch dưới và dấu chấm';
        }
      }]
    }),
    setting_value: useField<string>({
      value: entity?.setting_value || '',
      validates: [lengthLessThan(1000, 'Không được dài hơn 1000 ký tự.')]
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          [values.setting_name]: values.setting_value
        });
      } catch (e) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  return <Modal activator={null} open={open} onClose={() => onCloseCallback()} title={entity ? 'Cập nhật cài đặt' : 'Thêm cài đặt'} primaryAction={{
    content: entity ? __('update_label_button') : 'Lưu lại',
    loading: submitting,
    disabled: !dirty,
    onAction: submit
  }} secondaryActions={[{
    content: 'Đóng',
    disabled: submitting,
    onAction: () => onCloseCallback()
  }]}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <BlockStack gap="200">
            <BlockStack gap="500">
              <TextField label="Tên cài đặt" autoComplete="off" autoFocus requiredIndicator {...fields.setting_name} />
              <TextField label="Giá trị" autoComplete="off" multiline={3} maxLength={1000} showCharacterCount {...fields.setting_value} />
            </BlockStack>
          </BlockStack>
        </Form>
      </Modal.Section>
    </Modal>;
}