import { Page, Tabs } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import GeneralSecuritySetting from './general_setting';
import DeviceUUIDBlacklist from './device_uuid_blacklist';
import IPBlacklist from './ip_blacklist';
export default function Security() {
  let {
    hash
  } = useLocation();
  const [tabselected, setTabselected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => {
    window.location.hash = '#tab-' + selectedTabIndex;
  }, []);
  const tabs = useMemo(() => [{
    id: 'tab_0',
    content: 'Cài đặt chung',
    panelID: 'general_setting'
  }, {
    id: 'tab_1',
    content: 'Danh sách đen cho thiết bị',
    panelID: 'device_uuid_blacklist'
  }, {
    id: 'tab_2',
    content: 'Danh sách đen cho IP',
    panelID: 'ip_blacklist'
  }], []); // Empty dependency array means this will only run once on mount

  useEffect(() => {
    if (hash) {
      let tabb = Number(String(hash || ' ').replace('#tab-', ''));
      if (tabs[tabb] === undefined) tabb = 0;
      setTabselected(tabb);
    } else {
      setTabselected(0);
    }
  }, [hash, tabs]);
  return <>
      <Helmet>
        <title>Cài đặt bảo mật</title>
      </Helmet>

      <Page title="Security Setting">
        <div className="custom_tabs" style={{
        margin: '0 -15px'
      }}>
          <Tabs tabs={tabs} selected={tabselected} onSelect={handleTabChange}></Tabs>
          <div style={{
          margin: '15px'
        }}>
            {tabs[tabselected].panelID === 'general_setting' && <GeneralSecuritySetting />}
            {tabs[tabselected].panelID === 'device_uuid_blacklist' && <DeviceUUIDBlacklist />}
            {tabs[tabselected].panelID === 'ip_blacklist' && <IPBlacklist />}
          </div>
        </div>
      </Page>
    </>;
}