import { BlockStack, CalloutCard, FooterHelp, Link, Page, SkeletonBodyText } from '@shopify/polaris';
import EmptyState from 'components/EmptyState';
import { useGetGames } from 'queries/game.query';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
export default function GameIndex() {
  const {
    data,
    isFetching
  } = useGetGames({
    limit: 20,
    sort: 'createdAt: desc'
  });
  const [entities, setEntities] = useState(null);
  useEffect(() => {
    if (!data) return;
    let {
      body,
      totalItems
    } = data;
    setEntities(body);
  }, [data]);
  return <>
      <Helmet>
        <title>Quản lý game</title>
      </Helmet>
      <Page title="Game" subtitle="Danh sách game này được thiết kế dạng module, không thể thêm hay xóa">
        {isFetching === null && <SkeletonBodyText />}
        {Array.isArray(entities) && entities.length < 1 && <EmptyState>Không thấy có một game nào!</EmptyState>}
        {Array.isArray(entities) && entities.length > 0 && entities.map(element => {
        return <CalloutCard title={element.game_title} illustration={element.game_thumbnail} primaryAction={{
          content: 'Chi tiết',
          url: '/game/' + element.game_slug
        }}>
                <BlockStack gap="200">
                  {element.game_excerpt || ' '}
                  <br />
                </BlockStack>
              </CalloutCard>;
      })}

        <FooterHelp>
          Danh sách game còn thêm nữa, <Link url="https://google.com">liên hệ</Link> ngay đội ngũ phát triển.
        </FooterHelp>
      </Page>
    </>;
}