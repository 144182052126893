import React from 'react';
import Theme404 from '../../layout/404';
import { useParams } from 'react-router-dom';
import ListVoucher from './list';
import NewVoucher from './new';
import ViewVoucher from './view';
export default function VoucherIndex() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = ListVoucher;
      break;
    case 'new':
      ActualPage = NewVoucher;
      break;
    case 'edit':
      ActualPage = NewVoucher;
      break;
    case 'view':
      ActualPage = ViewVoucher;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>{<ActualPage />}</>;
}