import { useParams } from "react-router-dom";
import Theme404 from '../../layout/404';
import transaction_list from './transaction.list';
import transaction_view from './transaction.view';
export default function TransactionIndex() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || 'list';
  let ActualPage: any;
  switch (Param) {
    case 'list':
      ActualPage = transaction_list;
      break;
    case 'edit':
      ActualPage = transaction_view;
      break;
    case 'view':
      ActualPage = transaction_view;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>
            {<ActualPage />}
        </>;
}