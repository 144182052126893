import { DataTable, EmptyState, Layout, Page, Card, Text, Thumbnail, Link, InlineStack, Badge } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import dateandtime from 'date-and-time';
import { LockIcon, EditIcon } from '@shopify/polaris-icons';
import emptyIMG from '../../media/images/empty.png';
import Pagination from '../../components/pagination';
import helpers from '../../helpers';
import SkeletonLoading from 'components/skeletonPageLoading';
import SimpleFilter from 'components/simpleFilter';
import { ___ } from 'languages/index';
import { Helmet } from 'react-helmet-async';
import { TypedGift, useGetGifts } from 'queries/gift.query';
export default function RedeemList() {
  const history = useNavigate();
  const [entities, setEntities] = useState<TypedGift[]>(null);
  const [totalItems, setTotalItems] = useState<number>(0);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery: any = helpers.ExtractUrl(search) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 20,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const {
    data: allPosts,
    isLoading: loading,
    refetch: getEntities
  } = useGetGifts(mainQuery);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (search !== buildURLSearch) history('/gift' + buildURLSearch);
    getEntities();
  }, [mainQuery]);
  useEffect(() => {
    if (!allPosts) return;
    let {
      body,
      totalItems
    } = allPosts;
    setEntities(body);
    setTotalItems(totalItems);
  }, [allPosts]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const emptyData = <EmptyState heading="Chỗ này trống trơn!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>;
  const renderItem = (redeem: TypedGift) => {
    const {
      gift_id: redeem_id,
      gift_content: redeem_content,
      gift_description: redeem_description,
      gift_title: redeem_title,
      gift_slug: redeem_slug,
      _count,
      gift_status: redeem_status,
      gift_thumbnail_to_media: redeem_thumbnail_to_media,
      gift_value: redeem_value,
      gift_quantity: redeem_quantity,
      wallet_type,
      createdAt,
      voucher_id,
      voucher
    } = redeem;
    return [<InlineStack gap="400" align="start" blockAlign="center">
        <Thumbnail alt={''} size="small" source={helpers.getMediaLink(redeem_thumbnail_to_media?.media_thumbnail ? redeem_thumbnail_to_media?.media_thumbnail['128x128'] : redeem_thumbnail_to_media?.media_url)} />
        <Link url={'/gift/view/' + redeem_id}>
          <Text as="span">{redeem_title ? helpers.trimMiddleString(redeem_title, 15, 10) : 'Chưa có tiêu đề'}</Text>
        </Link>
      </InlineStack>, helpers.formatNumberWithCommas(redeem_value) + ' ' + wallet_type?.wallet_sign, voucher_id ? voucher?.voucher_name : 'VẬT LÝ', helpers.formatNumberWithCommas(redeem_quantity), helpers.formatNumberWithCommas(_count.redeem_request || 0), redeem_status === 1 ? <Badge tone="success">Đang hiển thị</Badge> : <Badge>Không hiển thị</Badge>, dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm')];
  };
  const PagesList = totalItems > 0 ? <>
        <DataTable verticalAlign={'middle'} columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']} headings={['Tên quà', 'Giá trị', 'Loại quà', 'S.lượng', 'Đã yêu cầu', 'Tình trạng', 'Ngày tạo']} rows={entities?.map(renderItem)} hideScrollIndicator footerContent={___('Show page {display_page_number} of {total_record_number} results', {
      display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
      total_record_number: <Text as="strong">{totalItems}</Text>
    })} />
      </> : emptyData;
  const Actual_page = <Page title="Quà để đổi" subtitle="Danh sách quà có thể để đổi từ ví" primaryAction={{
    content: 'Tạo mới quà',
    disabled: false,
    icon: EditIcon,
    onAction: () => {
      history('/gift/new');
    }
  }}>
      <Layout>
        <Layout.Section>
          <Card padding="0">
            <SimpleFilter loading={loading} onCallback={filterCallback} options={[]} sortField={[{
            label: 'Số lượng',
            field: 'redeem_quantity'
          }, {
            label: 'Giá trị',
            field: 'redeem_value'
          }]} />

            {PagesList}
          </Card>
          <br />
          {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
        </Layout.Section>
      </Layout>
    </Page>;
  return <>
      <Helmet>
        <title>Danh sách quà</title>
      </Helmet>
      {entities === null ? <SkeletonLoading fullWidth /> : Actual_page}
    </>;
}