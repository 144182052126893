import React from 'react';
import { useParams } from 'react-router-dom';
import Theme404 from 'layout/404';
import product_list from './product_collection.list';
import product_detail from './product_collection.view';
export default function ProductCollectionIndex() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = product_list;
      break;
    case 'view':
      ActualPage = product_detail;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>{<ActualPage />}</>;
}