import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import { TypedUser } from "interface/user.model";

export interface TypedUser_activity {
    activity_id?: string;
    user_id?: string;
    activity_type?: string;
    activity_source?: string;
    createdAt?: string;
    user?: TypedUser
}

type IQuery = TypedUser_activity & IQueryParams;

/*
* List of all app
*/
export function useGetUserActivities(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["user_activity/fetch_entity_list"],
        queryFn: () => axios.get<TypedUser_activity[]>(`/user_activity${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}