import React from 'react';
import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import List from './request.list';
import View from './request.view';
export default function RequestIndex() {
  let {
    slug = 'list'
  } = useParams();
  switch (slug) {
    case 'list':
      return <List />;
    case 'view':
      return <View />;
    // redeem_request_id

    default:
      return <Theme404 />;
  }
}