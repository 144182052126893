import { ReactECharts, ReactEChartsProps } from 'components/React-ECharts';
import { useEffect, useState } from 'react';
import { useGetStaffPerformanceLine } from 'queries/analytics.query';
// import helpers from "../../helpers";

export default function StaffLineChart({
  defaultDate
}) {
  const {
    data: staffPerformanceLine
  } = useGetStaffPerformanceLine({
    createdAt: defaultDate
  });
  const [drawBottomLine, setDrawBottomLine] = useState<any>([]);
  const [drawMiddleLine, setDrawMiddleLine] = useState<any>([]);
  useEffect(() => {
    if (!staffPerformanceLine) return;
    const {
      users,
      date
    } = staffPerformanceLine;
    let usrline: any[] = [];
    if (Array.isArray(users)) for (let n of users) {
      usrline.push({
        data: n.data,
        type: 'line',
        name: n.name || 'UnKnown',
        smooth: false
        //   itemStyle: {normal: {areaStyle: {type: 'default'}}},
        // lineStyle: {
        //     color: helpers.getRandomElementInArray(colors)
        // }
      });
    }
    setDrawBottomLine(date);
    setDrawMiddleLine(usrline);
  }, [staffPerformanceLine]);
  const lineChart: ReactEChartsProps['option'] = {
    title: {
      text: 'Số đơn hàng',
      left: 0
    },
    tooltip: {
      trigger: 'axis'
    },
    xAxis: {
      type: 'category',
      data: drawBottomLine
    },
    yAxis: {
      type: 'value'
    },
    series: drawMiddleLine
  };
  return <ReactECharts option={lineChart} settings={{
    notMerge: true
  }} style={{
    minHeight: 400
  }} />;
}