import React, { useEffect, useState } from 'react';
import dateandtime from 'date-and-time';
import { DataTable, Link, Text } from '@shopify/polaris';
import helpers from 'helpers/index';
import { TypedTransaction_to_order, useGetTransaction_to_orders } from 'queries/transaction_to_order.query';
export default function OrderTransaction({
  order_id
}: {
  order_id: string;
}) {
  /**
   * Danh sách giao dịch
   */
  const {
    refetch: getEntities,
    data
  } = useGetTransaction_to_orders(order_id);
  const [transactionsEntities, setTransactionsEntities] = useState<TypedTransaction_to_order[]>([]);
  useEffect(() => {
    if (data) {
      setTransactionsEntities(data.body);
    }
  }, [data]);
  useEffect(() => {
    getEntities();
  }, [order_id]);
  return <>
      {transactionsEntities && transactionsEntities.length > 0 ? <DataTable hasZebraStripingOnData increasedTableDensity hideScrollIndicator truncate columnContentTypes={['text', 'text', 'text', 'text']} headings={['Giá trị', 'Phương thức', 'Loại giao dịch', 'Người nộp', 'Ngày thêm']} rows={transactionsEntities?.map((walletData, index) => {
      const {
        transactions
      } = walletData;
      return [helpers.formatNumber(Math.abs(transactions.amount), ',') + ' ' + transactions.wallet_receiver.wallet_type.wallet_sign, transactions.transaction_type, transactions.transaction_category, <Link url={`/customer/view/${transactions.wallet_receiver.user.user_id}`}>
                {transactions.wallet_receiver.user.display_name || transactions.wallet_receiver.user.user_phonenumber || transactions.wallet_receiver.user.user_email}
              </Link>, dateandtime.format(new Date(Number(transactions.createdAt)), 'DD-MM-YYYY HH:mm')];
    })} /> : <Text as="p" tone="subdued">
          Chưa có giao dịch nào.
        </Text>}
    </>;
}