import { Badge, Button, CalloutCard, Card, Link, DataTable, ExceptionList, SkeletonBodyText, Text } from '@shopify/polaris';
import Comments from 'components/CommentsComponent';
import helpers from 'helpers/index';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import orderStatus from 'config/order.status.json';
import orderPlaceholder from 'media/images/order_placeholder.svg';
import { CheckSmallIcon, PlusCircleIcon } from '@shopify/polaris-icons';
import { useCreateOrder } from 'queries/orders.query';
import { TypedCustomer } from 'interface/customer.model';
import { getOrderStatusBadge } from 'entities/order/order.component';
export default function CustomerOtherInformation({
  entity
}: {
  entity: TypedCustomer;
}) {
  const {
    mutateAsync: orderCreateEntity,
    data,
    isPending: loadingOrders
  } = useCreateOrder();

  /**
   * Get all campaign this customer belong to...
   */

  const history = useNavigate();
  let {
    customer_id
  } = useParams();
  const QuickCreateOrder = useCallback(() => {
    orderCreateEntity({
      user_id: customer_id
    }).then(result => {
      history('/order/view/' + result.order_id);
    }).catch(e => {});
  }, []);
  function getPaymentStatusBadge(status: number) {
    switch (status) {
      case 0:
        return <Badge></Badge>;
      case 1:
        return <Badge tone={'warning'} progress={'partiallyComplete'}>
            T.Toán một phần
          </Badge>;
      case 2:
        return <Badge tone={'success'} progress={'complete'}>
            Đã thanh toán
          </Badge>;
    }
  }
  const LoadingElement = useCallback(() => {
    return <Card>
        <SkeletonBodyText />
      </Card>;
  }, []);
  return <>
      <Text as="h3" variant="headingMd">
        Đơn hàng của khách
      </Text>
      <br />

      <Card padding="0">
        {loadingOrders ? <LoadingElement /> : null}

        {entity?.orders_orders_user_idTouser?.length < 1 ? <CalloutCard title="Khách hàng này chưa mua đơn nào!" illustration={orderPlaceholder} primaryAction={{
        content: 'Lên đơn ngay',
        url: '#',
        onAction: QuickCreateOrder
      }} /> : null}

        {entity?.orders_orders_user_idTouser?.length > 0 ? <DataTable columnContentTypes={['text', 'text', 'text', 'text', 'text']} headings={['PNR', 'Tình trạng', 'Thanh toán', 'Giá trị', 'Ngày tạo']} rows={entity?.orders_orders_user_idTouser?.map(orderData => {
        const {
          order_id,
          order_pnr,
          order_status,
          order_total_price,
          payment_status,
          createdAt
        } = orderData;
        return [<Link url={'/order/view/' + order_id} target="_blank">
                  {order_pnr}
                </Link>, getOrderStatusBadge(orderStatus[order_status]), getPaymentStatusBadge(payment_status), helpers.formatNumber(Number(order_total_price), ','), helpers.subtractDate(createdAt, ' ngày trước')];
      })} hideScrollIndicator hasZebraStripingOnData increasedTableDensity /> : null}
      </Card>
      <br />
      <ExceptionList items={[{
      icon: PlusCircleIcon,
      description: <>
                <Button variant="plain" loading={loadingOrders} onClick={() => history(`/order?user_id=${customer_id}`)}>
                  Xem toàn bộ đơn
                </Button>{' '}
                của khách hàng này hoặc{' '}
                <Button variant="plain" loading={loadingOrders} disabled={loadingOrders} onClick={QuickCreateOrder}>
                  tạo nhanh đơn hàng
                </Button>{' '}
                cho khách
              </>
    }]} />
      <br />

      <Comments objectId={customer_id} commentType="customer_comment" title="Nhận xét" />
    </>;
}