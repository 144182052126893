import { compiledBlock as _compiledBlock } from "million/react";
const ForNormalUser_1 = _compiledBlock(_props => <slot>
      {_props.v0}
      <br />
      <br />
      {_props.v1}
      {_props.v2}
      <br />
      {_props.v3}
      <br />
      {_props.v4}
      <br />
      <br />
      <br />
      <br />
      <br />
    </slot>, {
  name: "ForNormalUser_1",
  portals: ["v0", "v1", "v2", "v3", "v4"]
});
const ForNormalUser = function ForNormalUser() {
  const {
    data: generalAnalyticsData,
    isLoading: loadingGeneralAnalytics
  } = useGetGeneralClientAnalytics();
  const {
    data: clientMonthlyReport,
    isLoading: loadingClientMonthlyReport
  } = useGetClientMonthlyReport();
  /** Dùng để vẽ biểu đồ */
  const [orderReportInterval, setOrderReportInterval] = useState<string>('today');
  const {
    data: orderLineData,
    refetch: reloadOrderReport,
    isLoading: loadingOrderReport
  } = useGetClientOrderReport(orderReportInterval);
  const HomeAnalytics = () => {
    return <div className="home_analytics">
        <InlineStack gap="400" align="space-between" blockAlign="start">
          <div className="dd">
            <Text as="h2" variant="headingMd" fontWeight="bold">
              Hi there, mọi thứ hôm nay
            </Text>
            <div className="home_analytics_body">
              <div className="home_analytics_badget">
                {loadingGeneralAnalytics ? <SkeletonDisplayText size="small" /> : <>
                    <div className="num">{helpers.getPad(generalAnalyticsData?.newCustomerToday ?? 0)}</div>
                    <div className="des">Khách hàng mới</div>
                  </>}
              </div>
              <div className="home_analytics_badget">
                {loadingGeneralAnalytics ? <SkeletonDisplayText size="small" /> : <>
                    <div className="num">{helpers.getPad(generalAnalyticsData?.orderCountToday ?? 0)}</div>
                    <div className="des">Đơn hàng mới</div>
                  </>}
              </div>
              <div className="home_analytics_badget">
                {loadingGeneralAnalytics ? <SkeletonDisplayText size="small" /> : <>
                    <div className="num">{helpers.formatNumber(generalAnalyticsData?.revenueToday ?? 0)}</div>
                    <div className="des">Doanh thu</div>
                  </>}
              </div>
            </div>
          </div>
        </InlineStack>
        <Text as="p" variant="bodyXs" tone="subdued">
          * Tạm tính đơn bao gồm đơn đang lên, đang giao, và đã giao.
          <br />* Mọi đơn hàng bạn tạo, hoặc người bạn giới thiệu tạo đều được tính.
        </Text>
      </div>;
  };

  /** dùng để vẽ biểu đồ */
  const [xAxisData, setXAxisData] = useState([]); // ngang
  const [yAxisData, setYAxisData] = useState([]); // doc

  useEffect(() => {
    if (orderLineData) {
      let allX = [];
      let allY = [];
      for (let data of orderLineData) {
        let xdt = data.date ? data.date : data.month ? data.month : data.hour ? data.hour : '-';
        let ydt = data.totalCount;
        allX.push(xdt);
        allY.push(ydt);
      }
      setXAxisData(allX);
      setYAxisData(allY);
    }
  }, [orderLineData]);
  const totalCustomersOption: ReactEChartsProps['option'] = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      show: false
    },
    grid: {
      left: 50,
      top: 20,
      right: 50,
      bottom: 50
    },
    xAxis: [{
      type: 'category',
      data: xAxisData,
      axisPointer: {
        type: 'shadow',
        label: {
          formatter: 'Thời gian: {value}'
        }
      }
    }],
    yAxis: [{
      type: 'value',
      name: '',
      alignTicks: true,
      axisLabel: {
        formatter: '{value}'
      }
    }],
    series: [{
      name: 'Số đơn',
      type: 'line',
      data: yAxisData
    }]
  };
  const [selectedTab, setSelectedTab] = useState(0);
  const tabs = [{
    content: 'Ngày hôm nay',
    id: 'today'
  }, {
    content: 'Ngày hôm qua',
    id: 'yesterday'
  }, {
    content: 'Tháng này',
    id: 'this_month'
  }, {
    content: 'Tháng trước',
    id: 'last_month'
  }, {
    content: 'Năm nay',
    id: 'this_year'
  }, {
    content: 'Năm ngoái',
    id: 'last_year'
  }];
  const handleTabChange = useCallback((selectedTabIndex: number) => {
    setSelectedTab(selectedTabIndex);
    //
    setOrderReportInterval(tabs[selectedTabIndex].id);
    setTimeout(reloadOrderReport, 1000);
  }, []);
  return /*@million jsx-skip*/<ForNormalUser_1 v0={<HomeAnalytics />} v1={<Text as="h3" variant="headingLg">
        Số đơn
      </Text>} v2={<Text as="p" variant="bodyXs" tone="subdued">
        * Tính mọi đơn và không phải là đơn đối soát cuối cùng.
      </Text>} v3={<Card padding="0">
        <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
          <ReactECharts settings={{
        notMerge: true
      }} option={totalCustomersOption} loading={loadingOrderReport} style={{
        minHeight: '400px'
      }} graphic={undefined} />
        </Tabs>
      </Card>} v4={<Card>
        <Text as="h3" variant="headingLg">
          Đã đối soát
        </Text>
        <br />
        <InlineGrid gap={'200'} columns={{
      xs: 1,
      md: 3
    }}>
          {clientMonthlyReport?.map(report => {
        let month_name = Object.keys(report)[0];
        let value = Object.values(report)[0];
        return <BlockStack gap="200">
                <Text as="p">{__(month_name)}</Text>
                <Divider />
                <BlockStack gap={'100'}>
                  <Text as="p" variant="headingLg" tone="subdued">
                    {loadingClientMonthlyReport ? <SkeletonDisplayText /> : `${value.order} đơn`}
                  </Text>
                  <Text as="p" variant="bodySm" tone="subdued">
                    {loadingClientMonthlyReport ? <SkeletonDisplayText /> : `${value.customer} khách`}
                  </Text>
                  <Text as="p" variant="bodySm" tone="subdued">
                    {loadingClientMonthlyReport ? <SkeletonDisplayText /> : `${helpers.formatNumber(value.revenue)} doanh thu`}
                  </Text>
                </BlockStack>
              </BlockStack>;
      })}
        </InlineGrid>
      </Card>} />;
};
import { block as _block } from "million/react";
import { BlockStack, Card, Divider, InlineGrid, InlineStack, SkeletonDisplayText, Tabs, Text } from '@shopify/polaris';
import { ReactECharts, ReactEChartsProps } from 'components/React-ECharts';
import helpers from 'helpers/index';
import { useGetClientMonthlyReport, useGetClientOrderReport, useGetGeneralClientAnalytics } from 'queries/analytics.forClient';
import __ from 'languages/index';
import { useCallback, useEffect, useState } from 'react';
export default ForNormalUser;