import { useGetCurrentUserData } from 'queries/user.query';
import React, { useEffect, useState } from 'react';

/**
 * Check capacity of a current user.
 * @current_user_can check user capacity
 * @extra_condition boolean, if you want to check more condition, pass true to @extra_condition and it will show after @current_user_can check the right capacity
 */
export default function Capacity({
  current_user_can,
  extra_condition = true,
  onFail,
  children
}: {
  current_user_can: string;
  extra_condition?: boolean;
  onFail?: React.ReactNode;
  children: JSX.Element | JSX.Element[] | (() => JSX.Element);
}) {
  const {
    data
  } = useGetCurrentUserData();
  const [allow, setAllow] = useState<boolean | null>(null);
  useEffect(() => {
    if (!data) return;
    let {
      capacities,
      user_role
    } = data;

    /**
     * Nếu check role, thì ok, đây, bảng user_role
     */
    if (user_role === current_user_can) {
      setAllow(true);
      return;
    }
    if (!Array.isArray(capacities)) return;
    if (capacities.indexOf(current_user_can) < 0) {
      setAllow(false);
    } else {
      setAllow(true);
    }
  }, [data, current_user_can]);
  return <>{allow !== null ? allow ? extra_condition && children : onFail || null : null}</>;
}