import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedMedia } from "./media.query";
import { TypedUser } from "interface/user.model";

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/

export type TypedUserRole = "agency" | "general";

export interface TypedUser_group {
    user_group_id?: bigint;
    user_group_name?: string;
    user_group_slug?: string;
    user_group_thumbnail?: string | bigint;
    user_group_description?: string;
    user_group_status?: number;
    user_group_leader?: string,
    user_role?: TypedUserRole
    createdAt?: bigint;

    user_group_thumbnail_to_media?: TypedMedia;
    user_group_leader_to_user?: TypedUser;

    _count?: {
        user_to_group: number
    }
}


type IQuery = TypedUser_group & IQueryParams;


/*
* List of all app
*/
export function useGetUser_groups(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["user_group/fetch_entity_list"],
        queryFn: () => axios.get<TypedUser_group[]>(`/user_group${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetUser_group(user_group_id: string) {
    return useQuery({
        queryKey: ['user_group/fetch_entity'],
        queryFn: () => axios.get<TypedUser_group>(`/user_group/${user_group_id}`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useUpdateUser_group() {
    return useMutation({
        mutationKey: ['user_group/update_entity'],
        mutationFn: ({ user_group_id, ...rest }: TypedUser_group) => axios.patch<TypedUser_group>(`/user_group/${user_group_id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user_group/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['user_group/fetch_entity'] });
        }
    });
}


export function useCreateUser_group() {
    return useMutation({
        mutationKey: ['user_group/create_entity'],
        mutationFn: (entity: TypedUser_group) => axios.post<TypedUser_group>(`/user_group`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user_group/fetch_entity_list'] });
        }
    });
}


export function useDeleteUser_group() {
    return useMutation({
        mutationKey: ['user_group/delete_entity'],
        mutationFn: (user_group_id: string) => axios.delete<any>(`/user_group/${user_group_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user_group/fetch_entity_list'] });
        }

    });
}