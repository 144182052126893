import { compiledBlock as _compiledBlock } from "million/react";
const ActivityView_1 = _compiledBlock(_props => <slot>
      {_props.v0}
    </slot>, {
  name: "ActivityView_1",
  portals: ["v0"]
});
const ActivityView = function ActivityView() {
  const recordPerPage = 50;
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const {
    mutate: assignUserToDeviceUUID
  } = useAssignUserToDeviceUUID();
  const {
    mutate: updateDevice
  } = useUpdateDevice();

  /**
   * Lấy URL ra... để query. chú ý phải load nội dung mới
   * trong useEffect
   */
  let {
    activity_id
  } = useParams();
  const {
    data: entity,
    refetch: getEntity
  } = useGetDevice(activity_id);
  const history = useNavigate();
  useEffect(() => {
    getEntity();
  }, [activity_id]);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(window.location.search) || false;

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  const [mainQuery, setMainQuery] = useState({
    ...{
      query: '',
      page: 1,
      limit: recordPerPage,
      sort: 'createdAt:desc',
      device_id: ''
    },
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data,
    isLoading: loadingEntities
  } = useGetActivities(mainQuery);
  useEffect(() => {
    if (data) {
      setEntities(data.entities);
      setTotalItems(data.totalItems);
    }
  }, [data]);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  useEffect(() => {
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  useEffect(() => {
    if (entity) {
      setMainQuery({
        ...mainQuery,
        page: 1,
        device_id: entity?.device_id
      });
    }
  }, [entity]);
  const goToUserProfile = (user_id: any) => {
    history('/customer/view/' + user_id);
  };

  //   dispatch( assignUserToDeviceUUID({
  //     user_id: String(user_id),
  //     device_uuid: entity?.device_uuid
  // }) );
  const [showAddnewUser, setShowAddnewUser] = useState(false);
  const onCloseCustomerModal = useCallback((user_id: string) => {
    if (user_id) {
      assignUserToDeviceUUID({
        user_id: String(user_id),
        device_uuid: entity?.device_uuid
      });
      getEntity();
    }
  }, [entity]);
  const [showActivityStatusOption, setShowActivityStatusOption] = useState(false);
  const handleToggleBacklistDeviceUUID = useCallback((allowed: boolean) => {
    updateDevice({
      device_id: entity?.device_id,
      status: Number(allowed)
    });
    setShowActivityStatusOption(false);
  }, [entity]);
  const groups = useRef('');
  const commentBodyComponent = useCallback((element, index) => {
    let datetimeCreate = new Date(Number(element.createdAt));
    let dateMonthYear = dateandtime.format(datetimeCreate, 'ddd, DD, MMM, YYYY'); // Fri, 28, Apr, 2023
    let {
      activity_id,
      device_id,
      activity_referrer,
      activity_target,
      activity_ip,
      device
    } = element;
    let {
      device_uuid,
      user_agent,
      system_type,
      status
    } = device;
    let A = false;
    if (dateMonthYear !== groups.current) {
      groups.current = dateMonthYear;
      A = true;
    }
    let N = dateMonthYear.toLowerCase().split(',');
    let M = __(N[0]) + ', ' + N[1] + ' ' + __(N[2]) + ', ' + N[3];
    return <>
        {A && <div key={index + '_hand'} className="step-item">
            {M}
          </div>}
        <div className="card" key={index + 'fghncfgnd'} id={'comment_' + activity_id}>
          <div className="timeline_handler" key={index + 'fhgnfdghnmdx'}></div>
          <div className="time" key={index + 'ghngnf'}>
            <span className="hour" key={index + 'ghfnfhmtu'}>
              {dateandtime.format(datetimeCreate, 'HH:mm')}
            </span>
            <div className="author" key={index + 'yukmfyumy'}>
              {system_type}
            </div>
          </div>
          <div className="info" key={index + 'kmfgyumjdry'}>
            <h3 className="title" key={index + '46u546u'}>
              Trang đích: {activity_target}
            </h3>
            <div key={'_jkd' + index}>
              {activity_referrer && <Text as="p" variant="bodyMd" key={index + '_kjhk'}>
                  Referrer: {activity_referrer}
                </Text>}
              <Text as="p" variant="bodyMd" key={index + 'stswe4ye'}>
                From IP: {activity_ip}
              </Text>
            </div>
          </div>
        </div>
      </>;
  }, []);
  return /*@million jsx-skip*/<ActivityView_1 v0={<Page backAction={{
    content: '',
    onAction: () => history('/activity')
  }}>
        {entity && <>
            <DescriptionList gap="tight" items={[{
        term: 'Device UUID',
        description: entity.device_uuid
      }, {
        term: 'User Agent',
        description: entity.user_agent
      }, {
        term: 'System type',
        description: entity.system_type
      }, {
        term: 'Status',
        description: <>
                      <Popover active={showActivityStatusOption} activator={<Link onClick={() => setShowActivityStatusOption(true)}>
                            {entity.status === 1 ? <Badge tone="success">Active</Badge> : <Badge>Deactive</Badge>}
                          </Link>} autofocusTarget="first-node" onClose={() => setShowActivityStatusOption(false)}>
                        <ActionList actionRole="menuitem" items={[{
              content: 'Cho phép hoạt động',
              onAction: () => handleToggleBacklistDeviceUUID(true),
              icon: ChartPopularIcon
            }, {
              content: 'Cấm thiết bị này',
              onAction: () => handleToggleBacklistDeviceUUID(false),
              icon: AlertCircleIcon
            }]} />
                      </Popover>
                    </>
      }, {
        term: 'Created',
        description: dateandtime.format(new Date(Number(entity.createdAt)), 'DD-MM-YYYY HH:mm')
      }]} />

            {entity?.user_to_device && <>
                <Divider />
                <br />
                <Text as="p">User sử dụng thiết bị này</Text>
                <br />
                <InlineStack gap={'200'} align="start" blockAlign="center">
                  {entity?.user_to_device.map(device_user => {
            return <Tag onClick={() => goToUserProfile(device_user.user.user_id)}>{device_user.user.display_name}</Tag>;
          })}
                  <Popover active={showAddnewUser} activator={<Tag onClick={() => setShowAddnewUser(true)}>
                        <InlineStack align="start" blockAlign="center" gap="200">
                          <span key="add_new_assignee">Thêm khách hàng</span>
                          <Icon source={PlusIcon} />
                        </InlineStack>
                      </Tag>} autofocusTarget="first-node" onClose={() => setShowAddnewUser(false)}>
                    <Card>
                      <QuickSearchUser title="" user_role="customer" onClose={__newUser => {
                onCloseCustomerModal(String(__newUser.user_id));
                setShowAddnewUser(false);
              }} />
                    </Card>
                  </Popover>
                </InlineStack>
              </>}
          </>}

        <div className="timeline" key={'xdfdfbdbf' + Math.random()}>
          <div className="outer" key="dfbdfb">
            {!loadingEntities && Array.isArray(entities) && (entities.length > 0 ? entities.map(commentBodyComponent) : <div style={{
          padding: '15px'
        }}>
                  <Text as="p">Chưa có một hoạt động nào.</Text>
                </div>)}
          </div>
        </div>

        {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery?.page} pageSize={mainQuery?.limit} onChange={onChangePagination} /> : null}
        <br />
        <br />
      </Page>} />;
};
import { block as _block } from "million/react";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PlusIcon, ChartPopularIcon, AlertCircleIcon } from '@shopify/polaris-icons';
import { Page, Text, DescriptionList, Link, Tag, Divider, Popover, Card, Icon, InlineStack, Badge, ActionList } from '@shopify/polaris';
import helpers from 'helpers/index';
import Pagination from 'components/pagination';
import __ from 'languages/index';
import dateandtime from 'date-and-time';
import QuickSearchUser from 'components/quickSearchUser';
import { useAssignUserToDeviceUUID, useGetDevice, useUpdateDevice } from 'queries/device.query';
import { useGetActivities } from 'queries/activity.query';
export default ActivityView;