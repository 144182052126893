import { useCallback, useEffect, useState } from 'react';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import { Form, Text, InlineGrid, Modal, Select, TextField, BlockStack } from '@shopify/polaris';
import QuickUploadImage from 'components/oneclick-upload-image';
import { TypedAchievementConditionAction, TypedAchievementConditionName, TypedUserAchievement, useCreateAchivement, useUpdateAchivement } from 'queries/user_archivement.query';
import __helpers from 'helpers/index';
import __ from 'languages/index';
export default function AddAchievementModal({
  open = false,
  entity,
  onClose,
  user_id
}: {
  open: boolean;
  onClose: Function;
  user_id?: string | bigint;
  entity?: TypedUserAchievement;
}) {
  const {
    mutateAsync: createAchievement
  } = useCreateAchivement();
  const {
    mutateAsync: updateEntity
  } = useUpdateAchivement();
  const closeModal = useCallback(() => {
    onClose?.call(this, null);
  }, []);
  const useFields = {
    achievement_badge: useField<string>({
      value: entity?.achievement_badge ?? '',
      validates: []
    }),
    achievement_badge_cache: useField<string>({
      value: entity?.achievement_badge_to_media ? entity.achievement_badge_to_media.media_thumbnail ? entity.achievement_badge_to_media.media_thumbnail['256x169'] : null : undefined,
      validates: []
    }),
    achievement_name: useField<string>({
      value: entity?.achievement_name ?? '',
      validates: [notEmpty('Trường này không được để trống.'), lengthLessThan(150, 'Không được dài hơn 150 ký tự.'), lengthMoreThan(2, 'Không được ngắn hơn 2 ký tự.')]
    }),
    achievement_description: useField<string>({
      value: entity?.achievement_description ?? '',
      validates: [notEmpty('Trường này không được để trống.'), lengthLessThan(150, 'Không được dài hơn 150 ký tự.'), lengthMoreThan(2, 'Không được ngắn hơn 2 ký tự.')]
    }),
    achievement_condition_name: useField<TypedAchievementConditionName>({
      value: entity?.achievement_condition_name ?? 'JOIN_DATE',
      validates: []
    }),
    achievement_condition_value: useField<string>({
      value: entity?.achievement_condition_value ?? '1',
      validates: [inputValue => {
        if (Number(inputValue) < 0) return 'Giá trị phải lớn hơn 0';
      }]
    }),
    achievement_interval: useField<TypedAchievementConditionAction>({
      value: entity?.achievement_interval ?? 'daily',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        let r: any = null;
        if (!entity) {
          r = await createAchievement({
            achievement_badge: values.achievement_badge,
            achievement_name: values.achievement_name,
            achievement_description: values.achievement_description,
            achievement_condition_name: values.achievement_condition_name,
            achievement_condition_value: values.achievement_condition_value,
            achievement_interval: values.achievement_interval
          });
        } else {
          r = await updateEntity({
            achievement_id: entity?.achievement_id,
            achievement_badge: values.achievement_badge,
            achievement_name: values.achievement_name,
            achievement_description: values.achievement_description,
            achievement_condition_name: values.achievement_condition_name,
            achievement_condition_value: values.achievement_condition_value,
            achievement_interval: values.achievement_interval
          });
        }
        if (typeof r.error === 'undefined') {
          closeModal();
        }
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success',
        errors: []
      };
    }
  });
  useEffect(() => {
    resetForm();
  }, []);
  const [uploadError, setUploadError] = useState(null);

  // condition
  const conditionOptions = [{
    label: 'Không có quy tắc',
    value: ''
  }, {
    label: 'Số ngày gia nhập',
    value: 'JOIN_DATE'
  }, {
    label: 'Số đơn hàng',
    value: 'ORDER_NUMBER'
  }, {
    label: 'Số tiền tiêu',
    value: 'PAYMENT_NUMBER'
  }, {
    label: 'Số task hoàn thành',
    value: 'TASK_DONE'
  }, {
    label: 'Số người giới thiệu',
    value: 'AFFILIATE_NUMBER'
  }];
  return <Modal open={open} title={'Thêm một thành tích'} onClose={closeModal} primaryAction={{
    content: 'Lưu lại',
    loading: submitting,
    disabled: !dirty,
    onAction: submit
  }} secondaryActions={[{
    content: 'Đóng lại',
    onAction: closeModal
  }]}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <InlineGrid columns={['oneThird', 'twoThirds']} gap={'200'}>
            <div className="d1">
              <QuickUploadImage onSuccess={e => {
              useFields.achievement_badge.onChange(e.media_id);
              useFields.achievement_badge_cache.onChange(e.media_url);
            }} onError={e => setUploadError(e)} title="Biểu trưng (44px x 36px)" placeholder={useFields.achievement_badge_cache.value ? __helpers.getMediaLink(useFields.achievement_badge_cache.value) : null} />
              <Text as="p" tone="critical">
                {uploadError}
              </Text>
            </div>
            <div className="d2">
              <TextField autoFocus autoComplete="off" label="Tựa đề" {...fields.achievement_name} />

              <TextField autoComplete="off" label="Mô tả" {...fields.achievement_description} />
            </div>
          </InlineGrid>

          <br />
          <Text as="h4" variant="headingSm">
            Tự động hóa
          </Text>
          <br />
          <BlockStack gap={'400'}>
            <Select label="Tiêu chí" options={conditionOptions} onChange={(e: TypedAchievementConditionName) => useFields.achievement_condition_name.onChange(e)} value={useFields.achievement_condition_name.value} />

            {useFields.achievement_condition_name.value === '' && <Text as="p" tone="subdued">
                Khi không có quy tắc, hệ thống sẽ không chạy tự động, và bạn sẽ trao thưởng cho mọi người bằng tay.{' '}
              </Text>}

            {useFields.achievement_condition_name.value !== '' && <>
                <TextField label="Lớn hơn" type="number" {...fields.achievement_condition_value} autoComplete="off" />

                <Select label="Cơ chế trao thưởng" options={[{
              label: 'Không tự động',
              value: 'manual'
            }, {
              label: 'Hàng ngày',
              value: 'daily'
            }, {
              label: 'Hàng tuần',
              value: 'weekly'
            }, {
              label: 'Hàng tháng',
              value: 'monthly'
            }, {
              label: 'Hàng năm',
              value: 'yearly'
            }]} onChange={(e: TypedAchievementConditionAction) => useFields.achievement_interval.onChange(e)} value={useFields.achievement_interval.value} />
              </>}
          </BlockStack>
        </Form>
      </Modal.Section>
    </Modal>;
}