import { Card, Badge, Text,
// IndexTable,
Link, Page,
// useIndexResourceState,
Thumbnail,
// LegacyCard,
DataTable,
// TableData,
InlineStack, Button } from '@shopify/polaris';
import { DeleteIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import helpers from 'helpers';
import dateandtime from 'date-and-time';
import Pagination from 'components/pagination';
// import SkeletonLoading from 'components/skeletonPageLoading';
import __, { ___ } from 'languages/index';
// import SimpleFilter from 'components/simpleFilter';
import { Helmet } from 'react-helmet-async';
import ProductCategoryModalAddAndEdit from './product_category.edit.modal';
import { TypedProductCategory } from 'interface/product.model';
import { useGetProductCategories, useDeleteProductCategory } from 'queries/product_category.query';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
export default function ProductCategory() {
  const [entities, setEntities] = useState<TypedProductCategory[] | null>(null);
  const [totalItems, setTotalItems] = useState(0);
  const {
    mutateAsync: deleteProductCategoryQuery
  } = useDeleteProductCategory();

  // const dispatch = useAppDispatch();
  // const history = useNavigate();

  const rootSearchPath = window.location.search;
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 20,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data: productCategoryData
  } = useGetProductCategories(mainQuery);
  useEffect(() => {
    if (productCategoryData) {
      let {
        body,
        totalItems
      } = productCategoryData;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [productCategoryData]);

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    setMainQuery(mainQuery);
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Product Category', '/product_category' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  const [showModal, setShowModal] = useState(false);
  const modalEntity = useRef(null);
  const clickToShowEditModal = useCallback((categoryData: TypedProductCategory) => {
    modalEntity.current = categoryData;
    setShowModal(true);
  }, []);

  // delete modal
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDeleteProductCategory = useCallback(async product_category_id => {
    const confirmed = await showDeleteConfirm('Xóa chuyên mục sản phẩm?', 'Chỉ các chuyên mục không có sản phẩm nào mới có thể xóa bỏ. Nếu chuyên mục còn sản phẩm, vui lòng di chuyển sang chuyên mục khác trước.');
    if (confirmed) {
      // Xử lý hành động xóa
      try {
        await deleteProductCategoryQuery(product_category_id);
      } catch (e) {}
    }
  }, []);
  return <>
      <Helmet>
        <title>Quản lý danh mục sản phẩm</title>
      </Helmet>

      <ProductCategoryModalAddAndEdit show={showModal} onClose={() => {
      modalEntity.current = null;
      setShowModal(false);
    }} entity={modalEntity.current} />

      <Page title="Danh mục sản phẩm" primaryAction={{
      content: 'Tạo mới danh mục',
      onAction: () => setShowModal(true)
    }}>
        <Card padding={'0'}>
          <DataTable verticalAlign="middle" columnContentTypes={['text', 'text', 'text', 'numeric', 'numeric', 'numeric']} headings={['Tên chuyên mục', 'Mô tả', 'Hiển thị', 'Sản phẩm', 'Ngày tạo', '-']} rows={entities?.length > 0 ? entities.map((categoryData, index) => {
          return [<Link removeUnderline onClick={() => clickToShowEditModal(categoryData)} key={index}>
                        {categoryData.category_thumbnail ? <InlineStack blockAlign="center" gap={'100'}>
                            <Thumbnail size="small" source={helpers.getMediaLink(categoryData.category_thumbnail_to_media ? categoryData.category_thumbnail_to_media.media_thumbnail['128x128'] : undefined)} alt={''} />
                            <Text as="span">{categoryData.category_name}</Text>
                          </InlineStack> : <Text as="span">{categoryData.category_name}</Text>}
                      </Link>, <Text as="span">{helpers.trimMiddleString(categoryData.category_excerpt, 30, 10)}</Text>, categoryData.category_status === 1 ? <Badge tone="success">Có</Badge> : <Badge tone="warning">Không</Badge>, categoryData._count?.product_to_category || 0, dateandtime.format(new Date(Number(categoryData.createdAt)), 'DD-MM-YYYY'), <Button variant="plain" onClick={() => handleDeleteProductCategory(categoryData.category_id)} icon={DeleteIcon}></Button>];
        }) : []} footerContent={___('Show {display_record_number} of {total_record_number} results', {
          display_record_number: <Text as="strong">{entities?.length || 0}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} />
        </Card>
        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
    </>;
}