import { LegacyStack, Tag, Autocomplete, Icon, InlineError } from '@shopify/polaris';
import __helpers from 'helpers/index';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SearchIcon, PlusCircleIcon } from '@shopify/polaris-icons';
import { useMutateGetProducts } from 'queries/product.query';
import { TypedProduct } from 'interface/product.model';

/** Phiên bản cải tiến tìm nhanh một product category, bản cải tiến triệt để ... */
export default function QuickSearchProduct2({
  current_product_id = [],
  label = 'Danh sách sản phẩm',
  errorMessage = '',
  onClose,
  allowMultiple = true
}: {
  current_product_id: any[];
  errorMessage?: string;
  label: string;
  onClose: (val: TypedProduct[]) => void;
  allowMultiple?: boolean;
}) {
  /******************************** *
   * ADVANCE PRODUCT CATEGORY ...
   ******************************** */

  /** Lưu cache hết lại để hiển thị cái tên trùng với cái ID chết tiệt ... */
  const cacheProductCategoryData = useRef<any[]>([]);
  const initialQuery = useMemo(() => {
    return {
      product_id: '',
      // trong trường hợp mà nó lưu sẵn rồi thì có thể để vào đây!
      limit: 10,
      query: '',
      product_status: 1,
      sort: 'createdAt:desc'
    };
  }, []);
  const [productCategoryQuery, setProductCategoryQuery] = useState(initialQuery);
  const {
    mutateAsync: refetchProductCategory,
    isPending: searchingCategory,
    data: entities
  } = useMutateGetProducts();
  const reduceProductCategoryRequest = useCallback(mainQuery => {
    refetchProductCategory(mainQuery);
    return mainQuery;
  }, []);
  const reduceRequestProductCategoryMemo = useMemo(() => {
    return __helpers.debounce(_value => {
      reduceProductCategoryRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    reduceRequestProductCategoryMemo(productCategoryQuery);
  }, [productCategoryQuery, reduceRequestProductCategoryMemo]);

  /** Chỉ khi hiện tại khác với current thì mới gọi sang bên kia ... */
  const handleClose = useCallback((newProductID: string[]) => {
    if (JSON.stringify(current_product_id) !== JSON.stringify(newProductID)) {
      let loadingFromCacheANDRETURN = cacheProductCategoryData.current.filter(el => newProductID.includes(el.product_id));
      onClose(loadingFromCacheANDRETURN);
    }
  }, [current_product_id, onClose, cacheProductCategoryData]);
  const [selectedOptions3, setSelectedOptions3] = useState<string[]>([]);
  const [inputValue3, setInputValue3] = useState('');
  const [options3, setOptions3] = useState([]);

  /**
   * Yêu cầu chỉ được nạp lần đầu tiên, không được nạp lại ...
   */
  let alreadyTakeCurrentCategory = useRef(false);
  useEffect(() => {
    if (current_product_id && alreadyTakeCurrentCategory.current === false) {
      setSelectedOptions3(current_product_id);
      alreadyTakeCurrentCategory.current = true;
      setProductCategoryQuery(old => {
        return {
          ...old,
          product_id: current_product_id.join(',')
        };
      });
    }
  }, [current_product_id]);

  /** Khối này theo dõi entities và nhét vào cache, sau đó nhét vào danh sách */

  const deselectedOptions3 = useCallback(entities => {
    const results = entities.body.map((productData: any) => {
      cacheProductCategoryData.current.push(productData);
      return {
        label: productData.product_name,
        value: String(productData.product_id)
      };
    });
    setOptions3(results);
  }, [cacheProductCategoryData.current]);
  useEffect(() => {
    if (!entities && typeof entities?.body === 'undefined') return;
    deselectedOptions3(entities);
  }, [entities]);
  const updateText3 = useCallback((value: string) => {
    setInputValue3(value);
    //   if (!value) return;
    let N = {
      ...productCategoryQuery,
      ...{
        query: value,
        product_id: '' /** unset product_id ddi */
      }
    };
    setProductCategoryQuery(N);
  }, [productCategoryQuery]);
  const updateSelectedCallback = useCallback((selected: any[]) => {
    setSelectedOptions3(selected);
    handleClose(selected);
  }, [selectedOptions3]);
  const removeTag3 = useCallback((tag: string) => () => {
    const selected = [...selectedOptions3];
    selected.splice(selected.indexOf(tag), 1);
    setSelectedOptions3(selected);
    handleClose(selected);
  }, [selectedOptions3]);
  const verticalContentMarkup3 = selectedOptions3.length > 0 ? <LegacyStack spacing="extraTight" alignment="center">
        {selectedOptions3.map(option => {
      if (!option) return null;
      let tagInCache = cacheProductCategoryData.current.find(el => BigInt(el.product_id) === BigInt(option));
      let tagLabel = tagInCache?.product_name || option;
      return <Tag key={`option${option}`} onRemove={removeTag3(option)}>
              {tagLabel}
            </Tag>;
    })}
      </LegacyStack> : null;
  return <>
      <Autocomplete allowMultiple={allowMultiple} loading={searchingCategory} options={options3} selected={selectedOptions3} textField={<>
            <Autocomplete.TextField onChange={updateText3} label={label} error={errorMessage ? true : false} placeholder="Chọn một sản phẩm" value={inputValue3} prefix={<Icon source={SearchIcon} tone="base" />} verticalContent={verticalContentMarkup3} autoComplete="off" />
            <div style={{
        marginTop: '4px'
      }}>
              <InlineError message={errorMessage} fieldID="TextFieldSelectCategory" />
            </div>
          </>} onSelect={updateSelectedCallback} // setSelectedOptions3
    listTitle="Danh sách sản phẩm" />
    </>;
}