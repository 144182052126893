import { Modal, Text } from '@shopify/polaris';
import QuickSearchUser from 'components/quickSearchUser';
import helpers from 'helpers/index';
import __ from 'languages/index';
import { TypedUserAchievement, useAssignAchivement } from 'queries/user_archivement.query';
import { useCallback, useState } from 'react';
type TypedAchievementImport = {
  open: boolean;
  onClose: Function;
  user_id?: string | bigint;
  entity?: TypedUserAchievement;
  achievement_id: string;
};
export default function AchievementDecorateModal({
  open = false,
  entity,
  onClose,
  user_id,
  achievement_id
}: TypedAchievementImport) {
  const [submiting, setSubmiting] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const {
    mutateAsync: assign
  } = useAssignAchivement();
  // const dispatch = useAppDispatch();
  const closeModal = useCallback(() => {
    onClose?.call(this, null);
  }, []);
  const submit = useCallback(async () => {
    if (!selectedUser) return;
    setSubmiting(true);
    if (typeof selectedUser.user_id !== 'undefined') {
      await assign({
        achievement_id: achievement_id,
        user_id: selectedUser.user_id
      }).then().catch(e => {});
      await helpers.sleep(1000);
    }
    setSubmiting(false);
    closeModal();
  }, [selectedUser]);
  return <Modal open={open} title={'Trao tặng huân chương cho ai đó'} onClose={closeModal} primaryAction={{
    content: 'Lưu lại',
    loading: submiting,
    onAction: submit
  }} secondaryActions={[{
    content: __('Close'),
    onAction: closeModal
  }]}>
      <Modal.Section>
        <QuickSearchUser support_create_new support_filter_user_role title="" onClose={people => setSelectedUser(people)} />
        <br />
        {selectedUser && <Text as="p" tone="subdued">
            <strong>{selectedUser?.display_name || selectedUser?.user_email || selectedUser?.user_login}</strong> sẽ có thể nhận thông báo qua
            Notification hoặc Email.
          </Text>}
      </Modal.Section>
    </Modal>;
}