import { compiledBlock as _compiledBlock } from "million/react";
const ViewWithdraw_1 = _compiledBlock(_props => <slot>
      {_props.v0}

      {_props.v1}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </slot>, {
  name: "ViewWithdraw_1",
  portals: ["v0", "v1"]
});
const ViewWithdraw = function ViewWithdraw() {
  let {
    slug: withdraw_id
  } = useParams();
  const history = useNavigate();
  const {
    data: entity
  } = useGetWithdraw(withdraw_id);
  const {
    mutateAsync: approveWithdraw
  } = useApprovedWithdraw();
  const {
    mutateAsync: deniedWithdraw
  } = useDeniedWithdraw();
  const [noteContent, setNoteContent] = useState('');
  const approveCallback = useCallback(async () => {
    try {
      await approveWithdraw({
        id: String(entity.id),
        request_status: 1,
        request_note: noteContent
      });
    } catch (e) {
      console.error(e, '<<< APPROVE ERROR');
    }
  }, [noteContent, entity]);
  const denyCallback = useCallback(async () => {
    try {
      await deniedWithdraw({
        id: String(entity.id),
        request_status: 1,
        request_note: noteContent
      });
    } catch (e) {
      console.error(e, '<<< DENY ERROR');
    }
  }, [noteContent, entity]);
  useEffect(() => {
    if (entity) setNoteContent(entity?.request_note ?? '');
  }, [entity]);
  const [user_bank_information_history, setUser_bank_information_history] = useState('{}');
  useEffect(() => {
    if (entity) {
      setUser_bank_information_history(entity?.user_bank_information_history);
    }
  }, [entity]);
  return /*@million jsx-skip*/<ViewWithdraw_1 v0={<Helmet>
        <title>Yêu cầu rút tiền</title>
      </Helmet>} v1={<Page narrowWidth title="Yêu cầu rút tiền" backAction={{
    content: 'Quay lại danh sách',
    onAction: () => history('/withdraw')
  }}>
        <DescriptionList items={[{
      term: 'Người yêu cầu',
      description: <BlockStack gap="400">
                  <InlineStack gap={'100'} align="start" blockAlign="center">
                    <Avatar size="lg" customer name={entity?.request_to_user?.display_name} initials={String(entity?.request_to_user?.display_name || 'R').charAt(0)} source={entity?.request_to_user.user_avatar ? process.env.REACT_APP_AJAX_UPLOAD_PERMALINK + entity?.request_to_user.user_avatar : 'https://placehold.co/100x100'} />
                    <Link url={`/customer/view/` + entity?.user_id + '#tab-4'}>
                      <Text variant="bodyMd" fontWeight="bold" as="span">
                        {entity?.request_to_user?.display_name}
                      </Text>
                    </Link>
                  </InlineStack>

                  <Text as="p">
                    Email: {entity?.request_to_user?.user_email ?? '-'} / Phone: {entity?.request_to_user?.user_phonenumber ?? '-'}
                  </Text>
                  <Text as="p">Danh sách ví của khách:</Text>
                  <List>
                    {entity?.request_to_user?.wallet && entity?.request_to_user?.wallet.map(wallet => {
            return <List.Item>
                            {`${wallet.wallet_type.wallet_name} (${wallet.wallet_type.wallet_unit}): ${__helpers.formatNumberWithCommas(wallet.balance)} 
                            ${wallet.wallet_type.wallet_sign}`}
                          </List.Item>;
          })}
                  </List>
                </BlockStack>
    }, {
      term: 'Yêu cầu',
      description: <>
                  <BlockStack gap="400">
                    <div>
                      Số tiền yêu cầu: <strong>{__helpers.formatNumberWithCommas(entity?.amount)}</strong> sẽ được rút khỏi ví{' '}
                      <strong>{entity?.wallet_unit}</strong> của người dùng trong trường hợp được duyệt.
                    </div>
                    <Divider />
                    <div>Ngân hàng: {JSON.parse(user_bank_information_history)?.bank_name}</div>
                    <div>Chủ tài khoản: {JSON.parse(user_bank_information_history)?.bank_owner_display_name}</div>
                    <div>Số tài khoản: {JSON.parse(user_bank_information_history)?.bank_owner_number_account || '-'}</div>
                    <div>Số thẻ: {JSON.parse(user_bank_information_history)?.bank_owner_card_number || '-'}</div>
                  </BlockStack>
                </>
    }, {
      term: 'Kết quả xét duyệt',
      description: <BlockStack gap="400">
                  <TextField label="Lý do từ chối hoặc chứng từ chuyển khoản" autoComplete="off" value={noteContent} onChange={v => setNoteContent(v)} multiline={5} maxLength={1000} showCharacterCount disabled={entity?.request_status !== 0} />

                  <ExceptionList items={[{
          icon: AlertTriangleIcon,
          status: 'critical',
          description: 'Khi bạn duyệt yêu cầu này, hãy chắc chắn ghi chú chuyển khoản vào ô ghi chú, một thông báo "Yêu cầu đã được duyệt" được gửi tới khách hàng. Và vì đây là hành động rút tiền, họ sẽ nhận được thông báo ví bị trừ tiền.'
        }, {
          icon: AlertTriangleIcon,
          status: 'critical',
          description: 'Khi bạn từ chối duyệt, khách hàng cũng sẽ nhận được thông báo từ chối với ghi chú của bạn là lý do từ chối duyệt.'
        }]} />

                  <Divider />

                  <Button size="large" icon={StatusActiveIcon}
        // tone="success"
        variant="primary" pressed={entity?.request_status === 1} onClick={() => approveCallback()} disabled={entity?.request_status !== 0}>
                    Duyệt
                  </Button>
                  <Button size="large" variant="secondary" icon={XSmallIcon} tone="critical" pressed={entity?.request_status === -1} onClick={() => denyCallback()} disabled={entity?.request_status !== 0}>
                    Từ chối duyệt
                  </Button>
                  {entity?.updatedBy && <>
                      Cập nhật lần cuối {__helpers.subtractTimeHistory(entity?.updatedAt)} bởi{' '}
                      {entity?.update_to_user?.display_name ?? entity?.update_to_user?.user_email ?? entity?.update_to_user?.user_phonenumber ?? '-'}
                    </>}
                </BlockStack>
    }]} />
      </Page>} />;
};
import { block as _block } from "million/react";
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DescriptionList, Page, Link, Button, Text, Avatar, InlineStack, BlockStack, Divider, TextField, List, ExceptionList } from '@shopify/polaris';
import __helpers from 'helpers/index';
import { Helmet } from 'react-helmet-async';
import { useApprovedWithdraw, useDeniedWithdraw, useGetWithdraw } from 'queries/withdraw.query';
import { AlertTriangleIcon, XSmallIcon, StatusActiveIcon } from '@shopify/polaris-icons';
export default ViewWithdraw;