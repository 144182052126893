import { EmptyState, Form, FormLayout, IndexTable, Layout, Modal, Page, TextField, useIndexResourceState, Card } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import emptyIMG from 'media/images/empty.png';
import Pagination from 'components/pagination';
import helpers from '../../helpers';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import SkeletonLoading from 'components/skeletonPageLoading';
import SimpleFilter from 'components/simpleFilter';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
import { useCreateComment, useDeleteComment, useGetComments, useUpdateComment } from 'queries/comment.query';
export default function CommentList() {
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const {
    mutateAsync: createEntity
  } = useCreateComment();
  const {
    mutateAsync: deleteEntity
  } = useDeleteComment();
  const {
    mutateAsync: updateEntity,
    isSuccess: updateSuccess
  } = useUpdateComment();
  const history = useNavigate();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search: searchParam
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(searchParam);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 25,
    comment_type: '',
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data,
    isLoading: loading
  } = useGetComments(mainQuery);
  useEffect(() => {
    if (data) {
      setEntities(data.entities);
      setTotalItems(data.totalItems);
    }
  }, [data]);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (searchParam !== buildURLSearch) history('/comment' + buildURLSearch);
    getEntities();
  }, [mainQuery]);
  const [showEditModel, setShowEditModel] = useState(false);
  const closeModal = useCallback(() => {
    setShowEditModel(active => !active);
  }, [showEditModel]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);

  /**
   * Delete comment
   */

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange
  } = useIndexResourceState(entities, {
    resourceIDResolver
  });
  function resourceIDResolver(comment_data: any) {
    return comment_data.comment_id ?? null;
  }
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDeleteComment = useCallback(async () => {
    const confirmed = await showDeleteConfirm('Xóa những comment này?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      deleteEntity(selectedResources.join(','));
      // @ts-ignore
      handleSelectionChange('all', false); // <~~ This will trigger the recalculation
    }
  }, [selectedResources]);
  const publishCommentStatus = useCallback(async () => {
    await updateEntity({
      comment_id: selectedResources.join(','),
      comment_status: 'publish'
    });
  }, [selectedResources]);
  const deleteCommentStatus = useCallback(async () => {
    await updateEntity({
      comment_id: selectedResources.join(','),
      comment_status: 'delete'
    });
  }, [selectedResources]);
  const spamCommentStatus = useCallback(async () => {
    updateEntity({
      comment_id: selectedResources.join(','),
      comment_status: 'spam'
    });
  }, [selectedResources]);

  /**
   * must use callback function ...
   */
  const [commentDetail, setCommentDetail] = useState<any>(null);
  const getCommentDetail = useCallback((comment_data: any) => {
    setCommentDetail(comment_data);
    setShowEditModel(true);
  }, []);
  const {
    fields,
    dirty,
    submit,
    submitting
  } = useForm({
    fields: {
      comment_title: useField<string>({
        value: commentDetail?.comment_title ?? '',
        validates: [notEmpty('Title is required!'), lengthMoreThan(5, 'Title must be more than 5 characters'), lengthLessThan(200, 'No more than 200 characters.')]
      }),
      comment_content: useField<string>({
        value: commentDetail?.comment_content ?? '',
        validates: [notEmpty('Content is required!'), lengthMoreThan(5, 'Content must be more than 5 characters'), lengthLessThan(200, 'No more than 200 characters.')]
      })
    },
    async onSubmit(values) {
      try {
        await updateEntity({
          comment_id: commentDetail.comment_id,
          comment_title: values.comment_title,
          comment_content: values.comment_content
        });
        setShowEditModel(false);
      } catch (e: any) {
        if (typeof e.params !== 'undefined' && e.params.field) {
          fields[e.params.field]?.setError(e.message);
        }
      }
      return {
        status: 'success'
      };
    }
  });

  /**
   * read out commentDetail
   */
  const detailModal = <Modal open={showEditModel} onClose={closeModal} title="Edit comment" primaryAction={{
    content: 'Save',
    disabled: !dirty,
    loading: submitting,
    onAction: submit
  }} secondaryActions={[{
    content: 'Close',
    onAction: closeModal
  }]}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <FormLayout>
            <TextField autoFocus autoComplete="off" requiredIndicator label="Title" {...fields.comment_title} />
            <TextField autoComplete="off" requiredIndicator multiline={4} label="Content" {...fields.comment_content} />
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>;

  /**
   *  Index table
   */
  function IndexTableComment() {
    const resourceName = {
      singular: 'comment',
      plural: 'comments'
    };
    const promotedBulkActions = [{
      content: 'Delete comment',
      onAction: () => handleDeleteComment()
    }];
    const bulkActions = [{
      content: 'Publish',
      onAction: () => {
        publishCommentStatus();
      }
    }, {
      content: 'Spam',
      onAction: () => {
        spamCommentStatus();
      }
    }, {
      content: 'Delete',
      onAction: () => {
        deleteCommentStatus();
      }
    }];
    const rowMarkup = entities?.map((props, index) => {
      const {
        comment_id,
        user,
        comment_title,
        comment_content,
        object_id,
        comment_type,
        comment_parent,
        comment_status,
        comment_point
      } = props;
      return <IndexTable.Row id={comment_id} key={comment_id} selected={selectedResources.includes(comment_id)} position={index}>
          <IndexTable.Cell>
            <div key={comment_id} onClick={() => getCommentDetail(props)}>
              {user.display_name}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div key={comment_id} onClick={() => getCommentDetail(props)}>
              {comment_title}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div key={comment_id} onClick={() => getCommentDetail(props)}>
              {comment_content}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div key={comment_id} onClick={() => getCommentDetail(props)}>
              {object_id}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div key={comment_id} onClick={() => getCommentDetail(props)}>
              {comment_type}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div key={comment_id} onClick={() => getCommentDetail(props)}>
              {comment_parent}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div key={comment_id} onClick={() => getCommentDetail(props)}>
              {comment_status}
            </div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div key={comment_id} onClick={() => getCommentDetail(props)}>
              {comment_point}
            </div>
          </IndexTable.Cell>
        </IndexTable.Row>;
    });
    return <IndexTable loading={loading} resourceName={resourceName} itemCount={entities.length} selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length} onSelectionChange={handleSelectionChange} bulkActions={bulkActions} promotedBulkActions={promotedBulkActions} headings={[{
      title: 'User'
    }, {
      title: 'Title'
    }, {
      title: 'Content'
    }, {
      title: 'Object id'
    }, {
      title: 'Type'
    }, {
      title: 'Comment parent'
    }, {
      title: 'Status'
    }, {
      title: 'Point'
    }]}>
        {rowMarkup}
      </IndexTable>;
  }
  const emptyData = <EmptyState heading="No comment to show here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>;
  const PagesList = totalItems > 0 ? <>
        <IndexTableComment />
      </> : emptyData;
  const Actual_page = <Page fullWidth title="Comment &amp; Review">
      {detailModal}
      <Layout>
        <Layout.Section>
          <Card padding="0">
            <SimpleFilter loading={loading} options={[]} onCallback={filterCallback} sortField={[]} />
            {PagesList}
          </Card>
          <br />
          {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
        </Layout.Section>
      </Layout>
    </Page>;
  return <>{entities === null ? <SkeletonLoading /> : Actual_page}</>;
}