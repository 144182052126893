import { Autocomplete, Card, Icon } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SearchIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import { useFindWallets } from 'queries/wallet.query';
export default function QuickSearchWallet({
  current_wallet_id,
  onClose,
  title,
  error
}: {
  current_wallet_id?: string | bigint;
  title?: string;
  error?: string;
  onClose: (val: string) => void;
}) {
  const {
    mutate: getEntities,
    isPending: loading,
    data: entities
  } = useFindWallets();

  /** Avoid load too much ... */
  const [current_setting, setCurrent_setting] = useState(null);
  let runCurrentSettingOrnot = useRef(false);
  useEffect(() => {
    if (!current_wallet_id) return;
    if (!entities) return; // make sure entity is loaded then do it

    if (runCurrentSettingOrnot.current === false) {
      helpers.sleep(1000).then(a => {
        setCurrent_setting(current_wallet_id);
      }).catch(e => {});
    }
    runCurrentSettingOrnot.current = true;
  }, [current_wallet_id, runCurrentSettingOrnot, entities]);

  /**
   * Assign cho ai đóa
   */
  const [userSelected, setUserSelected] = useState<string[]>([]);
  const [userQuickSearchText, setUserQuickSearchText] = useState<string>('');
  const [userListForQuickOption, setUserListForQuickOption] = useState([]);
  useEffect(() => {
    getEntities({
      sort: 'createdAt:desc',
      limit: 5
    });
  }, []);
  useEffect(() => {
    let innerCampaign = [];
    if (!entities) return;
    let {
      body
    } = entities;
    if (Array.isArray(body) && body.length > 0) for (let camp of body) {
      innerCampaign.push({
        value: camp.id,
        label: camp.wallet_name
      });
      if (current_setting) {
        if (camp.id === current_setting) {
          setUserQuickSearchText(camp.wallet_name);
        }
      }
    }
    setUserListForQuickOption(innerCampaign);
  }, [entities, current_setting]);
  const onQueryToServer = useCallback((keyword: string) => {
    getEntities({
      query: keyword,
      sort: 'createdAt:desc',
      limit: 10
    });
  }, []);
  const onChangeMainQueryCallback = useMemo(() => {
    return helpers.debounce(_value => {
      onQueryToServer?.call(this, _value);
    }, 400);
  }, []);
  const updateUserText = useCallback((value: string) => {
    setUserQuickSearchText(value);
    onChangeMainQueryCallback(value);
  }, []);
  const updateSelection = useCallback((selected: string[]) => {
    const selectedValue = selected.map(selectedItem => {
      const matchedOption = userListForQuickOption.find(option => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });
    setUserSelected(selected);
    setUserQuickSearchText(selectedValue[0] || '');
    onClose(selected[0]);
  }, [userListForQuickOption]);
  const textFieldSelectUser = <Autocomplete.TextField onChange={updateUserText} label={title || ''} clearButton onClearButtonClick={() => {
    setUserQuickSearchText('');
    updateSelection([]);
  }} value={userQuickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} placeholder="Tìm một ví" autoComplete="off" error={error} />;
  return <Autocomplete options={userListForQuickOption} selected={userSelected} onSelect={updateSelection} textField={textFieldSelectUser} loading={loading} />;
}