import { Autocomplete, Divider, Icon, InlineError, InlineStack, Tag, Text } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { SearchIcon, PlusCircleIcon } from '@shopify/polaris-icons';
import ProductCategoryModalAddAndEdit from './posts.category.edit.modal';
import { useGetPostCategories } from 'queries/post_category.query';
export default function QuickSearchPostCategory({
  onClose,
  errorMessage = '',
  current_category_id
}: {
  onClose: (val: string[]) => void;
  errorMessage?: string;
  current_category_id: string[];
}) {
  /**  Lưu cả array lại để tẹo hiển thị  */

  const {
    isLoading: searching,
    data: entities
  } = useGetPostCategories({
    limit: 100,
    category_status: 1,
    sort: 'createdAt:desc'
  });

  /**
   *  list
   */

  const [postCategoryList, setPostCategoryList] = useState(null);
  useEffect(() => {
    if (entities) {
      let {
        body,
        totalItems
      } = entities;
      setPostCategoryList(body);
    }
  }, [entities]);

  /**
   * Assign cho ai đóa
   */
  const [categorySelected, setCategorySelected] = useState<string[] | null>([]);
  const [userQuickSearchText, setUserQuickSearchText] = useState<string>('');
  const [productCategoryListForQuickOption, setproductCategoryListForQuickOption] = useState<{
    label: string;
    value: string;
  }[]>([]);
  useEffect(() => {
    if (!current_category_id) return;
    setCategorySelected(current_category_id);
  }, [current_category_id]);
  const callmebackCallback = useCallback(selected => {
    onClose(selected);
  }, []);
  useEffect(() => {
    let innerUser = [];
    if (postCategoryList) for (let categoryData of postCategoryList) {
      innerUser.push({
        value: categoryData.category_id,
        label: categoryData.category_name
      });
    }
    setproductCategoryListForQuickOption(innerUser);
  }, [postCategoryList]);
  const updateText = useCallback((value: string) => {
    setUserQuickSearchText(value);
    if (value === '') {
      setproductCategoryListForQuickOption(productCategoryListForQuickOption);
      return;
    }
    const filterRegex = new RegExp(value, 'i');
    const resultOptions = productCategoryListForQuickOption.filter(option => option.label.match(filterRegex));
    setproductCategoryListForQuickOption(resultOptions);
  }, [productCategoryListForQuickOption]);
  const updateSelection = useCallback((selected: string[]) => {
    const selectedValue = selected.map(selectedItem => {
      const matchedOption = productCategoryListForQuickOption.find(option => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });
    setCategorySelected(selected);
    //   setUserQuickSearchText(selectedValue[0] || '');
    callmebackCallback(selected);
  }, [productCategoryListForQuickOption]);

  /** ABC DEF GHI */

  const removeTag = useCallback((tag: string) => () => {
    const options = [...categorySelected];
    options.splice(options.indexOf(tag), 1);
    setCategorySelected(options);
    callmebackCallback(options);
  }, [categorySelected]);
  const [showQuickCreate, setShowQuickCreate] = useState(false);
  const verticalContentMarkup = categorySelected.length > 0 ? <InlineStack align="start" blockAlign="center" gap="200">
        {categorySelected.map(option => {
      if (!option) return;
      let l = productCategoryListForQuickOption.find(el => el.value === String(option))?.label;
      if (!l) return;
      return <Tag key={`option${option}`} onRemove={removeTag(option)}>
              {l}
            </Tag>;
    })}
      </InlineStack> : <Text as="p" tone="subdued" variant="bodySm">
        Chưa có một chuyên mục nào ...
      </Text>;
  const textFieldSelectUser = <>
      <Autocomplete.TextField error={errorMessage ? true : false} onChange={updateText} label="Tìm một chuyên mục" value={userQuickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} placeholder="Tìm kiếm" autoComplete="off" id="TextFieldSelectCategory" />
      <div style={{
      marginTop: '4px'
    }}>
        <InlineError message={errorMessage} fieldID="TextFieldSelectCategory" />
      </div>
    </>;
  return <>
      <ProductCategoryModalAddAndEdit show={showQuickCreate} onClose={(category_id: bigint) => {
      setShowQuickCreate(false);
      if (category_id) setCategorySelected(old => [...old, ...[String(category_id)]]);
    }} />

      {verticalContentMarkup}

      <br />
      <br />
      <Divider />
      <br />

      <Autocomplete actionBefore={{
      accessibilityLabel: 'Tạo chuyên mục mới',
      content: 'Tạo một chuyên mục mới',
      ellipsis: true,
      helpText: 'Tạo chuyên mục mới và chỉnh sửa sau',
      icon: PlusCircleIcon,
      onAction: () => {
        setShowQuickCreate(!showQuickCreate);
      }
    }} allowMultiple options={productCategoryListForQuickOption} selected={categorySelected} onSelect={updateSelection} textField={textFieldSelectUser} loading={searching} />
    </>;
}