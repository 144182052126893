import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import StaffReport from './staffReport';
import CustomerReport from './customerReport';
import others from './others';
/**
 *   Create index file for Comment
 */

export default function ListAnalytics() {
  let {
    feature = 'dashboard'
  } = useParams();
  let ActualPage: any;
  switch (feature) {
    case 'dashboard':
      ActualPage = others;
      break;
    case 'staff':
      ActualPage = StaffReport;
      break;
    case 'customer':
      ActualPage = CustomerReport;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>{<ActualPage />}</>;
}