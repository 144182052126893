import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedProductBrand {
    "brand_id"?: string,
    "brand_description"?: string,
    "brand_excerpt"?: string,
    "brand_name"?: string,
    "brand_thumbnail"?: string,
    "createdAt"?: string,
    "product_brand_to_media"?: {
        "media_id"?: string,
        "media_url"?: string,
        "media_thumbnail"?: string,
        "media_filetype"?: string
    },
    "_count"?: {
        "product_to_brand": number
    }
}


type IQuery = TypedProductBrand & IQueryParams;


/*
* List of all product_brand
*/
export function useGetProductBrands(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["product_brand/fetch_entity_list"],
        queryFn: () => axios.get<TypedProductBrand[]>(`/product_brand/admin${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}
export function useMutateGetProductBrands() {
    return useMutation({
        mutationKey: ["product_brand/fetch_entity_list"],
        mutationFn: (object: IQuery) => axios.get<TypedProductBrand[]>(`/product_brand/admin${helpers.buildEndUrl(object)}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
    });
}



export function useGetProductBrand(product_brand_id: string) {
    return useQuery({
        queryKey: ['product_brand/fetch_entity'],
        queryFn: () => axios.get<TypedProductBrand>(`/product_brand/${product_brand_id}`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useUpdateProductBrand() {
    return useMutation({
        mutationKey: ['product_brand/update_entity'],
        mutationFn: ({ brand_id, ...rest }: TypedProductBrand) => axios.patch<any>(`/product_brand/${brand_id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_brand/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['product_brand/fetch_entity'] });
        }
    });
}


export function useCreateProductBrand() {
    return useMutation({
        mutationKey: ['product_brand/create_entity'],
        mutationFn: (entity: TypedProductBrand) => axios.post<any>(`/product_brand`, helpers.cleanEntity(entity)).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_brand/fetch_entity_list'] });
        }
    });
}


export function useDeleteProductBrand() {
    return useMutation({
        mutationKey: ['product_brand/delete_entity'],
        mutationFn: (product_brand_id: string) => axios.delete<any>(`/product_brand/${product_brand_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['product_brand/fetch_entity_list'] });
        }

    });
}

export function useBatchReorder() {
    return useMutation({
        mutationKey: ['product_brand/batch_reorder'],
        mutationFn: (reorderData: any) => axios.patch<any>(`/product_brand/batch_reorder`, helpers.cleanEntity(reorderData))
    });
}