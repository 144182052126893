import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import helpers from "helpers/index";
import { TypedUserMeta } from "interface/user.meta.model";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/

export function useGetUserMeta() {

    return useMutation({
        mutationKey: ['user_meta/fetch_entity'],
        mutationFn: (meta_key: string) => axios.get<any>(`/user_meta/${meta_key}`).then((response) => response.data),
    });
}


export function useUpdateUserMeta() {
    return useMutation({
        mutationKey: ['user_meta/update_entity'],
        mutationFn: ({ meta_key, ...rest }: TypedUserMeta) => axios.patch<TypedUserMeta>(`/user_meta/${meta_key}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user_meta/fetch_entity'] });
        }
    });
}


export function useDeleteUserMeta() {
    return useMutation({
        mutationKey: ['user_meta/delete_entity'],
        mutationFn: (meta_key: string) => axios.delete<any>(`/user_meta/${meta_key}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['user_meta/fetch_entity_list'] });
        }

    });
}

