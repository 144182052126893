import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import order_list from './order.list';
import order_view from './order.view';

/**
 *   Create index file for Comment
 */

export default function List_comment() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = order_list;
      break;
    case 'view':
      ActualPage = order_view;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>{<ActualPage />}</>;
}