import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Badge, DataTable, Link, Page, Text, InlineStack, Card } from '@shopify/polaris';
import { useLocation, useNavigate } from 'react-router-dom';
import dateandtime from 'date-and-time';
import { PlusIcon } from '@shopify/polaris-icons';
import LogisticModal from './logistic.modal';
import { TypedLogistic_service, useGetLogistics, useUpdateLogistic } from 'queries/logistic_service.query';
import helpers from 'helpers/index';
import Pagination from 'components/pagination';
import { Helmet } from 'react-helmet-async';
export default function LogisticsManager() {
  const history = useNavigate();
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery: any = helpers.ExtractUrl(search) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 20,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data,
    isLoading: loading
  } = useGetLogistics(initialQuery);
  const {
    isPending: updating,
    isSuccess: updateSuccess
  } = useUpdateLogistic();
  const [entities, setEntities] = useState<TypedLogistic_service[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (!data) return;
    let {
      body,
      totalItems
    } = data;
    setEntities(body);
    setTotalItems(totalItems);
  }, [data]);
  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: limit
    });
  }, [mainQuery]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (search !== buildURLSearch) history('/logistic' + buildURLSearch);
    getEntities();
  }, [mainQuery]);
  const current_logistic_id = useRef<string>(null);
  const [showModalCreateNew, setShowModalCreateNew] = useState(false);
  const closeModalCallback = useCallback(() => {
    setShowModalCreateNew(false);
    current_logistic_id.current = null;
  }, []);
  const element = (logisticData: TypedLogistic_service) => {
    const {
      logistic_id,
      logistic_count,
      logistic_fee,
      module,
      logistic_name,
      logistic_status,
      logistic_delivery_method,
      createdAt
    } = logisticData;
    return [<Link onClick={() => {
      current_logistic_id.current = logistic_id;
      setShowModalCreateNew(true);
    }}>
        {logistic_name}
      </Link>, <InlineStack gap={'200'} blockAlign="center" align="start">
        {logistic_delivery_method?.split(',').map((e, i) => e && <Text as="span" key={i}>
                #{e}
              </Text>)}
      </InlineStack>, logistic_fee > 0 ? <Badge>{helpers.formatNumber(logistic_fee)}</Badge> : logistic_fee === -1 ? <Badge tone="info">Auto</Badge> : <Badge tone="success">Free</Badge>, logistic_status === 1 ? <Badge tone="success">Bật</Badge> : <Badge>Tắt</Badge>, module, logistic_count, dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY hh:mm')];
  };
  return <>
      <Helmet>
        <title>Quản lý đơn vị vận chuyển</title>
      </Helmet>
      <Page title="Dịch vụ vận chuyển" primaryAction={{
      content: 'Thêm mới',
      icon: PlusIcon,
      onAction: () => {
        current_logistic_id.current = null;
        setShowModalCreateNew(true);
      },
      loading: updating
    }}>
        <Card padding={'0'}>
          {entities.length > 0 ? <DataTable columnContentTypes={['text', 'text', 'text', 'numeric', 'text', 'numeric', 'numeric']} headings={['Tên đơn vị', 'Phương thức', 'Cách tính Phí', 'Tình trạng', 'Module', 'Đã giao', 'Ngày tạo']} hideScrollIndicator rows={entities?.map(element)} /> : <div style={{
          padding: '15px',
          textAlign: 'center'
        }}>
              <Text as="h1" variant="headingMd">
                Chưa có một nhà vận chuyển nào!
              </Text>
              <Text as="p" tone="caution">
                Bấm THÊM MỚI để cài đặt một nhà vận chuyển mặc định cho từng phương thức vận chuyển. Ví dụ như Grab cho vận chuyển nhanh!
              </Text>
            </div>}
        </Card>
      </Page>
      <br />
      {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}

      <LogisticModal logistic_id={current_logistic_id.current} show={showModalCreateNew} onClose={closeModalCallback} />
    </>;
}