import { Autocomplete, Avatar, Icon, Thumbnail } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import __ from 'languages/index';
import { SearchIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import { TypedVoucher, useGetVouchers } from 'queries/voucher.query';
import __helpers from 'helpers/index';
type TypedSearchVoucher = {
  current_voucher_id?: bigint | string /** vô dụng, không hoạt động ... */;
  onClose?: (val: TypedVoucher) => void;
  title: string;
  placeholder?: string;
  disabled?: boolean;
};

/**
 *
 * @param current_voucher_id bigint
 * @param  onClose callback with bigint userid
 * @returns
 */
export default function QuickSearchVoucher({
  disabled,
  current_voucher_id = null,
  onClose,
  title,
  placeholder = 'Tìm một voucher'
}: TypedSearchVoucher) {
  /**!SECTION
   * Select người handler
   */

  const [mainQuery, setMainQuery] = useState({
    query: '',
    sort: 'createdAt:desc',
    is_cloned: 0,
    limit: 5
  });
  const {
    refetch: searchVoucher,
    isPending: userLoading,
    data
  } = useGetVouchers(mainQuery);

  /**
   * User list
   */
  const [entities, setEntities] = useState<TypedVoucher[]>([]);
  /**
   */
  const [userSelected, setUserSelected] = useState<string[]>([]);
  const [userQuickSearchText, setUserQuickSearchText] = useState<string>('');
  const [userListForQuickOption, setUserListForQuickOption] = useState([]);
  useEffect(() => {
    if (data) {
      const {
        entities
      } = data;
      setEntities(entities); // bồi thêm data vào cái cũ
    }
  }, [data]);
  const onQueryToServer = useCallback(async () => {
    return await searchVoucher();
  }, []);
  useEffect(() => {
    let innerUser = [];
    if (entities) for (let voucher of entities) {
      innerUser.push({
        value: voucher.voucher_id,
        label: voucher.voucher_name,
        media: <Thumbnail source={__helpers.getMediaLink(voucher.voucher_thumbnail_to_media ? voucher.voucher_thumbnail_to_media.media_thumbnail ? voucher.voucher_thumbnail_to_media.media_thumbnail['128x128'] : '' : '')} alt="" />
      });
      if (current_voucher_id && String(voucher.voucher_id) === String(current_voucher_id)) {
        setUserQuickSearchText(voucher.voucher_name);
      }
    }
    setUserListForQuickOption(innerUser);
  }, [entities, current_voucher_id]);
  const onChangeMainQueryCallback = useMemo(() => {
    return helpers.debounce((_value: string) => {
      onQueryToServer?.call(this, _value);
    }, 400);
  }, []);
  const updateUserText = useCallback((value: string) => {
    setMainQuery(prevQuery => ({
      ...prevQuery,
      query: value
    }));
    setUserQuickSearchText(value);
    onChangeMainQueryCallback(value);
  }, []);
  const findUserSelectedInEntities = useCallback(voucher_id => {
    if (Array.isArray(entities)) {
      let a = entities.filter(a => a.voucher_id === voucher_id);
      return a[0] || null;
    }
  }, [entities]);
  const updateSelection = useCallback((selected: string[]) => {
    const selectedValue = selected.map(selectedItem => {
      const matchedOption = userListForQuickOption.find(option => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });
    setUserSelected(selected);
    setUserQuickSearchText(selectedValue[0] || '');
    // @ts-ignore
    onClose?.call(this, findUserSelectedInEntities(selected[0]));
  }, [userListForQuickOption]);
  useEffect(() => {
    onChangeMainQueryCallback('');
  }, []);
  const textFieldSelectUser = <>
      <Autocomplete.TextField align="left" onChange={updateUserText} label={title} disabled={disabled} value={userQuickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} placeholder={placeholder} autoComplete="off" />
    </>;
  return <>
      <Autocomplete options={userListForQuickOption} selected={userSelected} onSelect={updateSelection} textField={textFieldSelectUser} loading={userLoading} id="quickSearchVoucherModalPopover" />
    </>;
}