import { Button, Card, DescriptionList, Layout, Link, Text, Thumbnail } from '@shopify/polaris';
import { useState, useCallback } from 'react';
import dateandtime from 'date-and-time';
import { ResetIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import { useGenerateNewClientPublicKey, useGetApp } from 'queries/app.query';
export default function AppInformation({
  entity
}) {
  /**
   * Để access vào một giá trị trong hàm useMutation, cần phải thế này.
   * Nó trả về một Array với các lần query,
   */
  /*
  const data = useMutationState({
    filters: { mutationKey: ['app/fetch_entity'] },
    select: (mutation) => mutation.state,
  });
  */

  const {
    mutateAsync: generateNewClientPublicKey
  } = useGenerateNewClientPublicKey();
  const [creatingNewKeyForPublicKey, setCreatingNewKeyForPublicKey] = useState(false);
  const createNewPublicKey = useCallback(async () => {
    setCreatingNewKeyForPublicKey(true);
    await generateNewClientPublicKey(entity?.ID);
    await helpers.sleep(1000);
    setCreatingNewKeyForPublicKey(false);
  }, [entity]);
  return <Layout>
      <Layout.Section>
        <Card>
          <DescriptionList items={[{
          term: 'Tên ứng dụng',
          description: <>
                    <Thumbnail size="large" alt={entity?.app_name} source={helpers.getMediaLink(entity?.app_thumbnail ?? 'https://placehold.co/100x100')} />
                    <Text as={'p'}>{entity?.app_name}</Text>
                  </>
        }, {
          term: 'Trang chủ',
          description: entity?.app_homepage ? <Link url={entity?.app_homepage} target="_blank">
                    {entity?.app_homepage}
                  </Link> : '-'
        }, {
          term: 'Mô tả',
          description: entity?.app_description || '-'
        }, {
          term: 'Loại ứng dụng',
          description: entity?.app_type
        }, {
          term: 'Slug',
          description: entity?.app_slug
        }, {
          term: 'Khóa công khai',
          description: <>
                    <Text as="span">{entity?.client_public_key}</Text>
                    <br />
                    <br />
                    <Button variant="plain" size="micro" loading={creatingNewKeyForPublicKey} onClick={createNewPublicKey} tone="critical" icon={ResetIcon}>
                      Tạo mã mới
                    </Button>
                  </>
        }, {
          term: 'Khóa bí mật',
          description: <>
                    <Text as="span">{entity?.client_private_key}</Text>
                  </>
        }, {
          term: 'Ngày tạo',
          description: dateandtime.format(new Date(Number(entity?.createdAt)), 'DD-MM-YYYY HH:mm:ss')
        }]} />
        </Card>
      </Layout.Section>
      <Layout.Section variant="oneThird">
        <Card padding="400">
          <Text as="h2" fontWeight="bold" variant="bodyMd">
            Khóa công khai để làm gì?
          </Text>
          <br />
          <p>Khóa công khai để lấy thông tin dạng basic, hoặc dùng làm SSO đăng nhập.</p>
        </Card>
      </Layout.Section>
    </Layout>;
}