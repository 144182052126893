import { BlockStack, FooterHelp, Form, FormLayout, Link, Modal, Text, TextField } from '@shopify/polaris';
import helpers from 'helpers/index';
import { useCreateBlacklistIP } from 'queries/blacklist_ip.query';
import { useCallback, useEffect, useState } from 'react';
export default function AddIPModal({
  show = false,
  onClose
}: {
  show: boolean;
  onClose: () => void;
}) {
  const {
    mutateAsync: createEntity
  } = useCreateBlacklistIP();
  const modalClose = () => {
    onClose.apply(this, []);
  };
  const [dirty, setDirty] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentIP, setCurrentIP] = useState('');
  const [helpText, setHelpText] = useState('');
  const submit = useCallback(async () => {
    setHelpText('Đang lưu lại ...');
    setSaving(true);
    try {
      await createEntity({
        ip: currentIP
      });
      await helpers.sleep(1000);
      setHelpText('Đã lưu thành công ...');
      setCurrentIP('');
    } catch (e) {
      setHelpText('Chưa lưu được ...');
    }
    setDirty(false);
    setSaving(false);
  }, [currentIP]);
  useEffect(() => {
    if (currentIP && currentIP.length > 0) setDirty(true);else setDirty(false);
  }, [currentIP]);
  return <>
      <Modal open={show} onClose={() => modalClose()} title={'Thêm một IP vào danh sách cấm'} secondaryActions={[{
      content: 'Đóng',
      onAction: () => modalClose()
    }, {
      content: 'Lưu & Thêm nữa',
      disabled: !dirty,
      loading: saving,
      onAction: submit
    }]}>
        <Modal.Section>
          <Form onSubmit={submit}>
            <FormLayout>
              <TextField label="IP bị cấm" requiredIndicator autoComplete="off" value={currentIP} onChange={(val: string) => setCurrentIP(val)} helpText={helpText} />
            </FormLayout>
          </Form>
        </Modal.Section>
        <FooterHelp align="start">
          Các IP này sẽ không thể đăng nhập, đăng ký hoặc gửi yêu cầu liên hệ. Vui lòng xem thêm tại{' '}
          <Link url="https://help.shopify.com/manual/orders/fulfill-orders">vì sao IP bị cấm</Link>.
        </FooterHelp>
      </Modal>
    </>;
}