import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedMedia } from "./media.query";

/**
*   Interface/model file auto generate for Contactform
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedContactform {
    contactform_id?: string;
    user_id?: string;
    contactform_category?: string;
    contactform_title?: string;
    contactform_name?: string;
    contactform_email?: string;
    contactform_numberphone?: string;
    contactform_content?: string;
    contactform_ip?: string;
    contactform_status?: number;
    createdAt?: any;
    updatedAt?: any;

    contactform_media?: {
        media?: TypedMedia
    }[]

    // test
    updater?: any;
    user?: any;
    device_uuid?: string;
}

type IQuery = TypedContactform & IQueryParams;



/*
* List of all app
*/
export function useGetContactforms(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["contactform/fetch_entities_list"],
        queryFn: () => axios.get<TypedContactform[]>(`/contactform${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: true,
        enabled: true,
    });
}



export function useGetContactform() {
    return useMutation({
        mutationKey: ['contactform/fetch_entity'],
        mutationFn: (contactform_id: string) => axios.get<TypedContactform>(`contactform/${contactform_id}`).then((res) => res.data),
    });
}



export function useUpdateContactform() {
    return useMutation({
        mutationKey: ['contactform/update_entity'],
        mutationFn: ({ contactform_id, ...rest }: TypedContactform) => axios.patch<TypedContactform>(`contactform/${contactform_id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['contactform/fetch_entities_list'] });
        }
    });
}



/**
 * Assign
 */

export function useGetAssignList() {
    return useMutation({
        mutationKey: ['contactform/assign/fetch_entity_list'],
        mutationFn: (contactform_id: string | bigint) => axios.get<any>(`contactform/assign/${contactform_id}`).then((res) => res.data),
    });
}

// Add 
export function useAddAssignee() {
    return useMutation({
        mutationKey: ['contactform/assign/delete_entity'],
        mutationFn: (entity: { user_id: any, contactform_id: any }) => axios.post<any>(`contactform/assign/`, helpers.cleanEntity(entity)).then((res) => res.data),
    });
}

// Delete
export function useDeleteAssignee() {
    return useMutation({
        mutationKey: ['contactform/assign/create_entity'],
        mutationFn: (assign_id: string) => axios.delete<any>(`contactform/assign/${assign_id}`).then((res) => res.data),
    });
}