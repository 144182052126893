import { Avatar, Button, Card, Form, InlineGrid, InlineStack, Icon, Link, Popover, Select, SkeletonThumbnail, Tag, Text, TextField, Toast, BlockStack, ExceptionList } from '@shopify/polaris';
import StarRating from 'components/starRating';
import QuickUploadImage from 'components/oneclick-upload-image';
import { useCallback, useEffect, useState } from 'react';
import helpers from 'helpers';
import dateandtime from 'date-and-time';
import { StatusActiveIcon, AlertCircleIcon, PlusCircleIcon } from '@shopify/polaris-icons';
import CustomerAssign from './customer.assign';
import QuickSearchUser from 'components/quickSearchUser';
import __ from 'languages/index';
import { TypedCustomer } from 'interface/customer.model';
import { useUpdateReferrer } from 'queries/user_referrer.query';
import __helpers from 'helpers';
import { useUpdateCustomer } from 'queries/customer.query';
import { useAddUserSocialProfile, useDeleteUserSocialProfile } from 'queries/user_social_profile.query';
export default function CustomerGeneralDetail({
  entity
}: {
  entity: TypedCustomer;
}) {
  // const loading_user_referrer = useAppSelector(state => state.user_referrer.loading);

  const {
    mutateAsync: updateReferrer
  } = useUpdateReferrer();
  const {
    mutateAsync: updateEntity,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateCustomer();

  // user social profile
  const {
    mutateAsync: addUserSocialProfile,
    isPending: updating_user_social_profile
  } = useAddUserSocialProfile();
  const {
    mutateAsync: deleteUserSocialProfile
  } = useDeleteUserSocialProfile();
  const [internalErrorNotice, setInternalErrorNotice] = useState('');
  const [srcImageAvatar, setSrcImageAvatar] = useState('');
  useEffect(() => {
    setSrcImageAvatar(entity?.user_avatar);
  }, [entity]);
  const handQuickUpdateSuccess = useCallback(async (res: any) => {
    setSrcImageAvatar(res.media_url);
    try {
      await updateEntity({
        user_id: entity?.user_id,
        user_avatar: res.media_url
      });
    } catch (e) {}
  }, [entity]);
  const handUploadError = useCallback((err: any) => {
    setInternalErrorNotice(err);
  }, []);

  /**
   * Quick note
   */
  const [showQuickNote, setShowQuickNote] = useState(false);
  const [biotext, setBiotext] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const QueryCustomerAddr = useCallback(async () => {
    let dataAddressClone = [];
    let diachiXaData = await helpers.getDiaChinh(entity?.user_address_ward);
    dataAddressClone.push(diachiXaData?.name_with_type);
    let diachiHuyenData = await helpers.getDiaChinh(entity?.user_address_district);
    dataAddressClone.push(diachiHuyenData?.name_with_type);
    let diachiTinhData = await helpers.getDiaChinh(entity?.user_address_city);
    dataAddressClone.push(diachiTinhData?.name_with_type);
    let afterFilter = dataAddressClone.filter(el => {
      return el !== undefined;
    });
    setCustomerAddress(afterFilter.join(', '));
  }, [entity]);
  useEffect(() => {
    if (entity) {
      QueryCustomerAddr();
      setBiotext(entity.bio || '');
    }
  }, [entity]);
  useEffect(() => {
    if (updateSuccess === true) {
      setShowQuickNote(false);
    }
  }, [updateSuccess]);
  const saveQuickNoteButtonCallback = useCallback(async () => {
    try {
      await updateEntity({
        user_id: entity?.user_id,
        bio: biotext
      });
    } catch (e) {}
  }, [entity, biotext]);
  const handleQuickNoteChange = useCallback((newValue: string) => setBiotext(newValue), []);

  /**
   * ENd save quick note
   */

  const [showAddReferrer, setShowAddReferrer] = useState(false);
  const addNewReferrer = useCallback(async (referrer_id: bigint | string) => {
    await updateReferrer({
      customer_id: entity?.user_id,
      referrer_id: referrer_id
    });
  }, []);

  /**
   * Profile mạng xã hội
   */

  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(() => setPopoverActive(popoverActive => !popoverActive), []);
  const [socialName, setSocialName] = useState('facebook');
  const handleSocialNameChange = useCallback((value: string) => setSocialName(value), []);
  const [socialProfileID, setSocialProfileID] = useState('');
  const handleSocialProfileID = useCallback((value: string) => setSocialProfileID(value), []);
  const userSocialProfileRemove = useCallback(async (social_id: string) => {
    await deleteUserSocialProfile(social_id);
  }, []);
  const submitSocialProfile = useCallback(async () => {
    await addUserSocialProfile({
      user_id: String(entity?.user_id),
      social_name: socialName,
      social_profile_id: socialProfileID
    });
    setPopoverActive(false);
  }, [entity, socialName, socialProfileID]);

  /**
   * End profile mạng xã hội
   */

  return <>
      {internalErrorNotice && <Toast error content={internalErrorNotice} onDismiss={() => setInternalErrorNotice(null)} />}

      <InlineGrid columns={{
      xs: '1',
      sm: '1',
      md: '1',
      lg: ['twoThirds', 'oneThird']
    }} gap={'400'}>
        <Card>
          <InlineStack align="start" gap="400" blockAlign="center">
            <div>
              <QuickUploadImage onSuccess={handQuickUpdateSuccess} onError={handUploadError} title="">
                <Avatar name={entity?.display_name} customer source={srcImageAvatar ? __helpers.getMediaLink(srcImageAvatar) : null} size="lg" />
              </QuickUploadImage>
            </div>

            <BlockStack gap={'100'}>
              <Text as="h2" variant="headingLg" fontWeight="bold">
                {entity?.user_title && `(${__(entity?.user_title)})`} {entity?.display_name || entity?.user_login}{' '}
                {`(${__(`user_role_` + entity?.user_role || 'visitor')})`}
              </Text>
              <InlineStack gap={'200'}>
                <Text as="p" variant="bodySm" tone="subdued">
                  {`Tham gia ` + String(entity ? helpers.subtractDate(entity?.createdAt) : '1') + ' ngày trước'}
                </Text>
                {entity?.referrer?.referrer_data ? <Text as="p" variant="bodySm" tone="subdued">
                    {' - '} Giới thiệu bởi {entity?.referrer?.referrer_data?.display_name || entity?.referrer?.referrer_data?.user_email}
                  </Text> : <Popover active={showAddReferrer} activator={<Button variant="plain" disclosure onClick={() => setShowAddReferrer(true)}>
                        Thêm người giới thiệu
                      </Button>} autofocusTarget="first-node" onClose={() => setShowAddReferrer(false)}>
                    <Card>
                      <QuickSearchUser title="" user_role="" onClose={(userData: any) => {
                    addNewReferrer(userData.user_id);
                    setShowAddReferrer(false);
                  }} />
                    </Card>
                  </Popover>}
              </InlineStack>

              {entity?.user_to_rank && <Text as="p" tone="subdued">
                  Rank: <strong>{entity?.user_to_rank?.rank?.rank_name}</strong> - Cập nhật{' '}
                  {helpers.subtractTimeHistory(entity?.user_to_rank?.lastUpdated)}
                </Text>}

              {entity?.user_rate > 0 ? <div>
                  <StarRating num={entity?.user_rate} />
                  <Text as="span" tone="subdued">{`${entity?.user_rate_count} đánh giá`}</Text>
                </div> : null}
            </BlockStack>
          </InlineStack>

          <br />
          <InlineGrid gap="400" columns={{
          xs: 1,
          sm: 1,
          md: 1,
          lg: 4,
          xl: 4
        }} alignItems="center">
            <BlockStack>
              <Text as="p" tone="subdued">
                Chi tiêu
              </Text>
              <Text as="p" variant="headingLg">
                {helpers.formatNumber(entity?.customer_to_user?.customer_moneyspent_count, ',')} vnđ
              </Text>
            </BlockStack>

            <BlockStack>
              <Text as="p" tone="subdued">
                Đơn hàng
              </Text>
              <Text as="p" variant="headingLg">
                {entity?.customer_to_user?.customer_order_count || 0} đơn
              </Text>
            </BlockStack>

            <BlockStack>
              <Text as="p" tone="subdued">
                Tương tác
              </Text>
              <Text as="p" variant="headingLg">
                {entity?._count?.user_activity || 0} lần
              </Text>
            </BlockStack>

            <BlockStack>
              <Text as="p" tone="subdued">
                Tương tác gần nhất
              </Text>
              <Text as="p" variant="headingLg">
                {entity?._count?.user_activity > 0 ? helpers.subtractDate(entity?.user_activity[0]?.createdAt, 'ngày trước') : '-'}
              </Text>
            </BlockStack>
          </InlineGrid>
          <br />

          <BlockStack gap={'400'}>
            <div>
              {entity?.user_birthday && <>
                  <Text variant="bodyMd" as="p" fontWeight="bold">
                    Sinh nhật
                  </Text>
                  <Text as="p">
                    {dateandtime.format(new Date(entity?.user_birthday), 'DD/MM/YYYY')} {'  -  '}{' '}
                    {helpers.getAge(new Date(entity?.user_birthday), ' tuổi')}
                  </Text>
                </>}
            </div>

            <div>
              <Text variant="bodyMd" as="p" fontWeight="bold">
                Bio
              </Text>
              {showQuickNote ? <>
                  <TextField label="" multiline={2} value={biotext} onChange={handleQuickNoteChange} autoComplete="off" maxLength={250} showCharacterCount />
                  <Button variant="plain" onClick={saveQuickNoteButtonCallback} loading={updating}>
                    Save
                  </Button>
                  &nbsp; or &nbsp;
                  <Button variant="plain" onClick={() => {
                setShowQuickNote(false);
              }}>
                    Cancel
                  </Button>
                </> : <Link removeUnderline monochrome onClick={() => setShowQuickNote(true)}>
                  {entity?.bio ?? 'Chưa có giới thiệu'}
                </Link>}
            </div>

            <div>
              <CustomerAssign customerData={entity} />
            </div>
          </BlockStack>
        </Card>
        <Card>
          <Text as="h3" variant="headingMd">
            Thông tin liên hệ
          </Text>
          <BlockStack gap={'200'}>
            <Text variant="bodyMd" as="p" fontWeight="bold">
              Địa chỉ
            </Text>
            <Text as="p" tone="critical">
              {entity?.user_address ?? 'Chưa có thông tin'}
            </Text>
            <Text as="p" tone="critical">
              {customerAddress}
            </Text>
          </BlockStack>
          <br />
          <BlockStack gap={'200'}>
            <ExceptionList items={[{
            title: 'Email',
            status: 'warning',
            icon: entity?.user_verified_email === 1 ? StatusActiveIcon : AlertCircleIcon,
            description: entity.user_email ? <Link url="#!">{entity.user_email}</Link> : 'Chưa có'
          }, {
            title: 'Phone number',
            status: 'warning',
            icon: entity?.user_verified_email === 1 ? StatusActiveIcon : AlertCircleIcon,
            description: entity.user_phonenumber ? <Link url={`tel:` + entity.user_phonenumber}>{entity.user_phonenumber}</Link> : 'Chưa có'
          }, {
            title: 'Profile verified',
            status: entity?.user_verified_profile === 1 ? 'warning' : 'critical',
            icon: entity?.user_verified_profile === 1 ? StatusActiveIcon : AlertCircleIcon,
            description: <span>{entity?.user_verified_profile === 1 ? 'Đã xác minh' : 'Chưa xác minh'}</span>
          }]} />
          </BlockStack>

          <br />

          <BlockStack gap={'200'}>
            <Text variant="bodyMd" as="p" fontWeight="bold">
              Mạng xã hội
            </Text>
            <InlineStack gap={'100'} align="start">
              {entity?.user_social_profile && entity?.user_social_profile?.map((el, index) => {
              let {
                social_profile_id,
                social_name,
                social_id
              } = el;
              return <>
                      <Tag key={index + '_socialTag'} url={social_profile_id} onRemove={() => userSocialProfileRemove(social_id)}>
                        {social_name}
                      </Tag>
                    </>;
            })}
              {updating_user_social_profile && <SkeletonThumbnail size="extraSmall" />}

              <Popover active={popoverActive} activator={<Tag key={'dfhhte'} onClick={() => setPopoverActive(true)}>
                    <InlineStack gap={'100'} align="start">
                      <span>Thêm profile</span> <Icon source={PlusCircleIcon} />
                    </InlineStack>
                  </Tag>} onClose={togglePopoverActive} ariaHaspopup={false} sectioned>
                <Form onSubmit={submitSocialProfile}>
                  <Select label="Chọn mạng xã hội" options={[{
                  label: 'Facebook',
                  value: 'facebook'
                }, {
                  label: 'Twitter',
                  value: 'twitter'
                }, {
                  label: 'Tiktok',
                  value: 'tiktok'
                }, {
                  label: 'Zalo',
                  value: 'zalo'
                }, {
                  label: 'Skype',
                  value: 'skype'
                }, {
                  label: 'Instagram',
                  value: 'instagram'
                }, {
                  label: 'Pinterest',
                  value: 'pinterest'
                }, {
                  label: 'Youtube',
                  value: 'youtube'
                }, {
                  label: 'Telegram',
                  value: 'telegram'
                }]} onChange={handleSocialNameChange} value={socialName} />

                  <TextField label="Profile" value={socialProfileID} onChange={handleSocialProfileID} autoComplete="off" />
                </Form>
              </Popover>
            </InlineStack>
          </BlockStack>
        </Card>
      </InlineGrid>
    </>;
}