import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Avatar, InlineStack, ResourceItem, ResourceList, Scrollable, Spinner, Text } from '@shopify/polaris';
import dateandtime from 'date-and-time';
import CustomerNew from 'entities/customer/customer.new';
import helpers from 'helpers/index';
import { TypedPhoneCall, useMutateGetPhonelogs, useUpdatePhonelog } from 'queries/phonelog.query';
export default function PhoneCallLog({
  show
}: {
  show: boolean;
}) {
  const {
    mutateAsync: getEntities,
    data
  } = useMutateGetPhonelogs();
  const {
    mutateAsync: updateEntity
  } = useUpdatePhonelog();
  const [entities, setEntities] = useState<TypedPhoneCall[]>([]);
  const current_page = useRef(1);
  const [loading, setLoading] = useState(false);
  const [reachingEndData, setReachingEndData] = useState(false);

  // useEffect(() => {
  //   if (data) {
  //     setEntities(data.body);
  //     setTotalItems(data.totalItems);
  //   }
  // }, [data]);

  /**
   * Ngưỡng hiển thị ...
   */
  // const threshold_display_for_infinite_loading = useRef(20);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      let {
        body
      } = await getEntities({
        sort: 'createdAt:desc',
        limit: 20,
        page: current_page.current || 1
      });
      if (Array.isArray(body) && body.length < 1) {
        setReachingEndData(true);
      }
      let newData = [...body, ...entities];
      setEntities(newData);
    } catch (e) {}
    setLoading(false);
  }, []);
  useEffect(() => {
    if (!show) return;
    loadData();
  }, [show]);
  const [showNewCustomer, setShowNewCustomer] = useState(null);
  const currentNumber = useRef(null);
  const [currentPhonelogID, setCurrentPhonelogID] = useState(null);
  const doClick = useCallback((phonglog_id: string, phonelog_number: any, user?: any) => {
    if (user) return;
    setCurrentPhonelogID(phonglog_id);
    setShowNewCustomer(true);
    currentNumber.current = `0` + phonelog_number;
  }, [setCurrentPhonelogID]);
  const closeNewCustomerModal = useCallback(async (user_id: any) => {
    setShowNewCustomer(false);
    current_page.current = 1;
    if (currentPhonelogID) await updateEntity({
      phonelog_id: currentPhonelogID,
      user_id: user_id
    });
    await helpers.sleep(1000);
    await loadData();
  }, [currentPhonelogID]);
  const loadMoreAfterReachEnd = useCallback(() => {
    current_page.current = current_page.current + 1;
    loadData();
  }, [current_page]);
  const fetchDataMemo = useMemo(() => {
    if (reachingEndData) return;
    return helpers.debounce(() => {
      loadMoreAfterReachEnd?.call(this);
    }, 500);
  }, [loadMoreAfterReachEnd, reachingEndData]);
  const loadingMarkup = loading ? <InlineStack align="center" blockAlign="center" gap="200">
      <br />
      <Spinner accessibilityLabel="Loading more content" size="small" />
      <br />
    </InlineStack> : null;
  return <div id="phone_call_log">
      {<CustomerNew onClose={closeNewCustomerModal} show={!!showNewCustomer} defaultNumber={currentNumber.current} />}

      <Scrollable onScrolledToBottom={fetchDataMemo} style={{
      height: 'calc(100vh - 35px)'
    }} shadow focusable>
        <ResourceList resourceName={{
        singular: 'customer',
        plural: 'customers'
      }} items={data?.body || []} renderItem={(item, index) => {
        const {
          phonelog_id,
          phonelog_number,
          phonelog_source,
          createdAt,
          user_id,
          user
        } = item;
        const media = <Avatar customer size="md" name={user?.display_name} source={user?.user_avatar} />;
        return <ResourceItem key={index + '_uiuiu'} id={phonelog_id} url={user ? '/users/view/' + user.user_id : '#'} media={media} onClick={() => {
          doClick(phonelog_id, phonelog_number, user);
        }}>
                <Text variant="bodyMd" fontWeight="bold" as="h3">
                  {user_id && user?.display_name || `0` + phonelog_number}
                </Text>
                <Text tone="subdued" variant="bodySm" as="span">
                  {dateandtime.format(new Date(Number(createdAt)), 'hh:mm DD/MM')} - source {phonelog_source}
                </Text>
              </ResourceItem>;
      }} />
        {reachingEndData && <Text as="p" alignment="center" tone="critical" variant="bodySm" fontWeight="bold">
            Không còn dữ liệu
          </Text>}
        {loadingMarkup}
      </Scrollable>
    </div>;
}