import { DataTable, EmptyState, Layout, Page, Text, Link, Badge, Card } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import emptyIMG from '../../media/images/empty.png';
import Pagination from '../../components/pagination';
import helpers from '../../helpers';
import dateandtime from 'date-and-time';
import GeneralIncomingWebhookCreate from './incoming_webhook.create';
import SkeletonLoading from 'components/skeletonPageLoading';
import SimpleFilter from 'components/simpleFilter';
import { ___ } from 'languages/index';
import { useGetWebhookIncomings } from 'queries/webhook_incoming.query';
export default function General_incoming_webhook() {
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const history = useNavigate();

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery: any = helpers.ExtractUrl(search);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 25,
    api_access_method: '',
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const {
    data,
    refetch: getEntities,
    isPending: loading
  } = useGetWebhookIncomings(mainQuery);
  useEffect(() => {
    if (data) {
      setEntities(data.entities);
      setTotalItems(data.totalItems);
    }
  }, [data]);
  const [newModelactive, setNewModelactive] = useState(false);
  const toggleNewActive = useCallback(() => setNewModelactive(active => !active), []);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: limit
    });
  }, [mainQuery]);

  /**
   * Double request!
   */
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (search !== buildURLSearch) history('/incoming_webhook' + buildURLSearch);
    getEntities();
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);

  /**
   *
   * @param api_id
   */
  const shortcutActions = (api_id: number) => {
    history('view/' + api_id);
  };
  const emptyData = <EmptyState heading="No record here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>;
  const renderItem = (incoming_webhook: any) => {
    const {
      api_id,
      api_enable,
      api_count_request,
      api_slug,
      api_description,
      api_name,
      api_access_token,
      api_access_method,
      api_role,
      lastOpen,
      createdAt,
      updatedAt,
      default_webhook
    } = incoming_webhook;
    return [api_id, <Link key={api_id} onClick={() => shortcutActions(api_id)}>
        <div style={{
        minWidth: '150px'
      }}>{helpers.trimContentString(api_name)}</div>
      </Link>, api_enable === 1 ? <Badge tone="success">Active</Badge> : <Badge tone="warning">Inactive</Badge>, api_slug, api_count_request, helpers.trimContentString(api_description), api_access_token, api_access_method, api_role, createdAt ? dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm:ss') : '-', updatedAt ? dateandtime.format(new Date(Number(updatedAt)), 'DD-MM-YYYY HH:mm:ss') : '-', lastOpen ? dateandtime.format(new Date(Number(lastOpen)), 'DD-MM-YYYY HH:mm:ss') : '-'];
  };
  const PagesList = totalItems > 0 ? <>
        <DataTable hoverable fixedFirstColumns={2} columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']} headings={['ID', 'Name', '', 'Slug', 'Request Count', 'Description', 'Token', 'Method', 'Role', 'Created At', 'Updated At', 'Last Open']} rows={entities?.map(renderItem)} hideScrollIndicator footerContent={___('Show page {display_page_number} of {total_record_number} results', {
      display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
      total_record_number: <Text as="strong">{totalItems}</Text>
    })} />
      </> : emptyData;
  const Actual_page = <>
      <Page fullWidth title="Incoming Webhook" subtitle="Allow request from other software" primaryAction={{
      content: 'Create new webhook',
      disabled: false,
      onAction: toggleNewActive
    }}>
        <Layout>
          <Layout.Section>
            <Card padding="0">
              <SimpleFilter loading={loading} onCallback={filterCallback} options={[{
              label: 'get',
              value: 'get',
              field: 'api_access_method'
            }, {
              label: 'post',
              value: 'post',
              field: 'api_access_method'
            }, {
              label: 'patch',
              value: 'patch',
              field: 'api_access_method'
            }, {
              label: 'delete',
              value: 'delete',
              field: 'api_access_method'
            }]} sortField={[]} />
              {PagesList}
            </Card>
            <br />
            {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
          </Layout.Section>
        </Layout>
      </Page>
      <GeneralIncomingWebhookCreate show={newModelactive} onClose={() => setNewModelactive(false)} />
    </>;
  return <>{entities === null ? <SkeletonLoading fullWidth /> : Actual_page}</>;
}