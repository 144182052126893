import { Badge, Button, ButtonGroup, Text, DropZone, FormLayout, Layout, LegacyStack, Modal, Page, ProgressBar, TextField, Thumbnail, Tooltip } from "@shopify/polaris";
import React, { useCallback, useEffect, useState } from "react";
import { XCircleIcon, NoteIcon } from '@shopify/polaris-icons';
import axios from "axios";
import helpers from "helpers";

/**
*   Create upload Modal for Medias
*/
export default function MediaUpload({
  onClose
}) {
  const [updateProgress, setUpdateProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  /**
   * OPEN UPLOAD MODAL
   */
  const [uploadModelactive, setUploadModelactive] = useState(false);
  const toggleUpdateActive = useCallback(() => {
    setUploadModelactive(active => !active);
    onClose();
  }, [uploadModelactive]);
  const [files, setFiles] = useState([]);
  useEffect(() => {
    setUploadModelactive(true);
    showUploadModal();
  }, []);

  /**
   * Xác định xem file nào đang upload
   */
  const [processing_file, setProcessing_file] = useState(-1);
  let processing_file_error = [];
  //uploadProgress
  const handleDropZoneDrop = useCallback((_dropFiles, acceptedFiles, _rejectedFiles) => {
    // console.info(_dropFiles)
    setFiles(files => [...files, ..._dropFiles]);
  }, []);
  function showUploadModal() {
    // setUploadModelactive(true);
    setUpdateProgress(0);
    setFiles([]); // reset form
    setUploading(false);
  }
  const remove_upload_element = (element: number) => {
    if (uploading) return null;
    delete files[element];
    var filtered = files.filter(el => {
      return el != null;
    });
    setFiles(filtered);
  };
  const start_upload = async () => {
    setUpdateProgress(0);
    setUploading(true);
    let index = -1;
    let clone_files = [...[], ...files];
    for (let element of clone_files) {
      index++;
      setProcessing_file(index);
      if (element.size > 50282820) continue;
      var bodyFormData = new FormData();
      bodyFormData.append('files', element);
      try {
        await axios.post('media/upload', bodyFormData, {
          onUploadProgress: (progressEvent: any) => {
            let progress_percent = Math.floor(progressEvent.loaded / progressEvent.total) * 100;
            setUpdateProgress(progress_percent);
          }
        });
        // upload done? remove it
        delete files[index];
        setFiles(files);
        await helpers.sleep(2000);
        setUpdateProgress(0);
      } catch (error) {
        processing_file_error.push(index);
        //console.info('Error', JSON.stringify(error.response.data.message) );
      }
    }
    ;
    if (helpers.isEmpty(processing_file_error)) {
      setUploading(false);
      toggleUpdateActive();
    } else {
      setUploading(false);
      console.log(processing_file_error, 'processing_file_error');
    }
  };
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  const uploadedFiles = files.length > 0 && <LegacyStack vertical>
         {files.map((file, index) => file.size < 50282820 ? <LegacyStack alignment="center" key={index}>
             <div style={{
        width: "10px",
        cursor: "pointer"
      }} onClick={() => remove_upload_element(index)}><XCircleIcon /></div>
             <Thumbnail size="small" alt={file.name} source={validImageTypes.includes(file.type) ? window.URL.createObjectURL(file) : NoteIcon} />
             <div>
               {helpers.trimMiddleString(file.name, 20, 10)} <Text as="p" variant="bodySm" tone="critical">{helpers.bytesToSize(file.size / 1000)}</Text>
               {processing_file === index ? <ProgressBar progress={updateProgress} size="small" tone="success" /> : null}
             </div>
           </LegacyStack> : null)}
       </LegacyStack>;
  return <Modal open={uploadModelactive} onClose={toggleUpdateActive} title={uploading ? "Do NOT close this modal or refresh your browser!" : "Upload files"} primaryAction={{
    content: 'Start upload',
    onAction: start_upload,
    loading: uploading
  }} secondaryActions={[{
    content: 'Close',
    disabled: uploading,
    onAction: toggleUpdateActive
  }]}>
             <Modal.Section>
               <LegacyStack vertical>
                 {!uploading ? <DropZone accept="*" variableHeight errorOverlayText="File type can be image, video, zip, docx, xlsx, ppt" type="file" label="File should be less than 20MB" onDrop={handleDropZoneDrop}>
                   <DropZone.FileUpload />
                 </DropZone> : null}
                 {uploadedFiles}
               </LegacyStack>
             </Modal.Section>
           </Modal>;

  /**
   * END UPLOAD FORM
   * END UPLOAD FORM
   */
}