import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/


export const order_transport_status = ['created', 'notify', 'preparing', 'packing', 'moving', 'flying', 'transporting', 'unpacking', 'landing', 'delivering', 'delivering-retry', 'delivered', 'cancel', 'returning', 'done'];


interface TypedOrder_transport_log {
    transport_id?: string | bigint;
    order_id?: string | bigint;
    transport_title?: string;
    transport_content?: string;
    transport_status?: string;
    createdAt?: string | bigint;
}



type IQuery = TypedOrder_transport_log & IQueryParams;


/*
* List of all order transport log
*/
export function useGetOrderTransportLog(order_id: string) {
    return useQuery({
        queryKey: ["order_transport_log/fetch_entity_list"],
        queryFn: () => axios.get<TypedOrder_transport_log[]>(`/order_transport_log/${order_id}`).then(response => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}




export function useCreateOrderTransportLog() {
    return useMutation({
        mutationKey: ['order_transport_log/create_entity'],
        mutationFn: (entity: TypedOrder_transport_log) => axios.post<TypedOrder_transport_log>(`/order_transport_log`, helpers.cleanEntity(entity)).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['order_transport_log/fetch_entity_list'] });
        }
    });
}


export function useDeleteOrderTransportLog() {
    return useMutation({
        mutationKey: ['order_transport_log/delete_entity'],
        mutationFn: (transport_id: string) => axios.delete<any>(`/order_transport_log/${transport_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['order_transport_log/fetch_entity_list'] });
        }

    });
}