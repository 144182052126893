import React, { useCallback, useEffect, useState } from 'react';
import { BlockStack, SkeletonDisplayText, Tabs, Text } from '@shopify/polaris';
import __helpers from 'helpers/index';
import 'media/css/horizontalScroll.scss';
import { useGetPostCategories } from 'queries/post_category.query';
export default function PostCategoryFilter({
  onCallback
}: {
  onCallback: (selected_category) => void;
}) {
  const [selected, setSelected] = useState(0);

  /**  Lưu cả array lại để tẹo hiển thị  */
  const {
    isLoading: loading,
    data: entities
  } = useGetPostCategories({
    limit: 100,
    category_status: 1,
    sort: 'createdAt:desc'
  });
  const [tabsData, setTabsData] = useState([]);
  useEffect(() => {
    if (entities) {
      let {
        body,
        totalItems
      } = entities;
      if (body) {
        let X = [{
          id: BigInt('0'),
          content: 'Tất cả'
        }];
        for (let ITEM of body) {
          X.push({
            id: ITEM.category_id,
            content: ITEM.category_name
          });
        }
        setTabsData(X);
      }
    }
  }, [entities]);
  const handleTabChange = useCallback((selectedTabIndex: number) => {
    setSelected(selectedTabIndex);
    onCallback(tabsData[selectedTabIndex].id);
  }, [tabsData]);
  return <BlockStack gap="200">
      <Text as="h3">Lọc theo danh mục</Text>
      {loading ? <SkeletonDisplayText /> : __helpers.isEmpty(tabsData) ? <Text as="p" tone="subdued">
          Chưa có một danh mục nào
        </Text> : <div className="horizontal_scrollmenu">
          {tabsData.map((element, index) => {
        return <div className={`element clickable ` + (selected === index ? 'active' : '')} onClick={() => handleTabChange(index)} key={`element_index_` + index}>
                {element.content}
              </div>;
      })}
        </div>}

      <br />
    </BlockStack>;
}