import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedMedia } from "./media.query";
import { TypedProduct } from "interface/product.model";

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedReview {
    "review_id": string,
    "product_id": string,
    "variant_id": string,
    "order_id": string,
    "user_id": string,
    "review_meta_status": number,
    "createdAt": string,
    "review_meta": {
        "review_meta_id": string,
        "review_id": string,
        "review_title": string,
        "review_content": string,
        "review_point": number,
        "review_status": number,
        "review_media":
        {
            "review_to_media"?: TypedMedia
        }[]

    },
    "user": {
        "user_avatar": string,
        "user_email": string,
        "user_phonenumber": string,
        "user_id": string,
        "display_name": string,
    },

    /** dành cho detail */
    "product"?: {
        "product_id"?: string,
        "product_name"?: string,
        "product_slug"?: string,
        "product_meta"?: {
            product_meta_id: string,
            product_id: string,
            product_review_count: number,
            product_review_point: number,
        },
        "product_thumbnail_to_media"?: TypedMedia
    },
    "product_variant"?: {
        variant_id: string,
        variant_name: string,
        variant_slug: string,
        variant_thumbnail_to_media?: TypedMedia,
    },
    "orders"?: {
        "order_id"?: string,
        "order_pnr"?: string,
        "order_status"?: string,
        "createdAt"?: string,
    }
}



export interface TypedReviewMeta {
    review_meta_id?: string,
    review_id?: string,
    review_title?: string,
    review_content?: string,
    review_point?: number,
    review_status?: number,
    createdAt?: bigint,

    "review_media"?:
    {
        "review_to_media"?: TypedMedia
    }[],

    "review"?: TypedReview
}


type IQuery = TypedReview & IQueryParams;
type IQueryMeta = TypedReviewMeta & IQueryParams;


/*
* List of all app
*/
export function useGetReviews(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["review/fetch_entity_list"],
        queryFn: () => axios.get<TypedReview[]>(`/review${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}


export function useGetReview(review_id: string) {
    return useQuery({
        queryKey: ['review/fetch_entity'],
        queryFn: () => axios.get<TypedReview>(`/review/${review_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
        // enabled: false,
    });
}



export function useGetReviewMetas(object: IQueryMeta) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["review_meta/fetch_entity_list"],
        queryFn: () => axios.get<TypedReviewMeta[]>(`/review_meta${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}


export function useUpdateReviewMeta() {
    return useMutation({
        mutationKey: ['review_meta/update_entity'],
        mutationFn: ({ review_meta_id, ...rest }: TypedReviewMeta) => axios.patch<TypedReviewMeta>(`/review_meta/${review_meta_id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['review_meta/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['review_meta/fetch_entity'] });
        }
    });
}



export function useUpdateReview() {
    return useMutation({
        mutationKey: ['review/update_entity'],
        mutationFn: ({ review_id, ...rest }: TypedReview) => axios.patch<TypedReview>(`/review/${review_id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['review/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['review/fetch_entity'] });
        }
    });
}


export function useDeleteReview() {
    return useMutation({
        mutationKey: ['review/delete_entity'],
        mutationFn: (review_id: string) => axios.delete<any>(`/review/${review_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['review/fetch_entity_list'] });
        }

    });
}



type QuickCreateAReview = {
    "product_id": string,
    "user": {
        "display_name": string,
        "user_avatar": string
    },
    "review_meta": {
        "review_title": string,
        "review_content": string,
        "review_point": number,
    },
    "review_media": string[]
}

export function useQuickCreateReview() {
    return useMutation({
        mutationKey: ['review/quick_create'],
        mutationFn: (entity: QuickCreateAReview) => axios.post<QuickCreateAReview>(`/review/quick_create_a_review`, helpers.cleanEntity(entity)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['review/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['review_meta/fetch_entity_list'] });
        }
    });
}
