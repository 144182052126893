import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedUser } from "interface/user.model";

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedWalletCode {
    "wallet_code_id"?: string,
    "wallet_code_name"?: string,
    "redeem_code"?: string,
    "wallet_code_value"?: number,
    "wallet_type_id"?: string,
    "wallet_code_valid_to"?: number,
    "is_used"?: number,
    "used_by"?: string,
    "createdAt"?: string,
    "createdBy"?: string,
    "wallet_code_meta"?:
    {
        "meta_key": string,
        "meta_value": string
    }[]
    ,
    "wallet_type"?: {
        "wallet_name": string,
        "wallet_sign": string,
        "wallet_unit": string,
    },
    "used_by_to_user"?: TypedUser,

    "meta"?: {
        "meta_key": string,
        "meta_value": string,
    }[]
}


type IQuery = TypedWalletCode & IQueryParams;


/*
* List of all app
*/
export function useGetWalletCodes(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["wallet_code/fetch_entity_list"],
        queryFn: () => axios.get<TypedWalletCode[]>(`/wallet_code${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetWalletCode(wallet_code_id: string) {
    return useQuery({
        queryKey: ['wallet_code/fetch_entity'],
        queryFn: () => axios.get<TypedWalletCode>(`/wallet_code/${wallet_code_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useMutateGetWalletCode() {
    return useMutation({
        mutationKey: ['wallet_code/fetch_entity'],
        mutationFn: (wallet_code_id: string) => axios.get<TypedWalletCode>(`/wallet_code/${wallet_code_id}`).then((response) => response.data),
        retry: 1,
    });
}


export function useUpdateWalletCode() {
    return useMutation({
        mutationKey: ['wallet_code/update_entity'],
        mutationFn: ({ wallet_code_id, ...rest }: TypedWalletCode) => axios.patch<TypedWalletCode>(`/wallet_code/${wallet_code_id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['wallet_code/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['wallet_code/fetch_entity'] });
        }
    });
}


export function useCreateWalletCode() {
    return useMutation({
        mutationKey: ['wallet_code/create_entity'],
        mutationFn: (entity: TypedWalletCode) => axios.post<TypedWalletCode>(`/wallet_code`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['wallet_code/fetch_entity_list'] });
        }
    });
}


export function useCloneWalletCode() {
    return useMutation({
        mutationKey: ['wallet_code/clone_entity'],
        mutationFn: (wallet_code_id: string) => axios.post<any>(`/wallet_code/clone/${wallet_code_id}`).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['wallet_code/fetch_entity_list'] });
        }
    });
}


export function useDeleteWalletCode() {
    return useMutation({
        mutationKey: ['wallet_code/delete_entity'],
        mutationFn: (app_id: string) => axios.delete<any>(`/wallet_code/${app_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['wallet_code/fetch_entity_list'] });
        }

    });
}