import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedMedia } from "./media.query";
import { TypedUser } from "interface/user.model";
import { TypedProduct } from "interface/product.model";

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedSocial {
    "social_post_id"?: string,
    "social_post_content"?: string,
    "social_category"?: string,
    "social_suggest_product_id"?: string,

    "createdAt"?: string,
    "createdBy"?: string,
    media_id?: string[], // phần tạo 
    "social_post_media"?:
    {
        "media": TypedMedia
    }[]
    "social_post_like"?: [], // chỉ để check xem thằng đó like hay chưa ...
    "user"?: TypedUser,
    "product"?: TypedProduct,
    "_count"?: {
        "social_post_like": number,
        "social_post_comment": number
    }
}


type IQuery = TypedSocial & IQueryParams;


/*
* List of all app
*/
export function useGetSocialPosts(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["social_post/fetch_entity_list"],
        queryFn: () => axios.get<TypedSocial[]>(`/social_post${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: true,
        enabled: true,
    });
}
export function useMutateSocialPosts() {
    return useMutation({
        mutationKey: ["social_post/fetch_entity_list"],
        mutationFn: (object: IQuery) => axios.get<TypedSocial[]>(`/social_post${helpers.buildEndUrl(object)}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
    });
}



export function useGetSocialPost(app_id: string) {
    return useQuery({
        queryKey: ['social_post/fetch_entity'],
        queryFn: () => axios.get<TypedSocial>(`/social_post/${app_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useUpdateSocialPost() {
    return useMutation({
        mutationKey: ['social_post/update_entity'],
        mutationFn: ({ social_post_id, ...rest }: TypedSocial) => axios.patch<TypedSocial>(`/social_post/${social_post_id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['social_post/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['social_post/fetch_entity'] });
        }
    });
}


export function useCreateSocialPost() {
    return useMutation({
        mutationKey: ['social_post/create_entity'],
        mutationFn: (entity: TypedSocial) => axios.post<TypedSocial>(`/social_post`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['social_post/fetch_entity_list'] });
        }
    });
}


export function useDeleteSocialPost() {
    return useMutation({
        mutationKey: ['social_post/delete_entity'],
        mutationFn: (social_post_id: string) => axios.delete<any>(`/social_post/${social_post_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['social_post/fetch_entity_list'] });
        }
    });
}





export function useSocialPostLike() {
    return useMutation({
        mutationKey: ['social_post_like/create_entity'],
        mutationFn: (social_post_id: string) => axios.post<TypedSocial>(`/social_post_like/${social_post_id}`).then(response => response.data),
    });
}


export function useSocialPostUnlike() {
    return useMutation({
        mutationKey: ['social_post_like/delete_entity'],
        mutationFn: (social_post_id: string) => axios.delete<any>(`/social_post_like/${social_post_id}`),
    });
}




