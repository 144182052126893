import axios from 'axios';
import React, { useCallback, useRef } from 'react';
import 'media/css/quickUpload.scss';
type UploadRETURN = {
  media_filename: string;
  media_url: string;
  media_filetype: string;
  media_id: string;
};
const REACT_APP_AJAX_UPLOAD_URL = process.env.REACT_APP_AJAX_UPLOAD_URL;
interface IUpload {
  onSuccess?: (returndata: UploadRETURN) => void;
  onError?: (error_content: string) => void;
  children: any;
  acceptMimeType: string[];
}
/**
 * Click to upload at the moment, support image only!
 * MIME types
 */
export default function QuickUploadFile({
  onSuccess,
  onError,
  children,
  acceptMimeType
}: IUpload) {
  const loading = useRef(0);

  /**
   * return to main progress file, callback
   */
  function uploadSuccess(rep: UploadRETURN) {
    onSuccess?.call(this, rep);
  }
  function uploadFail(rep: string) {
    onError?.call(this, rep);
  }

  /**
   * Step2. save to server
   *
   * @param {File} file
   */
  async function saveToServer(file: File) {
    const fd = new FormData();
    fd.append('files', file); // append selected file to the bag named 'file'
    try {
      let result = await axios.post(REACT_APP_AJAX_UPLOAD_URL, fd, {
        onUploadProgress: (progressEvent: any) => {
          let progress_percent = Math.floor(progressEvent.loaded / progressEvent.total) * 100;
          loading.current = progress_percent;
        }
      });
      uploadSuccess(result.data);
      loading.current = 0;
    } catch (_) {
      loading.current = 0;
      uploadFail(_);
    }
  }

  /**
   * Step1. select local image
   *
   */
  const selectLocalFile = useCallback((e: any) => {
    e.preventDefault();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', acceptMimeType.join(', '));
    input.click();

    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0];
      if (acceptMimeType && acceptMimeType.length > 0) {
        let filetype = file.type;
        if (acceptMimeType.includes(filetype)) {
          return saveToServer(file);
        } else {
          return uploadFail('file_mimetype_not_allowed');
        }
      }
    };
  }, []);
  return <>
      <div className="quick_upload_file-outer">
        <div className="d clickable" onClick={selectLocalFile}>
          {children}
        </div>
      </div>
    </>;
}