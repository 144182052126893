import { Form, InlineGrid, Modal, TextField, Text, Select, Banner } from '@shopify/polaris';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import QuickUploadImage from 'components/oneclick-upload-image';
import __helpers from 'helpers/index';
import helpers from 'helpers/index';
import { TypedApp, useCreateApp, useUpdateApp } from 'queries/app.query';
import { useState, useEffect, useCallback } from 'react';
export default function AppModal({
  show = false,
  onClose = null,
  entity = null
}: {
  show: boolean;
  entity?: TypedApp;
  onClose?: () => void;
}) {
  const [internalError, setInternalError] = useState('');
  const [open, setOpen] = useState(false);
  const {
    mutateAsync: updateEntity
  } = useUpdateApp();
  const {
    mutateAsync: createEntity
  } = useCreateApp();
  const [internalData, setInternalData] = useState<TypedApp>(null);
  useEffect(() => {
    if (entity) {
      setInternalData(entity);
    }
  }, [entity]);
  useEffect(() => {
    if (show) setOpen(true);
  }, [show]);
  const onCloseCallback = useCallback(() => {
    onClose?.call(this, null);
    setOpen(false);
  }, []);
  useEffect(() => {
    reset();
  }, []);
  const useFields = {
    app_thumbnail: useField<string>({
      value: internalData?.app_thumbnail || '',
      validates: [notEmpty('không được để trống trường này')]
    }),
    app_homepage: useField<string>({
      value: internalData?.app_homepage || '',
      validates: [inputVal => {
        if (!helpers.isLink(inputVal)) {
          return 'Yêu cầu một đường link hợp lệ';
        }
      }]
    }),
    app_name: useField<string>({
      value: internalData?.app_name || '',
      validates: [notEmpty('không được để trống trường này'), lengthLessThan(50, 'Không được dài hơn 50 ký tự.'), lengthMoreThan(2, 'Trường này yêu cầu phải dài hơn 2 ký tự.')]
    }),
    app_description: useField<string>({
      value: internalData?.app_description || '',
      validates: [notEmpty('không được để trống trường này'), lengthLessThan(150, 'Không được dài hơn 150 ký tự.'), lengthMoreThan(2, 'Trường này yêu cầu phải dài hơn 2 ký tự.')]
    }),
    app_type: useField<string>({
      value: internalData?.app_type || 'website',
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      let addAPP: any;
      if (entity) {
        addAPP = await updateEntity({
          ID: internalData?.ID,
          app_homepage: values.app_homepage,
          app_thumbnail: values.app_thumbnail,
          app_name: values.app_name,
          app_description: values.app_description,
          app_type: values.app_type
        });
      } else {
        addAPP = await createEntity({
          app_thumbnail: values.app_thumbnail,
          app_homepage: values.app_homepage,
          app_name: values.app_name,
          app_description: values.app_description,
          app_type: values.app_type
        });
      }
      if (typeof addAPP?.error === 'undefined') {
        reset();
        onCloseCallback();
      } else {
        setInternalError(addAPP?.error?.message || 'Mã lỗi: E_8976');
      }
      return {
        status: 'success'
      };
    }
  });
  const [uploadError, setUploadError] = useState(null);
  return <Modal activator={null} open={open} onClose={onCloseCallback} title={entity ? 'Cập nhật ứng dụng' : 'Thêm ứng dụng'} primaryAction={{
    content: entity ? 'Cập nhật' : 'Lưu lại',
    loading: submitting,
    disabled: !dirty,
    onAction: submit
  }} secondaryActions={[{
    content: 'Đóng',
    disabled: submitting,
    onAction: onCloseCallback
  }]}>
      <Modal.Section>
        {internalError && <>
            <Banner tone="critical" onDismiss={() => setInternalError(null)}>
              {internalError}
            </Banner>
            <br />
          </>}

        <Form onSubmit={submit}>
          <InlineGrid gap="200">
            <InlineGrid columns={['oneThird', 'twoThirds']} gap={'200'}>
              <div className="d1" style={{
              width: '100px',
              height: '100px'
            }}>
                <QuickUploadImage onSuccess={e => {
                useFields.app_thumbnail.onChange(e.media_url);
              }} onError={e => setUploadError(e)} title="" placeholder={__helpers.getMediaLink(useFields.app_thumbnail.value, 'https://placehold.co/100x100')} />
                <Text as="p" tone="critical">
                  {uploadError}
                </Text>
              </div>
              <div className="d2">
                <TextField label="Tên app" autoComplete="off" requiredIndicator {...fields.app_name} />
              </div>
            </InlineGrid>

            <Select value={useFields.app_type.value} options={[{
            value: 'website',
            label: 'website'
          }, {
            value: 'android_app',
            label: 'android_app'
          }, {
            value: 'ios_app',
            label: 'ios_app'
          }, {
            value: 'desktop_app',
            label: 'desktop_app'
          }, {
            value: 'extension',
            label: 'extension'
          }, {
            value: 'tv_app',
            label: 'tv_app'
          }]} onChange={e => useFields.app_type.onChange(e)} label={'Phân loại ứng dụng quản lý'} />

            <TextField label="Trang chủ của App" autoComplete="off" {...fields.app_homepage} />

            <TextField label="Mô tả" autoComplete="off" multiline={2} maxLength={150} showCharacterCount requiredIndicator {...fields.app_description} />
            <Text tone="subdued" as="p">
              * Mã <code>client_private_key</code> và <code>client_public_key</code> sẽ được sinh ra tự động và bạn có thể thay đổi sau.
            </Text>
          </InlineGrid>
        </Form>
      </Modal.Section>
    </Modal>;
}