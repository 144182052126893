import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
// import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface Typedorder_fulfillment {
    order_fulfillment_id?: string;
    order_id?: string;
    logistic_id?: string;
    receiver_phonenumber?: string;
    receiver_fullname?: string;
    receiver_address?: string;
    receiver_address_ward?: string;
    receiver_address_district?: string;
    receiver_address_city?: string;
    receiver_address_fulltext?: string;
    order_fulfillment_private_status?: number;
    order_fulfillment_error?: string;
    order_fulfillment_code?: string;
    order_fulfillment_packingslip_uri?: string;
    order_estimated_dateofdelivery?: string;
    createdAt?: string;
    updatedAt?: string;
    logistic_service?: any
}



export function useGetorder_fulfillment(order_id: string) {
    return useQuery({
        queryKey: ['order_fulfillment/fetch_entity'],
        queryFn: () => axios.get<Typedorder_fulfillment>(`/order_fulfillment/${order_id}`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: true,
        enabled: true
    });
}

export function useMutateGetorder_fulfillment() {
    return useMutation({
        mutationKey: ['order_fulfillment/fetch_entity'],
        mutationFn: (order_id: string) => axios.get<Typedorder_fulfillment>(`/order_fulfillment/${order_id}`).then((res) => res.data),
        retry: 1,
    });
}


export function useUpdateorder_fulfillment() {
    return useMutation({
        mutationKey: ['order_fulfillment/update_entity'],
        mutationFn: ({ order_id, ...rest }: Typedorder_fulfillment) => axios.patch<Typedorder_fulfillment>(`/order_fulfillment/${order_id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['order_fulfillment/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['order_fulfillment/fetch_entity'] });
            queryClient.invalidateQueries({ queryKey: ['orders/fetch_many'] });
        }
    });
}


export function useCreateFulfillmentOrder() {
    return useMutation({
        mutationKey: ['order_fulfillment/create_order'],
        mutationFn: (order_id: any) => axios.post<any>(`/order_fulfillment/create_order`, { order_id: order_id }).then(res => res.data),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['order_fulfillment/fetch_entity'] });
            queryClient.invalidateQueries({ queryKey: ['orders/fetch_entity'] });
        }
    });
}


export function useCancelFulfillmentOrder() {
    return useMutation({
        mutationKey: ['order_fulfillment/cancel_order'],
        mutationFn: (order_id: string) => axios.delete<any>(`/order_fulfillment/cancel_order/${order_id}`).then(res => res.data),
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['order_fulfillment/fetch_entity'] });
            queryClient.invalidateQueries({ queryKey: ['orders/fetch_entity'] });
        }
    });
}


// export function useDeleteorder_fulfillment() {
//     return useMutation({
//         mutationKey: ['order_fulfillment/delete_entity'],
//         mutationFn: (order_fulfillment_id: string) => axios.delete<any>(`/order_fulfillment/${order_fulfillment_id}`),
//         onSuccess: () => {
//             queryClient.invalidateQueries({ queryKey: ['order_fulfillment/fetch_entity_list'] });
//         }

//     });
// }