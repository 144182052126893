import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, InlineStack, Link, Modal, ResourceItem, ResourceList, SkeletonTabs, Text, Tooltip } from '@shopify/polaris';
import dateandtime from 'date-and-time';
import helpers from 'helpers/index';
import { useGetUserBirthday } from 'queries/user.query';
function ShowModalUserBirthday({
  show,
  onClose,
  list
}: {
  show: boolean;
  onClose: Function;
  list: any;
}) {
  return <Modal activator={null} open={show} onClose={() => onClose()} title="Danh sách đồng nghiệp sinh nhật gần đây" secondaryActions={[{
    content: 'Đóng',
    onAction: () => onClose()
  }]}>
      <ResourceList resourceName={{
      singular: 'user',
      plural: 'user'
    }} items={list} renderItem={userData => {
      const {
        user_id,
        user_avatar,
        display_name,
        user_birthday
      } = userData;
      return <ResourceItem id={user_id} url={null} media={<Avatar size="md" name={display_name} source={user_avatar} initials={display_name.charAt(0)} />} shortcutActions={[{
        content: 'Xem thông tin khách',
        url: '/customer/view/' + user_id
      }]} name={display_name}>
              <Text variant="bodyMd" fontWeight="bold" as="h3">
                {display_name}
              </Text>
              <div>
                <Text as="p">
                  {helpers.getAge(new Date(user_birthday), ' tuổi')}
                  {' - '} Ngày sinh {dateandtime.format(new Date(user_birthday), 'DD/MM/YYYY')}
                </Text>
              </div>
            </ResourceItem>;
    }} />
    </Modal>;
}
export default function UserBirthDayList({
  limit
}: {
  limit: number;
}) {
  const {
    data: entities,
    error,
    isLoading: loading,
    isFetched
  } = useGetUserBirthday({
    limit: Number(limit)
  });
  const [userBirthdayModal, setUserBirthdayModal] = useState(false);
  return <>
      <ShowModalUserBirthday show={userBirthdayModal} onClose={() => setUserBirthdayModal(false)} list={entities} />
      <br />
      <Text as="h3" variant="headingMd">
        Sinh nhật của đồng nghiệp
      </Text>
      <br />
      {loading ? <SkeletonTabs /> : Array.isArray(entities) && entities.length > 0 ? <InlineStack align="start" blockAlign="center" gap={'200'} key={'dd456dfg'}>
          {entities.map((ctm, index) => {
        if (index > 5) return null;
        if (index === 5) {
          return <Tooltip width="wide" content={<>
                      <Text as="p">Còn {entities.length - 4} người khác, click để xem thêm.</Text>
                    </>}>
                  <Link onClick={() => setUserBirthdayModal(true)}>
                    <Avatar name="Other" initials="+" />
                  </Link>
                </Tooltip>;
        }
        return <Tooltip width="wide" key={index + '_hsh'} content={<>
                    <Text as="p" fontWeight="bold">
                      {ctm.display_name}
                    </Text>
                    <Text as="p">{helpers.getAge(new Date(ctm?.user_birthday), ' tuổi')}</Text>
                    <Text as="p">Ngày sinh {dateandtime.format(new Date(ctm?.user_birthday), 'DD/MM/YYYY')}</Text>
                  </>}>
                <Link url={'/profile/' + ctm.user_id}>
                  <Avatar source={ctm.user_avatar} name={ctm.display_name} initials={ctm.display_name.charAt(0)} />
                </Link>
              </Tooltip>;
      })}
        </InlineStack> : <Text as="p">Chưa có ai có sinh nhật vào dịp này.</Text>}
    </>;
}