import React, { useCallback, useEffect, useState } from 'react';
import { DataTable, Link } from '@shopify/polaris';
import dateandtime from 'date-and-time';
import Pagination from 'components/pagination';
import { useGetGameLuckyList } from 'queries/game.query';
export default function WinnerList() {
  const [totalItems, setTotalItems] = useState(0);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  const [mainQuery, setMainQuery] = useState({
    query: '',
    page: 1,
    limit: 20,
    sort: 'createdAt:desc'
  });

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: limit
    });
  }, [mainQuery]);
  const {
    data
  } = useGetGameLuckyList(mainQuery);

  /** LISTING */

  const [topTenData, setTopTenData] = useState([]);
  useEffect(() => {
    try {
      let {
        body,
        totalItems
      } = data;
      setTopTenData(body);
      setTotalItems(totalItems);
    } catch (e) {}
  }, [data]);
  return <>
      <DataTable columnContentTypes={['text', 'numeric', 'numeric', 'numeric']} headings={['Người chơi', 'Quà', 'Ngày quay']} rows={Array.isArray(topTenData) && topTenData.length > 0 ? topTenData.map((element, index) => {
      return [<Link url={'/users/view/' + element.user_id}>{element.user.display_name}</Link>, element?.game_lucky_wheel_present?.present_name, dateandtime.format(new Date(Number(element.createdAt)), 'DD-MM-YYYY')];
    }) : []} />

      <br />
      <br />
      {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery?.page} pageSize={mainQuery?.limit} onChange={onChangePagination} /> : null}
      <br />
      <br />
    </>;
}