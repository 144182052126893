import { compiledBlock as _compiledBlock } from "million/react";
const Template404_1 = _compiledBlock(() => <div id="wrap_404">
      <h1>404</h1>
      <div className="cloak__wrapper">
        <div className="cloak__container">
          <div className="cloak"></div>
        </div>
      </div>
      <div className="info">
        <h2>Không tìm thấy thông tin</h2>
        <p>Chúng tôi đã tìm thông tin bạn yêu cầu, nhưng nó không có ở đây.</p>
        <a href="/" rel="noreferrer noopener">
          Quay về trang chủ
        </a>
      </div>
    </div>, {
  name: "Template404_1"
});
const Template404 = function Template404() {
  return /*@million jsx-skip*/<Template404_1 />;
};
import { block as _block } from "million/react";
import React from 'react';
import '../media/css/404.scss';
export default Template404;