import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import incoming_webhook_list from './incoming_webhook.list';
import incoming_webhook_view from './incoming_webhook.view';

/**
 *   Create index file for Incoming_webhook
 */

export default function List_incoming_webhook() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = incoming_webhook_list;
      break;
    case 'view':
      ActualPage = incoming_webhook_view;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>{<ActualPage />}</>;
}