import { compiledBlock as _compiledBlock } from "million/react";
const QuickUploadImage_1 = _compiledBlock(_props => <div className="quick_upload_outer" style={_props.v0} id={_props.v1}>
      {_props.v2}
      <div className="quick_upload_wrap clickable" id={_props.v3}>
        <span className="quick_upload">
          <svg width="24" height="24" viewBox="0 0 24 24" role="presentation">
            <path d="M3 4.995C3 3.893 3.893 3 4.995 3h14.01C20.107 3 21 3.893 21 4.995v14.01A1.995 1.995 0 0119.005 21H4.995A1.995 1.995 0 013 19.005V4.995zM10.5 16.5L9 15l-3 3h12v-2.7L15 12l-4.5 4.5zM8 10a2 2 0 100-4 2 2 0 000 4z" fill="currentColor" fillRule="evenodd"></path>
          </svg>
        </span>
      </div>
      {_props.v4}
      <div className="children_wrap">
        {_props.v5}
        {_props.v6}
      </div>
    </div>, {
  name: "QuickUploadImage_1",
  portals: ["v2", "v4", "v5", "v6"]
});
const QuickUploadImage = function QuickUploadImage({
  onSuccess,
  onError,
  title,
  children,
  placeholder,
  width,
  height
}: IUpload) {
  const [loadingPercent, setLoadingPercent] = useState(0);
  const [imagePlaceholder, setImagePlaceholder] = useState<string | null>(null);
  useEffect(() => {
    if (placeholder) setImagePlaceholder(placeholder);
  }, [placeholder]);

  /**
   * return to main progress file, callback
   */
  function uploadSuccess(rep: any) {
    setImagePlaceholder(__helpers.getMediaLink(rep.media_url));
    onSuccess?.call(this, rep);
  }

  /**
   * Step2. save to server
   *
   * @param {File} file
   */
  async function saveToServer(file: File) {
    const fd = new FormData();
    fd.append('files', file); // append selected file to the bag named 'file'
    setLoadingPercent(0);
    const xProgress = (progressEvent: any) => {
      let progress_percent = Math.floor(progressEvent.loaded / progressEvent.total) * 100;
      setLoadingPercent(progress_percent);
    };
    await axios.post(REACT_APP_AJAX_UPLOAD_URL, fd, {
      onUploadProgress: xProgress
    }).then(result => {
      uploadSuccess(result.data);
    }).catch(e => {
      onError?.call(this, e.message);
    });
    setLoadingPercent(0);
  }
  const random_id_for_wrapper = __helpers.getRandomHash(12);

  /**
   * Step1. select local image
   *
   */
  const selectLocalImage = useCallback(() => {
    function clickAndUploadHandler(e) {
      e.preventDefault();
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();

      // Listen upload local image and save to server
      input.onchange = () => {
        const file = input.files[0];
        // file type is only image.
        if (/^image\//.test(file.type)) {
          saveToServer(file);
        } else {
          return onError('upload_image_only');
        }
      };
    }
    let UploadableDiv = document.getElementById('quick_upload_wrap_' + random_id_for_wrapper);
    UploadableDiv && UploadableDiv.addEventListener('click', clickAndUploadHandler);
    return () => {
      UploadableDiv.removeEventListener('click', clickAndUploadHandler);
    };
  }, [random_id_for_wrapper]);
  const styles = {
    width: width ? width + 'px' : '100%',
    height: height ? height + 'px' : ''
  };
  useEffect(() => {
    selectLocalImage();
  }, []);
  return /*@million jsx-skip*/<QuickUploadImage_1 v0={styles} v1={`quick_upload_outer_` + random_id_for_wrapper} v2={loadingPercent > 0 && loadingPercent < 100 && <div className="loaded" style={{
    width: loadingPercent + '%'
  }}></div>} v3={`quick_upload_wrap_` + random_id_for_wrapper} v4={imagePlaceholder && <img src={imagePlaceholder} alt="" className="imageAfterUploadPlaceholder" />} v5={title && <span className="textPlaceholder">{title}</span>} v6={children} />;
};
import { block as _block } from "million/react";
import axios from 'axios';
import __helpers from 'helpers/index';
import 'media/css/quickUpload.scss';
import { useCallback, useEffect, useState } from 'react';
export type TypedUploadResult = {
  media_id: string;
  media_url: string;
  media_filename: string;
  media_filetype: string;
};
const REACT_APP_AJAX_UPLOAD_URL = process.env.REACT_APP_AJAX_UPLOAD_URL;
interface IUpload {
  onSuccess?: (e: TypedUploadResult) => void;
  onError?: (message: string) => void;
  title?: string;
  placeholder?: string | null;
  children?: any;
  width?: number;
  height?: number;
}
/**
 * Click to upload at the moment, support image only!
 */
export default QuickUploadImage;