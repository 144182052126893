import { useEffect } from "react";
export const FormPrompt = ({
  hasUnsavedChanges
}: {
  hasUnsavedChanges: Boolean;
}) => {
  useEffect(() => {
    const onBeforeUnload = (e: unknown | any) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = "";
        return !window.confirm("You have unsaved changes, are you sure you want to leave?");
      }
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [hasUnsavedChanges]);
  return null;
};