import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedRank {
    rank_id?: string;
    rank_name?: string;
    rank_slug?: string;
    rank_description?: string;
    min_points?: number;
    timeframe?: string;
    createdAt?: string;
}


type IQuery = TypedRank & IQueryParams;


/*
* List of all app
*/
export function useGetRanks(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["rank/fetch_entity_list"],
        queryFn: () => axios.get<TypedRank[]>(`/rank${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetRank(rank_id: string) {
    return useQuery({
        queryKey: ['rank/fetch_entity'],
        queryFn: () => axios.get<TypedRank>(`/rank/${rank_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useUpdateRank() {
    return useMutation({
        mutationKey: ['rank/update_entity'],
        mutationFn: ({ rank_id, ...rest }: TypedRank) => axios.patch<TypedRank>(`/rank/${rank_id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['rank/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['rank/fetch_entity'] });
        }
    });
}


export function useCreateRank() {
    return useMutation({
        mutationKey: ['rank/create_entity'],
        mutationFn: (entity: TypedRank) => axios.post<TypedRank>(`/rank`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['rank/fetch_entity_list'] });
        }
    });
}


export function useDeleteRank() {
    return useMutation({
        mutationKey: ['rank/delete_entity'],
        mutationFn: (rank_id: string) => axios.delete<any>(`/rank/${rank_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['rank/fetch_entity_list'] });
        }

    });
}