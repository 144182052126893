import { Card, DataTable, Layout, Link, Page } from '@shopify/polaris';
import Pagination from '../../components/pagination';
import helpers from 'helpers/index';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import UserActivityFilter from './filter';
import { useGetUserActivities } from 'queries/user_activity.query';
export default function UserActivityList() {
  const history = useNavigate();
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery: any = helpers.ExtractUrl(search);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 40,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data
  } = useGetUserActivities(mainQuery);
  useEffect(() => {
    if (data) {
      setEntities(data.entities);
      setTotalItems(data.totalItems);
    }
  }, [data]);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (search !== buildURLSearch) history('/user_activity' + buildURLSearch);
    getEntities();
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const PagesList = useCallback(() => {
    return <DataTable columnContentTypes={['text', 'numeric', 'numeric', 'numeric', 'numeric']} headings={['TYPE', 'SOURCE', 'WHOIS', 'CREATED_AT']} stickyHeader fixedFirstColumns={1} hasZebraStripingOnData increasedTableDensity rows={Array.isArray(entities) && entities?.map((element, index) => {
      return [element.activity_type, element.activity_source, <Link url={`/users/view/` + element.user_id} removeUnderline key={index + '_keyLink'}>
                {element?.user?.user_email || element?.user?.user_phonenumber || element?.user?.display_name}
              </Link>, helpers.subtractTimeHistory(element.createdAt)];
    })} totals={[]} />;
  }, [entities]);
  return <>
      <Helmet>
        <title>User Activity Controller</title>
      </Helmet>
      <Page title="User Activity Controller" subtitle="Trung tâm kiểm soát mọi hoạt động của người dùng">
        <Card padding="0">
          <UserActivityFilter loading={entities === null} onCallback={filterCallback} sortField={[]} />
          {entities !== null && <PagesList />}
        </Card>
        <br />
        <br />

        {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery?.page} pageSize={mainQuery?.limit} onChange={onChangePagination} /> : null}
      </Page>

      <br />
    </>;
}