import { Autocomplete, Card, Icon } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import { useGetEmailSettings } from 'queries/email_setting.query';
export default function QuickSearchEmailSetting({
  current_setting,
  title,
  error,
  onClose
}: {
  current_setting?: string | bigint;
  title?: string;
  error?: any;
  onClose: (val: string) => void;
}) {
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [mainQuery, setMainQuery] = useState<any>({
    query: '',
    page: 1,
    limit: 5,
    server_status: 1,
    setting_type: 'smtp',
    sort: 'createdAt:desc'
  });
  const {
    data,
    refetch: getEntities,
    isLoading: loading
  } = useGetEmailSettings(mainQuery);
  useEffect(() => {
    if (data) {
      setEntities(data.entities);
      setTotalItems(data.totalItems);
    }
  }, [data]);

  /**
   * Assign cho ai đóa
   */
  const [userSelected, setUserSelected] = useState<string[]>([]);
  const [userQuickSearchText, setUserQuickSearchText] = useState<string>('');
  const [userListForQuickOption, setUserListForQuickOption] = useState([]);
  useEffect(() => {
    let innerCampaign = [];
    if (entities) for (let camp of entities) {
      innerCampaign.push({
        value: camp.id,
        label: camp.server_name
      });
      if (current_setting) if (camp.id === current_setting) {
        setUserQuickSearchText(camp.server_name);
      }
    }
    setUserListForQuickOption(innerCampaign);
  }, [entities]);
  const onQueryToServer = useCallback((keyword: string) => {
    setMainQuery({
      query: keyword,
      sort: 'createdAt:desc',
      server_status: 1,
      setting_type: 'smtp',
      limit: 10
    });
    getEntities();
  }, []);
  const onChangeMainQueryCallback = useMemo(() => {
    return helpers.debounce(_value => {
      onQueryToServer?.call(this, _value);
    }, 400);
  }, []);
  const updateUserText = useCallback((value: string) => {
    setUserQuickSearchText(value);
    onChangeMainQueryCallback(value);
  }, []);
  const updateSelection = useCallback((selected: string[]) => {
    const selectedValue = selected.map(selectedItem => {
      const matchedOption = userListForQuickOption.find(option => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });
    setUserSelected(selected);
    setUserQuickSearchText(selectedValue[0] || '');
    onClose?.call(this, selected[0]);
  }, [userListForQuickOption]);
  const textFieldSelectUser = <>
      <Autocomplete.TextField onChange={updateUserText} label={title ?? 'Tài khoản gửi email'} labelHidden={!title} clearButton onClearButtonClick={() => {
      setUserQuickSearchText('');
      updateSelection([]);
    }} error={error} value={userQuickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} placeholder="Tìm kiếm tài khoản Email" autoComplete="off" />
    </>;
  return <Autocomplete options={userListForQuickOption} selected={userSelected} onSelect={updateSelection} textField={textFieldSelectUser} loading={loading} />;
}