import { Page, BlockStack, Text, Divider, Checkbox, TextField, Card, Form, Popover, Button, Tag, InlineStack, Grid } from '@shopify/polaris';
import { Toast, ContextualSaveBar } from '@shopify/polaris';
import { useState, useCallback, useEffect, useMemo, Suspense, lazy } from 'react';
import { useField, useForm } from '@shopify/react-form';
import { useGetSettings, useUpdateSetting } from 'queries/setting.query';
import { useNotification } from 'context/NotificationContext';
import __ from 'languages/index';
import __helpers from 'helpers/index';
import QuickSearchUser from 'components/quickSearchUser';
import { useMutateGetUsers } from 'queries/users.query';
import { PlusIcon } from '@shopify/polaris-icons';
import { TypedUser } from 'interface/user.model';
// import QuickSearchWallet from 'components/quickSearchWallet';
const QuickSearchWallet = lazy(() => import('components/quickSearchWallet'));
export default function ReviewSetting() {
  const {
    addNotification
  } = useNotification();
  const {
    data: entities,
    isLoading: loading
  } = useGetSettings({
    fields: `setting_name,setting_value`,
    setting_name: `account_received_noti_for_new_low_vote,review_created_notification,review_automatic_cancel_time,review_after_approved_will_receive_value,review_after_approved_will_receive_wallet`
  });
  const {
    mutateAsync: updateSetting,
    isSuccess,
    isPending
  } = useUpdateSetting();
  const useFields = {
    review_automatic_cancel_time: useField<string>({
      value: entities?.review_automatic_cancel_time ?? '0',
      validates: [inputVal => {
        if (inputVal && !__helpers.isNumeric(inputVal)) return 'Phải là một số hợp lệ.';
      }]
    }),
    review_created_notification: useField<string>({
      value: entities?.review_created_notification ?? '0',
      validates: []
    }),
    review_after_approved_will_receive_value: useField<string>({
      value: entities?.review_after_approved_will_receive_value,
      validates: []
    }),
    review_after_approved_will_receive_wallet: useField<string>({
      value: entities?.review_after_approved_will_receive_wallet,
      validates: []
    })
  };
  const {
    fields,
    submit,
    dirty,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateSetting({
          review_automatic_cancel_time: Number(values.review_automatic_cancel_time),
          review_created_notification: Number(values.review_created_notification),
          review_after_approved_will_receive_value: Number(values.review_after_approved_will_receive_value),
          review_after_approved_will_receive_wallet: values.review_after_approved_will_receive_wallet
        });
      } catch (e: any) {
        console.error(`Submit error`, e);
      }
      return {
        status: 'success'
      };
    }
  });
  useEffect(() => {
    if (isSuccess) addNotification('info', __('update_successfully'));
  }, [isSuccess]);

  /**
   * ENd save quick note
   */

  /** AI NHẬN THÔNG BÁO? */
  const {
    mutate: getUserData,
    data
  } = useMutateGetUsers();
  const [userReceivedNotificationData, setUserReceivedNotificationData] = useState<TypedUser[]>([]);
  useEffect(() => {
    if (data) {
      let {
        body
      } = data;
      setUserReceivedNotificationData(body);
    }
  }, [data]);
  const [userAsigned, setUserAsigned] = useState([]);
  useEffect(() => {
    if (entities?.account_received_noti_for_new_low_vote) {
      setUserAsigned(String(entities?.account_received_noti_for_new_low_vote).split(','));
    }
  }, [entities?.account_received_noti_for_new_low_vote]);
  const [showAddReferrer, setShowAddReferrer] = useState(false);
  const addNewReferrer = useCallback(async (user_id: bigint | string) => {
    let new_record = entities.account_received_noti_for_new_low_vote ? entities.account_received_noti_for_new_low_vote + ',' + user_id : user_id;
    await updateSetting({
      account_received_noti_for_new_low_vote: new_record
    });
  }, [entities?.account_received_noti_for_new_low_vote, updateSetting]);
  const removeTag = useCallback(async (user_id: string) => {
    let newData = userAsigned.filter(previousTag => previousTag !== user_id);
    setUserAsigned(newData);
    await updateSetting({
      account_received_noti_for_new_low_vote: newData.join(',')
    });
  }, [userAsigned]);
  useEffect(() => {
    if (!__helpers.isEmpty(userAsigned)) {
      getUserData({
        user_id: userAsigned.join(','),
        limit: 10,
        sort: 'createdAt:desc'
      });
    }
  }, [userAsigned]);
  return <>
      <Page title="Cài đặt cho đánh giá đơn hàng" narrowWidth>
        <Form onSubmit={submit}>
          <Card>
            <BlockStack gap="400">
              <TextField autoComplete="off" label="Thời gian tự động hủy review" helpText={'Khi đơn hàng được hoàn thành, mà người dùng không đánh giá trong X giờ thì sẽ bị hủy'} suffix={<>Đơn vị tính: Giờ</>} {...useFields.review_automatic_cancel_time} />
              <Divider />

              <Checkbox label="Nhận thông báo khi review mới được tạo." checked={useFields.review_created_notification.value === '1'} onChange={() => useFields.review_created_notification.onChange(useFields.review_created_notification.value === '0' ? '1' : '0')} />

              <Divider />

              <Text as="h3" variant="headingMd">
                Người review có nhận được giá trị nào thêm?
              </Text>

              <Grid>
                <Grid.Cell columnSpan={{
                xs: 6,
                sm: 3,
                md: 3,
                lg: 6,
                xl: 6
              }}>
                  <TextField label="Họ sẽ được?" autoComplete="off" {...useFields.review_after_approved_will_receive_value} />
                </Grid.Cell>

                <Grid.Cell columnSpan={{
                xs: 6,
                sm: 3,
                md: 3,
                lg: 6,
                xl: 6
              }}>
                  <Suspense fallback={<>Loading...</>}>
                    <QuickSearchWallet title="Ví nhận" current_wallet_id={entities?.review_after_approved_will_receive_wallet} onClose={wallet_id => useFields.review_after_approved_will_receive_wallet.onChange(wallet_id)} />
                  </Suspense>
                </Grid.Cell>
              </Grid>

              <Divider />

              <Text as="h3" variant="headingMd">
                Người nhận thông báo nếu có review xấu (dưới 4 sao)
              </Text>

              <InlineStack gap={'400'}>
                {entities?.account_received_noti_for_new_low_vote ? userReceivedNotificationData?.map((userData, index) => {
                return <Tag key={`jfdskg_${index}`} onRemove={() => removeTag(userData?.user_id)}>
                          {userData.display_name}
                        </Tag>;
              }) : 'Chưa có ai, cài đặt ngay'}
              </InlineStack>

              <Popover active={showAddReferrer} activator={<Button variant="plain" icon={PlusIcon} onClick={() => setShowAddReferrer(true)}>
                    Thêm mới tài khoản nhận thông báo
                  </Button>} autofocusTarget="first-node" onClose={() => setShowAddReferrer(false)}>
                <Card>
                  <QuickSearchUser title="Admin, Sale Admin và Sale" user_role="admin,super_admin,sale_admin,sale" onClose={(userData: any) => {
                  addNewReferrer(userData.user_id);
                  setShowAddReferrer(false);
                }} />
                </Card>
              </Popover>
            </BlockStack>
          </Card>
        </Form>
        {dirty && <ContextualSaveBar message="Thay đổi chưa được lưu" saveAction={{
        onAction: () => submit(),
        loading: isPending
      }} discardAction={{
        onAction: () => resetForm()
      }} />}
      </Page>
    </>;
}