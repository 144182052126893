import React, { useMemo, useRef } from 'react';
import { useCallback, useEffect, useState } from 'react';
import helpers from '../../helpers';
import { Page, InlineGrid, DataTable, Card, Text, Divider, Avatar, InlineStack, Link } from '@shopify/polaris';
import { ReactECharts, ReactEChartsProps } from 'components/React-ECharts';
import DateRangePicker from 'components/dateRangePicker';
import dateandtime from 'date-and-time';
import StaffLineChart from './staff.linechart';
import { useGetStaffPerformance, useGetTop5 } from 'queries/analytics.query';
export default function StaffReport() {
  const [defaultDate, setDefaultDate] = useState<string>(`gte:${new Date(dateandtime.format(dateandtime.addDays(new Date(), -7), 'YYYY-MM-DD 00:00:00')).getTime()},
    lte:${new Date(dateandtime.format(new Date(), 'YYYY-MM-DD 23:59:59')).getTime()}`);
  const {
    refetch: getStaffPerformance,
    data: staffPerformanceData
  } = useGetStaffPerformance({
    createdAt: defaultDate
  });
  const {
    refetch: getTop5,
    data: top5people
  } = useGetTop5({
    createdAt: defaultDate
  });
  const getMainQuery = useCallback(async () => {
    await getTop5();
    await getStaffPerformance();
  }, []);
  const getMainQueryDebound = useMemo(() => helpers.debounce(_value => getMainQuery?.call(this, _value), 500), []);
  useEffect(() => {
    getMainQueryDebound(defaultDate);
  }, [defaultDate]);
  const [{
    top5peopleName,
    top5peopleCount
  }, setTop5Data] = useState<any>([]);
  useEffect(() => {
    if (!top5people) return;
    let people = [];
    let countingOrder = [];
    for (let x of top5people) {
      people.push(x.display_name);
      countingOrder.push(x.order_count);
    }
    setTop5Data({
      top5peopleName: people,
      top5peopleCount: countingOrder
    });
  }, [top5people]);
  const dateChangeCallback = useCallback(_e => {
    let {
      since,
      until
    } = _e.period;
    let FNS = [];
    if (since) {
      FNS.push(`gte:${new Date(dateandtime.format(since, 'YYYY-MM-DD 00:00:00')).getTime()}`);
    }
    if (until) {
      FNS.push(`lte:${new Date(dateandtime.format(until, 'YYYY-MM-DD 23:59:59')).getTime()}`);
    }
    setDefaultDate(FNS.join(','));
  }, []);
  const staffPerformance: ReactEChartsProps['option'] = {
    title: {
      text: 'TOP 5 nhân viên'
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      show: false
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '-5%',
      containLabel: true
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01]
    },
    yAxis: {
      type: 'category',
      data: top5peopleName
    },
    series: [{
      type: 'bar',
      data: top5peopleCount
    }]
  };
  const netSaleTotal = useRef(0);
  const grossSaleTotal = useRef(0);
  const orderCount = useRef(0);
  const customerCount = useRef(0);
  const assignCount = useRef(0);
  const [allUsers, setAllUsers] = useState([]);
  useEffect(() => {
    netSaleTotal.current = 0;
    grossSaleTotal.current = 0;
    customerCount.current = 0;
    orderCount.current = 0;
    assignCount.current = 0;
    let ListAllUser = [];
    staffPerformanceData?.map(staff => {
      const {
        display_name,
        user_avatar,
        user_id,
        order_count,
        order_total_price,
        order_total_original_price,
        customer_count,
        assign_count
      } = staff;
      netSaleTotal.current = netSaleTotal.current + Number(order_total_price || 0);
      grossSaleTotal.current = grossSaleTotal.current + Number(Number(order_total_price || 0) - Number(order_total_original_price || 0));
      customerCount.current = customerCount.current + Number(customer_count || 0);
      orderCount.current = orderCount.current + Number(order_count || 0);
      assignCount.current = assignCount.current + Number(assign_count || 0);
      ListAllUser.push([<>
          <InlineStack align="start" blockAlign="center" gap={'200'}>
            <Avatar size="sm" source={user_avatar} name={display_name} initials={display_name.charAt(0)}></Avatar>
            <Link url={'/profile/' + user_id}>
              <Text as="span" fontWeight="bold">
                {display_name}
              </Text>
            </Link>
          </InlineStack>
        </>,
      // <Tooltip content={`bla bla`}>
      //     <div style={{minWidth: 150}}><ProgressBar progress={78} size="medium" color="success" /></div>
      // </Tooltip>,
      customer_count || 0, order_count || 0, assign_count || 0, helpers.percentage(order_count || 0, assign_count || 0) + '%', helpers.formatNumber(order_total_price || 0, ','), helpers.formatNumber(order_total_price - order_total_original_price || 0, ',')]);
    });
    setAllUsers(ListAllUser);
  }, [staffPerformanceData]);
  return <>
      <Page fullWidth title="Hiệu năng nhân viên">
        <DateRangePicker onChange={dateChangeCallback} Alignment="left" />
        <br />
        <Card>
          <InlineGrid gap="400" columns={{
          xs: 1,
          sm: 1,
          md: 1,
          lg: ['twoThirds', 'oneThird'],
          xl: ['twoThirds', 'oneThird']
        }}>
            <StaffLineChart defaultDate={defaultDate} />
            <ReactECharts option={staffPerformance} settings={{
            notMerge: true
          }} style={{
            minHeight: 350
          }} />
          </InlineGrid>
          <br />
          <Divider />
          <br />
          {staffPerformanceData && staffPerformanceData.length > 0 ? <DataTable showTotalsInFooter firstColumnMinWidth="150" fixedFirstColumns={1} columnContentTypes={['text', 'numeric', 'numeric', 'numeric', 'numeric', 'numeric', 'numeric']} headings={['Tên', 'Số khách', 'Số đơn', 'Được giao', 'Tỉ lệ chuyển đổi', 'Tổng doanh thu', 'Tổng Lợi nhuận']} rows={allUsers} totals={['', customerCount.current + ' khách', orderCount.current + ' đơn', assignCount.current + ' lần', '-', helpers.formatNumber(netSaleTotal.current, ','), helpers.formatNumber(grossSaleTotal.current, ',')]} totalsName={{
          singular: 'Tổng',
          plural: 'Tổng'
        }} /> : <>
              <Text as="p">KHÔNG CÓ DỮ LIỆU, THỬ THAY ĐỔI NGÀY XEM BÁO CÁO HOẶC CHỌN KHOẢNG THỜI GIAN RỘNG HƠN. </Text>
            </>}
        </Card>
        <br />
        <br />
        <Text as="p" variant="bodySm" tone="subdued">
          * Doanh thu trong các bảng biểu này được tính cả các đơn hàng có tình trạng vận chuyển là hoàn thành nhưng chưa đối soát.
        </Text>
      </Page>
      <br />
      <br />
    </>;
}