import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';
import { TypedVoucher } from "./voucher.query";
import { TypedMedia } from "./media.query";
import { TypedWallet } from "./user_wallet.query";
import { TypedWallet_type } from "./wallet.query";
import { TypedWalletCode } from "./wallet_code.query";

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/

/**
*   Reducer used for front-end, with settings.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
*/


export interface TypedGame {
    id?: string;
    game_title?: string;
    game_excerpt?: string;
    game_description?: string;
    game_thumbnail?: string;
    game_slug?: string;
    game_setting?: {
        [prop: string]: string
    };
    game_status?: number;
    createdAt?: string;
}

type TQuery = TypedGame & IQueryParams;

/*
* List of all app
*/
export function useGetGames(object: TQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["game/fetch_entity_list"],
        queryFn: () => axios.get<TypedGame[]>(`/game${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetGame(app_id: string) {
    return useQuery({
        queryKey: ['game/fetch_entity'],
        queryFn: () => axios.get<TypedGame>(`/game/${app_id}`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useUpdateGame() {
    return useMutation({
        mutationKey: ['game/update_entity'],
        mutationFn: ({ id, ...rest }: TypedGame) => axios.patch<any>(`/game/${id}`, helpers.cleanEntity(rest)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['game/fetch_entity'] });
        }
    });
}



/**
 * Game checkin ...
 */

export function useClearGameCheckinSession() {
    return useMutation({
        mutationKey: ['game/checkin/clear_all_session_data'],
        mutationFn: () => axios.delete<any>(`game/checkin/clear_all`)
    })
}


export function useGetGameCheckinRank() {
    return useMutation({
        mutationKey: ['game/checkin/get_checkin_rank'],
        mutationFn: () => axios.get<any>(`game/checkin/rank`).then(response => response.data)
    })
}

export function useClearGameLuckyWheelSession() {
    return useMutation({
        mutationKey: ['game/lucky_wheel/clear_all_session_data'],
        mutationFn: () => axios.delete<any>(`game/lucky_wheel/clear_all`)
    })
}
export function useGetGameLuckyList(object: any) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ['game/lucky_wheel/get_lucky_list'],
        queryFn: () => axios.get<TypedGame[]>(`game/lucky_wheel/list${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        })
    })
}


export type TypedLuckyPresent = {
    present_id?: string
    present_name?: string
    present_name_short?: string
    present_slug?: string
    present_thumbnail?: string
    voucher_id?: string
    wallet_code_id?: string
    wallet_number?: number
    wallet_id?: string
    reward_probability?: number
    createdAt?: string

    voucher?: TypedVoucher,
    media?: TypedMedia,
    wallet_type?: TypedWallet_type,
    wallet_code?: TypedWalletCode,
    _count?: number

}


export function useGetGameLuckyListPresent(object: any) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ['game/lucky_wheel/get_present_list'],
        queryFn: () => axios.get<TypedLuckyPresent[]>(`game/lucky_wheel/present${EndURL}`).then(response => response.data)
    })
}

export function useGetPresent() {
    return useMutation({
        mutationKey: ['game/lucky_wheel/get_present'],
        mutationFn: (present_id: string) => axios.get<TypedLuckyPresent>(`game/lucky_wheel/present/${present_id}`).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['game/lucky_wheel/get_present_list'] })
        }
    })
}
export function useCreatePresent() {
    return useMutation({
        mutationKey: ['game/lucky_wheel/create_present'],
        mutationFn: (entity: TypedLuckyPresent) => axios.post<any>(`game/lucky_wheel/present`, helpers.cleanEntity(entity)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['game/lucky_wheel/get_present_list'] })
        }
    })
}
export function useUpdatePresent() {
    return useMutation({
        mutationKey: ['game/lucky_wheel/update_present'],
        mutationFn: (entity: TypedLuckyPresent) => axios.patch<TypedLuckyPresent>(`game/lucky_wheel/present`, helpers.cleanEntity(entity)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['game/lucky_wheel/get_present_list'] })
        }

    })
}


export function useDeletePresent() {
    return useMutation({
        mutationKey: ['game/lucky_wheel/delete_present'],
        mutationFn: (present_id: string) => axios.delete<any>(`game/lucky_wheel/present/${present_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['game/lucky_wheel/get_present_list'] })
        }

    })
}