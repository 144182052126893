import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import { TypedComment } from "interface/comment.model";
import queryClient from 'queries';


type IQuery = TypedComment & IQueryParams;


/*
* List of all app
*/
export function useGetComments(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["comment/fetch_entity_list"],
        queryFn: () => axios.get<TypedComment[]>(`/comment${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}

export function useGetComment(comment_id: string) {
    return useQuery({
        queryKey: ['comment/fetch_entity'],
        queryFn: () => axios.get<TypedComment>(`/comment/${comment_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useUpdateComment() {
    return useMutation({
        mutationKey: ['comment/update_entity'],
        mutationFn: ({ comment_id, ...rest }: TypedComment) => axios.patch<TypedComment>(`/comment/${comment_id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['comment/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['comment/fetch_entity'] });
        }
    });
}


export function useCreateComment() {
    return useMutation({
        mutationKey: ['comment/create_entity'],
        mutationFn: (entity: TypedComment) => axios.post<TypedComment>(`/comment`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['comment/fetch_entity_list'] });
        }
    });
}


export function useDeleteComment() {
    return useMutation({
        mutationKey: ['comment/delete_entity'],
        mutationFn: (comment_id: string) => axios.delete<any>(`/comment/${comment_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['comment/fetch_entity_list'] });
        }

    });
}