import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

import helpers from "helpers/index";
import queryClient, { IQueryParams } from 'queries';
import { TypedWallet } from "./user_wallet.query";

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedWithdrawal_request {
    id?: string | bigint;
    user_id?: string | bigint;
    amount?: number;
    wallet_unit?: string;
    request_note?: string;
    request_status?: number;
    user_bank_information_history?: string;
    createdAt?: string | bigint;
    updatedAt?: string;
    updatedBy?: string | bigint;

    "request_to_user": {
        "user_id": string,
        "user_phonenumber": string,
        "user_email": string,
        "display_name": string,
        "user_avatar": string,
        "user_bank_information"?: {
            "id": string,
            "user_id": string,
            "bank_name": string,
            "bank_owner_display_name": string,
            "bank_owner_number_account": string,
            "bank_owner_card_number": string,
            "createdAt": string,
        },
        wallet?: TypedWallet[]
    },
    "update_to_user"?: {
        "user_id": string,
        "user_phonenumber": string,
        "user_email": string,
        "display_name": string,
        "user_avatar": string,
    }


}


type IQuery = TypedWithdrawal_request & IQueryParams;


type TypedApproveOrDenie = {
    "id": string,
    "request_status": number,
    "request_note"?: string,
}


/*
* List of all app
*/
export function useGetWithdraws(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["withdrawal_request/fetch_entity_list"],
        queryFn: () => axios.get<TypedWithdrawal_request[]>(`/withdrawal_request${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}


/** only admin can use this router */
export function useGetWithdraw(withdraw_id: string) {
    return useQuery({
        queryKey: ['withdrawal_request/fetch_entity'],
        queryFn: () => axios.get<TypedWithdrawal_request>(`/withdrawal_request/admin/${withdraw_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: true,
        enabled: true,
    });
}


export function useApprovedWithdraw() {
    return useMutation({
        mutationKey: ['withdrawal_request/approved'],
        mutationFn: (entity: TypedApproveOrDenie) => axios.patch<TypedApproveOrDenie>(`/withdrawal_request/approved`, helpers.cleanEntity(entity)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['withdrawal_request/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['withdrawal_request/fetch_entity'] });
        }
    });
}
export function useDeniedWithdraw() {
    return useMutation({
        mutationKey: ['withdrawal_request/denied'],
        mutationFn: (entity: TypedApproveOrDenie) => axios.patch<TypedApproveOrDenie>(`/withdrawal_request/denied`, helpers.cleanEntity(entity)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['withdrawal_request/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['withdrawal_request/fetch_entity'] });
        }
    });
}
