import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/

export type TypeEmailSetting = {
    id?: string,
    setting_type?: string,
    server_name?: string,
    server_address?: string,
    server_port?: string,
    server_username?: string,
    server_password?: string,
    server_secure_protocol?: string,
    server_interval?: string,
    server_checked?: string,
    server_status?: number,
    createdAt?: string,
}

type IQuery = TypeEmailSetting & IQueryParams;


/*
* List of all
*/
export function useGetEmailSettings(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["email_setting/fetch_entity_list"],
        queryFn: () => axios.get<TypeEmailSetting[]>(`/email_setting${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetEmailSetting(id: string) {
    return useQuery({
        queryKey: ['email_setting/fetch_entity'],
        queryFn: () => axios.get<TypeEmailSetting>(`/email_setting/${id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}

export function useMutateGetEmailSetting() {
    return useMutation({
        mutationKey: ['email_setting/fetch_entity'],
        mutationFn: (id: string) => axios.get<TypeEmailSetting>(`/email_setting/${id}`).then((response) => response.data),
        retry: 1,
    });
}


export function useUpdateEmailSetting() {
    return useMutation({
        mutationKey: ['email_setting/update_entity'],
        mutationFn: ({ id, ...rest }: TypeEmailSetting) => axios.patch<TypeEmailSetting>(`/email_setting/${id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['email_setting/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['email_setting/fetch_entity'] });
        }
    });
}


export function useCreateEmailSetting() {
    return useMutation({
        mutationKey: ['email_setting/create_entity'],
        mutationFn: (entity: TypeEmailSetting) => axios.post<TypeEmailSetting>(`/email_setting`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['email_setting/fetch_entity_list'] });
        }
    });
}


export function useDeleteEmailSetting() {
    return useMutation({
        mutationKey: ['email_setting/delete_entity'],
        mutationFn: (email_setting: string) => axios.delete<any>(`/email_setting/${email_setting}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['email_setting/fetch_entity_list'] });
        }

    });
}