import React from "react";
import { useParams } from "react-router-dom";
import Theme404 from '../../layout/404';
import outgoing_webhook_list from './outgoing_webhook.list';
import outgoing_webhook_view from './outgoing_webhook.view';

/**
*   Create index file for Outgoing_webhook
*/

export default function List_outgoing_webhook() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || 'list';
  let ActualPage: any;
  switch (Param) {
    case 'list':
      ActualPage = outgoing_webhook_list;
      break;
    case 'view':
      ActualPage = outgoing_webhook_view;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>
            {<ActualPage />}
        </>;
}