import React from 'react';
import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import game_list from './game.list';
import game_view_checkin from './game.view.checkin';
import game_view_lucky_wheel from './game.view.lucky_wheel';
export default function Pagidex() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = game_list;
      break;
    case 'checkin':
      ActualPage = game_view_checkin;
      break;
    case 'lucky_wheel':
      ActualPage = game_view_lucky_wheel;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>{<ActualPage />}</>;
}