import React, { useCallback, useEffect, useState } from 'react';
import { useUpdateUserVerified, TypedUser_verified, useGetSingleVerifiedViaUserID } from 'queries/user_verified.query';
import { DescriptionList, InlineGrid, Image, Link, ButtonGroup, Button, Text } from '@shopify/polaris';
import __helpers from 'helpers/index';
export default function UserVerifiedData({
  user_id
}: {
  user_id: string;
}) {
  const {
    mutateAsync: getEntity
  } = useGetSingleVerifiedViaUserID(user_id);
  const {
    mutateAsync: updateEntity
  } = useUpdateUserVerified();
  const [entity, setEntity] = useState<TypedUser_verified>(null);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  useEffect(() => {
    getEntity().then(data => {
      setEntity(data);
      setActiveButtonIndex(data?.verified_status ?? 0);
    }).catch(e => {});
  }, []);
  const handleButtonClick = useCallback(async (index: number) => {
    await updateEntity({
      id: entity?.id,
      verified_status: index
    }).catch(e => {});
    setActiveButtonIndex(index);
  }, [activeButtonIndex, entity]);
  return !__helpers.isEmpty(entity) ? <DescriptionList items={[{
    term: 'Người yêu cầu',
    description: <div>
              Email: {entity?.user?.user_email ?? '-'} / Phone: {entity?.user?.user_phonenumber ?? '-'} / Tên: {entity?.user?.display_name ?? '-'}
            </div>
  }, {
    term: 'Hình ảnh đăng tải',
    description: <>
              <InlineGrid gap="400" columns={{
        xs: 1,
        md: 3
      }}>
                <Link url={__helpers.getMediaLink(entity?.cccd_front_image)} target="_blank">
                  <Image width={'150px'} source={__helpers.getMediaLink(entity?.cccd_front_image)} alt={'ảnh CCCD mặt trước'} />
                </Link>
                <Link url={__helpers.getMediaLink(entity?.cccd_back_image)} target="_blank">
                  <Image width={'150px'} source={__helpers.getMediaLink(entity?.cccd_back_image)} alt={'ảnh CCCD mặt sau'} />
                </Link>
                <Link url={__helpers.getMediaLink(entity?.user_face_image)} target="_blank">
                  <Image width={'150px'} source={__helpers.getMediaLink(entity?.user_face_image)} alt={'ảnh người chụp'} />
                </Link>
              </InlineGrid>
              <br />
              <Text as="p">CCCD ID: {entity?.cccd_id ?? '-'}</Text>
              <br />
              <Text as="p">Ghi chú: {entity?.user_note ?? '-'}</Text>
            </>
  }, {
    term: 'Kết quả xét duyệt',
    description: <>
              <ButtonGroup variant="segmented">
                <Button pressed={activeButtonIndex === 1} onClick={() => handleButtonClick(1)}>
                  Duyệt
                </Button>
                <Button pressed={activeButtonIndex === -1} onClick={() => handleButtonClick(-1)}>
                  Từ chối duyệt
                </Button>
              </ButtonGroup>
              <br />
              {entity?.updatedBy && <>
                  Cập nhật lần cuối {__helpers.subtractTimeHistory(entity?.updatedAt)} bởi{' '}
                  {entity?.confirmer?.display_name ?? entity?.confirmer?.user_email ?? entity?.confirmer?.user_phonenumber ?? '-'}
                </>}
            </>
  }]} /> : <Text as="p">Chưa có thông tin nào.</Text>;
}