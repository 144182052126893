import { Card, Page, DataTable, Text } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useState } from 'react';
import helpers from '../../helpers';
import dateandtime from 'date-and-time';
import Pagination from 'components/pagination';
import SimpleFilter from 'components/simpleFilter';
import { ___ } from 'languages/index';
import { useGetEmailIncomings } from 'queries/email_incoming.query';
export default function Webmail_incoming() {
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const rootSearchPath = window.location.search;
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data,
    isLoading: loading
  } = useGetEmailIncomings(mainQuery);
  useEffect(() => {
    if (data) {
      setEntities(data.entities);
      setTotalItems(data.totalItems);
    }
  }, [data]);

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Email incoming', '/webmail/incoming' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const renderItem = (item: any) => {
    let {
      id,
      from_name,
      from_email,
      to_email,
      subject,
      createdAt
    } = item;
    return [`${from_name} <${from_email}>`, to_email, helpers.trimContentString(subject, 50), dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm')];
  };
  return <Page fullWidth title="Email đến" subtitle="Email tương tác đến các tài khoản IMAP của bạn" compactTitle>
      <Card padding="0">
        <SimpleFilter loading={loading} onCallback={filterCallback} options={[]} sortField={[]} />
        <DataTable sortable={[false, false, false]} defaultSortDirection="descending" initialSortColumnIndex={6} onSort={null} columnContentTypes={['text', 'text', 'text', 'text']} headings={['Từ', 'Đến', 'Subject', 'Nhận lúc']} rows={entities ? entities?.map(renderItem) : []} hideScrollIndicator hasZebraStripingOnData increasedTableDensity stickyHeader footerContent={___('Show page {display_page_number} of {total_record_number} results', {
        display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
        total_record_number: <Text as="strong">{totalItems}</Text>
      })} />
      </Card>

      <br />
      {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
    </Page>;
}