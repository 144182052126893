import { LegacyStack, Tag, Autocomplete, Icon, InlineError } from '@shopify/polaris';
import __helpers from 'helpers/index';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SearchIcon, PlusCircleIcon } from '@shopify/polaris-icons';
import { TypedProductBrand, useMutateGetProductBrands } from 'queries/brand.query';
import ProductBrandModalAddAndEdit from 'entities/brands/brand.modal';
import useDidMountEffect from './useDidMountEffect';

/** Phiên bản cải tiến tìm nhanh một product brand, bản cải tiến triệt để ... */
export default function QuickSearchProductBrand({
  current_brand_id = [],
  label = 'Nhãn hiệu',
  errorMessage = '',
  onClose,
  allowMultiple = true
}: {
  current_brand_id: any[];
  errorMessage?: string;
  label: string;
  onClose: (val: string[]) => void;
  allowMultiple?: boolean;
}) {
  /******************************** *
   * ADVANCE PRODUCT CATEGORY ...
   ******************************** */
  const initialQuery = useMemo(() => {
    return {
      brand_id: '',
      // trong trường hợp mà nó lưu sẵn rồi thì có thể để vào đây!
      limit: 10,
      query: '',
      sort: 'createdAt:desc'
    };
  }, []);
  const [productCategoryQuery, setProductCategoryQuery] = useState(initialQuery);
  const {
    mutateAsync: refetchProductBrand,
    isPending: searchingCategory,
    data: entities
  } = useMutateGetProductBrands();

  /** Lấy thêm data nếu có current_brand_id, thay vì 1, thì thêm tí nữa */
  const fetchInitialData = useCallback(async () => {
    await __helpers.sleep(1000);
    refetchProductBrand({
      limit: 5,
      sort: 'createdAt:desc'
    });
  }, []);
  useEffect(() => {
    fetchInitialData();
  }, []);
  const reduceProductCategoryRequest = useCallback(mainQuery => {
    refetchProductBrand(mainQuery);
    return mainQuery;
  }, []);
  const reduceRequestProductCategoryMemo = useMemo(() => {
    return __helpers.debounce(_value => {
      reduceProductCategoryRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    reduceRequestProductCategoryMemo(productCategoryQuery);
  }, [productCategoryQuery, reduceRequestProductCategoryMemo]);

  /** Lưu cache hết lại để hiển thị cái tên trùng với cái ID chết tiệt ... */
  const cacheProductBrandData = useRef<any[]>([]);
  const [selectedOptions3, setSelectedOptions3] = useState<string[]>([]);
  const [inputValue3, setInputValue3] = useState('');
  const [options3, setOptions3] = useState([]);

  /** Chỉ khi hiện tại khác với current thì mới gọi sang bên kia ... */
  const handleClose = useCallback(selectedOptions3 => {
    if (JSON.stringify(current_brand_id) !== JSON.stringify(selectedOptions3)) {
      onClose(selectedOptions3);
    }
  }, [current_brand_id, onClose]);
  useDidMountEffect(() => {
    handleClose(selectedOptions3);
  }, [selectedOptions3]);

  /**
   * Yêu cầu chỉ được nạp lần đầu tiên, không được nạp lại ...
   */
  let alreadyTakeCurrentCategory = useRef(false);
  useEffect(() => {
    if (current_brand_id && alreadyTakeCurrentCategory.current === false) {
      setSelectedOptions3(current_brand_id);
      alreadyTakeCurrentCategory.current = true;
      setProductCategoryQuery(old => {
        return {
          ...old,
          brand_id: current_brand_id.join(',')
        };
      });
    }
  }, [current_brand_id]);

  /** Khối này theo dõi entities và nhét vào cache, sau đó nhét vào danh sách */

  const deselectedOptions3 = useCallback(entities => {
    const results = entities.body.map((productcategory: any) => {
      cacheProductBrandData.current.push(productcategory);
      return {
        label: productcategory.brand_name,
        value: String(productcategory.brand_id)
      };
    });
    setOptions3(results);
  }, [cacheProductBrandData.current]);
  useEffect(() => {
    if (!entities && typeof entities?.body === 'undefined') return;
    deselectedOptions3(entities);
  }, [entities]);
  const updateText3 = useCallback((value: string) => {
    setInputValue3(value);
    //   if (!value) return;
    let N = {
      ...productCategoryQuery,
      ...{
        query: value,
        brand_id: '' /** unset brand_id ddi */
      }
    };
    setProductCategoryQuery(N);
  }, [productCategoryQuery]);
  const updateSelectedCallback = useCallback((selected: any[]) => {
    setSelectedOptions3(selected);
    handleClose(selected);
  }, [selectedOptions3]);
  const removeTag3 = useCallback((tag: string) => () => {
    const selected = [...selectedOptions3];
    selected.splice(selected.indexOf(tag), 1);
    setSelectedOptions3(selected);
    handleClose(selected);
  }, [selectedOptions3]);

  /**************** HIEERN THIJ MODAL TAO MOI */
  const [showQuickCreate, setShowQuickCreate] = useState(false);
  const afterCreateProductBrandCallback = useCallback((newProductBrand?: TypedProductBrand) => {
    if (newProductBrand) {
      if (allowMultiple) {
        setSelectedOptions3(old => [...old, ...[newProductBrand.brand_id]]);
      } else {
        setSelectedOptions3(old => [newProductBrand.brand_id]);
      }
      cacheProductBrandData.current.push(newProductBrand);
    }
    setShowQuickCreate(false);
  }, [allowMultiple]);

  /** Show results */
  const verticalContentMarkup3 = selectedOptions3.length > 0 ? <LegacyStack spacing="extraTight" alignment="center">
        {selectedOptions3.map(option => {
      if (!option) return null;
      let tagInCache = cacheProductBrandData.current.find(el => BigInt(el.brand_id) === BigInt(option));
      let tagLabel = tagInCache?.brand_name || option;
      return <Tag key={`option${option}`} onRemove={removeTag3(option)}>
              {tagLabel}
            </Tag>;
    })}
      </LegacyStack> : null;
  return <>
      <Autocomplete actionBefore={{
      accessibilityLabel: 'Tạo nhãn hiệu mới',
      content: 'Tạo một nhãn hiệu mới',
      ellipsis: true,
      helpText: 'Tạo nhãn hiệu mới và chỉnh sửa sau',
      icon: PlusCircleIcon,
      onAction: () => {
        setShowQuickCreate(!showQuickCreate);
      }
    }} allowMultiple={allowMultiple} loading={searchingCategory} options={options3} selected={selectedOptions3} textField={<>
            <Autocomplete.TextField onChange={updateText3} label={label} error={errorMessage ? true : false} placeholder="Chọn một nhãn hiệu" value={inputValue3} prefix={<Icon source={SearchIcon} tone="base" />} verticalContent={verticalContentMarkup3} autoComplete="off" />
            <div style={{
        marginTop: '4px'
      }}>
              <InlineError message={errorMessage} fieldID="TextFieldSelectCategory" />
            </div>
          </>} onSelect={updateSelectedCallback} // setSelectedOptions3
    listTitle="Danh sách nhãn hiệu" />

      <ProductBrandModalAddAndEdit show={showQuickCreate} onClose={v => afterCreateProductBrandCallback(v)} />
    </>;
}