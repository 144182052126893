import { DataTable, EmptyState, Layout, Page, Text, Card } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dateandtime from 'date-and-time';
import MediaUpload from './media.upload';
import MediaView from './media.view';
import SimpleFilter from 'components/simpleFilter';
import { CheckSmallIcon, LockIcon, ExportIcon } from '@shopify/polaris-icons';
import emptyIMG from 'media/images/empty.png';
import Pagination from 'components/pagination';
import helpers from '../../helpers';
import SkeletonLoading from 'components/skeletonPageLoading';
import { ___ } from 'languages/index';
import { Helmet } from 'react-helmet-async';
import { TypedMedia, useGetMedias } from 'queries/media.query';
export default function GeneralMedia() {
  const [entities, setEntities] = useState<TypedMedia[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [showEditModel, setShowEditModel] = useState<string>('');
  const [uploadModelactive, setUploadModelactive] = useState(false);
  const toggleUpdateActive = useCallback(() => setUploadModelactive(active => !active), []);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery: any = helpers.ExtractUrl(search);
  const initialQuery = {
    query: '',
    page: 1,
    limit: 20,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const {
    data: mediasData,
    isLoading: loading,
    refetch: reloadMediasList
  } = useGetMedias(mainQuery);
  useEffect(() => {
    if (!mediasData) return;
    let {
      body,
      totalItems
    } = mediasData;
    setEntities(body);
    setTotalItems(totalItems);
  }, [mediasData]);
  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery({
      ...mainQuery,
      page: numPage,
      limit: limit
    });
  }, [mainQuery]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (search !== buildURLSearch) window.history.replaceState(null, 'Media', '/media' + buildURLSearch);
    reloadMediasList();
  }, [mainQuery]);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const getMediaDetail = useCallback(async (media_id: string) => {
    setShowEditModel(media_id);
  }, []);
  const emptyData = <EmptyState heading="No media here!" image={emptyIMG}>
      <p>Oh! There is no record here! Try remove filter or add a new record!</p>
    </EmptyState>;
  const renderItem = (media: any) => {
    const {
      media_id,
      media_url,
      media_filename,
      media_description,
      media_filetype,
      media_filesize,
      media_extention,
      private_access,
      user,
      createdBy,
      createdAt,
      updatedAt
    } = media;
    return [<div className="small-icon" key={media_id} onClick={() => getMediaDetail(media_id)}>
        {private_access ? <LockIcon /> : <CheckSmallIcon />}
      </div>, <div className="clickable" key={media_id} onClick={() => getMediaDetail(media_id)}>
        <p>{helpers.trimMiddleString(media_filename)}</p>
        {media_description ? <Text as="p" tone="caution">
            {helpers.getTrimContent(media_description, 30)}
          </Text> : null}
      </div>, media_filetype, helpers.bytesToSize(media_filesize), user.display_name, dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY HH:mm')];
  };
  const PagesList = totalItems > 0 ? <>
        <DataTable sortable={[false, false, false, false, false, false, false]} defaultSortDirection="descending" initialSortColumnIndex={6} onSort={null} columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text']} headings={['', 'Filename', 'Filetype', 'Filesize', 'Author', 'createdAt']} rows={entities?.map(renderItem)} hideScrollIndicator footerContent={___('Show page {display_page_number} of {total_record_number} results', {
      display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
      total_record_number: <Text as="strong">{totalItems}</Text>
    })} />
      </> : emptyData;
  const Actual_page = <>
      <Page primaryAction={{
      content: `Upload new File`,
      onAction: toggleUpdateActive,
      icon: ExportIcon
    }} title="Media" subtitle="Quản lý và chỉnh sửa file đã tải lên.">
        <Layout>
          <Layout.Section>
            <Card padding="0">
              <SimpleFilter loading={loading} onCallback={filterCallback} options={[{
              label: 'Public',
              value: '0',
              field: 'private_access'
            }, {
              label: 'Riêng tư',
              value: '1',
              field: 'private_access'
            }]} sortField={[{
              label: 'Dung lượng',
              field: 'media_filesize'
            }]} />

              {PagesList}
            </Card>
            <br />
            {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery.limit)} activeCurrentPage={Number(mainQuery.page)} /> : null}
          </Layout.Section>
        </Layout>
      </Page>
    </>;
  return <>
      <Helmet>
        <title>Quản lý file tải lên</title>
      </Helmet>
      {uploadModelactive ? <MediaUpload onClose={() => {
      reloadMediasList();
      toggleUpdateActive();
    }} /> : null}
      <MediaView show={!!showEditModel} current_media_id={showEditModel} onClose={() => {
      setShowEditModel('');
    }} />
      {entities === null ? <SkeletonLoading /> : Actual_page}
    </>;
}