import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';


/**
*   Reducer used for front-end, with settings.model.ts
*   Interface.ts can be use in both front-end and back-end! But prefer using settings.model.ts
*/

export interface TypedSms_thread {
    sms_thread_id?: string;
    sms_thread_number?: string;
    sms_thread_read?: number;
    sms_source?: string;
    createdAt?: string;
    updatedAt?: string;
}

interface SMSElement {
    sms_id?: bigint;
    sms_content?: string;
    sms_thread_id?: bigint;
    sms_mode?: string;
    createdAt?: string;
}

export interface TypedSms {
    sms_thread_id?: string;
    sms: SMSElement[];
    sms_thread?: TypedSms_thread;
}



type IQuery = TypedSms_thread & IQueryParams;


/*
* List of all app
*/
export function useGetSMSThreads(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["sms_thread/fetch_entity_list"],
        queryFn: () => axios.get<TypedSms_thread[]>(`/sms_thread${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}

export function useGetSMSs() {
    return useMutation({
        mutationKey: ["sms/fetch_entity_list"],
        mutationFn: (object: TypedSms | any) => axios.get<TypedSms>(`/sms${helpers.buildEndUrl(object)}`).then(response => response.data),
        retry: 1,
    });
}


export function useDeleteSMSThread() {
    return useMutation({
        mutationKey: ['sms_thread/delete_entity'],
        mutationFn: (sms_thread_id: string) => axios.delete<any>(`/sms_thread/${sms_thread_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['sms_thread/fetch_entity_list'] });
        }

    });
}

export function useSendSMS() {
    return useMutation({
        mutationKey: ['sms/send_sms'],
        mutationFn: (entity: {
            sms_thread_number: string,
            sms_content: string
        }) => axios.post<any>(`/sms/`, helpers.cleanEntity(entity)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['sms_thread/fetch_entity_list'] });
        }

    });
}