import { useMutation, useQuery } from "@tanstack/react-query";
import queryClient from 'queries';
import { TypedSettingResult, TypedSettings } from "interface/settings.model";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";


/** 
 * list
 */
export function useGetSettings(object: IQueryParams) {
    let entity = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["settings/fetch_entities"],
        queryFn: () => axios.get<TypedSettingResult>(`/settings${entity}`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: true,
    });
}

export function useGetAdminSettings(object: IQueryParams) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["settings/admin/fetch_entity_list"],
        queryFn: () => axios.get<TypedSettings[]>(`/settings/admin${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });

}

export function useGetSetting() {
    return useMutation({
        mutationKey: ['settings/fetch_entity'],
        mutationFn: (setting_key: string | number) => axios.get<TypedSettings>(`/settings/${setting_key}`).then((res) => res.data),
    });
}


export function useUpdateSetting() {
    return useMutation({
        mutationKey: ['settings/update_entity'],
        mutationFn: (entity: {
            [key: string]: any
        }) => axios.post<any>(`/settings`, helpers.cleanEntity(entity)).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['settings/fetch_entities'] })
            queryClient.invalidateQueries({ queryKey: ['settings/admin/fetch_entity_list'] })
        }
    });
}

/** Admin only ... */
export function useDeleteSetting() {
    return useMutation({
        mutationKey: ['settings/admin/delete_entity'],
        mutationFn: (id: string | number) => axios.delete<any>(`/settings/admin/${id}`).then((res) => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['settings/admin/fetch_entity_list'] })
        }
    });
}


export function useSendTestEmail() {
    return useMutation({
        mutationKey: ['utils/email_testing'],
        mutationFn: () => axios.post<any>(`utils/email_testing`).then((res) => res.data),
    });
}



export function useClearAllSession() {
    return useMutation({
        mutationKey: ['utils/clear_all_session'],
        mutationFn: () => axios.get<any>(`/utils/clear_all_session`).then((res) => res.data),
    });
}
