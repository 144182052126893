import React, { memo, useEffect } from 'react';

/**
 * Array function, Replace component for forEach or map: Eg: <For each={Array[]} fallback={JSX}> Callback(element, index) </For>
 */

/* Example of use:
<For each={comments} fallback={
    <div style={{padding: '15px'}}><Text as="p">no_data</Text></div>
}>
    {commentBodyComponent}
</For>
 */

export default memo(function For({
  children,
  each,
  fallback
}: {
  children: (...args: any) => any;
  each: any[];
  fallback?: JSX.Element;
}) {
  let A = [];
  useEffect(() => {
    return () => {
      A = null;
    };
  }, []);
  if (!Array.isArray(each)) {
    // console.error('each must be an Array. Return null');
    return null;
  }
  if (typeof children !== 'function') {
    throw new Error('children must be a callback function.');
  }
  if (typeof fallback !== 'undefined' && each.length < 1) {
    return fallback;
  }
  for (let i = 0; i < each.length; i++) {
    let value = each[i];
    A.push(children.apply(null, [value, i]));
  }
  return A;
});