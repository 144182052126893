import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import { TypedBlacklistIP } from "interface/blacklist.model";
import queryClient from 'queries';


type IQuery = TypedBlacklistIP & IQueryParams;


/*
* List of all app
*/
export function useGetBlacklistIPs(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["blacklist_ip/fetch_entity_list"],
        queryFn: () => axios.get<TypedBlacklistIP[]>(`/blacklist_ip${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useCreateBlacklistIP() {
    return useMutation({
        mutationKey: ['blacklist_ip/create_entity'],
        mutationFn: (entity: TypedBlacklistIP) => axios.post<TypedBlacklistIP>(`/blacklist_ip`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['blacklist_ip/fetch_entity_list'] });
        }
    });
}


export function useDeleteBlacklistIP() {
    return useMutation({
        mutationKey: ['blacklist_ip/delete_entity'],
        mutationFn: (id: string) => axios.delete<any>(`/blacklist_ip/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['blacklist_ip/fetch_entity_list'] });
        }

    });
}

