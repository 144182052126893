import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import List from './list';
import View from './view';
export default function GiftIndex() {
  let {
    slug = 'list'
  } = useParams();
  switch (slug) {
    case 'list':
      return <List />;
    case 'new':
      return <View />;
    case 'view':
      return <View />;
    default:
      return <Theme404 />;
  }
}