import React, { useState, useEffect, useCallback } from 'react';
import { ActionList, Text, Button, Card, IndexTable, InlineStack, Link, Page, Popover, SkeletonTabs, Thumbnail, useIndexResourceState, Divider } from '@shopify/polaris';
import Capacity from 'components/Capacity';
import SkeletonLoading from 'components/skeletonPageLoading';
import helpers from 'helpers/index';
import Theme404 from 'layout/404';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteIcon, PlusIcon } from '@shopify/polaris-icons';
import Parser from 'html-react-parser';
import ProductCollectionModal from './product_collection.edit.modal';
import QuickSearchProduct from 'components/quickSearchProduct';
import { useDeleteProductCollection, useGetProductCollection, useUpdateProductCollection } from 'queries/product_collections.query';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
import { useCreateProductToCollecton, useDeleteProductToCollection, useGetProductToCollections } from 'queries/product_to_collection.query';
export default function ProductCollectionView() {
  let {
    collection_id
  } = useParams();
  const {
    data: entity,
    isLoading: loading
  } = useGetProductCollection(collection_id);
  const {
    mutateAsync: deleteEntity
  } = useDeleteProductCollection();
  const {
    mutateAsync: updateEntity
  } = useUpdateProductCollection();

  /** relationship */
  const {
    data: productToCollectionData,
    refetch: getRelationship
  } = useGetProductToCollections({
    limit: 100,
    collection_id: String(collection_id)
  });
  const {
    mutateAsync: createRelationship,
    isPending: updatingRelationship
  } = useCreateProductToCollecton();
  const {
    mutateAsync: deleteRelationship
  } = useDeleteProductToCollection();
  const [allRelationship, setAllRelationship] = useState([]);
  useEffect(() => {
    if (productToCollectionData) {
      let {
        entities
      } = productToCollectionData;
      setAllRelationship(entities);
    }
  }, [productToCollectionData]);
  const history = useNavigate();

  /** Edit modal */
  const [showEditModal, setShowEditModal] = useState(false);
  const closeEditModal = useCallback(() => {
    setShowEditModal(false);
  }, []);

  // delete modal

  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async collection_id => {
    const confirmed = await showDeleteConfirm('Xóa bộ sưu tập này?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      await deleteEntity(collection_id);
      history('/product_collection');
    }
  }, []);
  const [activeQuickUpdate, setActiveQuickUpdate] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const quickUpdateSetting = useCallback(async (status: number) => {
    setUpdateLoading(true);
    setActiveQuickUpdate(false);
    await updateEntity({
      collection_id: entity.collection_id,
      collection_status: status
    });
    setUpdateLoading(false);
  }, [entity]);
  const resourceIDResolver = products => {
    return products.product_id;
  };
  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection
  } = useIndexResourceState(allRelationship ?? [], {
    resourceIDResolver
  });
  const [bulkDeleting, setBulkDeleting] = useState(false);
  const bulkDeleteActions = useCallback(async () => {
    setBulkDeleting(true);
    await helpers.sleep(1000);
    try {
      await deleteRelationship({
        collection_id: collection_id,
        product_id: helpers.ArrayToComma(selectedResources)
      });
    } catch (e) {}
    setBulkDeleting(false);
    clearSelection();
  }, [selectedResources]);
  const DisplayListOfProduct = () => {
    if (allRelationship === null) return <SkeletonTabs />;
    if (Array.isArray(allRelationship) && allRelationship.length < 1) return <Text as="p" tone="subdued">
          Chưa có gì ... Bấm thêm sản phẩm để thêm vào bộ sưu tập.
        </Text>;
    return <Card padding="0">
        <IndexTable resourceName={{
        singular: 'product',
        plural: 'products'
      }} itemCount={allRelationship?.length} selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length} onSelectionChange={handleSelectionChange} bulkActions={[]} onSort={null} loading={updatingRelationship} promotedBulkActions={[{
        content: 'Xóa khỏi bộ sưu tập',
        onAction: () => bulkDeleteActions(),
        icon: DeleteIcon,
        disabled: bulkDeleting
      }]} sortable={[]} headings={[{
        title: ''
      }, {
        title: 'Sản phẩm'
      }, {
        title: 'Tình trạng'
      }, {
        title: 'Giá bán',
        alignment: 'end'
      }, {
        title: 'Giá gốc',
        alignment: 'end'
      }]}>
          {allRelationship.map(({
          product
        }, index) => {
          let {
            product_id,
            product_name,
            product_price,
            product_original_price,
            product_type,
            product_sku,
            product_status,
            product_sold_quantity,
            product_thumbnail_to_media
          } = product;
          return <IndexTable.Row id={product_id} key={`AU_` + product_id} selected={selectedResources.includes(product_id)} position={index}>
                <IndexTable.Cell>
                  <Thumbnail source={helpers.getMediaLink(product_thumbnail_to_media ? product_thumbnail_to_media.media_thumbnail['128x128'] : undefined)} alt={'product thumbnail: ' + product_name} />
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Link onClick={() => history('/product/edit/' + product_id)}>{product_name}</Link>
                </IndexTable.Cell>
                <IndexTable.Cell>{product_status}</IndexTable.Cell>
                <IndexTable.Cell>
                  <Text as="span" variant="bodyMd" alignment="end">
                    {helpers.formatNumber(product_price, ',')}
                  </Text>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Text as="span" variant="bodyMd" alignment="end">
                    {helpers.formatNumber(product_original_price, ',')}
                  </Text>
                </IndexTable.Cell>
              </IndexTable.Row>;
        })}
        </IndexTable>
      </Card>;
  };
  const actualPage = !helpers.isEmpty(entity) ? <Page title={entity.collection_name} subtitle={entity.collection_excerpt} titleMetadata={<InlineStack gap="200">
          {/* <Badge tone="success">{entity.app_type}</Badge> */}
          <Popover active={activeQuickUpdate} activator={<Button loading={updateLoading} variant="tertiary" disclosure={'down'} onClick={() => setActiveQuickUpdate(true)}>
                {entity.collection_status === 1 ? 'Hiển thị' : 'Không hiển thị'}
              </Button>} autofocusTarget="first-node" onClose={() => setActiveQuickUpdate(false)}>
            <ActionList actionRole="menuitem" items={[{
        content: 'Hiển thị',
        onAction: () => quickUpdateSetting(1)
      }, {
        content: 'Không hiển thị',
        onAction: () => quickUpdateSetting(0)
      }]} />
          </Popover>
        </InlineStack>} backAction={{
    content: 'Back',
    onAction: () => history('/product_collection')
  }} primaryAction={{
    content: 'Thêm sản phẩm',
    icon: PlusIcon,
    onAction: () => setShowProductModal(true)
  }} secondaryActions={[{
    content: 'Xóa bộ sưu tập',
    onAction: () => handleDelete(entity?.collection_id)
  }, {
    content: 'Chỉnh sửa',
    onAction: () => setShowEditModal(true)
  }]}>
      <div className="content-wrap">{Parser(entity?.collection_description ?? '')}</div>
      <br />
      <Divider />
      <br />
      <DisplayListOfProduct />
    </Page> : <Theme404 />;
  const [showProductModal, setShowProductModal] = useState(false);
  const onCloseProductModal = useCallback(async (product_id: bigint | null) => {
    if (product_id) {
      createRelationship({
        collection_id: String(collection_id),
        product_id: String(product_id)
      });
    }
    setShowProductModal(false);
  }, [collection_id]);
  return <Capacity current_user_can="admin" onFail={<>
          <div>You are not allowed to do anything here.</div>
        </>}>
      <ProductCollectionModal show={showEditModal} onClose={closeEditModal} current_collection_id={entity?.collection_id} />
      <QuickSearchProduct show={showProductModal} onClose={onCloseProductModal} />
      {loading ? <SkeletonLoading /> : actualPage}

      <br />
      <br />
      <br />
      <br />
      <br />
    </Capacity>;
}