import React from "react";
import { useParams } from "react-router-dom";
import Theme404 from '../../layout/404';
import webmail_list from './webmail.list';
import webmail_incoming from './webmail_incoming.list';
import webmail_view from './webmail_view';
import webmail_new from './webmail_new';

// webmail_id
// slug

export default function Webmail() {
  let useParam = {} as any;
  useParam = useParams();
  let Param = useParam.slug || 'list';
  let ActualPage: any;
  switch (Param) {
    case 'list':
      ActualPage = webmail_list;
      break;
    case 'incoming':
      ActualPage = webmail_incoming;
      break;
    case 'view':
      ActualPage = webmail_view;
      break;
    case 'new':
      ActualPage = webmail_new;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>
            {<ActualPage />}
        </>;
}