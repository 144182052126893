import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import { TypedIncoming_webhook } from "interface/incoming_webhook.interface";
import queryClient from 'queries';


type IQuery = TypedIncoming_webhook & IQueryParams;


export function useGetWebhookIncomings(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["webhook/fetch_entity_list"],
        queryFn: () => axios.get<TypedIncoming_webhook[]>(`/webhook${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}

export function useGetIncomingLog(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    const incoming_webhook_id = object.incoming_webhook_id;
    return useQuery({
        queryKey: ["webhook/webhook_incoming_log"],
        queryFn: () => axios.get<TypedIncoming_webhook[]>(`/webhook_incoming_log/${incoming_webhook_id}${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetWebhookIncoming(app_id: string) {
    return useQuery({
        queryKey: ['webhook/fetch_entity'],
        queryFn: () => axios.get<TypedIncoming_webhook>(`/webhook/${app_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}

export function useMutateGetWebhookIncoming() {
    return useMutation({
        mutationKey: ['webhook/fetch_entity'],
        mutationFn: (app_id: string) => axios.get<TypedIncoming_webhook>(`/webhook/${app_id}`).then((response) => response.data),
        retry: 1,
    });
}


export function useUpdateWebhookIncoming() {
    return useMutation({
        mutationKey: ['webhook/update_entity'],
        mutationFn: ({ api_id, ...rest }: TypedIncoming_webhook) => axios.patch<TypedIncoming_webhook>(`/app/${api_id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['webhook/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['webhook/fetch_entity'] });
        }
    });
}


export function useCreateWebhookIncoming() {
    return useMutation({
        mutationKey: ['webhook/create_entity'],
        mutationFn: (entity: TypedIncoming_webhook) => axios.post<TypedIncoming_webhook>(`/webhook`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['webhook/fetch_entity_list'] });
        }
    });
}


export function useDeleteWebhookIncoming() {
    return useMutation({
        mutationKey: ['webhook/delete_entity'],
        mutationFn: (api_id: string) => axios.delete<any>(`/webhook/${api_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['webhook/fetch_entity_list'] });
        }

    });
}


export function useResetToken() {
    return useMutation({
        mutationKey: ['webhook/reset_api_access_token'],
        mutationFn: (api_id: string) => axios.patch<any>(`/webhook/${api_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['webhook/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['webhook/fetch_entity'] });
        }

    });
}