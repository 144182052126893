import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { TypedUser } from "interface/user.model";
import queryClient from 'queries';


export type TypedOrderBounty = {
    "id": string,
    "order_id": string,
    "product_id": string,
    "product_variant_id": string,
    "receiver_id": string,
    "bounty_value": number,
    "bounty_explane": string,
    "bounty_type": string,
    "wallet_id": string,
    "createdAt": string,
    "wallet"?: {
        "wallet_type": {
            "wallet_name": string,
            "wallet_unit": string,
            "wallet_sign": string,
        }
    },

    receiver_data?: TypedUser
}

/*
* List of all app
*/
export function useGetOrder_bounty(order_id: string) {
    return useQuery({
        queryKey: ["order_bounty/fetch_entity_list"],
        queryFn: () => axios.get<TypedOrderBounty[]>(`/order_bounty/${order_id}`).then(response => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}


export function useReCaculateOrderBounty() {
    return useMutation({
        mutationKey: ['order_bounty/re_calculator'],
        mutationFn: (order_id: string) => axios.post<any>(`/order_bounty/re_calculator/${order_id}`).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['order_bounty/fetch_entity_list'] });
        }
    });
}
