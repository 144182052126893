import { Autocomplete, Card, Icon } from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import { useGetEmailTemplates } from 'queries/email_template.query';
export default function QuickSearchEmailTemplate({
  current_setting,
  onClose,
  title
}: {
  current_setting?: string | bigint;
  title?: string;
  onClose: (val: string) => void;
}) {
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [mainQuery, setMainQuery] = useState<any>({
    query: '',
    page: 1,
    limit: 5,
    sort: 'createdAt:desc'
  });

  /**
   * Assign cho ai đóa
   */
  const [userSelected, setUserSelected] = useState<string[]>([]);
  const [userQuickSearchText, setUserQuickSearchText] = useState<string>('');
  const [userListForQuickOption, setUserListForQuickOption] = useState([]);
  const {
    refetch: getEntities,
    data,
    isLoading: loading
  } = useGetEmailTemplates(mainQuery);
  useEffect(() => {
    if (data) {
      setEntities(data.entities);
      setTotalItems(data.totalItems);
    }
  }, [data]);
  useEffect(() => {
    let innerCampaign = [];
    if (Array.isArray(entities) && entities.length > 0) for (let camp of entities) {
      innerCampaign.push({
        value: camp.id,
        label: camp.template_name
      });
      if (current_setting) {
        if (camp.id === current_setting) {
          setUserQuickSearchText(camp.template_name);
        }
      }
    }
    setUserListForQuickOption(innerCampaign);
  }, [entities]);
  const onQueryToServer = useCallback((keyword: string) => {
    setMainQuery({
      query: keyword,
      sort: 'createdAt:desc',
      limit: 10
    });
    getEntities();
  }, []);
  const onChangeMainQueryCallback = useMemo(() => {
    return helpers.debounce(_value => {
      onQueryToServer?.call(this, _value);
    }, 400);
  }, []);
  const updateUserText = useCallback((value: string) => {
    setUserQuickSearchText(value);
    onChangeMainQueryCallback(value);
  }, []);
  const updateSelection = useCallback((selected: string[]) => {
    const selectedValue = selected.map(selectedItem => {
      const matchedOption = userListForQuickOption.find(option => {
        return option.value.match(selectedItem);
      });
      return matchedOption && matchedOption.label;
    });
    setUserSelected(selected);
    setUserQuickSearchText(selectedValue[0] || '');
    onClose(selected[0]);
  }, [userListForQuickOption]);
  const textFieldSelectUser = <Autocomplete.TextField onChange={updateUserText} label={title || ''} clearButton onClearButtonClick={() => {
    setUserQuickSearchText('');
    updateSelection([]);
  }} value={userQuickSearchText} prefix={<Icon source={SearchIcon} tone="base" />} placeholder="Tìm mẫu email" autoComplete="off" />;
  return <Autocomplete options={userListForQuickOption} selected={userSelected} onSelect={updateSelection} textField={textFieldSelectUser} loading={loading} />;
}