import React from 'react';
import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import List from './list';
import View from './view';
export default function ProductIndex() {
  let {
    user_id = ''
  } = useParams();
  return <>{user_id ? <View /> : <List />}</>;
}