import React from 'react';
import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import ProductList from './product.list';
import ProductEdit from './product.edit';
export default function ProductIndex() {
  let {
    slug = 'list'
  } = useParams();
  switch (slug) {
    case 'list':
      return <ProductList />;
    case 'edit':
      return <ProductEdit />;
    case 'new':
      return <ProductEdit />;
    default:
      return <Theme404 />;
  }
}