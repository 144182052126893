/**
 * User view
 */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Page, BlockStack, Tabs } from '@shopify/polaris';
import { useParams, useLocation } from 'react-router-dom';
import UserLoginHistory from './user_detail/user.login.history';
import SkeletonLoading from 'components/skeletonPageLoading';
import UserGeneralInformation from './user_detail/user.general.information';
import UserEditInformation from './user_detail/user.edit';
import ChangeUserPassword from './user_detail/users.password';
import Comments from 'components/CommentsComponent';
import UserAchievement from 'components/user_achivement';
import UserActivity from 'components/user_activity';
import ActivityTracing from 'components/activityTracing';
import { TypedUser } from 'interface/user.model';
import __ from 'languages/index';
import { Helmet } from 'react-helmet-async';
import { useGetUser } from 'queries/users.query';
export default function UserDetail() {
  let {
    users_slug
  } = useParams();
  const {
    refetch: getEntity,
    data,
    isPending: loading
  } = useGetUser(users_slug);
  let {
    hash
  } = useLocation();
  const [entity, setEntity] = useState<TypedUser>(null);
  useEffect(() => {
    if (data) setEntity(data);
  }, [data]);

  /**
   * Lấy URL ra... để query. chú ý phải load nội dung mới
   * trong useEffect
   */

  useEffect(() => {
    if (users_slug) getEntity();
  }, [users_slug]);
  const [tabselected, setTabselected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => {
    window.location.hash = '#tab-' + selectedTabIndex;
  }, []);
  const tabs = useMemo(() => [{
    id: 'tab_0',
    content: 'Hoạt động',
    panelID: 'user_activity'
  }, {
    id: 'tab_1',
    content: 'Thành tựu',
    panelID: 'achived'
  }, {
    id: 'tab_2',
    content: 'Tương tác',
    panelID: 'user_react'
  }, {
    id: 'tab_3',
    content: 'Trace',
    panelID: 'activity_trace'
  }, {
    id: 'tab_4',
    content: 'Chỉnh sửa tài khoản',
    panelID: 'edit_user_account'
  }, {
    id: 'tab_5',
    content: 'Mật khẩu',
    panelID: 'user_password'
  }, {
    id: 'tab_6',
    content: 'Lịch sử đăng nhập',
    panelID: 'user_login_history'
  }], []);
  useEffect(() => {
    if (hash) {
      let tabb = Number(String(hash || ' ').replace('#tab-', ''));
      if (tabs[tabb] === undefined) tabb = 0;
      setTabselected(tabb);
    } else {
      setTabselected(0);
    }
  }, [hash, tabs]);
  return <>
      <Helmet>
        <title>{entity?.display_name}</title>
      </Helmet>
      {loading ? <SkeletonLoading /> : <Page title={'Quay về danh sách'} backAction={{
      content: __('all_users'),
      url: '/users'
    }}>
          {entity !== null && <BlockStack gap="400">
              <UserGeneralInformation entity={entity} />
              <div className="custom_tabs" style={{
          margin: '0 -15px'
        }}>
                <Tabs tabs={tabs} selected={tabselected} onSelect={handleTabChange}></Tabs>
                <div style={{
            margin: '15px'
          }}>
                  {tabs[tabselected].panelID === 'user_activity' && <Comments objectId={''} showAddnew={false} commentType={''} title={'Mọi hoạt động gần đây'} createdBy={entity?.user_id} />}
                  {tabs[tabselected].panelID === 'achived' && <UserAchievement user_id={entity?.user_id} showList={true} />}
                  {tabs[tabselected].panelID === 'user_react' && <UserActivity objectId={users_slug} title="Tương tác" />}
                  {tabs[tabselected].panelID === 'activity_trace' && <ActivityTracing userID={users_slug} />}

                  {tabs[tabselected].panelID === 'edit_user_account' && <UserEditInformation onClose={() => {}} entity={entity} />}
                  {tabs[tabselected].panelID === 'user_password' && <ChangeUserPassword user_data={entity} />}
                  {tabs[tabselected].panelID === 'user_login_history' && <UserLoginHistory user_id={users_slug} />}
                </div>
              </div>
            </BlockStack>}

          <br />
          <br />
          <br />
          <br />
        </Page>}
    </>;
}