import { Badge, Card, DataTable, Link, Page, Tabs } from '@shopify/polaris';
import helpers from 'helpers/index';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PlusIcon } from '@shopify/polaris-icons';
import EmailSettingPopup from './email_setting_popup';
import Pagination from 'components/pagination';
import dateandtime from 'date-and-time';
import { useGetEmailSettings } from 'queries/email_setting.query';
/**
 * Test mật khẩu ứng dụng: mcjambi@gmail.com trvs mgvx yjyz mesz
 * @returns
 */

/**
 * Incoming Mail (IMAP) Server
 * https://support.google.com/mail/answer/7126229?hl=en#zippy=%2Cstep-check-that-imap-is-turned-on
 */

export default function EmailGeneralSetting() {
  const [entities, setEntities] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  const rootSearchPath = window.location.search;
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    setting_type: '',
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    data,
    refetch: getEntities
  } = useGetEmailSettings(mainQuery);
  useEffect(() => {
    if (data) {
      setEntities(data.entities);
      setTotalItems(data.totalItems);
    }
  }, [data]);

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Email setting', '/email_setting' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  const [showIncomingEmailPopup, setShowIncomingEmailPopup] = useState(false);
  const currentID = useRef(null);
  function email_status(_status: number) {
    switch (_status) {
      case 0:
        return <Badge>deactive</Badge>;
      case 1:
        return <Badge tone="success">active</Badge>;
    }
  }
  function email_check_status(_status: number) {
    switch (_status) {
      case 0:
        return <Badge>uncheck</Badge>;
      case 1:
        return <Badge tone="success">pass</Badge>;
      case 9:
        return <Badge tone="critical">fail</Badge>;
    }
  }
  const renderItem = (itemData: any) => {
    if (!itemData) return;
    const {
      id,
      server_name,
      server_address,
      server_port,
      server_username,
      server_password,
      server_interval,
      server_checked,
      server_status,
      createdAt
    } = itemData;
    return [id, <Link onClick={() => {
      currentID.current = id;
      setShowIncomingEmailPopup(true);
    }}>
        {server_name}
      </Link>, server_address, server_port, server_username, server_interval, email_check_status(server_checked), email_status(server_status), dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY')];
  };
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => {
    setSelectedTab(selectedTabIndex);
    setMainQuery({
      ...mainQuery,
      ...{
        setting_type: selectedTabIndex === 0 ? '' : selectedTabIndex === 1 ? 'imap' : 'smtp'
      }
    });
  }, [mainQuery]);
  const emailSettingPopup = useCallback(() => {
    setShowIncomingEmailPopup(false);
    currentID.current = null;
    const rootSearchPath = window.location.search;
    let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
    reduceRequestMemo(StringQuery);
  }, []);
  const tabs = [{
    id: 'all-email',
    content: 'Tất cả',
    panelID: 'all-email'
  }, {
    id: 'email_incoming',
    content: 'Incoming Email',
    badge: 'IMAP',
    panelID: 'email_incoming'
  }, {
    id: 'email_outgoing',
    content: 'Outgoing Email',
    badge: 'SMTP',
    panelID: 'email_outgoing'
  }];
  return <>
      {<EmailSettingPopup show={showIncomingEmailPopup} current_id={currentID.current} onClose={emailSettingPopup} />}

      <Page title="Cài đặt Email đến và đi" secondaryActions={[{
      content: 'Thêm nguồn',
      onAction: () => setShowIncomingEmailPopup(true),
      icon: PlusIcon
    }]}>
        <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}></Tabs>

        <Card padding="0">
          <DataTable sortable={[false, false, false, false, false, false, false, false]} defaultSortDirection="descending" initialSortColumnIndex={6} onSort={null} columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']} headings={['ID', 'Name', 'Server', 'Port', 'Username', 'Interval', 'Checked', 'Status', 'Date']} rows={entities?.map(renderItem) || []} hideScrollIndicator hasZebraStripingOnData increasedTableDensity />
        </Card>

        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
    </>;
}