import { Form, InlineGrid, InlineStack, Modal, TextField, Text, Select, Banner, Checkbox, BlockStack } from '@shopify/polaris';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import TextFieldRichText from 'components/TextFieldRichText';
import helpers from 'helpers/index';
import __ from 'languages/index';
import { TypedProductCollection, useCreateProductCollection, useDeleteProductCollection, useGetMutationProductCollection, useUpdateProductCollection } from 'queries/product_collections.query';
import React, { useState, useEffect, useCallback } from 'react';
export default function ProductCategoryModalAddAndEdit({
  show = false,
  onClose = null,
  current_collection_id = null
}: {
  show: boolean;
  current_collection_id?: string | null;
  onClose?: (collection_id?: string) => void;
}) {
  const {
    mutateAsync: getEntity,
    data: entity
  } = useGetMutationProductCollection();
  const {
    mutateAsync: updateEntity
  } = useUpdateProductCollection();
  const {
    mutate: deleteEntity
  } = useDeleteProductCollection();
  const {
    mutateAsync: createEntity
  } = useCreateProductCollection();
  const [internalError, setInternalError] = useState('');
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (show) setOpen(true);
  }, [show]);
  const onCloseCallback = useCallback((collection_id?: string) => {
    onClose?.call(this, collection_id);
    setOpen(false);
  }, []);
  useEffect(() => {
    reset();
    if (current_collection_id) getEntity(current_collection_id);
  }, [current_collection_id, show]);
  const useFields = {
    collection_name: useField<string>({
      value: entity?.collection_name || '',
      validates: [notEmpty('không được để trống trường này'), lengthLessThan(150, 'Không được dài hơn 50 ký tự.'), lengthMoreThan(2, 'Trường này yêu cầu phải dài hơn 2 ký tự.')]
    }),
    collection_slug: useField<string>({
      value: entity?.collection_slug || '',
      validates: [lengthLessThan(150, 'Không được dài hơn 50 ký tự.'), lengthMoreThan(2, 'Trường này yêu cầu phải dài hơn 2 ký tự.'), inputValue => {
        if (helpers.isUTF8(inputValue)) {
          return 'UTF-8 can not be used here.';
        }
      }]
    }),
    collection_excerpt: useField<string>({
      value: entity?.collection_excerpt || '',
      validates: [lengthLessThan(255, 'Không được dài hơn 255 ký tự.')]
    }),
    collection_description: useField<string>({
      value: entity?.collection_description || '',
      validates: [notEmpty('không được để trống trường này'), lengthLessThan(65000, 'Không được dài hơn 65000 ký tự.')]
    }),
    collection_status: useField<number>({
      value: entity?.collection_status ?? 1,
      validates: [inputVal => {
        if (!helpers.isNumeric(inputVal)) return 'Phải là một số hợp lệ.';
      }]
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      let addProductCategory: any;
      if (entity) {
        addProductCategory = await updateEntity({
          collection_id: entity?.collection_id,
          collection_name: values.collection_name,
          collection_excerpt: values.collection_excerpt,
          collection_description: values.collection_description,
          collection_status: values.collection_status,
          collection_slug: values.collection_slug
        });
      } else {
        addProductCategory = await createEntity({
          collection_name: values.collection_name,
          collection_excerpt: values.collection_excerpt,
          collection_description: values.collection_description,
          collection_status: values.collection_status,
          collection_slug: values.collection_slug
        });
      }
      if (typeof addProductCategory?.error === 'undefined') {
        reset();
        onCloseCallback(addProductCategory.collection_id);
      } else {
        setInternalError(addProductCategory?.error?.message || 'Mã lỗi: E_8976');
      }
      return {
        status: 'success'
      };
    }
  });
  useEffect(() => {
    let slugText = helpers.slugify(useFields.collection_name.value);
    if (slugText && !entity) {
      useFields.collection_slug.onChange(slugText);
    }
  }, [useFields.collection_name, entity]);
  return <Modal activator={null} open={open} onClose={() => onCloseCallback()} title={entity ? 'Cập nhật bộ sưu tập' : 'Thêm bộ sưu tập'} primaryAction={{
    content: entity ? __('update_label_button') : 'Lưu lại',
    loading: submitting,
    disabled: !dirty,
    onAction: submit
  }} secondaryActions={[{
    content: 'Đóng',
    disabled: submitting,
    onAction: () => onCloseCallback()
  }]}>
      <Modal.Section>
        {internalError && <>
            <Banner tone="critical" onDismiss={() => setInternalError(null)}>
              {internalError}
            </Banner>
            <br />
          </>}

        <Form onSubmit={submit}>
          <BlockStack gap="200">
            <TextField label="Tên bộ sưu tập" autoComplete="off" requiredIndicator {...fields.collection_name} />
            <TextField label="Đường dẫn tĩnh" autoComplete="off" {...fields.collection_slug} />

            <TextField label="Mô tả ngắn" autoComplete="off" multiline={3} maxLength={255} showCharacterCount requiredIndicator {...fields.collection_excerpt} />

            <TextFieldRichText label={'Nội dung'} initialData={entity?.collection_description} onCallback={content => fields.collection_description.onChange(content)} />

            <Checkbox label="Cho phép hiển thị" helpText="Nếu tắt, Collection này sẽ không hiển thị trên ứng dụng hoặc website." checked={useFields.collection_status.value === 1 ? true : false} onChange={e => {
            useFields.collection_status.onChange(Number(e));
          }} />
          </BlockStack>
        </Form>
      </Modal.Section>
    </Modal>;
}