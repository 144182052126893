import { Button, Checkbox, Form, FormLayout, Modal, Select, Text, TextField } from '@shopify/polaris';
import { useField, useForm } from '@shopify/react-form';
import __ from 'languages/index';
import { useCreateEmailSetting, useDeleteEmailSetting, useMutateGetEmailSetting, useUpdateEmailSetting } from 'queries/email_setting.query';
import { useCallback, useEffect } from 'react';
export default function IncomingEmailPopup({
  show,
  onClose,
  current_id
}: {
  show: boolean;
  onClose: Function;
  current_id: string | null;
}) {
  const {
    data: entity,
    mutateAsync: getEntity
  } = useMutateGetEmailSetting();
  const {
    mutateAsync: updateEntity,
    isPending: updating,
    isSuccess: updateSuccess
  } = useUpdateEmailSetting();
  const {
    mutateAsync: createEntity
  } = useCreateEmailSetting();
  const {
    mutateAsync: deleteEntity,
    isPending: deleting,
    isSuccess: deleteSuccess
  } = useDeleteEmailSetting();
  useEffect(() => {
    if (current_id) getEntity(current_id);
  }, [current_id]);
  const closeMyModal = useCallback(() => {
    onClose?.call(this, null);
    resetForm();
  }, []);
  const deleteEmailSetting = useCallback(async () => {
    await deleteEntity(current_id);
  }, [current_id]);
  const useFields = {
    setting_type: useField<string>({
      value: entity?.setting_type ?? 'imap',
      validates: []
    }),
    server_name: useField<string>({
      value: entity?.server_name ?? '',
      validates: []
    }),
    server_address: useField<string>({
      value: entity?.server_address ?? '',
      validates: [inputValue => {
        if (inputValue.length < 5) return 'Bạn phải điền địa chỉ Server hợp lệ.';
      }]
    }),
    server_port: useField<string>({
      value: entity?.server_port ?? '',
      validates: []
    }),
    server_username: useField<string>({
      value: entity?.server_username ?? '',
      validates: []
    }),
    server_password: useField<string>({
      value: entity?.server_password ?? '',
      validates: []
    }),
    server_secure_protocol: useField<string>({
      value: entity?.server_secure_protocol ?? 'none',
      validates: []
    }),
    server_interval: useField<string>({
      value: entity?.server_interval ?? '15',
      validates: []
    }),
    server_status: useField<string>({
      value: String(entity?.server_status ?? ''),
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!current_id) await createEntity({
          setting_type: values.setting_type,
          server_name: values.server_name,
          server_address: values.server_address,
          server_port: values.server_port,
          server_username: values.server_username,
          server_password: values.server_password,
          server_secure_protocol: values.server_secure_protocol,
          server_interval: values.server_interval,
          server_status: Number(values.server_status || 1)
        });else await updateEntity({
          id: current_id,
          setting_type: values.setting_type,
          server_name: values.server_name,
          server_address: values.server_address,
          server_port: values.server_port,
          server_username: values.server_username,
          server_password: values.server_password,
          server_secure_protocol: values.server_secure_protocol,
          server_interval: values.server_interval,
          server_status: Number(values.server_status || 1)
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  useEffect(() => {
    resetForm();
  }, []);
  useEffect(() => {
    if (deleteSuccess) closeMyModal();
  }, [deleteSuccess]);
  useEffect(() => {
    if (updateSuccess) {
      closeMyModal();
      resetForm();
      makeClean();
    }
  }, [updateSuccess]);
  return <Modal activator={null} open={show} onClose={closeMyModal} title="Add Email Server" primaryAction={{
    content: current_id ? 'Cập nhật' : 'Thêm nguồn',
    onAction: () => submit(),
    loading: updating
  }} secondaryActions={[{
    content: 'Đóng lại',
    onAction: closeMyModal
  }]}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <FormLayout>
            <Select label={'Loại Email Server'} options={[{
            label: 'IMAP',
            value: 'imap'
          }, {
            label: 'SMTP',
            value: 'smtp'
          }]} value={fields.setting_type.value} onChange={w => fields.setting_type.onChange(w)} />

            <TextField autoFocus label="Tên server" autoComplete="off" {...fields.server_name} />

            <FormLayout.Group condensed>
              <TextField label="Port" autoComplete="off" placeholder="995, 587, 465" {...fields.server_port} />
              <TextField label="Server Address" autoComplete="off" placeholder="imap.gmail.com" {...fields.server_address} />
            </FormLayout.Group>

            <FormLayout.Group condensed>
              <TextField label="Username" autoComplete="off" {...fields.server_username} />
              <TextField label="Password" autoComplete="off" {...fields.server_password} />
            </FormLayout.Group>
            {fields.setting_type.value === 'imap' && <Select label="Tần suất kiểm tra email mới" helpText="Càng kiểm tra email mới nhiều lần, càng tốn tài nguyên của máy chủ. Bạn có thể chọn các khoảng thời gian hợp lý dựa theo tần suất nhận email của bạn." options={[{
            label: '15 phút một lần',
            value: '15'
          }, {
            label: '1 giờ một lần',
            value: '60'
          }, {
            label: '1 ngày một lần',
            value: '1440'
          }]} {...fields.server_interval} />}

            <Select label={'Cơ chế bảo mật'} options={[{
            label: 'TSL',
            value: 'tsl'
          }, {
            label: 'SSL',
            value: 'ssl'
          }, {
            label: 'NONE',
            value: 'none'
          }]} value={fields.server_secure_protocol.value} onChange={w => fields.server_secure_protocol.onChange(w)} />

            <Checkbox label="Turn on?" checked={fields.server_status.value === '1'} onChange={w => fields.server_status.onChange(w ? '1' : '0')} />

            {current_id && <Text as="p">
                Bạn có thể{' '}
                <Button variant="plain" loading={deleting} onClick={deleteEmailSetting}>
                  xóa bỏ
                </Button>{' '}
                cài đặt này.
              </Text>}
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>;
}