import { ActionList, Badge, Button, InlineStack, Page, Popover } from '@shopify/polaris';
import Capacity from 'components/Capacity';
import SkeletonLoading from 'components/skeletonPageLoading';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppInformation from './component/app.information';
import AppModal from './app.modal';
import { useDeleteApp, useGetApp, useUpdateApp } from 'queries/app.query';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
export default function AppDetail() {
  const {
    mutateAsync: deleteEntity
  } = useDeleteApp();
  const {
    mutateAsync: updateEntity
  } = useUpdateApp();

  // const dispatch = useAppDispatch();
  const history = useNavigate();
  let {
    ID: Param
  } = useParams();
  const {
    isPending: loading,
    data: entity
  } = useGetApp(Param);

  /** Edit modal */
  const [showEditModal, setShowEditModal] = useState(false);
  const closeEditModal = useCallback(() => {
    setShowEditModal(false);
  }, []);

  // delete modal

  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async () => {
    const confirmed = await showDeleteConfirm('Xóa ứng dụng này', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      await deleteEntity(Param);
      history('/app');
    }
  }, [Param]);
  const [activeQuickUpdate, setActiveQuickUpdate] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const quickUpdateSetting = useCallback(async (status: number) => {
    setUpdateLoading(true);
    setActiveQuickUpdate(false);
    await updateEntity({
      ID: entity?.ID,
      status: status
    });
    setUpdateLoading(false);
  }, [entity]);
  const actualPage = loading ? <>Loading</> : <Page title={entity?.app_name} subtitle={entity?.app_description} titleMetadata={<InlineStack gap="200">
          <Badge tone="success">{entity?.app_type}</Badge>
          <Popover active={activeQuickUpdate} activator={<Button loading={updateLoading} variant="tertiary" disclosure={'down'} onClick={() => setActiveQuickUpdate(true)}>
                {entity?.status === 1 ? 'Active' : 'inActive'}
              </Button>} autofocusTarget="first-node" onClose={() => setActiveQuickUpdate(false)}>
            <ActionList actionRole="menuitem" items={[{
        content: 'Active',
        onAction: () => quickUpdateSetting(1)
      }, {
        content: 'inActive',
        onAction: () => quickUpdateSetting(0)
      }]} />
          </Popover>
        </InlineStack>} backAction={{
    content: 'Back',
    onAction: () => history('/app')
  }} secondaryActions={[{
    content: 'Xóa ứng dụng',
    onAction: () => handleDelete()
  }, {
    content: 'Chỉnh sửa ứng dụng',
    onAction: () => setShowEditModal(true)
  }]}>
      <AppInformation entity={entity} />
    </Page>;
  return <Capacity current_user_can="admin" onFail={<>
          <div>You are not allowed to do anything here.</div>
        </>}>
      <AppModal show={showEditModal} onClose={closeEditModal} entity={entity} />
      {loading ? <SkeletonLoading /> : actualPage}
    </Capacity>;
}