import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import { TypedCustomer } from "interface/customer.model";
import queryClient from 'queries';


type IQuery = TypedCustomer & IQueryParams;


/*
* List of all app
*/
export function useGetCustomers(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["customer/fetch_entity_list"],
        queryFn: () => axios.get<TypedCustomer[]>(`/customer${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}



export function useGetCustomer(customer_id: string) {
    return useQuery({
        queryKey: ['customer/fetch_entity'],
        queryFn: () => axios.get<TypedCustomer>(`/customer/${customer_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}
export function useMutateGetCustomer() {
    return useMutation({
        mutationKey: ['customer/fetch_entity'],
        mutationFn: (customer_id: string) => axios.get<TypedCustomer>(`/customer/${customer_id}`).then((response) => response.data),
    });
}


export function useUpdateCustomer() {
    return useMutation({
        mutationKey: ['customer/update_entity'],
        mutationFn: ({ user_id, ...rest }: TypedCustomer) => axios.patch<TypedCustomer>(`/customer/${user_id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['customer/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['customer/fetch_entity'] });
        }
    });
}


export function useCreateCustomer() {
    return useMutation({
        mutationKey: ['customer/create_entity'],
        mutationFn: (entity: TypedCustomer) => axios.post<TypedCustomer>(`/customer`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['customer/fetch_entity_list'] });
        }
    });
}


export function useGetCustomerBirthday(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ['customer_birthday/fetch_entity_list'],
        queryFn: () => axios.get<TypedCustomer>(`/customer/birthday${EndURL}`).then((response) => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}



/**
 * Assign
 */




export function useGetCustomerAssignList(customer_id: string) {
    return useQuery({
        queryKey: ['customer/assign/fetch_entity_list'],
        queryFn: () => axios.get<any>(`/customer/assign/${customer_id}`).then((response) => response.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useCreateCustomerAssignee() {
    return useMutation({
        mutationKey: ['customer/assign/create_entity'],
        mutationFn: (entity: any) => axios.post<any>(`/customer/assign`, helpers.cleanEntity(entity)).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['customer/assign/fetch_entity_list'] });
        }
    });
}



export function useDeleteCustomerAssign() {
    return useMutation({
        mutationKey: ['customer/assign/delete_entity'],
        mutationFn: (assign_id: string) => axios.delete<any>(`/customer/assign/${assign_id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['customer/assign/fetch_entity_list'] });
        }

    });
}


export function useSetCustomerPassword() {
    return useMutation({
        mutationKey: ['customer/set_new_password'],
        mutationFn: (entity: { password: string; user_id?: string }) => axios.patch<any>(`/customer/set_new_password`, helpers.cleanEntity(entity)).then((response) => response.data),
    });
}

export function useImportCustomer() {
    return useMutation({
        mutationKey: ['customer/import'],
        mutationFn: (entity: { media_id: string }) => axios.post<any>(`/customer/import`, helpers.cleanEntity(entity)).then((response) => response.data),
    });
}