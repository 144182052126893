import { Form, InlineGrid, Modal, TextField, Text, Banner, Checkbox, BlockStack } from '@shopify/polaris';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import QuickUploadImage from 'components/oneclick-upload-image';
import __helpers from 'helpers/index';
import helpers from 'helpers/index';
import __ from 'languages/index';
import { TypedUser_group, useCreateUser_group, useUpdateUser_group } from 'queries/user_group.query';
import React, { useState, useEffect, useCallback } from 'react';
export default function UserGroupModalAddAndEdit({
  show = false,
  onClose = null,
  entity = null
}: {
  show: boolean;
  entity?: TypedUser_group;
  onClose?: (current_user_group_id: bigint | null) => void;
}) {
  const [internalError, setInternalError] = useState('');
  const [open, setOpen] = useState(false);
  const {
    mutateAsync: updateEntity,
    isSuccess: updateSuccess,
    data: afterUpdateData
  } = useUpdateUser_group();
  const {
    mutateAsync: createEntity,
    isSuccess: createSuccess,
    data: afterCreateData
  } = useCreateUser_group();
  useEffect(() => {
    if (show) setOpen(true);
  }, [show]);
  const onCloseCallback = useCallback((user_group_id?: bigint) => {
    onClose(user_group_id);
    setOpen(false);
  }, []);
  useEffect(() => {
    if (createSuccess) {
      onCloseCallback(afterCreateData?.user_group_id);
    }
    if (updateSuccess) {
      onCloseCallback(afterUpdateData?.user_group_id);
    }
  }, [updateSuccess, createSuccess, afterCreateData, afterUpdateData, onCloseCallback]);
  useEffect(() => {
    reset();
  }, []);
  const useFields = {
    user_group_thumbnail: useField<string>({
      value: String(entity?.user_group_thumbnail || ''),
      validates: []
    }),
    user_group_thumbnail_cache: useField<string>({
      value: entity?.user_group_thumbnail_to_media?.media_thumbnail ? entity?.user_group_thumbnail_to_media?.media_thumbnail['128x128'] : '',
      validates: []
    }),
    user_group_name: useField<string>({
      value: entity?.user_group_name || '',
      validates: [notEmpty('không được để trống trường này'), lengthLessThan(150, 'Không được dài hơn 150 ký tự.'), lengthMoreThan(2, 'Trường này yêu cầu phải dài hơn 2 ký tự.')]
    }),
    user_group_slug: useField<string>({
      value: entity?.user_group_slug || '',
      validates: [lengthLessThan(150, 'Không được dài hơn 150 ký tự.'), lengthMoreThan(2, 'Trường này yêu cầu phải dài hơn 2 ký tự.'), inputValue => {
        if (helpers.isUTF8(inputValue)) {
          return 'UTF-8 can not be used here.';
        }
      }]
    }),
    user_group_description: useField<string>({
      value: entity?.user_group_description || '',
      validates: [lengthLessThan(1000, 'Không được dài hơn 1000 ký tự.')]
    }),
    user_group_status: useField<number>({
      value: entity?.user_group_status,
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        let userGroup: any = null;
        if (entity) {
          userGroup = await updateEntity({
            user_group_id: entity?.user_group_id,
            user_group_name: values.user_group_name,
            user_group_description: values.user_group_description,
            user_group_thumbnail: values.user_group_thumbnail || undefined,
            user_group_status: values.user_group_status,
            user_group_slug: values.user_group_slug
          });
        } else {
          userGroup = await createEntity({
            user_group_name: values.user_group_name,
            user_group_description: values.user_group_description,
            user_group_thumbnail: values.user_group_thumbnail || undefined,
            user_group_status: values.user_group_status,
            user_group_slug: values.user_group_slug
          });
        }
        let {
          data
        } = await userGroup;
        reset();
        onCloseCallback(data.user_group_id);
      } catch (e) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  const [uploadError, setUploadError] = useState(null);
  useEffect(() => {
    let slugText = helpers.slugify(useFields.user_group_name.value);
    if (slugText && !entity) {
      useFields.user_group_slug.onChange(slugText);
    }
  }, [useFields.user_group_name, entity, useFields.user_group_slug]);
  return <Modal activator={null} open={open} onClose={() => onCloseCallback()} title={entity ? 'Cập nhật nhóm người dùng' : 'Thêm nhóm người dùng'} primaryAction={{
    content: entity ? __('update_label_button') : 'Lưu lại',
    loading: submitting,
    disabled: !dirty,
    onAction: submit
  }} secondaryActions={[{
    content: 'Đóng',
    disabled: submitting,
    onAction: () => onCloseCallback()
  }]}>
      <Modal.Section>
        {internalError && <>
            <Banner tone="critical" onDismiss={() => setInternalError(null)}>
              {internalError}
            </Banner>
            <br />
          </>}

        <Form onSubmit={submit}>
          <BlockStack gap="200">
            <InlineGrid columns={['oneThird', 'twoThirds']} gap={'400'}>
              <div>
                <QuickUploadImage width={150} height={150} onSuccess={e => {
                useFields.user_group_thumbnail.onChange(e.media_id);
                useFields.user_group_thumbnail_cache.onChange(e.media_url);
              }} onError={e => setUploadError(e)} title="" placeholder={__helpers.getMediaLink(useFields.user_group_thumbnail_cache.value, 'https://placehold.co/100x100')} />
                <Text as="p" tone="critical">
                  {uploadError}
                </Text>
              </div>
              <BlockStack gap="500">
                <TextField label="Tên nhóm" maxLength={150} showCharacterCount autoComplete="off" requiredIndicator {...fields.user_group_name} />
                <TextField label="Đường dẫn tĩnh" maxLength={150} showCharacterCount autoComplete="off" {...fields.user_group_slug} />
              </BlockStack>
            </InlineGrid>

            <TextField label="Mô tả" autoComplete="off" multiline={3} maxLength={1000} showCharacterCount requiredIndicator {...fields.user_group_description} />

            <Checkbox label="Cho phép hiển thị" helpText="Nếu tắt, Nhóm này sẽ không hiển thị trên ứng dụng hoặc website." checked={useFields.user_group_status.value === 1} onChange={e => {
            useFields.user_group_status.onChange(Number(e));
          }} />
          </BlockStack>
        </Form>
      </Modal.Section>
    </Modal>;
}