import { forwardRef, useState } from 'react';
const StarRatingComponent = forwardRef(({
  title = 'Cho điểm'
}: {
  title: string;
}, ref: any) => {
  const [point, setPoint] = useState(5);
  ref.current = point;
  const maxPoint = [5, 4, 3, 2, 1];
  return <div className="rate_wrap">
      <p>{title}</p>
      <div className="rate" key={Math.random()}>
        {maxPoint.map(p => {
        return <>
              <input type="radio" checked={p === point} id={`star${p}`} name="rate" value={p} onClick={() => setPoint(p)} onChange={e => {}} />
              <label htmlFor={`star${p}`} title="text">
                {`${p} stars`}
              </label>
            </>;
      })}
      </div>
    </div>;
});
export default StarRatingComponent;