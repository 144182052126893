import helpers from 'helpers/index';

/**
 * Check if filter is true
 * @param range_filter
 * @returns
 */
export function isRangeNumber(range_filter: string): boolean {
  return String(range_filter || ' ').match(/(gte|gt|lt|lte):[0-9]+/g) ? true : false;
}

/**
 *
 * @param range_filter query string, eg: field_name=gt:23&field_name=lt:99999999999999
 * @returns
 */
function extractRangeNumber(range_filter: string): string {
  try {
    let q = String(range_filter || ' ').split(':');
    let cmpare = q[0];
    let cmpare_value = q[1];
    if (['gt', 'gte', 'lt', 'lte'].indexOf(cmpare) < 0) {
      return;
    }
    if (!helpers.isNumeric(cmpare_value)) return;
    if (!helpers.isBigInt(cmpare_value)) return;
    return cmpare_value;
  } catch (e) {
    return null;
  }
}
export function formatDateRange(createdAt: string) {
  let d = [];
  let dateRangeSource = helpers.commaToArray(createdAt);
  if (isRangeNumber(dateRangeSource[0])) d.push(extractRangeNumber(dateRangeSource[0]));
  if (isRangeNumber(dateRangeSource[1])) d.push(extractRangeNumber(dateRangeSource[1]));
  return d;
}