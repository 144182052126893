import { Form, FormLayout, LegacyStack, Modal, RadioButton, Select, TextContainer, TextField, Text } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { lengthLessThan, lengthMoreThan, useField, useForm } from '@shopify/react-form';
import { useNotification } from 'context/NotificationContext';
import { useCreateOutgoingWebhook, useMutateGetOutgoingWebhook, useUpdateOutgoingWebhook } from 'queries/outgoing_webhook.query';
import __ from 'languages/index';
export default function Edit_outgoing_webhook({
  onClose,
  show
}: {
  onClose: () => void;
  show: boolean;
}) {
  const {
    addNotification
  } = useNotification();
  const {
    mutate: getEntity,
    data: entity
  } = useMutateGetOutgoingWebhook();
  const {
    mutateAsync: updateEntity,
    isPending: updating,
    isSuccess: updateSuccess
  } = useUpdateOutgoingWebhook();
  const {
    mutateAsync: createEntity,
    isSuccess: createSuccessfully
  } = useCreateOutgoingWebhook();

  /**
   * Button value
   */

  const [webhookActive, setWebhookActive] = useState(1);
  const [optionButtonValue, setOptionButtonValue] = useState(true);
  const handleRadioButtonChange = useCallback((_checked, newValue) => {
    setOptionButtonValue(newValue === 'enabled');
  }, []);

  /**
   * Option method
   */

  const [webhook_method, setWebhook_method] = useState('post');
  const method = [{
    label: 'get',
    value: 'get'
  }, {
    label: 'post',
    value: 'post'
  }, {
    label: 'patch',
    value: 'patch'
  }, {
    label: 'delete',
    value: 'delete'
  }];
  const handleSelectChange = useCallback(value => setWebhook_method(value), []);
  let {
    outgoing_webhook_slug
  } = useParams();
  useEffect(() => {
    if (outgoing_webhook_slug) {
      getEntity(outgoing_webhook_slug);
    }
  }, [outgoing_webhook_slug]);
  function takeEventsFromEntity(entity: any) {
    let allEvent = [];
    if (typeof entity.outgoing_webhook_action !== 'undefined') for (let element of entity.outgoing_webhook_action) {
      allEvent.push(element.action_key);
    }
    return allEvent.join(', ');
  }
  useEffect(() => {
    if (entity && entity?.webhook_method) {
      setWebhook_method(entity?.webhook_method);
      setWebhookActive(entity?.webhook_active);
      if (entity?.webhook_active === 0) {
        setOptionButtonValue(false);
      } else {
        setOptionButtonValue(true);
      }
    }
  }, [entity]);
  useEffect(() => {
    if (optionButtonValue === false) {
      setWebhookActive(0);
    } else {
      setWebhookActive(1);
    }
  }, [optionButtonValue]);
  useEffect(() => {
    if (updateSuccess) {
      onClose();
      Userreset();
      addNotification('info', outgoing_webhook_slug ? 'This webhook has been updated!' : 'This webhook has been created!');
    }
  }, [updateSuccess]);
  useEffect(() => {
    if (createSuccessfully) {
      onClose();
      Userreset();
      addNotification('info', outgoing_webhook_slug ? 'This webhook has been updated!' : 'This webhook has been created!');
    }
  }, [createSuccessfully]);
  const useFields = {
    webhook_title: useField<string>({
      value: entity?.webhook_title ?? '',
      validates: [lengthLessThan(200, 'No more than 200 characters.'), lengthMoreThan(2, 'No shorter than 2 characters.'), inputValue => {
        if (inputValue.length < 2) {
          return 'Your title is too short, or it is empty.';
        }
      }]
    }),
    webhook_description: useField<string>({
      value: entity?.webhook_description ?? '',
      validates: [lengthLessThan(250, 'No more than 250 characters.'), lengthMoreThan(2, 'No shorter than 2 characters.')]
    }),
    webhook_url: useField<string>({
      value: entity?.webhook_url ? decodeURIComponent(entity?.webhook_url) : '',
      validates: [lengthLessThan(1000, 'Your content is too long.'), lengthMoreThan(2, 'Your content is too short.')]
    }),
    webhook_events: useField<string>({
      value: entity?.webhook_outgoing_action ? takeEventsFromEntity(entity) : '',
      validates: [lengthLessThan(1000, 'Your content is too long.'), lengthMoreThan(2, 'Your content is too short.')]
    })
  };
  const {
    fields,
    submit,
    dirty,
    reset: Userreset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!entity) {
          // create new
          await createEntity({
            webhook_title: values.webhook_title,
            webhook_description: values.webhook_description,
            webhook_url: values.webhook_url,
            webhook_method: webhook_method,
            webhook_events: values.webhook_events,
            webhook_active: webhookActive
          });
        } else {
          await updateEntity({
            webhook_id: entity.webhook_id,
            webhook_title: values.webhook_title,
            webhook_description: values.webhook_description,
            webhook_url: values.webhook_url,
            webhook_method: webhook_method,
            webhook_events: values.webhook_events,
            webhook_active: webhookActive
          });
        }
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  return <>
      <Modal open={show} onClose={() => onClose()} title={outgoing_webhook_slug ? 'Update Outgoing webhook' : 'Create new Outgoing webhook'} primaryAction={{
      content: outgoing_webhook_slug ? 'Update' : 'Create',
      disabled: !dirty,
      loading: updating,
      onAction: () => {
        submit();
      }
    }} secondaryActions={[{
      content: 'Close',
      onAction: () => onClose()
    }]}>
        <Modal.Section>
          <Form onSubmit={submit}>
            <FormLayout>
              <TextField autoFocus autoComplete="off" requiredIndicator label="Webhook Title" {...fields.webhook_title} />
              <TextField autoComplete="off" maxLength={1000} requiredIndicator label="Webhook Url" {...fields.webhook_url} />
              <Select label="Webhook Method" options={method} requiredIndicator onChange={handleSelectChange} value={webhook_method} />
              <TextField autoComplete="off" maxLength={250} label="Webhook Description" {...fields.webhook_description} />
              <TextField autoComplete="off" maxLength={1000} label="Webhook Events" helpText="To get all webhook events that you can use, call endpoint /utils/list_hook with admin capacity. You can use multi hook separate by comma (,)" {...fields.webhook_events} />

              <TextContainer>
                <Text as="p" tone="subdued">
                  Webhook status:
                </Text>
              </TextContainer>

              <LegacyStack vertical>
                <RadioButton label="OFF" checked={optionButtonValue === false} id="disabled" name="accounts" value="0" onChange={handleRadioButtonChange} />
                <RadioButton label="ON" id="enabled" name="accounts" value="1" checked={optionButtonValue === true} onChange={handleRadioButtonChange} />
              </LegacyStack>
            </FormLayout>
          </Form>
        </Modal.Section>
      </Modal>
    </>;
}