import { compiledBlock as _compiledBlock } from "million/react";
const ActivityPoint_1 = _compiledBlock(_props => <slot>
      {_props.v0}
      {_props.v1}

      {_props.v2}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </slot>, {
  name: "ActivityPoint_1",
  portals: ["v0", "v1", "v2"]
});
const ActivityPoint = function ActivityPoint() {
  const {
    data: entities,
    isLoading: loadingSetting,
    isSuccess
  } = useGetSettings({
    fields: `setting_name,setting_value`,
    setting_name: `order_complete_point,referrer_added_point,referrer_removed_point,review_added_point`
  });
  const {
    mutateAsync: updateEntity,
    error,
    isSuccess: updateSuccess,
    isPending: updating
  } = useUpdateSetting();
  const useFields = {
    order_complete_point: useField<number>({
      value: entities?.order_complete_point ?? 0,
      validates: []
    }),
    referrer_added_point: useField<number>({
      value: entities?.referrer_added_point ?? 0,
      validates: []
    }),
    referrer_removed_point: useField<number>({
      value: entities?.referrer_removed_point ?? 0,
      validates: []
    }),
    review_added_point: useField<number>({
      value: entities?.review_added_point ?? 0,
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: Userreset
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await updateEntity({
          order_complete_point: values.order_complete_point,
          referrer_added_point: values.referrer_added_point,
          referrer_removed_point: values.referrer_removed_point,
          review_added_point: values.review_added_point
        });
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  return /*@million jsx-skip*/<ActivityPoint_1 v0={<Helmet>
        <title>Cài đặt tương tác và điểm</title>
      </Helmet>} v1={<Page narrowWidth title="Tương tác và điểm cho khách hàng" subtitle="Mỗi hành động sau đây của khách hàng tương ứng với một số điểm nhất định">
        <Form onSubmit={submit}>
          <BlockStack gap="400">
            <Card>
              <Text as="h3" variant="headingMd">
                Đơn hàng hoàn thành (Dành cho người mua)
              </Text>
              <Text as="p" variant="bodyXs" tone="subdued">
                Đơn hàng được đánh dấu là hoàn thành sau khi đơn hàng được giao hàng thành công và đối soát.
              </Text>
              <br />
              <TextField label="Điểm" autoComplete="off" requiredIndicator value={__helpers.formatNumber(useFields.order_complete_point.value)} onChange={e => useFields.order_complete_point.onChange(__helpers.parseNumeric(e))} />
            </Card>

            <Card>
              <Text as="h3" variant="headingMd">
                Mời thêm một khách hàng (referrer)
              </Text>
              <Text as="p" variant="bodyXs" tone="subdued">
                Khi mời được một thành viên vào hệ thống, bạn sẽ nhận được điểm.
              </Text>
              <br />
              <TextField label="Điểm" autoComplete="off" requiredIndicator value={__helpers.formatNumber(useFields.referrer_added_point.value)} onChange={e => useFields.referrer_added_point.onChange(__helpers.parseNumeric(e))} />
              <br />
              <Text as="h3" variant="headingMd">
                Khi khách mời bị deactive (khóa tài khoản)
              </Text>
              <Text as="p" variant="bodyXs" tone="subdued">
                Khi mời được một thành viên vào hệ thống, sau đó người này bị deactive (khóa tài khoản), bạn sẽ bị trừ điểm.
              </Text>
              <br />
              <TextField label="Điểm trừ" autoComplete="off" requiredIndicator value={__helpers.formatNumber(useFields.referrer_removed_point.value)} onChange={e => useFields.referrer_removed_point.onChange(__helpers.parseNumeric(e))} />
            </Card>

            <Card>
              <Text as="h3" variant="headingMd">
                Thêm một review
              </Text>
              <Text as="p" variant="bodyXs" tone="subdued">
                Khi khách hàng review và review đó được duyệt.
              </Text>
              <br />
              <TextField label="Điểm" autoComplete="off" requiredIndicator value={__helpers.formatNumber(useFields.review_added_point.value)} onChange={e => useFields.review_added_point.onChange(__helpers.parseNumeric(e))} />
            </Card>
          </BlockStack>
        </Form>
      </Page>} v2={dirty && <ContextualSaveBar message="Thay đổi của bạn chưa được lưu." saveAction={{
    onAction: submit,
    loading: updating,
    disabled: !dirty
  }} discardAction={{
    content: 'Hoàn tác',
    onAction: Userreset
  }} />} />;
};
import { block as _block } from "million/react";
import { BlockStack, Card, Form, Text, Page, TextField, ContextualSaveBar } from '@shopify/polaris';
import { useField, useForm } from '@shopify/react-form';
import __helpers from 'helpers/index';
import __ from 'languages/index';
import { useGetSettings, useUpdateSetting } from 'queries/setting.query';
import { Helmet } from 'react-helmet-async';
export default ActivityPoint;