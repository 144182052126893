import __ from 'languages/index';
import { Badge } from '@shopify/polaris';
import orderStatusJson from 'config/order.status.json';
import orderDeliverType from './order.delivery.json';
import { WatchIcon } from '@shopify/polaris-icons';
export function getBadgeOfPaymentMethod(_TTTT: string | number) {
  switch (_TTTT) {
    case 0:
      return <Badge progress="incomplete" tone="attention">
          Chưa thanh toán
        </Badge>;
    case 1:
      return <Badge progress="partiallyComplete" tone="warning">
          Thanh toán một phần
        </Badge>;
    case 2:
      return <Badge tone="success" progress="complete">
          Đã thanh toán
        </Badge>;
    case 3:
      return <Badge tone="critical" progress="incomplete">
          Chờ hoàn tiền
        </Badge>;
    case 4:
      return <Badge tone="critical" progress="incomplete">
          Đã hoàn tiền
        </Badge>;
    default:
      return <Badge></Badge>;
  }
}
export function getOrderStatusBadge(order_status: number): any {
  let name = orderStatusJson[order_status] || '';
  switch (order_status) {
    case 10:
    case 11:
    case 12:
    case 13:
      return <Badge tone="info" progress="partiallyComplete">
          {name}
        </Badge>;
    case 14:
      return <Badge tone="success" progress="complete">
          {name}
        </Badge>;
    case 15:
      return <Badge tone="attention" progress="complete">
          {name}
        </Badge>;
    case 16:
    case 19:
    case 20:
      return <Badge tone="critical">{name}</Badge>;
    default:
      return <Badge icon={WatchIcon}>Chờ xác nhận</Badge>;
  }
}
export function getOrderDeliveryMethod(_method: string | number) {
  return __(orderDeliverType[_method]) || <Badge></Badge>;
}