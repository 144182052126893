import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Order_fee
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedOrder_fee {
    order_fee_id?: string;
    order_id?: string;
    order_fee_name?: string;
    order_fee_value?: number;
    createdAt?: string;
    createdBy?: string;
}



export function useGetOrder_fees(order_id: string) {
    return useQuery({
        queryKey: ['order_fee/fetch_entity_list'],
        queryFn: () => axios.get<any>(`/order_fee/${order_id}`).then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
        // enabled: false,
    });
}


export function useGetOrderFeeList() {
    return useQuery({
        queryKey: ['order_fee/fetch_order_fee_list'],
        queryFn: () => axios.get<any>(`/order_fee/feename`).then(res => res.data),
    });
}


export function useCreateOrder_fee() {
    return useMutation({
        mutationKey: ['order_fee/create_entity'],
        mutationFn: (entity: TypedOrder_fee) => axios.post<TypedOrder_fee>(`/order_fee`, helpers.cleanEntity(entity)).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['order_fee/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['orders/fetch_entity'] });
        }
    });
}


export function useDeleteOrder_fee() {
    return useMutation({
        mutationKey: ['order_fee/delete_entity'],
        mutationFn: (id: string) => axios.delete<any>(`/order_fee/${id}`).then(res => res.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['order_fee/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['orders/fetch_entity'] });
        }
    });
}