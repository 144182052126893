import { Box, Card, Divider, InlineGrid, LegacyCard, Link, Page, Text, TextField, BlockStack, useBreakpoints } from '@shopify/polaris';
import { Helmet } from 'react-helmet-async';
export default function Embed() {
  const {
    smUp
  } = useBreakpoints();
  return <>
      <Helmet>
        <title>Mã nhúng và kết nối</title>
      </Helmet>
      <Page title="Mã nhúng và kết nối">
        <Helmet>
          <title>Cài đặt mã nhúng và kết nối</title>
        </Helmet>

        <BlockStack gap={{
        xs: '800',
        sm: '400'
      }}>
          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Mã nhúng cho web
                </Text>
                <Text as="p" variant="bodyMd">
                  Mã Passport.js là mã quan trọng, giúp các chức năng bên dưới hoạt động trơn tru và bảo vệ bạn khỏi Spambot tấn công.
                </Text>
              </BlockStack>
            </Box>
            <LegacyCard sectioned>
              <Text as="p" variant="bodySm" tone="subdued">
                Nhúng mã sau đây lên landing page của bạn, trước thẻ <code>&lt;/head&gt;</code> và để ở mọi trang.
              </Text>
              <TextField label=" " value={`<script crossorigin="anonymous" type="application/javascript" src="${process.env.REACT_APP_PUBLIC_URL}/passport.js"></script>`} onChange={() => {}} multiline={2} autoComplete="off" />
              <br />
              <Text as="p" variant="bodySm" tone="subdued">
                Nhúng mã sau đây lên landing page của bạn, trước thẻ <code>&lt;/body&gt;</code> và để ở trang bạn muốn tracking người dùng.
              </Text>
              <TextField label=" " value={`<script>
        var backend_url = '${process.env.REACT_APP_BACKEND_URL}/activity/web_activity'; 
        var requestee_option = {};
    </script>
    <script crossorigin="anonymous" defer="defer" type="application/javascript" src="${process.env.REACT_APP_PUBLIC_URL}/requestee.js"></script>`} onChange={() => {}} multiline={2} autoComplete="off" />
              <br />
              <Text as="p" variant="bodySm" tone="subdued">
                Nếu bạn muốn định nghĩa trang đích để tracking tốt hơn, bạn có thể đặt dữ liệu vào biến <br />
                <code>requestee_option.activity_target = 'YOUR_DEFINITION'; // định nghĩa tên trang đích </code> <br />
                <code>requestee_option.activity_referrer = 'YOUR_DEFINITION'; // định nghĩa trang giới thiệu </code> <br />
                Khi đó bạn có thể dễ dàng định nghĩa và lọc được người dùng truy cập một cách dễ dàng.
              </Text>
            </LegacyCard>
          </InlineGrid>

          {smUp ? <Divider /> : null}

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Contact form
                </Text>
                <Text as="p" variant="bodyMd">
                  Form liên hệ để kết nối giữa khách hàng và doanh nghiệp thông qua website hoặc app.
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap={'200'}>
                <Text as="h3" variant="headingSm">
                  Dành cho website
                </Text>
                <Text as="p">Bạn thực hiện truy vấn như sau:</Text>
                <pre>
                  {`URL: ${process.env.REACT_APP_BACKEND_URL}/contactform
METHOD: POST
BODY: {
    contactform_title: String,
    contactform_content: String,
    contactform_email: String,
    // hoặc
    contactform_name: String
}`}
                </pre>
                <Text as="p">
                  {' '}
                  Để có thể lưu một Contact Form trên website, bạn cần có mã nhúng ở phần 1. Mã nhúng sẽ giúp định danh khách hàng kể cả khi họ không
                  đăng nhập.{' '}
                </Text>

                <Text as="h3" variant="headingSm">
                  Dành cho mobile app
                </Text>
                <Text as="p">
                  Bạn thực hiện truy vấn như trên phiên bản web, tuy nhiên yêu cầu người dùng đăng nhập vì trên mobile app không sử dụng được thư viện
                  Passport.js
                </Text>
              </BlockStack>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}

          <InlineGrid columns={{
          xs: '1fr',
          md: '2fr 5fr'
        }} gap="400">
            <Box as="section" paddingInlineStart={{
            xs: '400',
            sm: '0'
          }} paddingInlineEnd={{
            xs: '400',
            sm: '0'
          }}>
              <BlockStack gap="400">
                <Text as="h3" variant="headingMd">
                  Kết nối
                </Text>
                <Text as="p" variant="bodyMd">
                  Đây là các cổng kết nối từ các phần mềm ngoài vào trong CRM
                </Text>
              </BlockStack>
            </Box>
            <Card roundedAbove="sm">
              <BlockStack gap={'200'}>
                <Text as="h3" variant="headingSm">
                  Query danh bạ cho tổng đài
                </Text>
                <Text as="p">
                  Để kết nối tổng đài cho phần danh bạ, vui lòng xem trong phần <Link url={'/incoming_webhook'}>incoming webhook</Link>. Bạn tìm tới
                  webhook có tên <i>Contact Query</i>.
                </Text>
              </BlockStack>

              <br />
              <br />

              <BlockStack gap={'200'}>
                <Text as="h3" variant="headingSm">
                  Dành cho SMS Gate
                </Text>
                <Text as="p">
                  Để nhận SMS từ SMS-Box, vui lòng xem trong phần <Link url={'/incoming_webhook'}>incoming webhook</Link>. Bạn tìm tới webhook có tên{' '}
                  <i>SMS Gate</i>.
                </Text>
              </BlockStack>

              <br />
              <br />

              <BlockStack gap={'200'}>
                <Text as="h3" variant="headingSm">
                  Dành cho Cổng thanh toán
                </Text>
                <Text as="p">
                  Cổng thanh toán có thể thực hiện request tới endpoint này khi thanh toán thành công. Để nhận thông tin thanh toán, vui lòng xem
                  trong phần <Link url={'/incoming_webhook'}>incoming webhook</Link>. Bạn tìm tới webhook có tên <i>Payment Gate</i>.
                </Text>
              </BlockStack>
            </Card>
          </InlineGrid>

          {smUp ? <Divider /> : null}
        </BlockStack>
        <br />
        <br />
        <br />
        <br />
      </Page>
    </>;
}