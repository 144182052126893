import { useParams } from 'react-router-dom';
import Theme404 from 'layout/404';
import achievement_list from './achievement.list';
import achievement_view from './achievement.view';

/**
 *   Create index file for achievement
 */

export default function Achievement() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = achievement_list;
      break;
    case 'view':
      ActualPage = achievement_view;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <ActualPage />;
}