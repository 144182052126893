import React, { useEffect } from 'react';

/** Make browser download a file using text response from server */
export default function triggerDownload(data: any, filename = 'export.csv', type = 'text/csv') {
  // console.log(response)
  // Wrap this response into a temporary page that will trigger the download
  const blob = new Blob([data], {
    type: type
  });
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.download = filename;
  tempLink.setAttribute('target', '_blank');
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(blobURL);
  return;
}