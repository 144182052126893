import { Button, DataTable, EmptyState, Layout, Page, Text, Box, DescriptionList, Badge, Card, BlockStack, Divider } from '@shopify/polaris';
import Parser from 'html-react-parser';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import dateandtime from 'date-and-time';
import Pagination from 'components/pagination';
import emptyIMG from '../../media/images/empty.png';
import OutgoingWebhookModal from './outgoing_webhook.create';
import { EditIcon, DeleteIcon } from '@shopify/polaris-icons';
import helpers from 'helpers';
import SkeletonLoading from 'components/skeletonPageLoading';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
import { useDeleteOutgoingWebhook, useGetOutgoingLog, useGetOutgoingWebhook } from 'queries/outgoing_webhook.query';
export default function Edit_outgoing_webhook() {
  let {
    outgoing_webhook_slug,
    search
  } = useParams();
  let StringQuery: any = helpers.ExtractUrl(search) || false;
  const [mainQuery, setMainQuery] = useState({
    ...{
      query: '',
      page: 1,
      limit: 20,
      webhook_outgoing_id: outgoing_webhook_slug,
      sort: 'createdAt:desc'
    },
    ...StringQuery
  });
  const {
    data: entity
  } = useGetOutgoingWebhook(outgoing_webhook_slug);
  const {
    mutateAsync: deleteEntity,
    isPending: loading
  } = useDeleteOutgoingWebhook();

  //
  const [entities_log, setEntities_log] = useState([]);
  const [totalLogItems, setTotalLogItems] = useState(0);
  const {
    refetch: getOutgoingLog,
    data: logEntities
  } = useGetOutgoingLog(mainQuery);
  useEffect(() => {
    if (logEntities) {
      setEntities_log(logEntities.entities);
      setTotalLogItems(logEntities.totalItems);
    }
  }, [logEntities]);
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const [newModelactive, setNewModelactive] = useState(false);
  const toggleNewActive = useCallback(() => setNewModelactive(active => !active), []);
  useEffect(() => {
    if (outgoing_webhook_slug) {
      getOutgoingLog();
    }
  }, [mainQuery]);
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async outgoing_webhook_slug => {
    const confirmed = await showDeleteConfirm('Xóa webhook này?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      await deleteEntity(outgoing_webhook_slug);
    }
  }, []);
  const emptyData = <EmptyState heading="No log here!" image={emptyIMG}>
      <p>Thử lại sau!</p>
    </EmptyState>;
  function convertToRow(entity: any) {
    return [entity.log_id, entity.log_method, dateandtime.format(new Date(Number(entity.createdAt)), 'DD-MM-YYYY HH:mm:ss')];
  }
  function takeEventsFromEntity(entity: any) {
    let allEvent = [];
    if (typeof entity.outgoing_webhook_action !== 'undefined') for (let element of entity.outgoing_webhook_action) {
      allEvent.push(element.action_key);
    }
    return allEvent.join(', ');
  }
  const Actual_page = entity || !outgoing_webhook_slug ? <>
        <Page title="Outgoing Webhook" backAction={{
      content: 'outgoing_webhook list',
      url: '/outgoing_webhook'
    }} primaryAction={{
      content: 'Chỉnh sửa',
      disabled: false,
      icon: EditIcon,
      onAction: toggleNewActive
    }}>
          <Layout>
            <Layout.Section>
              <Card padding="0">
                {entities_log.length > 0 ? <DataTable columnContentTypes={['text', 'text', 'text']} headings={['ID', 'Method', 'Date']} rows={entities_log?.map(convertToRow)} /> : emptyData}
              </Card>
              {totalLogItems > mainQuery.limit ? <Pagination TotalRecord={totalLogItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
              <br />
              <br />
              <br />
            </Layout.Section>

            <Layout.Section variant="oneThird">
              <Card padding={'400'}>
                <BlockStack gap="400">
                  <Text as="h3" variant="headingMd">
                    Webhook details
                  </Text>
                  <Divider />

                  <Box>
                    <p style={{
                  fontWeight: 'bold'
                }}>{entity.webhook_title}</p>
                    <p>{Parser(entity.webhook_description || 'No description!')}</p>
                    <br />

                    <DescriptionList gap="tight" items={[{
                  term: 'Method',
                  description: String(entity.webhook_method).toUpperCase()
                }, {
                  term: 'Webhook URL',
                  description: String(entity.webhook_url)
                }, {
                  term: 'Created At',
                  description: dateandtime.format(new Date(Number(entity.createdAt)), 'DD-MM-YYYY HH:mm:ss')
                }, {
                  term: 'Updated At',
                  description: entity.updatedAt ? dateandtime.format(new Date(Number(entity.updatedAt)), 'DD-MM-YYYY HH:mm:ss') : <>
                              {' '}
                              <Badge></Badge> <Badge></Badge>{' '}
                            </>
                }, {
                  term: 'Last Open',
                  description: entity.lastOpen ? dateandtime.format(new Date(Number(entity.lastOpen)), 'DD-MM-YYYY HH:mm:ss') : <>
                              {' '}
                              <Badge></Badge> <Badge></Badge>{' '}
                            </>
                }, {
                  term: 'Success Open',
                  description: entity.successOpen ? dateandtime.format(new Date(Number(entity.successOpen)), 'DD-MM-YYYY HH:mm:ss') : <>
                              {' '}
                              <Badge></Badge> <Badge></Badge>{' '}
                            </>
                }, {
                  term: 'Fail Open',
                  description: entity.failOpen ? dateandtime.format(new Date(Number(entity.failOpen)), 'DD-MM-YYYY HH:mm:ss') : <>
                              {' '}
                              <Badge></Badge> <Badge></Badge>{' '}
                            </>
                }]} />
                    <br />
                    <Text fontWeight="bold" as="p">
                      Events will firing{' '}
                    </Text>
                    <p>
                      <code>{takeEventsFromEntity(entity)}</code>
                    </p>
                  </Box>

                  <Divider />
                  <Button icon={DeleteIcon} onClick={() => handleDelete(entity?.webhook_id)}>
                    DELETE
                  </Button>
                </BlockStack>
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
        <OutgoingWebhookModal show={newModelactive} onClose={() => setNewModelactive(false)} />
      </> : emptyData;
  return <>{loading ? <SkeletonLoading /> : Actual_page}</>;
}