import { Badge, Card, DataTable, InlineStack, Page, Thumbnail, Text, Link } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import crownICON from 'media/images/crown.svg';
// import medalICON from 'media/medal.svg';
// import cupICON from 'media/cup.svg';

import { PlusIcon } from '@shopify/polaris-icons';
import AddAchievementModal from './achievement.new';
import helpers from 'helpers/index';
import Pagination from 'components/pagination';
import SkeletonLoading from 'components/skeletonPageLoading';
import { TypedUserAchievement, useGetAchivements } from 'queries/user_archivement.query';
import { Helmet } from 'react-helmet-async';
import SimpleFilter from 'components/simpleFilter';
import dateandtime from 'date-and-time';
export default function AchievementList() {
  const [showModal, setShowModal] = useState(false);

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let {
    search
  } = useLocation();
  let StringQuery = helpers.ExtractUrl(search) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 100,
    sort: 'createdAt:desc'
  };
  const [mainQuery, setMainQuery] = useState({
    ...initialQuery,
    ...StringQuery
  });
  const {
    data,
    isLoading,
    refetch: getEntities
  } = useGetAchivements(mainQuery);
  const [totalItems, setTotalItems] = useState(0);
  const [entities, setEntities] = useState([]);
  useEffect(() => {
    if (data) {
      let {
        body,
        totalItems
      } = data;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [data]);

  /**
   * Change page number
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Achievement', '/achievement' + buildURLSearch);
    }
    getEntities();
  }, [mainQuery]);
  const closeModal = useCallback(() => {
    setShowModal(false);
    getEntities();
  }, []);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  return <>
      <Helmet>
        <title>Thành tích của người dùng</title>
      </Helmet>
      {isLoading ? <SkeletonLoading /> : <Page title="Thành tích" subtitle="Dành cho tất cả mọi người" primaryAction={{
      content: 'Tạo mới thành tích',
      icon: PlusIcon,
      onAction: () => setShowModal(true)
    }}>
          <Card padding="0">
            <SimpleFilter onCallback={filterCallback} options={[{
          label: 'Trao theo năm',
          value: 'yearly',
          field: 'achievement_interval'
        }, {
          label: 'Trao theo tháng',
          value: 'monthly',
          field: 'achievement_interval'
        }, {
          label: 'Trao theo tuần',
          value: 'weekly',
          field: 'achievement_interval'
        }, {
          label: 'Trao theo ngày',
          value: 'daily',
          field: 'achievement_interval'
        }, {
          label: 'Trao thưởng không tự động',
          value: 'manual',
          field: 'achievement_interval'
        }]} loading={isLoading} sortField={[{
          label: 'Số trao thưởng',
          field: 'A'
        }]} />
            <DataTable verticalAlign="middle" columnContentTypes={['text', 'numeric', 'numeric', 'numeric', 'numeric']} headings={['Tên thành tích', 'Tình trạng', 'Loại', 'Số lượng đã trao thưởng', 'Ngày tạo']} rows={Array.isArray(entities) ? entities.map((achivement: TypedUserAchievement, index) => {
          const {
            achievement_name,
            achievement_interval,
            achievement_count,
            achievement_badge,
            achievement_description,
            achievement_badge_to_media,
            achievement_id,
            achievement_status,
            createdAt
          } = achivement;
          let i = achievement_badge_to_media ? helpers.getMediaLink(achievement_badge_to_media.media_thumbnail ? achievement_badge_to_media.media_thumbnail['128x128'] : '') : crownICON;
          return [<InlineStack gap="200" align="start" blockAlign="center">
                          <Thumbnail source={i} alt={''} size="small" />
                          <Link key={'dsfg_' + index} url={`/achievement/view/${achievement_id}`} removeUnderline>
                            <Text as="span" fontWeight="bold">
                              {achievement_name}
                            </Text>
                            <Text as="p" variant="bodyXs" tone="subdued">
                              {helpers.trimMiddleString(achievement_description)}
                            </Text>
                          </Link>
                        </InlineStack>, achievement_status === 1 ? <Badge tone="success">Bật</Badge> : <Badge>Tắt</Badge>, achievement_interval, helpers.formatNumber(achievement_count), dateandtime.format(new Date(Number(createdAt)), 'DD-MM-YYYY')];
        }) : []} />
          </Card>

          {totalItems > mainQuery.limit ? <Pagination TotalRecord={totalItems} activeCurrentPage={mainQuery.page} pageSize={mainQuery.limit} onChange={onChangePagination} /> : null}
        </Page>}
      <AddAchievementModal open={showModal} onClose={closeModal} />
    </>;
}