import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import helpers from "helpers/index";
import queryClient from 'queries';


export function useAddUserSocialProfile() {
    return useMutation({
        mutationKey: ['user_social_profile/add_user_social_profile'],
        mutationFn: (entity: { social_name: string, social_profile_id: string, user_id: string }) => axios.post<any>(`user/user_social_profile`, helpers.cleanEntity(entity)),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['app/fetch_entity_list'] });
        }
    });
}


export function useDeleteUserSocialProfile() {
    return useMutation({
        mutationKey: ['user_social_profile/delete_user_social_profile'],
        mutationFn: (social__id: string) => axios.delete<any>(`/user/user_social_profile/${social__id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['app/fetch_entity_list'] });
        }
    });
}

