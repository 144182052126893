import React from 'react';
import { useParams } from 'react-router-dom';
import ListReview from './list';
import ViewReview from './view';
import Template404 from 'layout/404';
export default function ReviewIndex() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = <ListReview />;
      break;
    case 'view':
      ActualPage = <ViewReview />;
      break;
    default:
      ActualPage = <Template404 />;
      break;
  }
  return ActualPage;
}