import { Button, Checkbox, ChoiceList, Form, FormLayout, LegacyStack, Modal, Select, TextField, Text, Toast } from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { lengthLessThan, useField, useForm } from '@shopify/react-form';
import helpers from 'helpers';
import __ from 'languages/index';
import { useCreateWebhookIncoming, useMutateGetWebhookIncoming } from 'queries/webhook_incoming.query';
const {
  v4: uuidv4
} = require('uuid');

/**
 *   Create upload Modal for Incoming Webhook
 */

export default function GeneralIncomingWebhookCreate({
  onClose,
  show
}) {
  const {
    mutateAsync: createEntity,
    isPending: updating,
    isSuccess: updateSuccess
  } = useCreateWebhookIncoming();
  const {
    mutateAsync: getEntity,
    data: entity
  } = useMutateGetWebhookIncoming();

  /**
   * API role
   */
  const [api_role_seleted, setApi_role_seleted] = useState<string[]>(['user']);
  const handleChoiceListChange = useCallback(value => setApi_role_seleted(value), []);

  /**
   * Token
   */
  const [generateToken, setGenerateToken] = useState<string>('');
  const handleChangeToken = useCallback(() => {
    setGenerateToken(uuidv4());
  }, []);
  let {
    incoming_webhook_slug
  } = useParams();
  useEffect(() => {
    if (incoming_webhook_slug) {
      getEntity(incoming_webhook_slug);
    }
  }, [incoming_webhook_slug]);
  useEffect(() => {
    reset_incoming();
  }, []);
  const [checked, setChecked] = useState<boolean>(false);
  const handleChange = useCallback(newChecked => setChecked(newChecked), []);
  const [apiaccessmethod, setApiaccessmethod] = useState('post');
  const handleSelectChange = useCallback(value => setApiaccessmethod(value), []);
  const options = [{
    label: 'POST',
    value: 'post'
  }, {
    label: 'PATCH',
    value: 'patch'
  }, {
    label: 'PUT',
    value: 'put'
  }, {
    label: 'DELETE',
    value: 'delete'
  }, {
    label: 'GET',
    value: 'get'
  }];

  //Set generateToken = api_access_token
  useEffect(() => {
    if (entity) {
      setGenerateToken(entity.api_access_token);
      setChecked(entity.api_enable === 1);
      setApiaccessmethod(entity.api_access_method);
    }
  }, [entity]);
  const useFields = {
    api_slug: useField<string>({
      value: '',
      validates: [lengthLessThan(250, 'No more than 250 characters.'), inputValue => {
        if (inputValue && inputValue.length < 2) {
          return 'API slug is too short, or it is empty.';
        }
        if (inputValue && inputValue.match(/[^a-zA-Z0-9-]/g)) {
          return 'Like URL, API slug accepted only A-Z, 0-9 and "-" characters.';
        }
      }]
    }),
    api_description: useField<string>({
      value: '',
      validates: [inputValue => {
        if (inputValue.length < 2) {
          return 'API description is too short, or it is empty.';
        }
      }]
    }),
    api_name: useField<string>({
      value: '',
      validates: [lengthLessThan(100, 'No more than 100 characters.'), inputValue => {
        if (inputValue.length < 2) {
          return 'API name is too short, or it is empty.';
        }
      }]
    }),
    api_access_token: useField<string>({
      value: '',
      validates: []
    })
  };
  const {
    fields,
    submit,
    dirty,
    reset: reset_incoming
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!incoming_webhook_slug) {
          await createEntity({
            api_name: values.api_name,
            api_slug: values.api_slug || helpers.slugify(values.api_name),
            api_description: values.api_description,
            api_access_token: values.api_access_token,
            api_access_method: apiaccessmethod,
            api_role: api_role_seleted.pop(),
            api_enable: Number(checked)
          });
        }
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });

  /**
   * Log debug
   */
  useEffect(() => {
    if (updateSuccess === true) {
      return onClose();
    }
  }, [updateSuccess]);
  const Actual_page = <Modal open={show} onClose={() => onClose()} title={'Create new incoming webhook'} primaryAction={{
    content: 'Create',
    disabled: !dirty,
    loading: updating,
    onAction: submit
  }} secondaryActions={[{
    content: 'Close',
    onAction: () => onClose()
  }]}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <FormLayout>
            <TextField autoComplete="off" label="API Name *" {...fields.api_name} />

            <TextField autoComplete="off" label="API Description *" {...fields.api_description} />

            <TextField autoComplete="off" helpText="Left it empty if you do not need to something special." label="API Slug" {...fields.api_slug} />

            <LegacyStack>
              <LegacyStack.Item fill>
                <TextField autoComplete="off" label="API Token" disabled value={generateToken} />
              </LegacyStack.Item>
              <div className="change-token">
                <LegacyStack.Item>
                  <Button variant="primary" onClick={handleChangeToken}>
                    Generate
                  </Button>
                </LegacyStack.Item>
              </div>
            </LegacyStack>

            <Select label="Access method" options={options} onChange={handleSelectChange} value={apiaccessmethod} />

            <ChoiceList title="While the customer is checking out" choices={[{
            label: 'This incoming webhook should act as user',
            value: 'user',
            helpText: 'By default, all incoming webhook will act as a normal user, no special task will be performed.'
          }, {
            label: 'This incoming webhook should act as an admin',
            value: 'admin',
            helpText: 'Danger, but depend on developer, will act this incoming webhook as an admin.'
          }]} selected={api_role_seleted} onChange={handleChoiceListChange} />

            <Checkbox label="API Enable" checked={checked} onChange={handleChange} />

            <Text tone="subdued" as="p">
              {'To make a incoming webhook request, making a HTTP method to /webhook_incoming/[api_slug]/[api_access_token]'}
            </Text>
            <Text tone="subdued" as="p">
              {'To handle this incoming request, [add_action] to [pre_incoming_webhook_{api_slug}]'}
            </Text>
            <Text tone="subdued" as="p">
              In case no plugin or module handle this request, it will return 200 HTTP code with "no_handler" content.
            </Text>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>;
  return <>{Actual_page}</>;
}