import { compiledBlock as _compiledBlock } from "million/react";
const BankCard_1 = _compiledBlock(_props => <slot>
      <div className="bank_card_container">
        <header>
          <span className="logo">
            <img src={_props.v0} alt="" />
            <h5>{_props.v1}</h5>
          </span>
          <img src={_props.v2} alt="" className="chip" />
        </header>

        <div className="card-details">
          <div className="name-number">
            {_props.v3}
            <h5 className="name">{_props.v4}</h5>
          </div>
        </div>
      </div>
    </slot>, {
  name: "BankCard_1",
  portals: ["v1", "v3", "v4"]
});
const BankCard = function BankCard({
  name_of_bank = '',
  account_number = '',
  card_number = 'xxxx xxxx xxxx xxxx',
  name_on_card = 'NGUYEN VAN A',
  valid_date = new Date()
}: {
  /** Tên ngân hàng */
  name_of_bank?: string;
  /** Số tài khoản */
  account_number?: string;
  /** Hoặc số card */
  card_number: string;
  /** Tên trên tài khoản */
  name_on_card: string;
  /** Tùy chọn, không validate nó ... */
  valid_date?: Date;
}) {
  let logo = '',
    name_of_card = '';
  if (!name_on_card) name_on_card = 'NGUYEN VAN A';
  if (!__helpers.isDate(valid_date)) valid_date = new Date();

  // Xử lý card_number để thêm các placeholder "xxxx"
  let a = __helpers.parseNumeric(card_number);
  let c = String(a || ' ').match(/.{1,4}/g).join(' ');
  const cardNumberParts = c.split(' ');
  const formattedCardNumber = cardNumberParts.map(part => part.padEnd(4, 'x')).concat(Array(4).fill('xxxx')).slice(0, 4).join(' ');
  switch (detectCardType(card_number)) {
    case 'Visa':
      logo = visaLogo;
      name_of_card = 'Visa Card';
      break;
    case 'MasterCard':
      logo = masterCardLogo;
      name_of_card = 'Master Card';
      break;
    default:
      logo = unknownLogo;
      name_of_card = 'UNKNOWN';
  }
  if (!name_of_bank) name_of_bank = name_of_card;
  return /*@million jsx-skip*/<BankCard_1 v0={logo} v1={name_of_bank} v2={chipp} v3={account_number ? <>
                <h6>{__('bank_card_account_number_label')}</h6>
                <h5 className="number">{account_number}</h5>
              </> : <>
                <h6>{__('bank_card_number_label')}</h6>
                <h5 className="number">{formattedCardNumber}</h5>
              </>} v4={name_on_card} />;
};
import { block as _block } from "million/react";
import React from 'react';
import 'media/css/bankCardTemplate.scss';
import masterCardLogo from 'media/images/bank_card_mastercard_logo.png';
import visaLogo from 'media/images/bank_card_visa_logo.svg';
import unknownLogo from 'media/images/bank_card_unknown_logo.svg';
import chipp from 'media/images/bank_card_chip.png';
import __helpers from 'helpers/index';
import __ from 'languages/index';
function detectCardType(cardNumber: any) {
  // Loại bỏ khoảng trắng, nếu có
  const cleanedCardNumber = (cardNumber || ' ').replace(/\s+/g, '');

  // Kiểm tra thẻ Visa (bắt đầu bằng số 4)
  if (cleanedCardNumber.startsWith('4')) {
    return 'Visa';
  }

  // Kiểm tra thẻ MasterCard (bắt đầu bằng số từ 51 đến 55 hoặc từ 2221 đến 2720)
  const firstTwoDigits = parseInt(cleanedCardNumber.slice(0, 2), 10);
  const firstFourDigits = parseInt(cleanedCardNumber.slice(0, 4), 10);
  if (firstTwoDigits >= 51 && firstTwoDigits <= 55 || firstFourDigits >= 2221 && firstFourDigits <= 2720) {
    return 'MasterCard';
  }

  // Trả về 'Unknown' nếu không xác định được loại thẻ
  return 'Unknown';
}

/**
 * Display bankcard
 * @returns
 */
export default BankCard;