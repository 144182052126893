import { useCallback, useEffect } from 'react';
import { useField, useForm } from '@shopify/react-form';
import { Form, Modal, Select, TextField, BlockStack } from '@shopify/polaris';
import __ from 'languages/index';
import { order_transport_status, useCreateOrderTransportLog } from 'queries/order_transport_log.query';
export default function ShowCreateTransportModal({
  show = false,
  current_order_id,
  onClose
}: {
  show: boolean;
  current_order_id: string;
  onClose: () => void;
}) {
  const {
    mutateAsync: createOrderTransportLog
  } = useCreateOrderTransportLog();
  const closeModal = useCallback(() => {
    onClose?.call(this, null);
  }, []);
  const useFields = {
    transport_title: useField<string>({
      value: '',
      validates: []
    }),
    transport_content: useField<string>({
      value: '',
      validates: []
    }),
    transport_status: useField<string>({
      value: order_transport_status[0],
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        await createOrderTransportLog({
          order_id: current_order_id,
          transport_title: values.transport_title,
          transport_content: values.transport_content,
          transport_status: values.transport_status
        });
        closeModal();
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success',
        errors: []
      };
    }
  });
  useEffect(() => {
    resetForm();
  }, []);
  return <Modal open={show} title={'Thêm một dữ liệu vận chuyển'} onClose={closeModal} primaryAction={{
    content: 'Lưu lại',
    loading: submitting,
    disabled: !dirty,
    onAction: submit
  }} secondaryActions={[{
    content: 'Đóng lại',
    onAction: closeModal
  }]}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <BlockStack gap={'400'}>
            <TextField label="Tựa đề" {...fields.transport_title} autoComplete="off" />
            <TextField label="Nội dung" {...fields.transport_content} autoComplete="off" />

            <Select label="Loại thông báo" options={order_transport_status.map(el => {
            return {
              value: el,
              label: el
            };
          })} onChange={e => useFields.transport_status.onChange(e)} value={useFields.transport_status.value} />
          </BlockStack>
        </Form>
      </Modal.Section>
    </Modal>;
}