import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import { memo } from 'react';
import 'media/css/starRating.scss';

/** Size include small | default */
const StarRatingComponent_2 = _compiledBlock(_props => <div className={_props.v0}>
          <span className="star checked"></span>
          <span className="star"></span>
          <span className="star"></span>
          <span className="star"></span>
          <span className="star"></span>
        </div>, {
  name: "StarRatingComponent_2"
});
const StarRatingComponent_3 = _compiledBlock(_props2 => <div className={_props2.v0}>
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star"></span>
          <span className="star"></span>
          <span className="star"></span>
        </div>, {
  name: "StarRatingComponent_3"
});
const StarRatingComponent_4 = _compiledBlock(_props3 => <div className={_props3.v0}>
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star"></span>
          <span className="star"></span>
        </div>, {
  name: "StarRatingComponent_4"
});
const StarRatingComponent_5 = _compiledBlock(_props4 => <div className={_props4.v0}>
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star"></span>
        </div>, {
  name: "StarRatingComponent_5"
});
const StarRatingComponent_6 = _compiledBlock(_props5 => <div className={_props5.v0}>
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star checked"></span>
          <span className="star checked"></span>
        </div>, {
  name: "StarRatingComponent_6"
});
const StarRatingComponent_1 = function StarRatingComponent({
  num,
  size = 'medium'
}: {
  num: number;
  size?: string;
}) {
  num = Math.floor(num);
  switch (num) {
    case 1:
      return /*@million jsx-skip*/<StarRatingComponent_2 v0={`star_wrap ${size}`} />;
    case 2:
      return /*@million jsx-skip*/<StarRatingComponent_3 v0={`star_wrap ${size}`} />;
    case 3:
      return /*@million jsx-skip*/<StarRatingComponent_4 v0={`star_wrap ${size}`} />;
    case 4:
      return /*@million jsx-skip*/<StarRatingComponent_5 v0={`star_wrap ${size}`} />;
    case 5:
      return /*@million jsx-skip*/<StarRatingComponent_6 v0={`star_wrap ${size}`} />;
  }
};
const StarRating = memo(StarRatingComponent_1);
export default StarRating;