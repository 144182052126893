import { Page, Tabs } from '@shopify/polaris';
import { Helmet } from 'react-helmet-async';
import SocialPost from './socialPost';
import { useCallback, useEffect, useState } from 'react';
import helpers from 'helpers/index';
export default function SocialTab() {
  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => setSelected(selectedTabIndex), []);
  const tabs = [{
    id: '',
    content: 'Tất cả'
  }, {
    id: 'live',
    content: 'Thông báo'
  }, {
    id: 'business',
    content: 'Đăng bán'
  }];
  const [mode, setMode] = useState('');
  useEffect(() => {
    let find_id = tabs[selected].id || '';
    setMode(find_id);
  }, [selected]);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl({
      mode
    });
    if (window.location.search !== buildURLSearch) window.history.replaceState(null, 'Social', '/social' + buildURLSearch);
  }, [mode]);
  return <>
      <Helmet>
        <title>Social</title>
      </Helmet>
      <div>
        <Page narrowWidth>
          <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}></Tabs>
        </Page>
      </div>
      <Page narrowWidth>
        <SocialPost mode={mode} />
      </Page>
    </>;
}