import { Form, InlineGrid, Modal, TextField, Banner, SkeletonBodyText, BlockStack, Checkbox } from '@shopify/polaris';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import { useState, useEffect, useCallback } from 'react';
import { SaveIcon } from '@shopify/polaris-icons';
import __ from 'languages/index';
import { useCreateWallet, useGetWallet, useUpdateWallet } from 'queries/wallet.query';
import __helpers from 'helpers/index';
import { useNotification } from 'context/NotificationContext';

/**
 * Show popup in element ...
 * @param param0
 * @returns
 */
export default function WalletTypeModal({
  show = false,
  onClose = null,
  wallet_type_id
}: {
  show: boolean;
  wallet_type_id?: bigint | string;
  onClose?: () => void;
}) {
  const [internalError, setInternalError] = useState('');
  const [open, setOpen] = useState(false);
  const {
    addNotification
  } = useNotification();
  const {
    mutateAsync: createEntity
  } = useCreateWallet();
  const {
    mutateAsync: updateEntity,
    isPending
  } = useUpdateWallet();
  const {
    mutateAsync: getEntity,
    data: entity
  } = useGetWallet();
  useEffect(() => {
    if (show) setOpen(true);
  }, [show]);
  const onCloseCallback = useCallback(() => {
    setOpen(false);
    onClose?.call(this, null);
  }, []);
  const useFields = {
    wallet_name: useField<string>({
      value: wallet_type_id ? entity?.wallet_name : '',
      validates: [notEmpty('không được để trống trường này'), lengthLessThan(50, 'Không được dài hơn 150 ký tự.'), lengthMoreThan(1, 'Trường này yêu cầu phải dài hơn 1 ký tự.')]
    }),
    wallet_unit: useField<string>({
      value: wallet_type_id ? entity?.wallet_unit : '',
      validates: [lengthLessThan(1000, 'Không được dài hơn 100 ký tự.')]
    }),
    wallet_sign: useField<string>({
      value: wallet_type_id ? entity?.wallet_sign : '',
      validates: [lengthLessThan(1000, 'Không được dài hơn 10 ký tự.')]
    }),
    wallet_description: useField<string>({
      value: wallet_type_id ? String(entity?.wallet_description || '') : '',
      validates: [lengthMoreThan(2, 'Trường này yêu cầu phải dài hơn 2 ký tự.'), lengthLessThan(1000, 'Trường này yêu cầu phải ít hơn 1000 ký tự.')]
    }),
    is_default: useField<number>({
      value: wallet_type_id ? entity?.is_default : 0,
      validates: []
    }),
    rank_allowed: useField<number>({
      value: wallet_type_id ? entity?.rank_allowed : 0,
      validates: []
    }),
    is_allowed_withdraw: useField<number>({
      value: wallet_type_id ? entity?.is_allowed_withdraw : 0,
      validates: []
    }),
    withdraw_min_allowed: useField<number>({
      value: wallet_type_id ? entity?.withdraw_min_allowed : 0,
      validates: []
    }),
    withdraw_min_balance: useField<number>({
      value: wallet_type_id ? entity?.withdraw_min_balance : 0,
      validates: []
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!wallet_type_id) {
          await createEntity({
            wallet_name: values.wallet_name,
            wallet_description: values.wallet_description,
            wallet_unit: values.wallet_unit,
            wallet_sign: values.wallet_sign,
            is_default: values.is_default,
            rank_allowed: values.rank_allowed,
            is_allowed_withdraw: values.is_allowed_withdraw,
            withdraw_min_allowed: values.withdraw_min_allowed,
            withdraw_min_balance: values.withdraw_min_balance
          });
        } else {
          await updateEntity({
            id: wallet_type_id,
            wallet_name: values.wallet_name,
            wallet_description: values.wallet_description,
            wallet_unit: values.wallet_unit,
            wallet_sign: values.wallet_sign,
            is_default: values.is_default,
            rank_allowed: values.rank_allowed,
            is_allowed_withdraw: values.is_allowed_withdraw,
            withdraw_min_allowed: values.withdraw_min_allowed,
            withdraw_min_balance: values.withdraw_min_balance
          });
        }
        resetForm();
        onCloseCallback();
        addNotification('info', __('update_successfully'));
      } catch (e) {
        setInternalError(e?.message || 'Mã lỗi: E_8976');
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  useEffect(() => {
    if (!show) return;
    if (wallet_type_id) {
      getEntity(String(wallet_type_id));
    } else {
      resetForm();
    }
    setInternalError(null);
  }, [wallet_type_id, resetForm, show]);
  const bodyText = <Form onSubmit={submit}>
      <InlineGrid gap="200">
        <TextField label="Tên ví" autoComplete="off" requiredIndicator autoFocus {...fields.wallet_name} />
        <TextField label="Mô tả (không hiển thị cho người dùng)" autoComplete="off" multiline={4} requiredIndicator {...fields.wallet_description} />
        <TextField label="Đơn vị tính" autoComplete="off" requiredIndicator helpText={'* Không chứa ký tự đặc biệt, là tên của đơn vị tính, ví dụ: dong, kimcuong ...'} {...fields.wallet_unit} />
        <TextField label="Ký hiệu" autoComplete="off" requiredIndicator helpText={'* Ký tự của đơn vị trong ví.'} {...fields.wallet_sign} />

        <Checkbox label="Đặt làm ví mặc định" checked={useFields.is_default.value === 1} onChange={e => {
        useFields.is_default.onChange(Number(e));
      }} />

        <Checkbox label="Cho phép xếp hạng?" checked={useFields.rank_allowed.value === 1} onChange={e => {
        useFields.rank_allowed.onChange(Number(e));
      }} />

        <Checkbox label="Cho phép rút khỏi ví?" checked={useFields.is_allowed_withdraw.value === 1} onChange={e => {
        useFields.is_allowed_withdraw.onChange(Number(e));
      }} />

        {useFields.is_allowed_withdraw.value === 1 && <>
            <TextField label="Được rút số tối thiểu là..." autoComplete="off" requiredIndicator value={__helpers.formatNumberWithCommas(fields.withdraw_min_allowed.value)} onChange={v => fields.withdraw_min_allowed.onChange(__helpers.parseNumeric(v))} />
            <TextField label="Giữ trong ví số tối thiểu là..." autoComplete="off" requiredIndicator helpText={'Đây là số dư tối thiểu trong ví bị giữ lại, người dùng không thể rút số tiền này.'} value={__helpers.formatNumberWithCommas(fields.withdraw_min_balance.value)} onChange={v => fields.withdraw_min_balance.onChange(__helpers.parseNumeric(v))} />
          </>}
      </InlineGrid>
    </Form>;
  return <Modal activator={null} open={open} onClose={() => onCloseCallback()} title={wallet_type_id ? 'Sửa ví' : 'Thêm ví mới'} primaryAction={{
    content: wallet_type_id ? 'Cập nhật' : 'Lưu lại',
    icon: SaveIcon,
    loading: submitting,
    disabled: !dirty,
    onAction: submit
  }} secondaryActions={[{
    content: 'Đóng',
    disabled: submitting,
    onAction: () => onCloseCallback()
  }]}>
      <Modal.Section>
        {internalError && <>
            <Banner tone="critical" onDismiss={() => setInternalError(null)}>
              {internalError}
            </Banner>
            <br />
          </>}

        {isPending ? <BlockStack gap="500">
            <SkeletonBodyText />
            <SkeletonBodyText />
          </BlockStack> : bodyText}
      </Modal.Section>
    </Modal>;
}