import { compiledBlock as _compiledBlock } from "million/react";
const ViewReview_1 = _compiledBlock(_props => <slot>
      {_props.v0}
      {_props.v1}

      {_props.v2}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </slot>, {
  name: "ViewReview_1",
  portals: ["v0", "v1", "v2"]
});
const ViewReview = function ViewReview() {
  let {
    review_id
  } = useParams();
  const history = useNavigate();
  const {
    refetch: getEntity,
    data
  } = useGetReview(review_id);
  const {
    mutateAsync: updateEntity
  } = useUpdateReviewMeta();
  const {
    mutateAsync: deleteEntity
  } = useDeleteReview();
  const [entity, setEntity] = useState<TypedReview>(null);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  useEffect(() => {
    if (data) {
      setEntity(data);
      setActiveButtonIndex(data?.review_meta?.review_status ?? 0);
    }
  }, [data]);
  const handleReviewStatusChange = useCallback(async (index: number) => {
    await updateEntity({
      review_meta_id: entity?.review_meta?.review_meta_id,
      review_status: index
    }).catch(e => {});
    setActiveButtonIndex(index);
  }, [activeButtonIndex, entity]);
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async review_id => {
    const confirmed = await showDeleteConfirm('Xóa review này?', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      await deleteEntity(review_id);
      history('/review');
    }
  }, []);
  const [modalEdit, setModalEdit] = useState(false);
  return /*@million jsx-skip*/<ViewReview_1 v0={<Helmet>
        <title>Review</title>
      </Helmet>} v1={<Page title="Duyệt nhận xét" backAction={{
    content: 'Back',
    onAction: () => history('/review')
  }} primaryAction={{
    content: 'Chỉnh lại nội dung',
    onAction: () => setModalEdit(true)
  }}>
        <DescriptionList items={[{
      term: 'Người review',
      description: <BlockStack gap="200">
                  <InlineStack gap={'400'} align="start" blockAlign="center">
                    <Avatar size="sm" customer name={entity?.user?.display_name} initials={String(entity?.user?.display_name || 'R').charAt(0)} source={entity?.user?.user_avatar ? process.env.REACT_APP_AJAX_UPLOAD_PERMALINK + entity?.user.user_avatar : 'https://placehold.co/100x100'} />
                    <Text variant="bodyMd" fontWeight="bold" as="span">
                      <Link url={`/customer/view/` + entity?.user_id + '#tab-4'}>{entity?.user?.display_name}</Link>
                    </Text>
                  </InlineStack>

                  <Text as="p">
                    Email: {entity?.user?.user_email ?? '-'} / Phone: {entity?.user?.user_phonenumber ?? '-'} / Tên:{' '}
                    {entity?.user?.display_name ?? '-'}
                  </Text>
                </BlockStack>
    }, {
      term: 'Nội dung review',
      description: <>
                  <InlineGrid gap="400" columns={{
          xs: 1,
          md: 3
        }}>
                    {entity?.review_meta?.review_media?.map(el => {
            return <Link url={__helpers.getMediaLink(el?.review_to_media.media_url)} target="_blank">
                          <Image width={'150px'} source={__helpers.getMediaLink(el.review_to_media.media_thumbnail['512x512'])} alt={''} />
                        </Link>;
          })}
                  </InlineGrid>
                  <br />
                  <br />
                  <Text as="p" fontWeight="bold">
                    {entity?.review_meta?.review_title ?? '-'}
                  </Text>
                  <StarRating num={entity?.review_meta?.review_point ?? 5} />
                  <Text as="p">{entity?.review_meta?.review_content ?? '-'}</Text>
                </>
    }, {
      term: 'Thông tin thêm',
      description: <BlockStack gap="400">
                  <InlineStack gap={'400'} align="start" blockAlign="center">
                    <Thumbnail size="medium" source={__helpers.getMediaLink(entity?.product?.product_thumbnail_to_media ? entity?.product?.product_thumbnail_to_media.media_thumbnail ? entity?.product?.product_thumbnail_to_media.media_thumbnail['128x128'] : '' : '')} alt={''} />
                    <BlockStack>
                      <Link url={`/product/edit/` + entity?.product.product_id}>{entity?.product.product_name}</Link>
                      <StarRating num={entity?.product?.product_meta ? entity?.product?.product_meta.product_review_point : 5} />
                      {entity?.product?.product_meta && entity?.product?.product_meta.product_review_count + ' review(s)'}
                    </BlockStack>
                  </InlineStack>
                  {entity?.product_variant && <Text as="p">Khách mua phiên bản: {entity?.product_variant?.variant_name}</Text>}
                  {entity?.orders && <Text as="p">
                      Trong đơn hàng <Link url={`/order/view/${entity?.orders?.order_id}`}>{entity?.orders?.order_pnr}</Link> ngày{' '}
                      {dateandtime.format(new Date(Number(entity?.orders.createdAt)), 'DD-MM-YYYY')}
                    </Text>}
                </BlockStack>
    }, {
      term: 'Kết quả xét duyệt',
      description: <>
                  <ButtonGroup variant="segmented">
                    <Button pressed={activeButtonIndex === 1} onClick={() => handleReviewStatusChange(1)}>
                      Duyệt
                    </Button>
                    <Button pressed={activeButtonIndex === -1} onClick={() => handleReviewStatusChange(-1)}>
                      Từ chối
                    </Button>
                  </ButtonGroup>
                  <br />
                  <Text as="p">
                    Nếu bạn cho rằng review này là spam, bạn có thể{' '}
                    <Button tone="critical" onClick={() => handleDelete(review_id)}>
                      Xóa nó
                    </Button>{' '}
                    tại đây!
                  </Text>
                  <br />
                </>
    }]} />
      </Page>} v2={<EditReview entity={entity?.review_meta} show={modalEdit} onClose={() => {
    setModalEdit(false);
    getEntity();
  }} />} />;
};
import { block as _block } from "million/react";
import { Button, ButtonGroup, DescriptionList, InlineGrid, Page, Text, Link, Image, InlineStack, Avatar, BlockStack, Thumbnail } from '@shopify/polaris';
import StarRating from 'components/starRating';
import __helpers from 'helpers/index';
import { TypedReview, useDeleteReview, useGetReview, useUpdateReviewMeta } from 'queries/review.query';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import dateandtime from 'date-and-time';
import EditReview from './edit';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
export default ViewReview;