import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import queryClient from 'queries';



/*
* List of all app
*/
export function useGetBackups() {
    return useQuery({
        queryKey: ["backup/fetch_entity_list"],
        queryFn: () => axios.get<any[]>(`/utils/backup`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}


export function useCreateBackup() {
    return useMutation({
        mutationKey: ['backup/create_entity'],
        mutationFn: () => axios.post<any>(`/utils/backup`).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['backup/fetch_entity_list'] });
        }
    });
}


export function useDeleteBackup() {
    return useMutation({
        mutationKey: ['backup/delete_entity'],
        mutationFn: (file_name: string) => axios.delete<any>(`/utils/backup/${file_name}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['backup/fetch_entity_list'] });
        }

    });
}