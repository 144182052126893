import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedActivity {
    activity_id?: string;
    device_id?: string;
    activity_referrer?: string;
    activity_target?: string;
    activity_ip?: string;
    device?: any;
    createdAt?: string;
}


type IQuery = TypedActivity & IQueryParams;


/*
* List of all app
*/
export function useGetActivities(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["activity/fetch_entity_list"],
        queryFn: () => axios.get<TypedActivity[]>(`/activity${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: false,
        enabled: true,
    });
}