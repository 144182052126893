import { Badge, Button, Card, DataTable, Text } from '@shopify/polaris';
import { TypedUser_address_book, useDeleteUserAddressBooks, useGetUserAddressBooks } from 'queries/user_address_book';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PhoneIcon, DeleteIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
export default function CustomerAddressBook({
  user_id
}) {
  const {
    data
  } = useGetUserAddressBooks({
    limit: 20,
    user_id: user_id,
    sort: 'createdAt:desc'
  });
  const [entities, setEntities] = useState<TypedUser_address_book[]>(null);
  useEffect(() => {
    if (!data) return;
    let {
      totalItems,
      body
    } = data;
    setEntities(body);
  }, [data]);

  // delete ...
  const {
    mutateAsync: deleteUserAddressBook
  } = useDeleteUserAddressBooks();
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async id => {
    const confirmed = await showDeleteConfirm('Xóa bài đăng này', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      deleteUserAddressBook(id);
    }
  }, []);
  return <>
      <Card padding={'0'}>
        <DataTable stickyHeader verticalAlign="middle" columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text']} headings={['-', 'Tên liên hệ', 'Địa chỉ', 'Xã', 'Huyện', 'Tỉnh']} rows={entities?.map(element => {
        return [<Button variant="plain" icon={DeleteIcon} onClick={() => handleDelete(element.user_address_book_id)}></Button>, <>
                  <Text fontWeight="bold" as="p">
                    {element.user_fullname}
                  </Text>
                  {element.user_phonenumber && <Button variant="plain" icon={PhoneIcon}>
                      {element.user_phonenumber}
                    </Button>}
                </>, <>
                  <Badge>{element.user_address_type}</Badge> {element.user_address}
                </>, helpers.getDiaChinhAsync(element.user_address_ward)?.name_with_type, helpers.getDiaChinhAsync(element.user_address_district)?.name_with_type, helpers.getDiaChinhAsync(element.user_address_city)?.name_with_type];
      }) || []} />
      </Card>
    </>;
}